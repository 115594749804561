import TagsField from "components/fields/TagsField";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { auth } from '../../../firebase';
import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { db } from "../../../firebase";
import { ref, get, set, getDatabase, runTransaction, update } from "firebase/database";
import { Alert, AlertIcon, AlertDescription, Select } from "@chakra-ui/react";
import WrappedPaymentModal from "views/volunteer/volunteerDashboard/settings/components/PaymentModal";
import { AuthContext } from "contexts/AuthContext";
import { subscriptionOptions } from "data/ subscription";
  import { getAuth } from "firebase/auth";
const SignUpVolunteer = () => {
  const navigate = useNavigate();
  const { setUserRole, updateCompanyKey, updateContributeKey } = useContext(AuthContext);
  const defaultProfileIconUrl = "https://i.pinimg.com/736x/c0/c2/16/c0c216b3743c6cb9fd67ab7df6b2c330.jpg";
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [errorMessage, setErrorMessage] = useState('');
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [userType, setUserType] = useState("volunteer");
  const [businessName, setBusinessName] = useState("");
  const [companyKey, setCompanyKey] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isBusinessPaid, setIsBusinessPaid] = useState(false);
const user = getAuth().currentUser

  const checkUsernameExists = async (username) => {
    try {
      const usersRef = ref(db, `volunteers/byUsername/${username}`);
      const snapshot = await get(usersRef);
      return snapshot.exists();
    } catch (error) {
      console.error("Failed to check username existence:", error);
      setErrorMessage("Error checking username availability.");
      return false;  // Assume username does not exist if error occurs
    }
  };
  const createBusiness = async (user, businessName, subscriptionType) => {
    const db = getDatabase();
    const businessIdRef = ref(db, 'businessIds/nextId');
    const businessId = await runTransaction(businessIdRef, (currentId) => {
      return (currentId || 0) + 1;
    });

    const newBusinessRef = ref(db, `companies/businessId${businessId.snapshot.val()}`);
    await set(newBusinessRef, {
      name: businessName,
      ownerId: user.uid,
      subscriptionType,
      createdAt: new Date().toISOString(),
    });

    return `businessId${businessId.snapshot.val()}`;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!isTermsAccepted) {
        setErrorMessage('You must accept the terms and conditions.');
        return;
    }
    if (password.length < 6) {
        setErrorMessage('Password must be at least 6 characters long.');
        return;
    }
    const usernameExists = await checkUsernameExists(username);
    if (usernameExists) {
        setErrorMessage('Username already in use, please choose another one.');
        return;
    }

    if (userType === "business") {
        setShowPaymentModal(true); // Show payment modal instead of immediately creating the business
    } else {
        // Handle other user types similarly as needed
        finalizeRegistration();
    }
};

const finalizeRegistration = async () => {
    try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        let path = 'volunteers';
        let companyKeyToUpdate = '';

        if (userType === "business" && isBusinessPaid) {  // Check if payment was successful
            const businessId = await createBusiness(user, businessName, 'basic'); // Assuming 'basic' as the default subscription type
            path = `businesses/byName/${businessName.toLowerCase()}/members`;
            companyKeyToUpdate = businessId;
        } else if (userType === "company") {
            path = `companies/${companyKey}/members`;
            companyKeyToUpdate = companyKey;
        }

        const userProfile = {
            firstName,
            lastName,
            username,
            uid: user.uid,
            profileURL: defaultProfileIconUrl,
            contributeCode: 'CCXB',
            companyKey: companyKeyToUpdate,
            userType,
        };

        await set(ref(getDatabase(), `${path}/${user.uid}`), userProfile);
        await set(ref(getDatabase(), `volunteers/byUsername/${username}`), { uid: user.uid });

        setUserRole(userType);
        updateCompanyKey(companyKeyToUpdate);
        updateContributeKey('CCXB');
        setErrorMessage('');
        navigate("/volunteer/contribute");
    } catch (error) {
        console.error("Error creating user: ", error);
        if (error.code === 'auth/email-already-in-use') {
            setErrorMessage('Email already in use.');
        } else if (error.code === 'auth/invalid-email') {
            setErrorMessage('Invalid email.');
        } else {
            setErrorMessage('An error occurred during registration.');
        }
    }
};

const updateBusinessStatus = (status) => {
    setIsBusinessPaid(status);
    if (status) {
        setShowPaymentModal(false);
        finalizeRegistration();  // Finalize registration only after payment
    }
};

  

  return (
    <>
      <Default
        maincard={
          <div className="mb-5 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
            {/* Sign up section */}
            <div className="mt-[5vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
              <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
                Sign Up
              </h3>
              <p className="mt-[10px] ml-1 text-base text-gray-600">
                Enter your email, password and company ID to sign up!
              </p>
              <br />
              <div className="mt-6 mb-4 flex items-center gap-3">
                <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
                <p className="text-base font-medium text-gray-600"> Start Here </p>
                <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
              </div>
              <form onSubmit={onSubmit}>
                <Select value={userType} onChange={e => setUserType(e.target.value)} placeholder='Select Profile Type' className="dark:text-white mb-4">
                  <option value="volunteer">Volunteer</option>
                  <option value="business">Business Representative</option>
                </Select>
                {userType === 'business' && (
                  <InputField label="Business Name*" value={businessName} onChange={(e) => setBusinessName(e.target.value)} id={'businessName'} placeholder={"HarmonyFlow"} extra="mb-3" variant="auth" />
                )}
                {errorMessage && (
                  <Alert status="error" mb={4}>
                    <AlertIcon />
                    <AlertDescription>{errorMessage}</AlertDescription>
                  </Alert>
                )}
                <div className="mb-3 flex w-full items-center justify-center gap-4">
                  <div className="w-1/2">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="First Name*"
                      placeholder="John Smith"
                      id="firstname"
                      type="text"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </div>
                  <div className="w-1/2">
                    <InputField
                      variant="auth"
                      extra="mb-3"
                      label="Last Name*"
                      placeholder="Doe"
                      id="lastname"
                      type="text"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Email*"
                  placeholder="mail@harmonyflow.com"
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Username*"
                  placeholder="FunVolunteer1"
                  id="username"
                  type="text"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                />
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Password*"
                  placeholder="Min 8 characters"
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="mt-4 flex items-center justify-between px-2">
                  <div className="flex">
                  <Checkbox
  id="checkbox"
  checked={isTermsAccepted}
  onChange={(e) => setIsTermsAccepted(e.target.checked)}
/>
<label htmlFor="checkbox" className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white">
  By creating an account, you agree to the Terms and Conditions, and our Privacy Policy.
</label>
                  </div>
                </div>
                <button type="submit" className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                  Create my account
                </button>
                <div className="mt-3">
                  <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                    Already a member?
                  </span>
                  <a href="/auth/sign-in/default" className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white">
                    Sign In
                  </a>
                </div>
              </form>
            </div>
          </div>
        }
      />
      {showPaymentModal && (
        <WrappedPaymentModal
          isOpen={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
          updateBusinessStatus={updateBusinessStatus}
        />
      )}
    </>
  );
};

export default SignUpVolunteer;
