import React from 'react';
import IdeaGeneration from './components/MissionGeneration';


const ImpactWaveComponent = () => {
  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[18px] xl:flex-row">
 <IdeaGeneration />
  </div>
  );
};

export default ImpactWaveComponent;
