import { User } from "firebase/auth";
import React from "react";


function SingleMessage(props: {
  message: string;
  time: string;
  senderId: string;
  receiverId: string;
  currentUser: User | null;
}) {
  const { message, time, senderId, currentUser } = props;

  // Determine if the current user is the sender of the message
  const isCurrentUserSender = currentUser && senderId === currentUser.uid;

  return (
    <div
      className={`mb-3 p-5 ${
        isCurrentUserSender
          ? "bg-brand-500 text-white w-fit rounded-br-xl bg-brand-500 text-white float-right " // Sender styling (right-aligned with blue background)
          : "!bg-lightPrimary w-fit md:w-[300px] rounded-r-xl rounded-bl-xl" // Receiver styling (left-aligned with white background)
      }`}
    >
      <h1 className={`text-md pb-2 ${isCurrentUserSender ? "text-white" : "text-navy-700 dark:text-black"}`}>
        {message}
      </h1>
      <p className={`text-sm font-medium ${isCurrentUserSender ? "text-white" : "text-gray-400 dark:text-black"}`}>
        {new Date(time).toLocaleTimeString()}
      </p>
    </div>
  );
}






export default SingleMessage;

