// src/components/NewThreadForm.tsx
import React, { useEffect, useState } from 'react';
import { addDoc, collection,doc,getDoc,getDocs,serverTimestamp } from "firebase/firestore"; 
import { getFirestore, } from 'firebase/firestore';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from '@chakra-ui/react';
import { MdAdd } from 'react-icons/md';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import InputField from 'components/fields/InputField';
import firebase from '../../../../../firebase';
import auth from '../../../../../firebase';


export const NewThreadForm: React.FC<{
  categoryId: string;
  isOpen: boolean;
  onClose: () => void;
  onThreadCreated?: () => void; // Optional prop for refreshing threads
}> = ({ categoryId, isOpen, onClose, onThreadCreated }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const storage = getStorage();
  const bg = useColorModeValue('white', 'gray.800');
  const [content, setContent] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const db = getFirestore();
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [groups, setGroups] = useState([]);


  useEffect(() => {
    const fetchGroups = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user is logged in");
        return;
      }
      const userGroupsQuerySnapshot = await getDocs(collection(db, 'groups'));
      const userGroups = [];
  
      for (const groupDoc of userGroupsQuerySnapshot.docs) {
        const memberId = user.uid; // Current user's ID
        const memberDocSnapshot = await getDoc(doc(db, `groups/${groupDoc.id}/members/${memberId}`));
        if (memberDocSnapshot.exists()) {
          // The user is a member of this group
          userGroups.push({ id: groupDoc.id, ...groupDoc.data() });
        }
      }
  
      setGroups(userGroups);
      // Optionally, set the first group as the default selected group
      if (userGroups.length > 0) {
        setSelectedGroupId(userGroups[0].id);
      }
    };
  
    fetchGroups();
  }, [db]);
  

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setImage(event.target.files[0]);
    }
  };
  const handleCreateThread = async () => {
    let imageUrl = '';
    if (image) {
      const imageRef = storageRef(storage, `thread_images/${image.name}`);
      const snapshot = await uploadBytes(imageRef, image);
      imageUrl = await getDownloadURL(snapshot.ref);
    }

    const user = auth.currentUser;
    if (!user) {
      // Handle the case where the user is not logged in
      console.error("No user is logged in");
      return;
    }



    await addDoc(collection(db, 'threads'), {
      title,
      content,
      imageUrl,
      categoryId,
      groupId: selectedGroupId,
      createdAt: serverTimestamp(),
      createdBy: user.uid,

      // ...other thread data
    });
   
    setTitle('');
    setDescription('');
    setContent('');
    setImage(null);
    onClose();
    // Optionally refresh the thread list in the UI
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
<ModalOverlay />
<ModalContent>
  <ModalHeader>Create New Thread</ModalHeader>
  <ModalCloseButton />
  <ModalBody>
  <Box p={5} shadow="md" bg={bg} w="100%">
       <FormControl>
      <FormLabel>Title</FormLabel>
      <Input value={title} onChange={(e) => setTitle(e.target.value)} />
      <FormLabel>Content</FormLabel>
      <Input value={content} onChange={(e) => setContent(e.target.value)} />
      <FormLabel>Image</FormLabel>
      <Input type="file" onChange={handleImageChange} />
      <FormLabel>Group</FormLabel>
          <Select value={selectedGroupId} onChange={(e) => setSelectedGroupId(e.target.value)}>
            {groups.map(group => (
              <option key={group.id} value={group.id}>{group.title}</option> // Assuming each group has a 'name' field
            ))}
          </Select>
      {/* <Button onClick={handleCreateThread}>Create Thread</Button> */}
    </FormControl>
    </Box>
  </ModalBody>
  <ModalFooter>
    <Button  mr={3} onClick={handleCreateThread}>
      Create Thread
    </Button>
    <Button variant="ghost" onClick={onClose}>Cancel</Button>
  </ModalFooter>
</ModalContent>
</Modal>
  );
};
