import React, { useEffect, useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from "@chakra-ui/react";
import Post from "./Post"; // Assuming Post component is correctly implemented
import ProfileView from "components/actions/ProfileView";
import { getDatabase, ref, query, orderByChild, equalTo, onValue, update } from "firebase/database";
import auth from "../../../../firebase";

interface UserProfile {
  id: string;
  firstName: string;
  lastName: string;
  profileURL: string;
  username: string;
}

interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
  profile: UserProfile;
  usersData: Record<string, UserProfile>;
}

const ProfileOverviewModal: React.FC<ProfileModalProps> = ({ isOpen, onClose, profile, usersData }) => {
  const [userPosts, setUserPosts] = useState<any[]>([]);
  const [isFollowing, setIsFollowing] = useState<boolean>(false);
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (!profile.id) return;
  
    const db = getDatabase();
    const postsRef = ref(db, 'posts/');
    const userPostsQuery = query(postsRef, orderByChild('authorId'), equalTo(profile.id));
  
    onValue(userPostsQuery, (snapshot) => {
      const posts = [];
      snapshot.forEach((childSnapshot) => {
        const post = childSnapshot.val();
        if (post.authorId === profile.id) {
          posts.push({
            key: childSnapshot.key,
            ...post,
          });
        }
      });
      setUserPosts(posts);
    });
  
    return () => {
      // Cleanup if needed
    };
  }, [profile.id]);

  useEffect(() => {
    if (!currentUser) return;

    const db = getDatabase();
    const followRef = ref(db, `follows/${currentUser.uid}/${profile.id}`);
    onValue(followRef, (snapshot) => {
      setIsFollowing(snapshot.exists());
    });

    return () => {
      // Cleanup if needed
    };
  }, [currentUser, profile.id]);

  const handleFollowToggle = async () => {
    if (!currentUser) return;

    const db = getDatabase();
    const followRef = ref(db, `follows/${currentUser.uid}/${profile.id}`);

    if (isFollowing) {
      await update(followRef, null);
    } else {
      await update(followRef, { followedAt: Date.now() });
    }

    setIsFollowing(!isFollowing);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {profile.username}'s Profile
          {currentUser?.uid !== profile.id && (
            <Button onClick={handleFollowToggle} ml={4}>
              {isFollowing ? 'Unfollow' : 'Follow'}
            </Button>
          )}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProfileView profile={profile} />
          {/* {userPosts.length > 0 ? (
            userPosts.map((post) => (
              <Post key={post.key} data={post} usersData={usersData} />
            ))
          ) : (
            <p>No posts found.</p>
          )} */}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProfileOverviewModal;
