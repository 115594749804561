import React, { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Button, Select } from '@chakra-ui/react';
import FormFieldComponent from './FormFieldComponent';

export interface FormField {
  label: string;
  required: boolean;
  type: string; // 'text', 'file', 'number', 'date', 'time', 'select', etc.
  value?: string | FileList | number | string[]; // Adapt based on type
  options?: string[]; // For 'select' type
}

interface FormBuilderModalProps {
  formFields: FormField[];
  setFormFields: React.Dispatch<React.SetStateAction<FormField[]>>;
}

const FormBuilderModal: React.FC<FormBuilderModalProps> = ({ formFields, setFormFields }) => {
  const [isGroupAdded, setIsGroupAdded] = useState(false);
  const [isFieldEdited, setIsFieldEdited] = useState(false);

  const addFormField = (fieldType: string, label: string = '', options: string[] = []) => {
    const newField: FormField = { type: fieldType, label, required: false };
    if (options.length > 0 && fieldType === 'select') {
      newField.options = options;
    }
    setFormFields([...formFields, newField]);
  };

  const updateFormField = (index: number, updatedField: FormField) => {
    const newFormFields = [...formFields];
    newFormFields[index] = updatedField;
    setFormFields(newFormFields);
  };

  const removeFormField = (index: number) => {
    const newFormFields = formFields.filter((_, i) => i !== index);
    setFormFields(newFormFields);
  };

  const removeAllFields = () => {
    setFormFields([]);
    setIsGroupAdded(false); // Reset to indicate no group of fields is present
    setIsFieldEdited(false); // Reset as all fields are removed, thus none is edited
  };

  const addActivityFields = () => {
    const activityFields = [
      { type: 'text', label: 'Activity Name', required: true },
      { type: 'text', label: 'Description', required: true },
      { type: 'date', label: 'Date', required: true },
      { type: 'time', label: 'Time', required: true },
      { type: 'select', label: 'Requirements', options: ['Gloves', 'Trash Bags'], required: false },
    ];
    setFormFields([...formFields, ...activityFields]);
    setIsGroupAdded(true);
  };  

  const addResourceFields = () => {
    const resourceFields = [
      { type: 'text', label: 'Resource Name', required: true },
      { type: 'select', label: 'Type', options: ['Consumable', 'Non-consumable'], required: true },
      { type: 'number', label: 'Quantity', required: true },
      { type: 'file', label: 'Image', required: false },
    ];
    setFormFields([...formFields, ...resourceFields]);
    setIsGroupAdded(true);
  };

  return (
    <div>
      {isGroupAdded && !isFieldEdited && (
        <Button onClick={removeAllFields} colorScheme="red" variant="solid">
          Remove All Fields
        </Button>
      )}
      {formFields.map((field, index) => (
        <FormFieldComponent
          key={index}
          field={field}
          onUpdate={(updatedField: FormField) => updateFormField(index, updatedField)}
          onRemove={() => removeFormField(index)}
        />
      ))}
      <Button className='linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200' onClick={addActivityFields}>
        Create a Task
      </Button>
      <Button className='linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200' onClick={addResourceFields}>
        Add a Resource Need
      </Button>
      <Button className='linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200' onClick={() => addFormField('file')}>
        Add Image/Video
      </Button>
    </div>
  );
};

export default FormBuilderModal;
