import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";
import companyLogo from 'assets/svg/HarmonyFlow (Website Logo).png';
import cardImage from 'assets/svg/update1.png';
import './style.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const getLinkClass = (path) => {
    return location.pathname === path ? 'nav-item active' : 'nav-item';
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Apply a white background if on the Business Harmony page
  const isBusinessHarmonyPage = location.pathname === "/auth/business-harmony";

  return (
    <nav className={`navbar ${scrolled ? 'navbar-scrolled' : ''} ${isBusinessHarmonyPage ? 'navbar-white' : ''}`}>
      <div className="navbar-container">
        <Link to="/admin" className="flex items-center space-x-2 navbar-left">
          <img src={companyLogo} alt="HarmonyFlow Logo" className="h-10 w-auto mt-10 lg:mt-0" />
          <span className="text-xl font-bold tracking-wider mt-10 lg:mt-0">HarmonyFlow</span>
        </Link>
        <div className={`navbar-links hidden md:flex`}>
          <Link to="/auth/default" className={getLinkClass('/auth/default')}>Home</Link>
          <Link to="/auth/business-harmony" className={getLinkClass('/auth/business-harmony')}>Business</Link>
          <Link to="/auth/volunteer" className={getLinkClass('/auth/volunteer')}>Social Impact</Link>
          <Link to="/auth/default/pricing" className={getLinkClass('/auth/default/pricing')}>Pricing</Link>
          <Link to="/auth/sign-in/default" className={getLinkClass('/auth/sign-in/default')}>Login</Link>
          <Link to="/auth/sign-up/default" className={getLinkClass('/auth/sign-up/default')}>Sign Up</Link>
        </div>
        <button onClick={toggleMenu} className={`hamburger md:hidden p-2 focus:outline-none focus:shadow-outline ${isOpen ? 'open' : ''}`}>
          <div className="line top"></div>
          <div className="line bottom"></div>
        </button>

        {isOpen && (
          <div className="mobile-menu md:hidden">
            <h2 className="menu-heading">Business Platform</h2>
            <Link to="/auth/business-harmony" className="menu-item">Business</Link>
            <Link to="/auth/default/pricing" className="menu-item">Pricing</Link>
            <Link to="/auth/default" className="menu-item">Platform Overview</Link>
            <h2 className="menu-heading mt-4">Social Media & Responsibility Platform</h2>
            <Link to="/auth/volunteer" className="menu-item">Social Impact</Link>
            <h2 className="menu-heading mt-4">Authentication/Registration</h2>
            <Link to="/auth/sign-in/default" className="menu-item">Login</Link>
            <Link to="/auth/sign-up/default" className="menu-item">Sign Up</Link>

            {/* Card with image and text overlay */}
            <div className="card mt-8 p-4 rounded-lg shadow-lg relative">
              <img src={cardImage} alt="Update 2.0 Coming Soon" className="w-full h-60 object-cover rounded-lg" />
              <div className="overlay-text absolute inset-0 flex items-center justify-center text-white text-xl font-bold">
                Update 2.0 Coming Soon
              </div>    
              <div className="overlay-text relative inset-0 flex items-center justify-center text-white text-md">
               2024 FALL RELEASE
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
