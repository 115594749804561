import React from 'react';
import { Input, Button, Checkbox, Box, Text, useToast } from '@chakra-ui/react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestore, doc, setDoc } from 'firebase/firestore'; 
import { v4 as uuidv4 } from 'uuid'; // For unique file names

export interface FormField {
  label: string;
  required: boolean;
  type: string; // 'text', 'file', 'number', 'date', 'time', 'select', etc.
  value?: string | number | FileList | string[] | File[]; // Now includes File[]
  options?: string[]; // For 'select' type
}

export interface FormFieldComponentProps {
  field: FormField;
  onUpdate: (updatedField: FormField) => void;
  onRemove: () => void;
}

const FormFieldComponent: React.FC<FormFieldComponentProps> = ({ field, onUpdate, onRemove }) => {
  const toast = useToast();
  const storage = getStorage();
  const firestore = getFirestore();

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...field, label: e.target.value });
  };

  const handleRequiredChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ ...field, required: e.target.checked });
  };

  const handleFilesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const fileName = `${uuidv4()}-${file.name}`;
      const storageRef = ref(storage, `images/${fileName}`);

      try {
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);

        // Save the download URL to Firestore
        const fileDocRef = doc(firestore, 'files', uuidv4());
        await setDoc(fileDocRef, { fileUrl: downloadURL });

        onUpdate({ ...field, value: downloadURL });
        toast({
          title: 'File uploaded successfully',
          description: 'File URL has been saved to Firestore.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error("Error uploading file: ", error);
        toast({
          title: 'Error uploading file',
          description: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box p={4} shadow="md" borderWidth="1px" borderRadius="md" my={4} bg="white">
      {field.type !== 'file' && (
        <>
          <Input
            value={field.label}
            onChange={handleLabelChange}
            placeholder="Field Label"
            size="md"
            variant="filled"
            mb={3}
          />
          <Checkbox isChecked={field.required} onChange={handleRequiredChange} colorScheme="purple" mb={3}>
            Required
          </Checkbox>
        </>
      )}
      {field.type === 'file' && (
        <>
          <Text mb={2}>{field.label || 'Upload File'}</Text>
          <Input
            type="file"
            accept="image/*"
            onChange={handleFilesChange}
            variant="flushed"
            size="md"
          />
        </>
      )}
      <Button onClick={onRemove} mt={4} colorScheme="red" size="sm" variant="outline">
        Remove Field
      </Button>
    </Box>
  );
};

export default FormFieldComponent;
