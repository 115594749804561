import React, { useContext, useEffect, useState } from "react";
import { Link, To } from "react-router-dom";
import Dropdown from "../dropdown";
import auth from '../../firebase'
import { MdHome } from "react-icons/md";
import { IoIosCompass, IoMdNotificationsOutline } from "react-icons/io";
import { Text } from "@chakra-ui/react";
import companyLogo from 'assets/svg/HarmonyFlow (Website Logo).png'
import avatar from './../../assets/img/avatars/avatar1.png'
import { signOut } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useVolunteer } from "contexts/VolunteerContext";
import Notifications from "components/notifacations";
import { collection, getFirestore, onSnapshot, query, where } from "firebase/firestore";

const NavbarVolunteer = (props) => {
  const { brandText, routes, sidebarWidth, onOpenSidenav, ...rest } = props;
  const { fetchProfilePicture } = useVolunteer();
  const [profilePicUrl, setProfilePicUrl] = useState('');
  const navigate = useNavigate();
  const [unreadCount, setUnreadCount] = useState(0);
  const firestore = getFirestore();
  const userId = auth.currentUser?.uid;

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/');
      console.log('Signed Out!')
    })
  }
  const handleNavigation = (path: To) => {
    navigate(path);
  };

  useEffect(() => {
    if (auth.currentUser) {
      fetchProfilePicture(auth.currentUser.uid)
        .then(url => {
         setProfilePicUrl(url)
        })
        .catch(error => {
          console.error('Error fetching profile picture:', error);
        });
    }
  }, [fetchProfilePicture]);
  useEffect(() => {
    if (!userId) return;

    const notificationsRef = collection(firestore, 'notifications');
    const q = query(notificationsRef, where('userId', '==', userId), where('read', '==', false));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setUnreadCount(querySnapshot.size);
    });

    return () => unsubscribe();
  }, [userId, firestore]);

  return (
    <nav className="fixed top-0 left-0 right-0 flex w-full flex-row items-center justify-center p-2">
      <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
        <img src={companyLogo} alt="HarmonyFlow Logo" className="h-8 w-auto" /> 
        
        <Link to="/volunteer/contribute" className="icon-link">
          <p className="cursor-pointer">
            <div className="flex flex-col items-center justify-center">
              <MdHome className="h-4 w-4 text-gray-600 dark:text-white" />
              <Text className="text-sm text-gray-800 dark:text-white hover:dark:text-white">Home</Text>
            </div>
          </p>
        </Link>

        <Dropdown
          button={
            <p className="cursor-pointer">
              <div className="flex flex-col items-center justify-center">
                <IoIosCompass className="h-4 w-4 text-gray-600 dark:text-white" />
                <Text className="text-sm text-gray-800 dark:text-white hover:dark:text-white">Explore</Text>
              </div>
            </p>
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">Community</p>
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20" />
              <div className="mt-3 ml-4 flex flex-col">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation('/volunteer/impactWave');
                  }}
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  ImpactWave
                </a>
                <a
                  href="#"
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                  onClick={(e) => {
                    e.preventDefault();
                    handleNavigation('/volunteer/groups');
                  }}
                >
                  Groups
                </a>
              </div>
            </div>
          }
          classNames={"py-2 top-8 -left-[180px] w-max"}
        />

<Dropdown
          button={
            <p className="cursor-pointer">
              <div className="relative flex flex-col items-center justify-center">
                <img className="h-10 w-10 rounded-full" src={profilePicUrl || avatar} alt="Profile" />
                {unreadCount > 0 && (
                  <span className="absolute top-0 right-0 inline-block h-2 w-2 rounded-full bg-red-500"></span>
                )}
              </div>
            </p>
          }
          children={
            <div className="flex h-max w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
              <div className="mt-3 ml-4">
                <div className="flex items-center gap-2">
                  <p className="text-sm font-bold text-navy-700 dark:text-white">
                    👋 Hey, {auth.currentUser.email}
                  </p>
                </div>
              </div>
              <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20" />
              <div className="mt-3 ml-4 flex flex-col">
                <a
                  onClick={() => handleNavigation('profile/settings')}
                  className="text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Profile Settings
                </a>
                <a
                  onClick={() => handleNavigation('billing')}
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Billing
                </a>
                <a
                  onClick={() => handleNavigation('notifications')}
                  className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                >
                  Notifications
                </a>
                <div className="mt-3 flex flex-col gap-3">
                  <Notifications userId={userId} />
                </div>
                <button
                  className="mt-3 text-sm font-medium text-red-500 hover:text-red-500"
                  onClick={handleLogout}
                >
                  Log Out
                </button>
              </div>
            </div>
          }
          classNames={"py-2 top-8 right-0 w-max"}
        />
      </div>
    </nav>
  );
};

export default NavbarVolunteer;
