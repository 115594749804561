import React, { useState } from 'react';
import Footer from "components/footer/FooterAuthDefault";
import NavbarAuth from "views/auth/signUp/NavbarAuth";

function Default(props) {
  const { maincard, displayImage = false } = props;
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative flex flex-col min-h-screen w-full overflow-x-hidden overflow--hidden">
      <NavbarAuth />
      <div className="flex flex-1 mx-auto w-full max-w-screen-xl justify-center px-4 py-8 page-content">
        <div className="w-full lg:w-[48%] xl:w-full content-wrapper">
          {maincard}
        </div>
        {displayImage && (
          <div className="hidden lg:flex lg:w-[49vw] xl:w-[44vw] h-full min-h-screen bg-cover bg-no-repeat absolute right-0">
            {/* Image goes here */}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default Default;
