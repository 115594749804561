import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button } from "@chakra-ui/react";
import EventForm from "./EventForm";
import  { db, auth} from "../../firebase"
import { onValue,ref, set ,push, update, remove} from "firebase/database";
import { useEffect } from "react";
function EventCalendar({ calendarData, initialDate, onDateSelect }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState(null);

  const handleDateSelect = (selectInfo: any) => {
    setSelectedInfo(selectInfo);
    setIsModalOpen(true);
  };


  // Assuming you have a component <EventForm /> for event details input
  const handleSubmit = (eventDetails) => {
  const currentUser = auth.currentUser;
  if (!currentUser) return;

  let refPath = `events/${currentUser.uid}`;
  let eventRef;
  
  if (eventDetails.id) {
    // Update existing event
    eventRef = ref(db, `${refPath}/${eventDetails.id}`);
    update(eventRef, eventDetails)
      .then(() => console.log("Event updated successfully"))
      .catch((error) => console.error("Error updating event:", error));
  } else {
    // Add new event
    eventRef = push(ref(db, refPath));
    set(eventRef, eventDetails)
      .then(() => console.log("New event added successfully"))
      .catch((error) => console.error("Error adding new event:", error));
  }
};

  return (
    <>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        contentHeight="600px"
        events={calendarData}
        initialDate={initialDate}
        editable={true}
        selectable={true}
        select={handleDateSelect}
      />
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Event</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <ModalBody>
  <EventForm onSubmit={handleSubmit}  />
</ModalBody>

          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={() => setIsModalOpen(false)}>
              Close
            </Button>
            {/* Your form's submit button will be here, assuming it calls handleSubmit when clicked */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EventCalendar;
