import { initializeApp } from "firebase/app";
import { getAuth,onAuthStateChanged, signOut,signInWithEmailAndPassword,NextOrObserver,User } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { getDatabase, ref, push, child,remove,update, onValue } from "firebase/database";
import { getFunctions,connectFunctionsEmulator } from "firebase/functions";
import { enableIndexedDbPersistence } from "firebase/firestore";


import { getStorage } from "firebase/storage";
import express from "express";

import firebase from 'firebase/compat/app';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  const db = getDatabase(app)
  export const functions = getFunctions(app);
 
  const firestore = getFirestore(app)

  const storage = getStorage(app)
  export const handleUserSubmit = async (event: { preventDefault: () => void; target: { elements: { username: { value: any; }; email: { value: any; }; firstName: { value: any; }; lastName: { value: any; }; job: { value: any; }; aboutMe: { value: any; }; }; }; }) => {
    event.preventDefault(); // prevent the page from refreshing
  
    // get the form values
    const username = event.target.elements.username.value;
    const email = event.target.elements.email.value;
    const firstName = event.target.elements.firstName.value;
    const lastName = event.target.elements.lastName.value;
    const job = event.target.elements.job.value;
    const aboutMe = event.target.elements.aboutMe.value;
 
    // save the user's information to Firebase
   
 
  };
  const getCurrentUserCompany = (currentUser: any, setUserEmail: any, setCompanyKey: any, setUserKey: any) => {
    const email = currentUser.email || '';
 
    if (email) {
      setUserEmail(email);
      const usersRef = ref(db, 'companies');
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                setUserKey(userKey);
                localStorage.setItem('companyKey', companyKey);
            
              }
            }
          }
        }
      });
    }
  };
  export const signInUser = async (
    email: string, 
    password: string
  ) => {
    if (!email && !password) return;

    return await signInWithEmailAndPassword(auth, email, password)
  }

  enableIndexedDbPersistence(firestore)
  .catch((err) => {
    if (err.code == 'failed-precondition') {
        console.error("Multiple tabs open, persistence can only be enabled in one tab at a time.");
    } else if (err.code == 'unimplemented') {
        console.error("The current browser does not support all of the features required to enable persistence");
    }
});
  
  export const userStateListener = (callback:NextOrObserver<User>) => {
    return onAuthStateChanged(auth, callback)
  }

  export const SignOutUser = async () => await signOut(auth);


  export {  db, getCurrentUserCompany,ref,storage,firestore,};

 export const auth = getAuth(app);
  
export default auth ;


