import InputField from "components/fields/InputField";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Tooltip,
  Box,
  Button,
} from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';

const CampaignSettings = () => {
  const [campaign, setCampaign] = useState({
    name: '',
    segment: 'Engaged Users',
    /* ...other fields... */
  });

  
  useEffect(() => {
    localStorage.setItem('campaignData', JSON.stringify(campaign));
  }, [campaign]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    setCampaign({ ...campaign, [name]: value });
  };

  const segmentSuggestions = ['Engaged Users', 'New Users', 'Lapsed Users'];

  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [file, setFile] = React.useState(null);

  const handleFileChange = (e: { target: { files: any[]; }; }) => {
    setFile(e.target.files[0]);
  };



  return (
    <Box p={5} >
    <FormControl id="campaignName" isRequired>
      <FormLabel>Campaign Name</FormLabel>
      <Input
        name="name"
        placeholder="Enter Campaign Name"
        value={campaign.name}
        onChange={handleInputChange}
      />
    </FormControl>

    <FormControl id="segment" mt={4}>
      <FormLabel>
        <Tooltip label="Select a user segment for this campaign" aria-label="Segment Tooltip">
          <span>Segment</span>
        </Tooltip>
      </FormLabel>
      <Select
        name="segment"
        value={campaign.segment}
        onChange={handleInputChange}
      >
        {segmentSuggestions.map((seg, idx) => (
          <option key={idx} value={seg}>
            {seg}
          </option>
        ))}
      </Select>
    </FormControl>

    {/* ...other form controls... */}

  </Box>
);
};
export default CampaignSettings;
