import { SetStateAction, useState } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import TagsField from "components/fields/TagsField";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";

interface TeamAndVolunteersProps {
  companyKey: string;
  missionId: string;
  teamDetails: {
    teamContact: string;
    teamLeader: string;
    team: string;
    volunteerRequirements: string;

  };
  setTeamDetails: (details: SetStateAction<TeamAndVolunteersProps["teamDetails"]>) => void;
  saveTeamDetails: Function;
}

const TeamAndVolunteers = ({
  companyKey,
  missionId,
  teamDetails,
  setTeamDetails,
  saveTeamDetails,
}: TeamAndVolunteersProps) => {
  const { teamContact, teamLeader, team, volunteerRequirements } = teamDetails;

  const handleInputChange = (field: keyof TeamAndVolunteersProps["teamDetails"], value: any) => {
    setTeamDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Team and Volunteers Info
      </h4>

      {/* content */}
      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-4"
            label="Team Contact"
            placeholder="eg. Volunteer Director"
            id="Contact"
            type="text"
            value={teamContact}
            onChange={(e: any) => handleInputChange("teamContact", e.target.value)}
          />
       
        </div>
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-3"
            label="Team Leader"
            placeholder="eg. Volunteer Manager"
            id="Manager"
            type="text"
            value={teamLeader}
            onChange={(e: any) => handleInputChange("teamLeader", e.target.value)}
          />
          <FormControl id="team" mt={4}>
            <FormLabel>Team</FormLabel>
            <Select
              value={team}
              onChange={(e: any) => handleInputChange("team", e.target.value)}
              placeholder="Select Team"
              bgColor="F7FAFC"
              _placeholder={{ color: "gray.400" }}
            >
              <option value="Executive Team">Executive Team</option>
              <option value="Development/Fundraising Team">Development/Fundraising Team</option>
              <option value="Marketing/Communications Team">Marketing/Communications Team</option>
              <option value="Programs/Services Team">Programs/Services Team</option>
              <option value="Volunteer Management Team">Volunteer Management Team</option>
              <option value="Finance/Accounting Team">Finance/Accounting Team</option>
              <option value="Human Resources Team">Human Resources Team</option>
              <option value="Technology/IT Team">Technology/IT Team</option>
              <option value="Operations/Administration Team">Operations/Administration Team</option>
              <option value="Board of Directors">Board of Directors</option>
            </Select>
          </FormControl>
          <TextField
            label="Volunteer Requirements"
            placeholder="Short description about what needs to be done for a successful mission!"
            id="volunteerrequirements"
            cols={30}
            rows={7}
            state={volunteerRequirements}
            onChange={(e: any) => handleInputChange("volunteerRequirements", e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamAndVolunteers;
