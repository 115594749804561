import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { MdArrowDropUp } from "react-icons/md";
import { doc, setDoc, onSnapshot } from "firebase/firestore";
import Card from "components/card";
import LineChart from "components/charts/LineChart";
import { lineChartOptionsOverallRevenue } from "variables/charts";
import { getFirestore } from "firebase/firestore";
const OverallRevenue = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [inputValue, setInputValue] = useState("");
  const [overallRevenue, setOverallRevenue] = useState(37500); // Default revenue value in cents
  const [chartData, setChartData] = useState([{ name: "Revenue", data: [] }]);
  const db = getFirestore();
  useEffect(() => {
    // Listen for real-time updates from Firebase
    const docRef = doc(db, "companies", "YOUR_COMPANY_ID"); // Replace with dynamic user company ID
    const unsubscribe = onSnapshot(docRef, (docSnap) => {
      if (docSnap.exists()) {
        const revenueData = docSnap.data().overallRevenueData || [];
        setOverallRevenue(docSnap.data().overallRevenue || 37500);
        setChartData([{ name: "Revenue", data: revenueData }]);
      } else {
        console.log("No such document!");
      }
    });

    return () => unsubscribe();
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSave = async () => {
    const revenue = parseFloat(inputValue) * 1000; // Convert to cents

    // Update Firebase
    const docRef = doc(db, "companies", "YOUR_COMPANY_ID"); // Replace with dynamic user company ID
    const docSnap = await setDoc(docRef, { 
      overallRevenue: revenue,
      overallRevenueData: [...chartData[0].data, revenue / 1000] // Update the data array
    }, { merge: true });

    onClose();
  };

  return (
    <Card extra={"h-[381px] pb-8 px-6 pt-6"}>
      <div className="flex justify-between px-3 pt-1">
        <div className="flex items-center">
          <div className="flex flex-col">
            <p className="text-[34px] font-bold text-navy-700 dark:text-white">
              ${overallRevenue / 1000}K
            </p>
            <p className="text-sm font-medium text-gray-600">Overall Revenue</p>
          </div>
          <div className="ml-4 flex items-end pb-2">
            <MdArrowDropUp className="font-medium text-green-500" />
            <span className="text-sm font-bold text-green-500">+2.45%</span>
          </div>
        </div>

        <div className="flex items-center justify-center">
          <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="weekly">Weekly</option>
          </select>
          <Button onClick={onOpen} >
            Input Data
          </Button>
        </div>
      </div>

      {/* Charts */}
      <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="h-full w-full">
          <LineChart
            chartData={chartData}
            chartOptions={lineChartOptionsOverallRevenue}
          />
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Input Revenue Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              placeholder="Enter revenue in thousands"
              value={inputValue}
              onChange={handleInputChange}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSave}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}

export default OverallRevenue;
