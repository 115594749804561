import React, { useState, useEffect } from 'react';
import { Box, Button, useDisclosure } from '@chakra-ui/react';
import { getFirestore, collection, query, where, getDocs, orderBy } from 'firebase/firestore'; 
import Thread from './Threads';
import { useParams, useNavigate } from 'react-router-dom';
import { NewThreadForm } from '../components/NewThreadForm';

const ThreadList: React.FC = () => {
  const [threads, setThreads] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const db = getFirestore();
  const { categoryId } = useParams();
  
  // Fetch threads and their replies
  const fetchThreadsAndReplies = async () => {
    if (!categoryId) return;

    const threadsQuery = query(
      collection(db, 'threads'),
      where("categoryId", "==", categoryId),
      orderBy("createdAt", "desc")
    );
    const threadsSnapshot = await getDocs(threadsQuery);

    const threadsWithRepliesPromises = threadsSnapshot.docs.map(async (threadDoc) => {
      const threadData = threadDoc.data();
      const repliesQuery = query(
        collection(db, 'threads', threadDoc.id, 'replies'),
        orderBy("createdAt", "asc")
      );
      const repliesSnapshot = await getDocs(repliesQuery);
      
      const replies = repliesSnapshot.docs.map(replyDoc => ({
        id: replyDoc.id,
        ...replyDoc.data()
      }));

      return {
        ...threadData,
        id: threadDoc.id,
        replies, // Add replies directly to the thread object
      };
    });

    const threadsWithReplies = await Promise.all(threadsWithRepliesPromises);
    setThreads(threadsWithReplies);
  };

  useEffect(() => {
    fetchThreadsAndReplies();
  }, [categoryId, db]);

  // Function to refresh the thread list
  const refreshThreads = () => {
    fetchThreadsAndReplies();
  };

  // Navigate back to the previous page
  const goBack = () => navigate(-1);

  return (
    <Box className='z-5 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none  w-full h-full !p-4 3xl:p-![18px] '>
      <Button mt={2} mr={4} ml={2} onClick={goBack}>Back</Button>
      <Button mt={2} onClick={onOpen}>Create New Thread</Button>
      {threads.map(thread => (
        <Thread key={thread.id} threadId={thread.id}  />
      ))}
      {isOpen && <NewThreadForm isOpen={isOpen} onClose={onClose} categoryId={categoryId} onThreadCreated={refreshThreads} />}
    </Box>
  );
};

export default ThreadList;
