import { useDisclosure } from "@chakra-ui/hooks";
import { onValue, get, ref, set, update } from "firebase/database";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Banner from "./components/Banner";
import auth, { db } from "../../../../../firebase";
import BusinessLinker from "./components/BusinessLinker";
import DashboardsCarInterface from "views/admin/dashboards/carInterface";
import Notification from "./components/Notification";
import Project from "./components/Project";

type CompanyData = {
  name: string;
  type: string;
  description?: string;
  location?: string;
  industry?: string;
  founded?: string;
  website?: string;
  profileImage?: string;
  bannerImage?: string;
  csr?: object;
  galleryImages?: object;
  tags?: object;
  team?: object;
  companyCode?: string;
  contributeCode?: string;
};

type Mission = {
  id: string;
  missionDetails: {
    missionName: string;
    location: string;
    missionCategory: string;
    missionDescription: string;
    missionGoals: string;
    teamAndVolunteers: {
      teamContact: string;
      teamLeader: string;
      team: string;
      volunteerRequirements: string;
    };
    missionLogistics: {
      missionPartnerships: string;
      missionResources: string;
      missionStartDate: string;
    };
  };
};

const ProfileOverview = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [entityKey, setEntityKey] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentUser = auth.currentUser;
  const [userEmail, setUserEmail] = useState("");
  const [userKey, setUserKey] = useState("");
  const [companyData, setCompanyData] = useState<Partial<CompanyData> | null>(null);
  const [missionData, setMissionData] = useState<Mission[] | null>(null);
  const [businessData, setBusinessData] = useState([]);

  const { companyId } = useParams();

  useEffect(() => {
    const storedCompanyKey = localStorage.getItem("companyKey");
    if (storedCompanyKey) {
      setEntityKey(storedCompanyKey);
    }

    if (companyId) {
      const companyRef = ref(db, `companies/${companyId}/data`);
      onValue(companyRef, (snapshot) => {
        setCompanyData(snapshot.val());
      });

      const linkedBusinessesRef = ref(db, `companies/${companyId}/businessesLinked`);
      onValue(linkedBusinessesRef, (snapshot) => {
        const businessIds = snapshot.val();
        if (businessIds) {
          const businessPromises = Object.keys(businessIds).map(businessId =>
            get(ref(db, `businesses/byName/${businessId}/info`)).then(res => ({ ...res.val(), id: businessId }))
          );
          Promise.all(businessPromises).then(results => {
            setBusinessData(results);
          });
        }
      });
    }
  }, [companyId]);

  const handleLinkBusiness = async (businessId, businessName) => {
    if (!businessId || !businessName) {
      console.error('Invalid business ID or business name');
      return;
    }

    const companyId = entityKey;
    if (companyId) {
      try {
        const companySnapshot = await get(ref(db, `companies/${companyId}/data`));
        const companyData = companySnapshot.val();

        if (!companyData || !companyData.name) {
          console.error('Company data or company name is undefined');
          return;
        }

        const businessRef = ref(db, `businesses/byName/${businessName}`);
        const businessSnapshot = await get(businessRef);
        if (!businessSnapshot.exists()) {
          // Create a new business with the full company data structure if it doesn't exist
          await set(businessRef, {
            analytics: {},
            cards: {},
            csrGoals: {},
            info: {
              description: companyData.description,
              industry: companyData.industry,
              location: companyData.location,
              name: companyData.name,
              profileImage: companyData.profileImage,
              website: companyData.website
            },
            team: {}
          });
        }

        const updates = {};
        updates[`businesses/byName/${businessName}/linkedCompanies/${companyId}`] = {
          name: companyData.name,
          companyId: companyId,
        };
        updates[`companies/${companyId}/businessesLinked/${businessId}`] = {
          name: companyData.name,
          businessId: businessId,
        };

        await update(ref(db), updates);
        setBusinessData(prevData => [...prevData, { id: businessId, name: companyData.name }]);
      } catch (error) {
        console.error('Error linking business:', error);
      }
    } else {
      console.error('No company key found in local storage');
    }
  };

  const handleUnlinkBusiness = async (businessId) => {
    if (!businessId) {
      console.error('Invalid business ID');
      return;
    }

    const companyId = entityKey;
    if (companyId) {
      try {
        const updates = {};
        updates[`businesses/byName/${businessId}/linkedCompanies/${companyId}`] = null;
        updates[`companies/${companyId}/businessesLinked/${businessId}`] = null;

        await update(ref(db), updates);
        setBusinessData(prevData => prevData.filter(business => business.id !== businessId));
      } catch (error) {
        console.error('Error unlinking business:', error);
      }
    } else {
      console.error('No company key found in local storage');
    }
  };

  useEffect(() => {
    const email = currentUser?.email || "";
    const uid = currentUser?.uid || "";
    if (email && uid) {
      setUserEmail(email);

      const userCompanyRef = ref(db, `users/${uid}`);
      get(userCompanyRef).then((snapshot) => {
        if (snapshot.exists()) {
          const userData = snapshot.val();
          const foundEntityKey = userData.companyId;
          if (foundEntityKey) {
            setEntityKey(foundEntityKey);
            setUserKey(foundEntityKey);
            localStorage.setItem("companyKey", foundEntityKey);
          } else {
            console.warn("No company associated with this user.");
          }
        } else {
          console.warn("User not found.");
        }
      }).catch((error) => {
        console.error("Error fetching user data:", error);
      });
    } else {
      console.warn("No current user or email.");
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchMissionData = async () => {
      if (entityKey) {
        console.log("Fetching missions for entity key:", entityKey);
        const missionsRef = ref(db, `companies/${entityKey}/missions/`);
        onValue(missionsRef, (snapshot) => {
          const missions = snapshot.val();
          if (missions) {
            const missionArray: Mission[] = Object.values(missions);
            setMissionData(missionArray);
          } else {
            console.warn("No missions found in companies node.");
            setMissionData([]);
          }
        });
      } else {
        console.warn("Entity key is null, cannot fetch mission data.");
      }
    };

    fetchMissionData();
  }, [entityKey]);

  return (
    <div className="flex w-full flex-col gap-5 lg:gap-5">
      <div className="w-full mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-2">
        <div className="col-span-2 lg:!mb-0">
          <Banner companyKey={entityKey} setCompanyData={setCompanyData} />
        </div>
        <div className="z-0 col-span-2 lg:!mb-0">
          <DashboardsCarInterface />
        </div>
      </div>
      {/* all project & ... */}
      <div className="mb-4 grid h-full grid-cols-1 gap-5 lg:grid-cols-12">
      <div className="col-span-12 lg:col-span-12 lg:mb-0">
          <BusinessLinker
            linkedBusinesses={businessData}
            onLink={handleLinkBusiness}
            onUnlink={handleUnlinkBusiness}
          />
          </div>
        <div className="col-span-12 lg:col-span-6 lg:mb-0">
          <Project missionData={missionData || []} />
        </div>
        <div className="col-span-12 lg:col-span-6 lg:mb-0">
          <Notification />
        </div>
       
        
      </div>
    </div>
  );
};

export default ProfileOverview;
