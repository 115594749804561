import React, { useEffect, useState } from 'react';
import { VStack, Heading, Text, Box, Button, Input, useColorModeValue, Avatar, Flex, HStack, Stack, Tag, Divider, Icon,Image, useDisclosure, Modal, ModalContent, ModalOverlay, CloseButton } from '@chakra-ui/react';
import { doc, onSnapshot, collection, addDoc, serverTimestamp, getDocs, query, orderBy, getDoc, increment, updateDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import auth, { ref } from '../../../../../firebase'; // Ensure correct import path
import { formatDistanceToNow } from 'date-fns';
import Card from 'components/card';
import { onValue, get, getDatabase } from 'firebase/database';
import { FiMessageCircle, FiSend, FiThumbsUp } from 'react-icons/fi';
import Reply from './Reply';
import ProfileView from 'components/actions/ProfileView';


interface Profile {
  volunteerName?: {
    firstName: string;
    lastName: string;
  };
  firstName:string
  lastName:string
  profileURL?: string;
  username?: string;
  job?: string;
  aboutMe?: string;
}

interface ReplyType {
  id: string;
  content: string;
  createdAt: Date; // or firebase.firestore.Timestamp
  createdBy: string;
  parentReplyId?: string;
  upvotes: number;
  downvotes: number;
  threadId: string; // Add this to the props
}

interface VolunteerType {
  firstName: string;
  lastName: string;
  profileURL: string;
  username: string;
}
export const Thread: React.FC<{ threadId: string }> = ({ threadId }) => {
  const [thread, setThread] = useState({
    createdBy: '',
    title: '',
    content: '',
    replies: [] as ReplyType[],
    imageUrl: undefined,
    createdAt: new Date(), // Initialize with a default Date object
  });
  const [volunteerInfo, setVolunteerInfo] = useState<{ [key: string]: VolunteerType }>({});
  const [newReply, setNewReply] = useState('');
  const db = getFirestore();
  const cardBg = useColorModeValue('whiteAlpha.900', 'gray.700'); // Example use of color mode value
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900');
  const db2 = getDatabase();
  const [creatorInfo, setCreatorInfo] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedProfile, setSelectedProfile] = useState<Profile | null>(null);


  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, "threads", threadId), (doc) => {
      const data = doc.data();
      if (data) {
        setThread({
          title: data.title,
          content: data.content,
          replies: [],
          imageUrl: data.imageUrl,
          createdBy: data.createdBy,
          createdAt: data.createdAt
        });
      }
    });

    return () => unsubscribe();
  }, [db, threadId]);

  useEffect(() => {
    const fetchVolunteerInfo = async (uid: string) => {
      const volunteerRef = ref(db2, `volunteers/${uid}`);
      const snapshot = await get(volunteerRef);
      if (snapshot.exists()) {
        setVolunteerInfo(prev => ({
          ...prev,
          [uid]: snapshot.val(),
        }));
      }
    };

    // Unique UIDs for fetching volunteer information
    thread.replies.forEach(reply => {
      fetchVolunteerInfo(reply.createdBy);
    });
  }, [db2, thread.replies]);

  useEffect(() => {
    if (thread.createdBy) {
      const creatorRef = doc(db, `volunteers/${thread.createdBy}`);
      
      const unsubscribe = onSnapshot(creatorRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          setCreatorInfo(docSnapshot.data());
        } else {
          console.log("No data available for creator.");
        }
      });
  
      return () => unsubscribe();
    }
  }, [db, thread.createdBy]);
  
  

  useEffect(() => {
    const repliesRef = collection(db, 'threads', threadId, 'replies');
    const q = query(repliesRef, orderBy('createdAt', 'asc'));
  
    const unsubscribe = onSnapshot(q, querySnapshot => {
      const replies = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as ReplyType,
      }));
      setThread(currentThread => ({ ...currentThread, replies }));
    });
  
    return () => unsubscribe();
  }, [db, threadId]);
  

  useEffect(() => {
    const fetchReplies = async () => {
      const repliesRef = collection(db, 'threads', threadId, 'replies');
      const q = query(repliesRef, orderBy('createdAt', 'asc'));
  
      const querySnapshot = await getDocs(q);
      const newReplies = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as ReplyType,
      }));
  
      // Only update if there are changes
      setThread(currentThread => {
        const existingReplyIds = new Set(currentThread.replies.map(r => r.id));
        const updatedReplies = [...currentThread.replies];
        
        newReplies.forEach(reply => {
          if (!existingReplyIds.has(reply.id)) {
            updatedReplies.push(reply);
          }
        });
  
        return { ...currentThread, replies: updatedReplies };
      });
    };
  
    fetchReplies();
  }, [db, threadId]);
  
  
  const handleAddReply = async () => {
    if (!newReply.trim()) return;
  
    await addDoc(collection(db, 'threads', threadId, 'replies'), {
      content: newReply,
      createdAt: serverTimestamp(),
      createdBy: auth.currentUser?.uid,
    });
  
    setNewReply('');
   
  };
   
  const replyBgColor = useColorModeValue('gray.50', 'gray.700');

  const handleNewNestedReply = async (parentReplyId: any, newReplyContent: any) => {
    // Assuming you have a way to get the current user's ID
    if (!auth.currentUser) {
      // Handle the case where the user is not authenticated
      console.log('User must be logged in to reply.');
      return;
    }
  
    const newReplyData = {
      content: newReplyContent,
      createdAt: serverTimestamp(),
      createdBy: auth.currentUser.uid,
      parentReplyId: parentReplyId,
      upvotes: 0,
      downvotes: 0,
      // include other necessary fields
    };
  
    // Add the new reply to Firestore under the thread's 'replies' collection
    await addDoc(collection(db, 'threads', threadId, 'replies'), newReplyData);
  };

 const handleProfileClick = (profile: Profile) => {
    setSelectedProfile(profile); // Set the selected profile
    onOpen(); // Open the modal
  };

  return (
    <VStack spacing={4} align="stretch" className='mt4'>
    {/* Thread Card */}
    <Box bg={cardBg} color={textColor} p={6} shadow="md" rounded="lg" mb={4} className='w-full h-full px-[30px] py-[21px] mb-4 z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none'>
      {/* Thread Creator Info */}
      {creatorInfo && (
        <Flex align="center" mb={4}>
          <Avatar src={creatorInfo.profileURL} name={`${creatorInfo.firstName} ${creatorInfo.lastName}`} size="md" mr={4}  onClick={() => handleProfileClick(volunteerInfo[creatorInfo.createdBy])}/>
          <VStack align="start" >
            <Text fontWeight="bold"  onClick={() => handleProfileClick(volunteerInfo[creatorInfo.createdBy])}>{`${creatorInfo.firstName} ${creatorInfo.lastName}`}</Text>
            {/* <Text fontSize="sm" color="gray.500"> {thread.createdAt ? formatDistanceToNow(thread.createdAt, { addSuffix: true }) : 'Unknown time'}
</Text> */}
          </VStack>
        </Flex>
      )}
      {/* Thread Title & Content */}
      <Text fontWeight="bold" fontSize="xl" mb={2}>{thread.title}</Text>
      <Text mb={4}>{thread.content}</Text>
      {/* Thread Image if exists */}
      {thread.imageUrl && (
        <Image src={thread.imageUrl} alt="Thread image" rounded="md" mb={4} />
      )}
      {/* Reply Preview */}
      {thread.replies.map((reply) => (
          <Flex direction="column" key={reply.id}>
          <Box pl={4} borderLeft="2px solid grey">
          <Reply
        reply={{
          ...reply,
          user: {
            firstName: creatorInfo?.firstName, // Use optional chaining here
            profileURL: creatorInfo?.profileURL, // And here
          },
        }}
        allReplies={thread.replies}
        threadId={threadId}
      />
  </Box>
  </Flex>
))}
      {/* View More Replies Button */}
{/*     
        <Button variant="link" colorScheme="blue" onClick={() => console.log("See more replies")} mt={4}>
          See more replies
        </Button> */}
   
      {/* Reply Input */}
      <Flex mt={4} align="center">
  <Input
    value={newReply}
    onChange={(e) => setNewReply(e.target.value)}
    placeholder="Write a reply..."
    size="sm"
    rounded="full"
    bg={useColorModeValue('white', 'gray.800')}
    border={0}
    _focus={{ shadow: 'outline' }}
    mr={2} // Add some right margin
  />
  <Button onClick={handleAddReply} size="sm">
    <FiSend />
  </Button>
</Flex>
          
        {/* Action Icons */}
        <Divider my={4} />
        <HStack spacing={4}>
          <Button size="sm" variant="ghost">
            <Icon as={FiThumbsUp} mr={1} />
            Like
          </Button>
          <Button size="sm" variant="ghost">
            <Icon as={FiMessageCircle} mr={1} />
            Comment
          </Button>
        </HStack>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} >
        <ModalOverlay />
        <ModalContent>
          <ProfileView profile={selectedProfile} />
        </ModalContent>
        <CloseButton/>
      </Modal>
    </VStack>
  );
};

export default Thread;


