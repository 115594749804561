import { useToast } from '@chakra-ui/react';

const useShare = () => {
  const toast = useToast();

  const share = async (url, title = 'Check this out!') => {
    if (!url) {
      console.error('Share URL is required');
      return;
    }

    // Check if the Web Share API is available
    if (navigator.share) {
      try {
        await navigator.share({
          title, // Title of the thing to share, optional
          url, // The URL to share
        });
        toast({
          title: 'Shared successfully!',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Failed to share',
          description: error.message,
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      // Fallback to copying the URL to the clipboard
      try {
        await navigator.clipboard.writeText(url);
        toast({
          title: 'Link copied!',
          description: 'The link has been copied to your clipboard.',
          status: 'info',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        toast({
          title: 'Failed to copy the link',
          description: 'Please copy the link manually.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return share;
};

export default useShare;
