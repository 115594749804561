import BarChart from "components/charts/BarChart";
import { barChartOptionsDailyTraffic } from "variables/charts";
import { MdArrowDropUp, MdArrowDropDown } from "react-icons/md";
import Card from "components/card";
import { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";

interface GrowthPercentage {
  [day: number]: number;
}

const Traffic = ({ businessName }) => {
  const [trafficData, setTrafficData] = useState([]);
  const [growthPercentage, setGrowthPercentage] = useState<GrowthPercentage>({});

  useEffect(() => {
    const db = getDatabase();
    const currentRef = ref(db, `businesses/byName/${businessName}/analytics/dailyVisits`);
    const previousRef = ref(db, `businesses/byName/${businessName}/analytics/previousVisits`);

    onValue(currentRef, (currentSnapshot) => {
      const currentData = currentSnapshot.val() || {};
      onValue(previousRef, (previousSnapshot) => {
        const previousData = previousSnapshot.val() || {};
        const calculatedData = Object.keys(currentData).map(day => {
          const currentValue = currentData[day] || 0;
          const previousValue = previousData[day] || 0;
          const growth = previousValue ? ((currentValue - previousValue) / previousValue * 100) : (currentValue ? 100 : 0);
          setGrowthPercentage(prev => ({ ...prev, [day]: growth }));
          return {
            name: day,
            data: [currentValue]
          };
        });
        setTrafficData(calculatedData);
      });
    });
  }, [businessName]);

  return (
    <Card extra="pb-7 p-5">
      <div className="flex flex-row justify-between">
        <div className="ml-1 pt-2">
          <p className="text-[34px] font-bold text-navy-700 dark:text-white">
            Page Visits
          </p>
          {Object.entries(growthPercentage).map(([day, growth]) => (
            <div key={day} className="text-sm font-medium leading-4">
              {growth >= 0 ? (
                <div className="flex items-center text-green-500">
                  <MdArrowDropUp className="h-5 w-5" />
                  <p className="font-bold">{day}: {Math.abs(growth.toFixed(2))}% Increase</p>
                </div>
              ) : (
                <div className="flex items-center text-red-500">
                  <MdArrowDropDown className="h-5 w-5" />
                  <p className="font-bold">{day}: {Math.abs(growth.toFixed(2))}% Decrease</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="h-[300px] w-full pt-10 pb-0">
        <BarChart
          chartData={trafficData}
          chartOptions={barChartOptionsDailyTraffic}
        />
      </div>
    </Card>
  );
};

export default Traffic;
