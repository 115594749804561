// ComplexTable.tsx
import React, { useEffect, useState } from 'react';
import CardMenu from "./CardMenu";
import Card from "components/card";
import { MdEdit } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import ProfileModal from "./memberDetails/ProfileModal";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

interface Donation {
  dollars: string;
  cents: string;
  date?: string;
}

interface MailchimpAttributes {
  subscribed: boolean;
  lastCampaignDate?: string;
  account_id: string;
}

interface KindfulAttributes {
  eventsAttended?: string[];
}

type ActivityType = 'email' | 'call' | 'donation';

interface Activity {
  timestamp: Date;
  description: string;
  type: string;
}

interface RowObj {
  address: any;
  id: string;
  name: [string, string, string];
  email: string;
  profileType: string;
  communication: string;
  donations: Donation[];
  status: string;
  date: string;
  edit?: any;
  memberMissions: string[];  // Adjusted to string array based on previous discussions.
  priceNumeric?: number;
  activities: Activity[];
  companyName: string; // New field for business information
  position: string;    // New field for business information
  businessPhone: string; // New field for business information
}

const columnHelper = createColumnHelper<RowObj>();

export default function ComplexTable(props: { tableData: RowObj[]; onImportedData: (arg0: any) => void }) {
  const { tableData, onImportedData } = props;

  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedUserData, setSelectedUserData] = useState<RowObj | null>(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterProfileType, setFilterProfileType] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleMemberClick = (member: RowObj) => {
    setSelectedUserData(member);
    setIsProfileModalOpen(true);
  };

  const handleImportedData = (importedData: any) => {
    if (onImportedData) {
      onImportedData(importedData);
    }
  };

  const getProfileTypeColor = (profileType: string) => {
    switch (profileType.toLowerCase()) {
      case "donor":
        return "bg-blue-100 text-blue-500 dark:bg-blue-50";
      case "customer":
        return "bg-green-100 text-green-500 dark:bg-green-50";
      case "member":
        return "bg-purple-100 text-purple-500 dark:bg-purple-50";
      default:
        return "bg-gray-100 text-gray-500 dark:bg-gray-50";
    }
  };

  const filterData = (data: any[], query: string) => {
    if (!query) return data;

    return data.filter((row) => {
      const fullName = `${row.name[0]} ${row.name[1]}`.toLowerCase();
      return fullName.includes(query.toLowerCase());
    });
  };

  const filterByProfileType = (data: any[], profileType: string | null) => {
    if (!profileType) return data;
    return data.filter(row => row.profileType.toLowerCase() === profileType.toLowerCase());
  };

  const filteredData = filterByProfileType(filterData(tableData, searchQuery), filterProfileType);

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      header: () => (
        <p className="flex items-center gap-2 pr-16 text-sm font-bold text-gray-600 sm:min-w-[120px] md:min-w-[150px] lg:w-full lg:pr-0">
          NAME
        </p>
      ),
      cell: (info) => {
        const nameParts = info.getValue();
        const displayName = `${nameParts[0]} `;

        return (
          <div 
            className="flex items-center gap-2 pr-16 cursor-pointer sm:min-w-[120px] md:min-w-[150px] lg:w-full lg:pr-0"
            onClick={() => handleMemberClick(info.row.original)}
          >
            <div className="flex w-full flex-col">
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {displayName}
              </p>
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <p className="w-full text-sm font-bold text-gray-600 sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
          JOIN-DATE
        </p>
      ),
      cell: (info) => (
        <p className="w-full text-sm font-bold dark:text-white sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
          {info.getValue()}
        </p>
      ),
    }),
    columnHelper.accessor("status", {
      id: "status",
      header: () => (
        <p className="text-sm font-bold text-gray-600 sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
          STATUS
        </p>
      ),
      cell: (info) => {
        let statusColor = "";
        switch (info.getValue().toLowerCase()) {
          case "lapsed":
            statusColor = "bg-red-100 text-red-500 dark:bg-red-50";
            break;
          case "active":
            statusColor = "bg-green-100 text-green-500 dark:bg-green-50";
            break;
          case "pending":
            statusColor = "bg-yellow-100 text-yellow-500 dark:bg-yellow-50";
            break;
          case "prospective":
            statusColor = "bg-blue-100 text-blue-500 dark:bg-blue-50";
            break;
          default:
            statusColor = "bg-gray-100 text-gray-500 dark:bg-gray-50";
            break;
        }

        return (
          <div className="sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
            <div
              className={`flex w-[95px] items-center justify-center rounded-[10px] py-1.5 text-sm font-bold uppercase ${statusColor}`}
            >
              {info.getValue()}
            </div>
          </div>
        );
      },
    }),
    columnHelper.accessor("donations", {
      id: "donations",
      header: () => (
        <p className="text-sm font-bold text-gray-600 dark:text-white sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
          DONATIONS
        </p>
      ),
      cell: (info) => (
        <div
          className={`w-full text-sm font-bold dark:text-white sm:min-w-[100px] lg:min-w-[unset]`}
        >
          {info.getValue()?.length || 0}
        </div>
      ),
    }),
    columnHelper.accessor("profileType", {
      id: "profileType",
      header: () => (
        <p className="text-sm font-bold text-gray-600 sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
          PROFILE TYPE
        </p>
      ),
      cell: (info) => {
        const profileTypeColor = getProfileTypeColor(info.getValue());

        return (
          <div className="sm:min-w-[120px] md:min-w-[150px] lg:min-w-[unset]">
            <div
              className={`flex w-[95px] items-center justify-center rounded-[10px] py-1.5 text-sm font-bold uppercase ${profileTypeColor}`}
            >
              {info.getValue()}
            </div>
          </div>
        );
      },
    }),
  ];

  const [data, setData] = useState<RowObj[]>(tableData);
  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const table = useReactTable({
    data: filteredData,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Card extra={"w-full h-full px-8 pb-8 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Client Management
        </div>
        <CardMenu onImport={handleImportedData} />
      </div>
      <div className="my-4 flex justify-between items-center">
        <input
          type="text"
          placeholder="Search by name..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="dark:text-white dark:bg-navy-700"
        />
        <select
          value={filterProfileType || ""}
          onChange={(e) => setFilterProfileType(e.target.value || null)}
          className="ml-4 p-2 border rounded dark:text-white dark:bg-navy-700"
        >
          <option value="">All Profile Types</option>
          <option value="donor">Donor</option>
          <option value="customer">Customer</option>
          <option value="member">Member</option>
        </select>
      </div>
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="mb-2 cursor-pointer border-b border-gray-200 pt-4 pb-4 pr-4 text-start dark:border-white/10"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="border-white/0 py-3 pr-4">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {isProfileModalOpen && (
          <div>
            <ProfileModal 
              userData={selectedUserData!} 
              onClose={() => setIsProfileModalOpen(false)}
            />
          </div>
        )}
      </div>
    </Card>
  );
}
