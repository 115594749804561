import React, { useState } from 'react';
import { db } from '../../../../../../../firebase';
import { update, ref } from 'firebase/database';
import { Avatar, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import InterestTimeline from './InterestTimeline';
import { ActivityTimeline } from './ActivityTimeline';

interface Donation {
  dollars: string;
  cents: string;
  date?: string;  // Assuming you may want to also track the date of each donation.
}

interface MailchimpAttributes {
  subscribed: boolean;        // Whether the member is subscribed to newsletters.
  lastCampaignDate?: string;
  account_id: string;
}

interface KindfulAttributes {
  eventsAttended?: string[];  // List of events attended.
}

type ActivityType = 'email' | 'call' | 'donation';

interface Activity {
  timestamp: Date;
  description: string;
  type: string; // e.g., 'email', 'call', 'donation'
}

interface Member {
  address: any;
  id: string;
  name: [string, string, string];
  email: string;
  profileType: string | number | readonly string[];
  communication: string;
  donations: Donation[];
  status: string;
  date: string;
  edit?: any;
  memberMissions: string[];  // Adjusted to string array based on previous discussions.
  priceNumeric?: number;
  mailchimp?: MailchimpAttributes;  // Optional attributes specific to Mailchimp.
  kindful?: KindfulAttributes; // Optional attributes specific to Kindful.
  activities: Activity[];
  companyName: string; // New field for business information
  position: string;    // New field for business information
  businessPhone: string; // New field for business information
}

type InterestLevel = 'unlikely' | 'likely' | 'highly likely';

interface ProfileModalProps {
  userData: Member;
  onClose: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ userData, onClose }) => {
  const [editedData, setEditedData] = useState(userData);
  const companyKeySaved = localStorage.getItem('companyKey');
  const [address, setAddress] = useState({
    street: userData.address?.street || '',
    city: userData.address?.city || '',
    state: userData.address?.state || '',
    zip: userData.address?.zip || '',
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setEditedData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setAddress(prevAddress => ({ ...prevAddress, [name]: value }));
  };

  const handleUpdate = async () => {
    const updatedData = {
      ...editedData,
      address: address
    };

    const userRef = ref(db, `companies/${companyKeySaved}/members/${updatedData.id}`);
    await update(userRef, updatedData);
  };

  function determineInterest(member: Member): InterestLevel {
    let score = 0;

    if (member.mailchimp?.subscribed) {
      score += 10;
    }

    if (member.kindful?.eventsAttended) {
      score += member.kindful.eventsAttended.length * 5;
    }

    if (member.donations) {
      score += member.donations.length * 10;
      member.donations.forEach(donation => {
        const amount = parseInt(donation.dollars);
        if (amount >= 1000) score += 10;
      });
    }

    if (member.memberMissions) {
      score += member.memberMissions.length * 2;
    }

    if (score >= 50) return "highly likely";
    if (score >= 30) return "likely";
    return "unlikely";
  }

  const interestLevel = determineInterest(userData);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 dark:bg-black dark:bg-opacity-80">
      <div className="max-w-4xl w-full mx-4 p-6 bg-white rounded-xl shadow-lg flex flex-col dark:bg-gray-800">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-700 dark:text-white">{editedData.name[0][0] ?? ''}'s Profile</h2>
          <button onClick={onClose} className="text-gray-700 dark:text-white">
            ✖
          </button>
        </div>
        <div className="overflow-y-auto max-h-[75vh]">
          <div className="flex items-center mb-4 pb-4 dark:text-black">
            <Avatar name={`${editedData.name[0][0]} ${editedData.name[0][1]}`} src="" size="xl" />
            <div className="ml-4">
              <p className="text-xl font-semibold">
                {`${editedData.name[0][0] ?? ''} ${editedData.name[0][1]}`}
              </p>
              <p className="text-md text-gray-500">{editedData.email}</p>
              <input
                type="file"
                accept="image/*"
                className="mt-2"
                onChange={(e) => {
                  if (e.target.files?.[0]) {
                    // Implement profile picture upload logic here
                  }
                }}
              />
            </div>
          </div>
          <Tabs variant="enclosed" className="mb-4">
            <TabList>
              <Tab>Contact Details</Tab>
              <Tab>Professional Details</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div className="col-span-full md:col-span-1 dark:text-black">
                    <label htmlFor="phone" className="text-gray-600 dark:text-gray-200">Phone Number:</label>
                    <input
                      type="text"
                      name="phone"
                      value={editedData.businessPhone}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="123-456-7890"
                    />
                  </div>

                  <h2 className="text-2xl font-bold mb-6 col-span-full dark:text-white">Address</h2>
                  <div className="col-span-full md:col-span-1 dark:text-black">
                    <label htmlFor="street" className="text-gray-600 dark:text-gray-200">Street:</label>
                    <input
                      type="text"
                      name="street"
                      value={address.street}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="123 Main St"
                    />
                  </div>

                  <div className="col-span-full md:col-span-1">
                    <label htmlFor="city" className="text-gray-600">City:</label>
                    <input
                      type="text"
                      name="city"
                      value={address.city}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Anytown"
                    />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="state" className="text-gray-600">State:</label>
                    <input
                      type="text"
                      name="state"
                      value={address.state}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="CA"
                    />
                  </div>

                  <div className="col-span-1">
                    <label htmlFor="zip" className="text-gray-600">Zip Code:</label>
                    <input
                      type="text"
                      name="zip"
                      value={address.zip}
                      onChange={handleAddressChange}
                      className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="90210"
                    />
                  </div>

                  <p className="text-gray-600 dark:text-gray-200">Status:</p>
                  <select
                    name="status"
                    value={editedData.status}
                    onChange={handleSelectChange}
                    className='block w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  >
                    <option value="active">Active</option>
                    <option value="prospective">Prospective</option>
                    <option value="pending">Pending</option>
                    <option value="lapsed">Lapsed</option>
                  </select>

                  <p className="text-gray-600">Profile Type:</p>
                  <select
                    name="profileType"
                    value={editedData.profileType}
                    onChange={handleSelectChange}
                    className='block w-full bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500'
                  >
                    <option value="donor">Donor</option>
                    <option value="customer">Customer</option>
                    <option value="member">Member</option>
                  </select>

                  <p className="text-gray-600">Communication:</p>
                  <div>
                    <div className="flex space-x-4">
                      <label>
                        <input
                          type="radio"
                          name="communication"
                          value="email"
                          checked={editedData.communication === "email"}
                          onChange={handleInputChange}
                        />
                        <span className="ml-2">Email</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="communication"
                          value="text"
                          checked={editedData.communication === "text"}
                          onChange={handleInputChange}
                        />
                        <span className="ml-2">Text</span>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="communication"
                          value="phone"
                          checked={editedData.communication === "phone"}
                          onChange={handleInputChange}
                        />
                        <span className="ml-2">Phone</span>
                      </label>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div className="col-span-full md:col-span-1 dark:text-black">
                    <label htmlFor="companyName" className="text-gray-600 dark:text-gray-200">Company Name:</label>
                    <input
                      type="text"
                      name="companyName"
                      value={editedData.companyName}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Company Name"
                    />
                  </div>

                  <div className="col-span-full md:col-span-1">
                    <label htmlFor="position" className="text-gray-600">Position:</label>
                    <input
                      type="text"
                      name="position"
                      value={editedData.position}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                      placeholder="Position"
                    />
                  </div>
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <h2 className="text-2xl font-bold mb-6 text-center text-gray-700 dark:text-white">Profile Activity</h2>
          <div className="mb-4">
            <ActivityTimeline activities={userData.activities || []} fallbackDate={userData.date} />
          </div>

          <div className="w-full md:w-auto">
            <InterestTimeline interest={interestLevel} />
            <div className="flex justify-between mt-2">
              <button className="px-4 py-2 text-white bg-green-600 rounded" onClick={handleUpdate}>Update</button>
              <button className="px-4 py-2 text-white bg-red-600 rounded" onClick={onClose}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
