type InterestLevel = 'unlikely' | 'likely' | 'highly likely';

interface Donation {
    dollars: string;
    cents: string;
    date?: string;  // Assuming you may want to also track the date of each donation.
  }
  
  interface MailchimpAttributes {
    subscribed: boolean;        // Whether the member is subscribed to newsletters.
    lastCampaignDate?: string; 
    account_id: string;
    
    // Date of the last campaign they were involved in.
  
  }
  
  interface KindfulAttributes {
    eventsAttended?: string[];  // List of events attended.
  
  }
  
  interface Member {
    id: string;
    name:[string,string,string]
    email: string;
    profileType: string | number | readonly string[];
    communication: string;
    donations: Donation[];
    status: string;
    date: string;
    edit?: any;
    memberMissions: string[];  // Adjusted to string array based on previous discussions.
    priceNumeric?: number;
    mailchimp?: MailchimpAttributes;  // Optional attributes specific to Mailchimp.
    kindful?: KindfulAttributes;      // Optional attributes specific to Kindful.
  }

interface ProfileModalProps {
    userData: Member;
    onClose: () => void;
}

const colors: Record<InterestLevel, string> = {
    'unlikely': 'bg-red-500',
    'likely': 'bg-yellow-500',
    'highly likely': 'bg-green-500'
};

    function InterestTimeline({ interest }: { interest: InterestLevel }) {
 
        function getInterestHeight(interest: InterestLevel) {
            switch (interest) {
                case 'unlikely':
                    return 'h-4';
                case 'likely':
                    return 'h-16';
                case 'highly likely':
                    return 'h-32';
                default:
                    return 'h-4'; // Default, just in case
            }
        }

    return (
        <div className="ml-4">
            <h3>Relationship Level</h3>
            <div className="mt-2 relative h-32 w-1 bg-gray-300">
            <div className={`absolute bottom-0 left-0 right-0 ${getInterestHeight(interest)} ${colors[interest]}`} />

            </div>
        </div>
    );
}
export default InterestTimeline