import React from "react";
import {
  MdAdd,
  MdChat,
  MdCheck,
  MdEvent,
  MdMail,
  MdPhone,
  MdMonetizationOn,
  MdHelp,
} from "react-icons/md";

type ActivityType = 'email' | 'call' | 'donation' | string;

interface Activity {
  timestamp: Date;
  description: string;
  type: ActivityType;
}

interface TimelineItemProps {
  icon: JSX.Element;
  title: string;
  subtitle: string;
  color: string;
}

// Define the iconMap outside of the component
const iconMap: { [key: string]: JSX.Element } = {
  email: <MdMail className="text-blue-600" />,
  call: <MdPhone className="text-teal-600" />,
  donation: <MdMonetizationOn className="text-green-600" />,
  default: <MdHelp className="text-gray-600" />, // Default icon
};

const TimelineItem: React.FC<TimelineItemProps> = ({ icon, title, subtitle, color }) => {
  return (
    <div className="flex items-start">
      <div className={`flex-shrink-0 text-${color}-600`}>
        {icon}
      </div>
      <div className="flex-grow pl-4">
        <h3 className={`text-sm font-medium text-${color}-600`}>{title}</h3>
        <p className="text-xs text-gray-500">{subtitle}</p>
      </div>
    </div>
  );
};

export const ActivityTimeline: React.FC<{ activities: Activity[], fallbackDate: string }> = ({ activities, fallbackDate }) => {
  const timelineItems: TimelineItemProps[] = activities.map(activity => {
    // Check if timestamp exists and is a valid Date object
    const formattedDate = activity.timestamp && activity.timestamp instanceof Date
      ? activity.timestamp.toLocaleDateString()
      : 'Unknown Date';

    const activityIcon = iconMap[activity.type] || iconMap.default;

    return {
      icon: activityIcon,
      title: activity.description,
      subtitle: formattedDate,
      color: 'gray', // Replace with logic to determine color if necessary
    };
  });

  if (timelineItems.length === 0 && fallbackDate) {
    timelineItems.push({
      icon: <MdEvent className="text-gray-600" />,
      title: "Member joined",
      subtitle: new Date(fallbackDate).toLocaleDateString(),
      color: 'gray',
    });
  }

  return (
    <div className="timeline-container">
      {timelineItems.map((item, index) => (
        <TimelineItem key={index} {...item} />
      ))}
    </div>
  );
};
