
import { MdVerified } from 'react-icons/md';
import { getDatabase, ref, onValue } from 'firebase/database';
import CircularProgress from './CircularCSRProgress';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useState, useEffect } from 'react';

interface CSRGoal {
  description: string;
  progress: number;
  title: string;
  achieved: boolean;
}

interface BusinessInfo {
  name: string;
  description: string;
  bannerImage?: string;
  profileImage?: string;
  galleryImages?: string[];
  website?: string;
}

interface Mission {
  completed: boolean;
  impactScore: number;
  likes: number;
  comments: number;
  completionMonth: number;
}

interface Business {
  id: string;
  info: BusinessInfo;
  csrGoals?: Record<string, CSRGoal>;
  missions?: Record<string, Mission>;
}

const defaultBannerImage = 'https://cdn.pfps.gg/banners/6789-sunset-waves.gif';
const defaultProfileImage = 'https://via.placeholder.com/150';
const defaultCSRScore = 50;

const calculateCSRScore = (missions: Record<string, Mission>, csrGoals: Record<string, CSRGoal>) => {
  let missionCompletionScore = 0;
  let impactScore = 0;
  let engagementScore = 0;
  let consistencyScore = 0;
  let goalAchievementScore = 0;

  const completedMissions = Object.values(missions).filter(mission => mission.completed);
  missionCompletionScore = completedMissions.length * 10;

  for (const mission of completedMissions) {
    impactScore += mission.impactScore;
    engagementScore += mission.likes * 1 + mission.comments * 2;
  }

  const monthlyMissions = Array(12).fill(0);
  for (const mission of completedMissions) {
    monthlyMissions[mission.completionMonth - 1]++;
  }

  const consistentMonths = monthlyMissions.filter(count => count > 0).length;
  if (consistentMonths >= 6) {
    consistencyScore = 50;
  }

  for (const goal of Object.values(csrGoals)) {
    if (goal.achieved) {
      goalAchievementScore += 50;
    }
  }

  const totalCSRScore = missionCompletionScore + impactScore + engagementScore + consistencyScore + goalAchievementScore;
  return Math.min(totalCSRScore, 100);  // Ensure score doesn't exceed 100
};

const Banner = ({ business }: { business: Business }) => {
  const [csrScore, setCsrScore] = useState(defaultCSRScore);

  useEffect(() => {
    const db = getDatabase();
    const firestore = getFirestore();

    const fetchBusinessInfo = async () => {
      const missionsRef = collection(firestore, 'missions');
      const q = query(missionsRef, where('coAuthors', 'array-contains', business.id));
      const csrGoalsRef = ref(db, `csrGoals/${business.id}`);
      const defaultCSRGoals = {};

      const generateCSRScoreForCompany = async () => {
        let missions = {};
        let csrGoals = defaultCSRGoals;

        const missionDocs = await getDocs(q);
        missionDocs.forEach((doc) => {
          missions[doc.id] = doc.data();
        });

        onValue(csrGoalsRef, (snapshot) => {
          if (snapshot.exists()) {
            csrGoals = snapshot.val();
          }

          const score = calculateCSRScore(missions, csrGoals);
          setCsrScore(score || defaultCSRScore);
        });
      };

      generateCSRScoreForCompany();
    };

    fetchBusinessInfo();
  }, [business]);

  return (
  <div className="flex h-full w-full flex-col items-center rounded-[20px] dark:bg-navy-900 dark:text-white">
  <div
    style={{ backgroundImage: `url(${business.info.bannerImage || defaultBannerImage})` }}
    className="relative flex h-[150px] w-full items-center justify-center rounded-[20px] bg-cover bg-center md:h-[120px] lg:h-[170px] 3xl:h-[220px] mt-4"
  >
    <div className="absolute -bottom-20 flex h-[120px] w-[120px] md:h-[180px] md:w-[180px] items-center justify-center rounded-full border-[10px] border-white bg-navy-700 dark:!border-navy-900">
      <img className="h-full w-full rounded-full" src={business.info.profileImage || defaultProfileImage} alt="Profile" />
    </div>
  </div>
  <div className="mt-[65px] md:mt-[85px] flex flex-col items-center">
    <h1 className="mt-2 text-[30px] md:text-[55px] font-bold text-navy-700 dark:text-white flex items-center">
      {business.info.name}
      <span className="ml-2 text-[#3965FF]">
        <MdVerified />
      </span>
    </h1>
    {business.info.website && (
      <a href={business.info.website} className="text-blue-500 hover:underline text-sm md:text-base mb-4" target="_blank" rel="noopener noreferrer">
        {business.info.website}
      </a>
    )}
    <CircularProgress title="Social Responsibility Score" percentage={csrScore !== null ? csrScore : defaultCSRScore} />
  </div>
  <div className="flex h-full w-full items-center justify-center md:w-[760px] lg:w-[870px] px-4">
    <p className="mt-10 w-full text-center font-dm text-lg leading-8 text-navy-700 dark:text-white md:w-full md:text-lg">
      {business.info.description}
    </p>
  </div>
  <h4 className="ml-[23px] mt-8 text-2xl font-bold text-navy-700 dark:text-white">
    Gallery
  </h4>
  <div className="flex flex-wrap justify-center mt-6">
    {business.info.galleryImages && business.info.galleryImages.map((image, index) => (
      <img key={index} src={image} alt={`Gallery ${index}`} className="m-2 h-32 w-32 object-cover rounded-lg" />
    ))}
  </div>
</div>
);
};

export default Banner;