// Custom components
import { useState } from "react";
import { MdClose } from "react-icons/md";

function TagsField(props: {
  id?: string;
  label?: string;
  placeholder?: string;
  placeholderTags?: { name: string; id: number }[];
  tags: { name: string; id: number }[]; // Accept tags from parent
  setTags: (tags: any[]) => void; // Function to update tags in the parent component
  onChange?: any;
  [x: string]: any;
  
}) {
  const { label, id, onChange, placeholderTags, placeholder,tags,setTags,isViewMode = false,
    ...rest  } = props;
  let initialTags = [
    {
      name: "Example: Respectful",
      id: 1,
    },
  ];
  if (placeholderTags) initialTags = placeholderTags;
 

  const keyPress = (e:any) => {
   if (!isViewMode && e.keyCode === 13 && e.target.value.trim() !== "") {
     const newTag = {
       name: e.target.value.trim(),
       id: tags.length === 0 ? 1 : Math.max(...tags.map(t => t.id)) + 1, // Ensure unique ID
     };
     setTags([...tags, newTag]);
     e.target.value = "";
   }
 };

 return (
   <div>
     <label htmlFor={id} className="mb-2 text-sm font-bold">
       {label}
     </label>
     <div className="min-h-10 flex flex-row flex-wrap rounded-xl border border-gray-200 bg-none p-3 focus:border-brand-200 dark:border-white/10">
       {tags.map((tag, index) => (
         <div key={index} className="mb-1.5 mr-1.5 flex items-center rounded-xl bg-brand-500 py-0.5 px-3 text-sm font-medium text-white">
           <p>{tag.name}</p>
           {!isViewMode && (
             <MdClose
               className="ml-2 cursor-pointer"
               onClick={() => setTags(tags.filter((_, i) => i !== index))}
             />
           )}
         </div>
       ))}
       {!isViewMode && (
         <input
           type="text"
           onKeyDown={keyPress}
           placeholder={placeholder}
           className="flex h-[48px] w-full border-none bg-none p-0 text-sm outline-none dark:bg-navy-800"
           {...rest}
         />
       )}
     </div>
   </div>
 );
}

export default TagsField;
