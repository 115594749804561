import React, { useState, useEffect } from "react";
import Card from "components/card";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { getDatabase, ref, runTransaction, get, onValue, push } from "firebase/database";
import { FaRegComment } from "react-icons/fa";
import { Box, Input, Button, Text, VStack } from "@chakra-ui/react";
import Slider from "rc-slider";
interface PollOption {
  label: string;
  votes: number;
}

interface CompanyCardsDisplayProps {
  images?: string[]; // Update to an array of images
  title: string;
  description: string;
  type: string;
  extra?: string;
  options?: PollOption[];
  question?: string;
  businessId?: string;
  cardId?: string;
}

interface Comment {
  id: string;
  text: string;
}

const CompanyCardsDisplay: React.FC<CompanyCardsDisplayProps> = ({
  images,
  title,
  description,
  type,
  extra,
  options: initialOptions,
  question: initialQuestion,
  businessId,
  cardId,
}) => {
  const [heart, setHeart] = useState(false);
  const [likes, setLikes] = useState(0);
  const [voted, setVoted] = useState<number | null>(null);
  const [options, setOptions] = useState<PollOption[] | null>(initialOptions || null);
  const [question, setQuestion] = useState<string | null>(initialQuestion || null);
  const [comments, setComments] = useState<Comment[]>([]);
  const [newComment, setNewComment] = useState("");

  const db = getDatabase();

  useEffect(() => {
    if (!initialOptions || !initialQuestion) {
      const cardRef = ref(db, `businesses/byName/${businessId}/cards/${cardId}`);

      get(cardRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const cardData = snapshot.val();
            const normalizedOptions = cardData.options.map((option: any, index: number) => ({
              label: typeof option === 'string' ? option : option.label,
              votes: option.votes || 0,
            }));
            setOptions(normalizedOptions);
            setQuestion(cardData.question || null);
          }
        })
        .catch((error) => {
          console.error("Error fetching card data:", error);
        });
    }

    const likesRef = ref(db, `businesses/byName/${businessId}/cards/${cardId}/likes`);
    const commentsRef = ref(db, `businesses/byName/${businessId}/cards/${cardId}/comments`);

    // Listen for likes changes
    onValue(likesRef, (snapshot) => {
      if (snapshot.exists()) {
        setLikes(snapshot.val());
      }
    });

    // Listen for comments changes
    onValue(commentsRef, (snapshot) => {
      if (snapshot.exists()) {
        const commentsData = snapshot.val();
        const commentsArray = Object.keys(commentsData).map((key) => ({
          id: key,
          text: commentsData[key],
        }));
        setComments(commentsArray);
      }
    });
  }, [initialOptions, initialQuestion, businessId, cardId]);

  const handleVote = (optionIndex: number) => {
    if (voted === null && type === "poll" && options) {
      const optionRef = ref(db, `businesses/byName/${businessId}/cards/${cardId}/options/${optionIndex}/votes`);

      runTransaction(optionRef, (votes) => {
        return (votes || 0) + 1;
      })
        .then(() => {
          setOptions((prevOptions) =>
            prevOptions
              ? prevOptions.map((option, index) =>
                  index === optionIndex ? { ...option, votes: (option.votes || 0) + 1 } : option
                )
              : null
          );
          setVoted(optionIndex);
        })
        .catch((error) => {
          console.error("Error updating vote count:", error);
        });
    }
  };

  const handleLike = () => {
    setHeart(!heart);
    const likesRef = ref(db, `businesses/byName/${businessId}/cards/${cardId}/likes`);

    runTransaction(likesRef, (currentLikes) => {
      return (currentLikes || 0) + 1;
    })
      .then(() => {
        setLikes((prevLikes) => prevLikes + 1);
      })
      .catch((error) => {
        console.error("Error updating likes count:", error);
      });
  };

  const handleAddComment = () => {
    if (newComment.trim()) {
      const commentsRef = ref(db, `businesses/byName/${businessId}/cards/${cardId}/comments`);
      push(commentsRef, newComment)
        .then(() => {
          setNewComment("");
        })
        .catch((error) => {
          console.error("Error adding comment:", error);
        });
    }
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <Card extra={`flex flex-col w-full h-full !p-4 ${extra || ''}`}>
      <div className="relative w-full dark:bg-navy-900 dark:text-white">
      {images && images.length > 0 && (
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  className="mb-3 h-48 w-full object-cover rounded-xl"
                  alt={`${title} ${index + 1}`}
                />
              </div>
            ))}
          </Slider>
        )}
        <button
          onClick={handleLike}
          className="absolute top-3 right-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:bg-gray-100"
        >
          {heart ? (
            <IoHeart className="text-red-500 text-xl" />
          ) : (
            <IoHeartOutline className="text-xl" />
          )}
        </button>
      </div>
      <div className="px-4 py-2">
        <h3 className="text-lg font-bold text-navy-700">{title}</h3>
        <p className="text-sm text-gray-600">{description}</p>
        <p className="mt-1 text-xs text-black-500">Type: {type}</p>
        {type === "poll" && question && (
          <>
            <p className="mt-2 text-lg font-semibold">{question}</p>
            {options && voted === null && (
              <div>
                {options.map((option, index) => (
                  <button
                    key={index}
                    className="mt-2 block w-full rounded py-2 text-center text-black hover:bg-blue-500"
                    onClick={() => handleVote(index)}
                  >
                    {option.label} {option.votes} Votes
                  </button>
                ))}
              </div>
            )}
          </>
        )}
        {voted !== null && <p className="mt-2 text-green-500">Thank you for voting!</p>}
        <div className="mt-4 flex items-center">
          <FaRegComment className="mr-2 text-gray-600" />
          <Text fontSize="sm">{comments.length} comments</Text>
        </div>
        <VStack align="start" mt={4} spacing={2}>
          {comments.map((comment) => (
            <Box key={comment.id} p={2} bg="gray.100" rounded="md" w="full">
              {comment.text}
            </Box>
          ))}
        </VStack>
        <Input
          mt={4}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder="Add a comment"
        />
        <Button mt={2} onClick={handleAddComment} >
          Comment
        </Button>
      </div>
    </Card>
  );
};

export default CompanyCardsDisplay;
