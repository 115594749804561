import React, { useState } from "react";
import Dropdown from "components/dropdown";
import { AiOutlineUser } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { AiOutlineShop } from "react-icons/ai";
import { TiLightbulb } from "react-icons/ti";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Switch, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from "@chakra-ui/react";
import AddMemberForm from "./addMember";
import { getAuth } from "firebase/auth";


function CardMenu(props: { transparent?: boolean; vertical?: boolean; onImport: (importedData: any[]) => void}) {
  const { transparent, vertical,onImport } = props;
  const [open, setOpen] = useState(false);
  const auth = getAuth();
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userId = auth.currentUser.uid
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(new Set());
  // Function to handle opening the modal for adding a volunteer manually
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal for adding a volunteer manually
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle opening the modal for inviting a volunteer
  const openInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  // Function to handle closing the modal for inviting a volunteer
  const closeInviteModal = () => {
    setIsInviteModalOpen(false);
  };
  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  // Function to handle closing the modal for inviting a volunteer
  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };
  const toggleUserSelection = (userId: any) => {
    setSelectedUsers(prev => {
      const newSelected = new Set(prev);
      if (newSelected.has(userId)) {
        newSelected.delete(userId);
      } else {
        newSelected.add(userId);
      }
      return newSelected;
    });
  };
// Inside CardMenu component
const handleImport = async () => {
  setIsLoading(true);
  try {
    const response = await fetch(
      "https://us-central1-safehaven-80301.cloudfunctions.net/fetchMailchimpData",
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: userId })
      }
    );

    const data = await response.json();

    // Check if data is an array, indicating a list of users.
    if (Array.isArray(data)) {
      setUsers(data);
      setShowConfirmationModal(true);
      console.log(data)
    } else {
      console.error('Data is not in expected format:', data);  
  }
  } catch (error) {
    console.error("Error during import:", error);
  } finally {
    setIsLoading(false);
  }
};




const handleConfirmImport = () => {
  const confirmedUsers = users.filter(user => selectedUsers.has(user.id));
  props.onImport(confirmedUsers);
  setShowConfirmationModal(false);
  setUsers([]);
  setSelectedUsers(new Set());
};


  return (
    <div>
      <Dropdown
        button={
          <button
            onClick={() => setOpen(!open)}
            className={`flex items-center text-xl hover:cursor-pointer ${
              transparent
                ? "bg-none text-white hover:bg-none active:bg-none"
                : vertical
                ? "bg-none text-navy-700 dark:text-white"
                : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            {vertical ? (
              <p className="text-[24px] hover:cursor-pointer">
                <BsThreeDotsVertical />
              </p>
            ) : (
              <BsThreeDots className="h-6 w-6" />
            )}
          </button>
        }
        animation="origin-top-right transition-all duration-300 ease-in-out"
        classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
        children={
          <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p
              className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium"
              onClick={openModal}
            >
              <span>
                <AiOutlineUser />
              </span>
              Add Customer Manually
            </p>
            {/* <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium"
            onClick={openAddModal}>
              <span>
                <AiOutlineShop />
              </span>
              Contact Customer
            </p> */}
            <p
              className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium"
              onClick={openInviteModal}
            >
              <span>
                {/* <TiLightbulb /> */}
              </span>
           
            </p>
            <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium"
          >
              <span>
                <FiSettings />
              </span>
              <button onClick={handleImport} disabled={isLoading}>
      {isLoading ? 'Importing...' : 'Import Contacts'}
    </button>
            </p>
          </div>
        }
      />

      {/* Modal for adding a volunteer manually */}
      <Modal isOpen={isModalOpen} onClose={closeModal} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Customer Manually</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <AddMemberForm/>
            {/* ... */}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              Cancel
            </Button>
            {/* Add other footer buttons if needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for inviting a volunteer */}
      <Modal isOpen={isInviteModalOpen} onClose={closeInviteModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Data</ModalHeader>
          
          <ModalCloseButton />
          <ModalBody>
          
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeInviteModal}>
              Cancel
            </Button>
            {/* Add other footer buttons if needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for adding volunteer to mission */}
      <Modal isOpen={isAddModalOpen} onClose={closeAddModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Import Data</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
       
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeAddModal}>
              Cancel
            </Button>
            {/* Add other footer buttons if needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>

       {/* Confirmation Modal */}
       {showConfirmationModal && (
       <Modal isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
       <ModalOverlay />
       <ModalContent>
         <ModalHeader>Confirm Users</ModalHeader>
         <ModalCloseButton />
         <ModalBody>
           {users.map(user => (
             <div key={user.id}>
               {user.email_address}
               <Switch 
                 isChecked={selectedUsers.has(user.id)}
                 onChange={() => toggleUserSelection(user.id)}
               />
             </div>
           ))}
         </ModalBody>
         <ModalFooter>
           <Button colorScheme="blue" onClick={handleConfirmImport}>Confirm</Button>
           <Button colorScheme="gray" ml={3} onClick={() => setShowConfirmationModal(false)}>Cancel</Button>
         </ModalFooter>
       </ModalContent>
        </Modal>
      )}
    </div>
  );
}

export default CardMenu;
