import React, { useState, useEffect, useRef } from 'react';
import { getDatabase, ref, push, onValue, update, remove, set } from 'firebase/database';
import ColumnChart from 'components/charts/BarChart';
import Card from 'components/card';
import {
  Button, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,
  useDisclosure, IconButton, HStack, Flex, Box, Text, VStack, Input, Textarea, Select, Switch
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon, DownloadIcon } from '@chakra-ui/icons';
import { MdReduceCapacity, MdDiversity3, MdPeopleOutline } from 'react-icons/md';
import BusinessManagement from './BusinessManagement';
import Donation from 'views/admin/nfts/page/components/Donation';

const CSRManagement = ({ companyName }) => {
  const [csrGoals, setCsrGoals] = useState([]);
  const [benchmarkData, setBenchmarkData] = useState([]);
  const [currentGoal, setCurrentGoal] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [goalForm, setGoalForm] = useState({ title: '', description: '', progress: 0, industry: '' });
  const [industries, setIndustries] = useState([]);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [donationsEnabled, setDonationsEnabled] = useState(false);
  const db = getDatabase();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const scrollRef = useRef(null);

  useEffect(() => {
    const businessInfoRef = ref(db, `businesses/byName/${companyName}/info`);
    onValue(businessInfoRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setBusinessInfo({ ...data, id: companyName });
        setDonationsEnabled(data.donationsEnabled || false);
      } else {
        // If business info doesn't exist, create it
        const newBusinessInfo = { name: companyName, donationsEnabled: false };
        set(businessInfoRef, newBusinessInfo);
        setBusinessInfo({ ...newBusinessInfo, id: companyName });
      }
    });

    const goalsRef = ref(db, `businesses/byName/${companyName}/csrGoals`);
    onValue(goalsRef, (snapshot) => {
      const data = snapshot.val();
      const goalsArray = data ? Object.keys(data).map(key => ({
        id: key,
        ...data[key]
      })) : [];
      setCsrGoals(goalsArray);
    });

    const industriesRef = ref(db, 'industries');
    onValue(industriesRef, (snapshot) => {
      const data = snapshot.val();
      setIndustries(data ? Object.keys(data) : []);
    });

  }, [db, companyName]);

  const handleGoalFormChange = (field, value) => {
    setGoalForm(prev => ({ ...prev, [field]: value }));
  };

  const handleAddGoal = async () => {
    const goalsRef = ref(db, `businesses/byName/${companyName}/csrGoals`);
    const newGoalRef = push(goalsRef);
    await set(newGoalRef, goalForm);
    setGoalForm({ title: '', description: '', progress: 0, industry: '' });
    onClose();
  };

  const handleUpdateGoal = async () => {
    const goalRef = ref(db, `businesses/byName/${companyName}/csrGoals/${currentGoal.id}`);
    await update(goalRef, goalForm);
    setGoalForm({ title: '', description: '', progress: 0, industry: '' });
    setIsEditing(false);
    setCurrentGoal(null);
    onClose();
  };

  const handleEditGoal = (goal) => {
    setCurrentGoal(goal);
    setGoalForm(goal);
    setIsEditing(true);
    onOpen();
  };

  const handleDeleteGoal = async (goalId) => {
    const goalRef = ref(db, `businesses/byName/${companyName}/csrGoals/${goalId}`);
    await remove(goalRef);
  };

  const fetchBenchmarkData = async (industry) => {
    const benchmarkRef = ref(db, `benchmarks/${industry}`);
    const snapshot = await onValue(benchmarkRef, (snapshot) => {
      const data = snapshot.val();
      setBenchmarkData(data ? Object.keys(data).map(key => data[key]) : []);
    });
  };

  useEffect(() => {
    if (goalForm.industry) {
      fetchBenchmarkData(goalForm.industry);
    }
  }, [goalForm.industry]);

  const handleToggleDonations = async () => {
    const businessInfoRef = ref(db, `businesses/byName/${companyName}/info`);
    if (businessInfo) {
      await update(businessInfoRef, { donationsEnabled: !donationsEnabled });
      setBusinessInfo(prev => ({ ...prev, donationsEnabled: !donationsEnabled }));
    } else {
      const newBusinessInfo = { name: companyName, donationsEnabled: !donationsEnabled };
      await set(businessInfoRef, newBusinessInfo);
      setBusinessInfo({ ...newBusinessInfo, id: companyName });
    }
    setDonationsEnabled(prev => !prev);
  };

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: csrGoals.map(goal => goal.title),
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "11px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "11px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 9,
        colorStops: [
          {
            offset: 0,
            color: "#4318FF",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(67, 24, 255, 1)",
            opacity: 0.28,
          },
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
    title: {
      text: 'CSR Goals Progress',
      align: 'center',
    },
  };

  const chartData = [{
    name: 'Progress',
    data: csrGoals.map(goal => goal.progress),
  }];

  const benchmarkChartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: benchmarkData.map(goal => goal.title),
      show: true,
      labels: {
        show: true,
        style: {
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          fontSize: "14px",
        },
      },
    },
    grid: {
      show: true,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: "#FF5733",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(255, 87, 51, 1)",
            opacity: 0.28,
          },
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
    title: {
      text: 'Benchmark Progress',
      align: 'center',
    },
  };

  const benchmarkChartData = [{
    name: 'Progress',
    data: benchmarkData.map(goal => goal.progress),
  }];

  const handleExport = () => {
    // Export logic for CSR goals and benchmarking data
  };

  return (
    <div>
      <Card extra='items-center pt-[16px] pb-10 px-[16px] bg-cover mt-2 mb-4'>
        <h1 className="text-xl font-bold text-gray-800 dark:text-white">Edit Business Details for {companyName}</h1>
        <p className="text-gray-600 dark:text-gray-400">Manage your business information so your community knows who you are.</p>
        {businessInfo && (
          <BusinessManagement
            companyName={businessInfo.name}
            businessId={businessInfo.id}
          />
        )}
      </Card>

      <Card extra='items-center pt-[16px] pb-10 px-[16px] bg-cover mb-4'>
        <h1 className="text-xl font-bold text-gray-800 dark:text-white">CSR Goals Management for {businessInfo?.name}</h1>
        <p className="text-gray-600 dark:text-gray-400">Select and manage CSR goals to drive positive impact in your community.</p>
        <div className="flex flex-col items-center text-center w-full rounded-[20px]">
          <Button className='mt-4' onClick={onOpen}>Manage Goals</Button>
        </div>
      </Card>

      <Drawer placement="right" isOpen={isOpen} onClose={onClose} size={'full'}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{isEditing ? 'Edit CSR Goal' : 'Add CSR Goal'}</DrawerHeader>
          <DrawerBody>
            <VStack spacing={4}>
              <Input placeholder="Title" value={goalForm.title} onChange={(e) => handleGoalFormChange('title', e.target.value)} />
              <Textarea placeholder="Description" value={goalForm.description} onChange={(e) => handleGoalFormChange('description', e.target.value)} />
              <Input placeholder="Progress" type="number" value={goalForm.progress} onChange={(e) => handleGoalFormChange('progress', e.target.value)} />
              <Select placeholder="Select industry" value={goalForm.industry} onChange={(e) => handleGoalFormChange('industry', e.target.value)}>
                {industries.map(industry => <option key={industry} value={industry}>{industry}</option>)}
              </Select>
              <Button  onClick={isEditing ? handleUpdateGoal : handleAddGoal}>
                {isEditing ? 'Update Goal' : 'Add Goal'}
              </Button>
              {csrGoals.map(goal => (
                <Card extra='items-center pt-[16px] pb-10 px-[16px] bg-cover' key={goal.id}>
                  <Flex justify="space-between" align="center" w="full">
                    <Box>
                      <Text fontWeight="bold">{goal.title}</Text>
                      <Text mt={4}>{goal.description}</Text>
                    </Box>
                    <HStack spacing={4}>
                      <IconButton icon={<EditIcon />} onClick={() => handleEditGoal(goal)} aria-label={''} />
                      <IconButton icon={<DeleteIcon />} onClick={() => handleDeleteGoal(goal.id)} aria-label={''} />
                    </HStack>
                  </Flex>
                </Card>
              ))}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Card extra='items-center bg-cover mb-4'>
        <Flex justify="space-between" align="center" w="full">
          <h1 className="text-xl font-bold text-gray-800 dark:text-white">CSR Goals Progress</h1>
          <Button leftIcon={<DownloadIcon />} onClick={handleExport}>Export</Button>
        </Flex>
        <Box w="full" h={{ base: "300px", md: "350px", lg: "400px" }}>
          <ColumnChart chartData={chartData} chartOptions={chartOptions} />
        </Box>
      </Card>

      {/* {businessInfo && (
        <Card extra='items-center pt-[16px] pb-10 px-[16px] bg-cover mb-4'>
          <h1 className="text-xl font-bold text-gray-800 dark:text-white">Donation Settings</h1>
          <Flex align="center" justify="space-between">
            <Text className="text-gray-600 dark:text-gray-400">Enable Donations</Text>
            <Switch
              isChecked={donationsEnabled}
              onChange={handleToggleDonations}
            />
          </Flex>
          {donationsEnabled && (
            <Donation
              name={businessInfo.name}
              creator={businessInfo.creator || 'Unknown Creator'}
              creatorAvatar={businessInfo.profileImage || 'default_avatar_url'}
              price="3.00 $"
              bid=".82 $"
              businessId={businessInfo.name}
            />
          )}
        </Card>
      )} */}
    </div>
  );
};

export default CSRManagement;
