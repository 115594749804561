function Email(props: {
    subject: string;
    from: string;
    body: string;
    time: string;
    onClick?: () => void;
  }) {
    const { subject, from, body, time, onClick } = props;
  
    return (
      <div className="flex w-full items-center gap-3 px-2 py-6 border-b border-gray-100 cursor-pointer" onClick={onClick}>
        {/* You can add an icon or a default avatar for emails */}
        <div className="relative h-[50px] !w-[60px] rounded-full bg-gray-300"></div>
        
        <div className="w-full">
          <div className="flex w-full items-center justify-between">
            <h1 className="text-xl font-bold text-navy-700 dark:text-white">
              {subject} 
            </h1>
            <h4 className="text-sm font-medium text-gray-400 ">{time} </h4>
          </div>
          <p className="text-sm font-normal tracking-normal text-navy-700 dark:text-white">
            From: {from}
          </p>
          <p className="text-sm font-normal tracking-normal text-navy-700 dark:text-white">
            {body}
          </p>
        </div>
      </div>
    );
  }
  
  export default Email;
  