import React, { useEffect, useState } from "react";
import { MdOutlineFavoriteBorder } from "react-icons/md";
import Card from "components/card";
import { Avatar, Box, Flex, IconButton, Image, Text, Button, useToast } from "@chakra-ui/react";
import { getFirestore, updateDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getDatabase, get, ref } from "firebase/database";

interface Mission {
  id: string;
  missionName: string;
  fileUrl?: string;
  isMyMission: boolean;
  authorId: string;
  missionDescription: string;
  missionCategory: string;
  coAuthors?: string[];
  likes?: number;
  authorImageUrl?: string;
}

interface MissionItemProps {
  mission: Mission;
}

const MissionItem: React.FC<MissionItemProps> = ({ mission }) => {
  const [likeCount, setLikeCount] = useState(mission.likes || 0);
  const [isDescriptionExpanded, setDescriptionExpanded] = useState(false);
  const [authorImageUrl, setAuthorImageUrl] = useState<string>(mission.authorImageUrl || '');
  const toast = useToast();
  const firestore = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthorImageUrl = async (authorUid: string) => {
      const db = getDatabase();
      const authorRef = ref(db, `volunteers/${authorUid}/profileURL`);
      const snapshot = await get(authorRef);
      if (snapshot.exists()) {
        setAuthorImageUrl(snapshot.val());
      } else {
        setAuthorImageUrl(''); // Set to a default image URL if needed
      }
    };

    if (!authorImageUrl) {
      fetchAuthorImageUrl(mission.authorId);
    }
  }, [mission.authorId, authorImageUrl]);

  const handleMissionSelect = (missionId: string) => {
    navigate(`/volunteer/missions/${missionId}`);
  };

  const handleLike = async () => {
    const newLikeCount = likeCount + 1;
    setLikeCount(newLikeCount);

    const missionRef = doc(firestore, "missions", mission.id);
    try {
      await updateDoc(missionRef, {
        likes: newLikeCount
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to update likes.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const toggleDescription = () => {
    setDescriptionExpanded(!isDescriptionExpanded);
  };

  return (
    <Card extra={"w-full h-full px-[30px] py-[21px] mb-4"}>
      <Box rounded="lg" overflow="hidden" position="relative" cursor="pointer" h={{ base: "300px", md: "200px" }} w="full">
        <Image
          src={mission.fileUrl || ""}
          className="w-full max-w-[400px] rounded-xl object-cover mx-auto cursor-pointer mt-2"
        />
        <Avatar
          src={authorImageUrl}
          size="md"
          position="absolute"
          top="2"
          left="2"
          zIndex="4"
          className='border-[3px] border-[#FF9966]'
        />
        <Box p="12" position="absolute" bottom="0" left="0" right="6" zIndex="4">
          <Flex justify="space-between" align="center" mb="2">
            <Text fontSize="xl" fontWeight="bold" color="white" onClick={() => handleMissionSelect(mission.id)}>{mission.missionName}</Text>
          </Flex>
        </Box>
        <Box position="absolute" top="0" left="0" right="0" bottom="0" zIndex="3" />
      </Box>
      <Flex alignItems="center">
        <IconButton
          aria-label="Like mission"
          icon={<MdOutlineFavoriteBorder />}
          onClick={handleLike}
          variant="ghost"
          className="dark:text-white"
        />
        <Text className="dark:text-white">{likeCount.toLocaleString()}</Text>
      </Flex>
      <Text
        className={`text-sm font-bold text-navy-700 dark:text-white ${!isDescriptionExpanded ? 'line-clamp-2' : ''}`}
        noOfLines={isDescriptionExpanded ? undefined : 2}
      >
        {mission.missionDescription}
      </Text>
      <Button onClick={toggleDescription} variant="link" size="sm">
        {isDescriptionExpanded ? 'Read Less' : 'Read More'}
      </Button>
    </Card>
  );
};

export default MissionItem;
