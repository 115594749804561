// VolunteerContext.js
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { getDatabase, ref, onValue, query, orderByKey, startAfter, limitToFirst, DataSnapshot, get,update } from 'firebase/database';
import { AuthContext } from './AuthContext'; // Assuming you have AuthContext
import auth from '../firebase';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { UserProfile, VolunteeringExperience, Contribution, ContactInfo, SocialMediaLinks, Availability } from './types/UserProfileTypes';

interface Props {
  children?: ReactNode;
}
interface PostAuthor {
  id: string;
  name: string;
  profileURL: string;
  username: string;
}

interface PostComment {
  id: string;
  profileURL:string
  authorId: string;
  content: string;
  timestamp: number;
}

interface UserPost {
  id: string;
  authorId: string; // Now directly includes authorId
  content: string;
  image: string | string[]; // Adjust this line
  timestamp: number;
  likes: number;
  comments: PostComment[];
  tags?: string[];
}

// MissionPosts
export interface MissionDetails {
  location: string;
  missionCategory: string;
  missionDescription: string;
  missionGoals: string;
  missionName: string;
}

export interface MissionLogistics {
  backgroundColor: string;
  missionEndDate: string;
  missionPartnerships: string;
  missionResources: string;
}

export interface Mission {
  missionDetails: MissionDetails;
  missionLogistics: MissionLogistics;
}

type MissionData = {
  id: string;
  missionDetails: {
    missionName: string;
    location: string;
    missionCategory: string;
    missionDescription: string;
    missionGoals: string;
    teamAndVolunteers: {
      teamContact: string;
      teamLeader: string;
      team: string;
      volunteerRequirements: string;
    };
    missionLogistics: {
      missionPartnerships: string;
      missionResources: string;
    };
  };

};

interface VolunteerContextState {
  missionData: MissionData[];
  posts: UserPost[];
  filter: string;
  volunteerInfo: UserProfile | null; // volunteerInfo can be null initially
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  fetchProfilePicture: (userId: string) => Promise<string>;
  updateVolunteerInformation: (volunteerInfo: Partial<UserProfile>) => Promise<void>;
  handleImageUpload: (file: File, type: 'profile' | 'banner') => Promise<void>;
}
const VolunteerContext = createContext<VolunteerContextState>({
  missionData: [],
  posts: [],
  filter: 'Popular',
  volunteerInfo: null, // Set to null as default
  setFilter: () => {},
  fetchProfilePicture: async () => 'default_profile_pic_url',
  updateVolunteerInformation: async () => {},
  handleImageUpload: async () => {},
});

export const VolunteerProvider: React.FC<Props> = ({ children }) => {
  const [missionData, setMissionData] = useState<MissionData[]>([]);
  const [posts, setPosts] = useState<UserPost[]>([]);
  const [companyKey, setCompanyKey] = useState(null);
  const [userKey, setUserKey] = useState("");
  const [filter, setFilter] = useState('Popular');
  const { currentUser } = useContext(AuthContext);
  const [userEmail, setUserEmail] = useState("");
  const POSTS_LIMIT = 10; // Number of posts to load at once
  const [lastFetchedPost, setLastFetchedPost] = useState(null);
  const profilePictureCache: { [key: string]: string } = {};
  const [lastFetchedPostKey, setLastFetchedPostKey] = useState(null);
  const [volunteerInfo, setVolunteerInfo] = useState<UserProfile>(null); 
  const db = getDatabase();
  
  // ... (Your useEffect hooks go here)
  useEffect(() => {
    const email = currentUser.email || "";
    if (email) {
      setUserEmail(email);
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                setUserKey(userKey);
                localStorage.setItem("companyKey", companyKey);
              }
            }
          }
        }
      });
    }
  }, [currentUser, db]);

// Fetch Mission Info
  useEffect(() => {
    const fetchMissionData = async () => {
      if (companyKey) {
        const missionsRef = ref(db, `companies/${companyKey}/missions/`);
        onValue(missionsRef, (snapshot: DataSnapshot) => {
          const missions = snapshot.val();
          if (missions) {
            const missionArray: MissionData[] = Object.values(missions);
            setMissionData(missionArray);
          } else {
            setMissionData([]);
          }
        });
      }
    };

    fetchMissionData();
  }, [companyKey]);


// Fetch Post
  useEffect(() => {
    const fetchPostsAndComments = async () => {
      
      const postsQuery = lastFetchedPost
        ? query(ref(db, 'posts'), orderByKey(), startAfter(lastFetchedPost.key), limitToFirst(POSTS_LIMIT))
        : query(ref(db, 'posts'), orderByKey(), limitToFirst(POSTS_LIMIT));
  
      onValue(postsQuery, async (snapshot) => {
        if (snapshot.exists()) {
          const postsData = snapshot.val();
          const postsArray = await Promise.all(Object.keys(postsData).map(async (key) => {
            const post = postsData[key];
            // Fetch profile picture URL for each post author
            const profileURL = await fetchProfilePicture(post.authorId);
            // Update the author object with the profile URL
            const updatedAuthor = { ...post.author, profileURL };
  
            // Process comments as before
            const commentsArray = post.comments
              ? Object.keys(post.comments).map(commentKey => {
                const comment = post.comments[commentKey];
                return comment ? { ...comment as PostComment, id: commentKey } : null;
              }).filter(comment => comment !== null)
              : [];
  
            return { ...post, author: updatedAuthor, comments: commentsArray, id: key };
          }));
  
          // Sort and set posts as before
          if (filter === 'Latest') {
            postsArray.sort((a, b) => b.timestamp - a.timestamp);
          } else if (filter === 'Popular') {
            postsArray.sort((a, b) => (b.likes || 0) - (a.likes || 0));
          }
  
          setPosts(postsArray);
        }
      });
    };
  
    fetchPostsAndComments();
  }, [filter, db,lastFetchedPost]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const volunteerRef = ref(db, `volunteers/${user.uid}`);
      onValue(volunteerRef, (snapshot) => {
        const data = snapshot.val();
        setVolunteerInfo(data); // This should update the volunteerInfo state with the fetched data
      }, {
        onlyOnce: true
      });
    }
  }, [auth.currentUser, db]);
  

  const fetchProfilePicture = async (authorId: string): Promise<string> => {
    try {
      // Adjust the path according to your database structure
      const profileRef = ref(db, `volunteers/${authorId}/profileURL`);
      const snapshot = await get(profileRef);
      return snapshot.exists() ? snapshot.val() : 'default_profile_pic_url';
    } catch (error) {
      console.error("Failed to fetch profile picture for authorId:", authorId, error);
      return 'default_profile_pic_url'; // Fallback URL in case of error
    }
  };

  
  const updateVolunteerInformation = async (info: Partial<UserProfile>): Promise<void> => {
    if (!currentUser) return;
    const userRef = ref(db, `volunteers/${currentUser.uid}`);
    await update(userRef, info);
  };

  const handleImageUpload = async (file: File, type: 'profile' | 'banner'): Promise<void> => {
    if (!currentUser) return;
    const fileRef = storageRef(getStorage(), `images/${type}/${currentUser.uid}`);
  
    try {
      await uploadBytes(fileRef, file);
      const fileURL = await getDownloadURL(fileRef);
      await updateProfileImageURL(type, fileURL);
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  // Function to update profile image URL
  const updateProfileImageURL = async (type: 'profile' | 'banner', url: string): Promise<void> => {
    if (!currentUser) return;
    const updates = type === 'profile' ? { profileURL: url } : { bannerURL: url };
    await update(ref(db, `volunteers/${currentUser.uid}`), updates);
  };
  const value = {
    missionData,
    posts,
    filter,
    setFilter,
    volunteerInfo,
    fetchProfilePicture,
    updateVolunteerInformation,
    handleImageUpload,
  };

  return <VolunteerContext.Provider value={value}>{children}</VolunteerContext.Provider>;
};

export const useVolunteer = () => useContext(VolunteerContext);
