import React from 'react';
import {
  Box,
  Text,
  VStack,
  Avatar,
  Badge,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack
} from '@chakra-ui/react';
import { Participant } from '../components/MissionDetails';



type ParticipantsListProps = {
  participants: Participant[]; // Directly use an array of Participant
};

const ParticipantsList: React.FC<ParticipantsListProps> = ({ participants }) => {
  const maxAvatars = 5; // Define the maximum number of avatars to display
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <VStack align="start" spacing={4} >
        <Text fontWeight="bold" fontSize="xl">Participants</Text>
        <HStack>
          {participants.slice(0, maxAvatars).map((participant, index) => (
            <div>
              <Avatar key={index} name={participant.name} src={participant.profileUrl} />
              <Text fontWeight="bold" fontSize="sm">{participant.name}</Text>
            </div>
          ))}
          {participants.length > maxAvatars && (
            <>
              <Badge colorScheme="purple" ml={2} p={1}>
                +{participants.length - maxAvatars}
              </Badge>
              <Button size="sm" onClick={onOpen}>See More</Button>
            </>
          )}
        </HStack>
      </VStack>

      {/* Modal to show all participants */}
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Participants</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              {participants.map((participant, index) => (
                <HStack key={index} width="full" justifyContent="start">
                  <Avatar name={participant.name} src={participant.profileUrl} />
                  <Text>{participant.name}</Text>
                </HStack>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ParticipantsList;
