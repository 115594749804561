import { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, addDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore'; 

export const useFirebaseMissions = () => {
  const [missions, setMissions] = useState([]);
  const [selectedMission, setSelectedMission] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
   const db = getFirestore(); 
   
  useEffect(() => {
    const fetchMissions = async () => {
      setLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'missions'));
        const formattedData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMissions(formattedData);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch missions');
        setLoading(false);
      }
    };

    fetchMissions();
  }, []);

  const selectMission = (mission) => {
    setSelectedMission(mission);
  };

  const handleEditMission = async (missionId, missionDetails) => {
    const missionRef = doc(db, `missions/${missionId}`);
    try {
      await updateDoc(missionRef, missionDetails);
      console.log('Mission updated successfully');
    } catch (err) {
      setError('Failed to update mission');
      console.error(err);
    }
  };

  const addMission = async (missionDetails) => {
    try {
      await addDoc(collection(db, 'missions'), missionDetails);
      console.log('Mission added successfully');
    } catch (err) {
      setError('Failed to add mission');
      console.error(err);
    }
  };

  return {
    missions,
    selectedMission,
    loading,
    error,
    selectMission,
    handleEditMission,
    addMission,
  };
};
