import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  Input, Textarea, Button, Box, FormControl, FormLabel, Select, Stack, Image,
  Heading, HStack, useToast
} from "@chakra-ui/react";
import Card from 'components/card';
import TagsField from 'components/fields/TagsField';

const BusinessForm = ({ companyName }) => {
  const [businessInfo, setBusinessInfo] = useState({
    name: '',
    description: '',
    website: '',
    location: '',
    founded: '',
    industry: '',
    tags: [],
    bannerImage: '',
    profileImage: '',
    galleryImages: []
  });
  const [loading, setLoading] = useState(false);
  const db = getDatabase();
  const storage = getStorage();
  const toast = useToast();

  useEffect(() => {
    const businessRef = ref(db, `businesses/byName/${companyName}/info`);
    return onValue(businessRef, (snapshot) => {
      const data = snapshot.val() || {};
      setBusinessInfo(prev => ({
        ...prev,
        ...data,
        galleryImages: data.galleryImages || []
      }));
    });
  }, [companyName, db]);

  const handleInputChange = (field, value) => {
    setBusinessInfo(prev => ({ ...prev, [field]: value }));
  };

  const handleFileUpload = async (file, type) => {
    const fileRef = storageRef(storage, `images/${type}/${companyName}/${file.name}`);
    try {
      await uploadBytes(fileRef, file);
      const fileURL = await getDownloadURL(fileRef);
      if (type === 'banner') {
        handleInputChange('bannerImage', fileURL);
      } else if (type === 'profile') {
        handleInputChange('profileImage', fileURL);
      } else if (type === 'gallery') {
        handleInputChange('galleryImages', [...businessInfo.galleryImages, fileURL]);
      }
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const saveBusinessInfo = async () => {
    setLoading(true);
    const businessRef = ref(db, `businesses/byName/${companyName}/info`);
    try {
      await update(businessRef, businessInfo);
      toast({
        title: "Success",
        description: "Business Info Updated Successfully!",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Failed to update business info:', error);
      toast({
        title: "Error",
        description: "Failed to update business info.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <Card extra={"w-full mt-3 px-6 py-6"}>
      <Heading size="lg" mb={6}>Business Settings</Heading>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Profile Image</FormLabel>
          <Input type="file" onChange={(e) => handleFileUpload(e.target.files[0], 'profile')} />
          {businessInfo.profileImage && (
            <Image src={businessInfo.profileImage} alt="Profile" mt={2} boxSize="100px" objectFit="cover" borderRadius="full" />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Banner Image</FormLabel>
          <Input type="file" onChange={(e) => handleFileUpload(e.target.files[0], 'banner')} />
          {businessInfo.bannerImage && (
            <Image src={businessInfo.bannerImage} alt="Banner" mt={2} width="100%" height="150px" objectFit="cover" borderRadius="md" />
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Gallery Images</FormLabel>
          <Input type="file" multiple onChange={(e) => Array.from(e.target.files).forEach(file => handleFileUpload(file, 'gallery'))} />
          <HStack spacing={2} mt={2}>
            {businessInfo.galleryImages && businessInfo.galleryImages.map((image, index) => (
              <Image key={index} src={image} alt={`Gallery ${index}`} boxSize="100px" objectFit="cover" borderRadius="md" />
            ))}
          </HStack>
        </FormControl>
        <FormControl>
          <FormLabel>Business Name</FormLabel>
          <Input value={businessInfo.name} onChange={(e) => handleInputChange('name', e.target.value)} placeholder="Enter business name" />
        </FormControl>
        <FormControl>
          <FormLabel>Business Description</FormLabel>
          <Textarea value={businessInfo.description} onChange={(e) => handleInputChange('description', e.target.value)} placeholder="Describe your business" />
        </FormControl>
        <FormControl>
          <FormLabel>Business Website</FormLabel>
          <Input value={businessInfo.website} onChange={(e) => handleInputChange('website', e.target.value)} placeholder="https://example.com" />
        </FormControl>
        <FormControl>
          <FormLabel>Location</FormLabel>
          <Input value={businessInfo.location} onChange={(e) => handleInputChange('location', e.target.value)} placeholder="Start typing address" />
        </FormControl>
        <FormControl>
          <FormLabel>Founded</FormLabel>
          <Input type="number" value={businessInfo.founded} onChange={(e) => handleInputChange('founded', e.target.value)} placeholder="Year of Establishment" />
        </FormControl>
        <FormControl>
          <FormLabel>Industry/Sector</FormLabel>
          <Select value={businessInfo.industry} onChange={(e) => handleInputChange('industry', e.target.value)} placeholder="Select industry">
            {/* Add options here */}
            <option value="Technology">Technology</option>
            <option value="Retail">Retail</option>
            <option value="Finance">Finance</option>
            <option value="Healthcare">Healthcare</option>
            <option value="Manufacturing">Manufacturing</option>
            <option value="Education">Education</option>
            <option value="Energy">Energy</option>
            <option value="Transportation">Transportation</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Entertainment">Entertainment</option>
            <option value="Nonprofit">Nonprofit</option>
            <option value="Agriculture">Agriculture</option>
            <option value="Food & Beverage">Food & Beverage</option>
            <option value="Hospitality">Hospitality</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Tags</FormLabel>
          {businessInfo.tags && <TagsField 
            tags={businessInfo.tags} 
            setTags={(newTags) => handleInputChange('tags', newTags)} 
            placeholder="Add tags"
          />}
        </FormControl>
        <Button colorScheme="blue" isLoading={loading} onClick={saveBusinessInfo}>Save Changes</Button>
      </Stack>
    </Card>
  );
};

export default BusinessForm;
