import SingleChat from "./components/SingleChat";
import CardMenu from "./components/CardMenu"
import { BsPencilSquare } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import Chat from "./components/Chat";
import { db, auth } from "../../../../../firebase";
import { off, onChildAdded, onValue, push, ref, set } from 'firebase/database'
import avatar2 from "assets/img/profile/avatar2.png";
import Card from "components/card";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "contexts/AuthContext";
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from "contexts/store";
import { addMessage, clearMessages } from 'store/Actions/action';
import RootState from "store/rootState";
import { getMessages, selectUser } from "store/userSelector";
import { setSelectedChat } from "store/Reducers/chatReducer";
import Emails from "./components/Emails";
export interface TeamMessage {
  id: string;
  chatId: string; // Make sure this is included
  senderId: string;
  receiverId: string;
  time: number;
  message: string;
}
const Messages = () => {
  const user = useSelector((state: RootState) => state.user);
  const searchContainerRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState(''); // new state variable for the search term
  const [messageText, setMessageText] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [companyKey, setCompanyKey] = useState("");
  const [userKey, setUserKey] = useState("");
  const timestamp = Date.now();
  const [view, setView] = useState<'chat' | 'emails'>('chat');
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const selectedChat = useSelector((state: RootState) => state.chat.selectedChat);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const { currentUser } = useContext(AuthContext);
  // Filter users based on search term
  const filteredUsers = users.filter((user) =>
    (`${user.firstName} ${user.lastName}`).toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Name info
  const findUserById = (id: string) => {
    const user = users.find(user => user.id === id);
    return user || null;
  };

  const messages = useSelector(getMessages);

  const findUserNameById = (id: string) => {
    // Assuming users is an array of user objects
    const user = users.find((user) => user.id === id);
    return user ? [user.firstName, user.lastName, user.photoURL] : [];
  };
  const findUserFullNameById = (id: string) => {
    const user = users.find((user) => user.id === id);
    return user ? `${user.firstName} ${user.lastName}` : '';
  };


  const extractName = (nameString: string) => {
    // Perform necessary operations on nameString
    return nameString.trim(); // Example: trim any leading or trailing whitespace
  };

  const dispatch = useDispatch();

  const [id] = useState("");

  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    if (open && window.innerWidth < 800)
      document.documentElement.style.overflow = "hidden";
    else document.documentElement.style.overflow = "unset";
  });

  useEffect(() => {
    const email = currentUser.email || "";
    if (email) {
      setUserEmail(email);
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                setUserKey(userKey);
                localStorage.setItem('companyKey', companyKey);
              }
            }
          }
        }
      });
    }
  }, [currentUser, db])

  useEffect(() => {
    if (selectedChat) {
      dispatch(clearMessages());
      const chatRef = ref(db, `companies/${companyKey}/data/chats/${selectedChat}/messages`);
  
      // Listen to new messages being added
      const unsub = onChildAdded(chatRef, (snapshot) => {
        const newMessage: TeamMessage = {
          id: snapshot.key,
          chatId: selectedChat, // Include the chatId in each message
          senderId: snapshot.val().senderId,
          receiverId: snapshot.val().receiverId,
          time: snapshot.val().time,
          message: snapshot.val().message,
        };
  
        dispatch(addMessage([newMessage])); // Dispatch with only the new message
      });
  
      return () => {
        unsub();
      };
    }
  }, [selectedChat, companyKey, dispatch]);
  




  // Fetch users when component loads
  useEffect(() => {
    // Fetch users from database
    const usersRef = ref(db, `companies/${companyKey}/users`);
    onValue(usersRef, (snapshot) => {
      if (snapshot.exists()) {
        setUsers(snapshot.val());
      }
    });
  }, [db, companyKey]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
        setSearchTerm(''); // Clear the search term to hide the search results
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);




  // useEffect for fetching chats
  useEffect(() => {
    const currentUserId = currentUser.uid; // Assuming currentUser is defined
  const chatsRef = ref(db, `companies/${companyKey}/data/chats`);
      onValue(chatsRef, (snapshot) => {
        const chatsData = snapshot.val();
        const chats = [];
        for (const chatKey in chatsData) {
          const chat = chatsData[chatKey];
          if (chat.participants && chat.participants.includes(currentUser.uid)) {
            chat.id = chatKey;

            // Assuming the last message is the one you want to display
            const messagesArray: TeamMessage[] = chat.messages ? Object.values(chat.messages) : [];
            const lastMessage: TeamMessage | null = messagesArray.pop() || null;

            // Assuming participants[0] is always the sender
            const senderId = chat.participants[0];
            const receiverId = chat.participants[1];
            
            // Find the corresponding user info for the sender
            const senderUser = findUserById(senderId);

            // Update the chat object with the required properties
            chat.name = senderUser ? `${senderUser.firstName} ${senderUser.lastName}` : '';
            chat.message = lastMessage ? lastMessage.message : '';
            chat.senderId = senderId;
            chat.receiverId = receiverId;
            chat.time = lastMessage ? lastMessage.time : null;
            chat.photo = ''; // Add logic to get the photo if needed

            chats.push(chat);
          }
        }
        setChats(chats);
      });
    
  }, [companyKey, currentUser]);


  // Handle the selection of a user
  const createNewChat = async (selectedUser: { id: any; }) => {
    // Get current user's ID
    const currentUserId = currentUser.uid;
    // Get selected user's ID
    const selectedUserId = selectedUser.id;
    // Define participants
    const participants = [currentUserId, selectedUserId];
    // Define initial messages if needed
    const messages: any[] = [];
    // Create a new chat object
    const newChat = {
      participants,
      messages,
    };
  
    // Push the new chat object to the database
    const chatsRef = ref(db, `companies/${companyKey}/data/chats`);
    const newChatRef = push(chatsRef);
    await set(newChatRef, newChat);
  
    // Get the new chat's key (ID)
    const newChatId = newChatRef.key;
  
    // Optionally, dispatch actions to update the UI
    dispatch(setSelectedChat(newChatId)); // Set the new chat as the selected chat
  
    console.log(`New chat created with ID: ${newChatId}`);
  };
  

  const handleUserSelect = (user: { id: any; }) => {
    setSelectedUser(user);
    const chatId = chats.find(chat =>
      chat.participants.includes(currentUser.uid) &&
      chat.participants.includes(user.id)
    );

    if (chatId) {
      const chatIdString = chatId.id;
      dispatch(setSelectedChat(chatIdString)); // Dispatch Redux action
  
      console.log(chatIdString)
    } else {
      createNewChat(user);
    }
  };
 

  // User Search
  useEffect(() => {
    const usersRef = ref(db, `companies/${companyKey}/users`);
    onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const usersArray = Object.keys(data).map((key) => ({
          id: key,
          ...data[key],
        }));

        setUsers(usersArray);
        setSelectedUser(usersArray);  // Select the first user by default
      }
    });
  }, [companyKey, user]);  // Added companyKey as a dependency

  // Passing the Determined User
  const determineReceiverId = () => {
    if (selectedChat) {
      // Find the chat object in your chats array
      const chat = chats.find(chat => chat.id === selectedChat);

      // Extract the participants
      const participants = chat?.participants || [];

      // Filter out the current user's ID
      const receiverId = participants.find((id: string) => id !== currentUser.uid);

      return receiverId;
    }
    return null;
  };
  const receiverId = determineReceiverId();
  const otherParticipant = findUserById(receiverId);
  const otherParticipantName = otherParticipant ? `${otherParticipant.firstName} ${otherParticipant.lastName}` : '';

  return (
    <div className="mt-[80px] flex w-full grid-cols-10 flex-col gap-5 md:mt-[20px] lg:mt-6 lg:min-h-[85vh] lg:flex-row">
      <div className="w-full lg:w-[35%]">
        <Card extra={"w-full h-full p-4"}>
          {/* header */}
          <div className="mb-3 flex items-center justify-between">
            <h1 className="font-dm text-[24px] font-bold text-navy-700 dark:text-white">
              Your Chats
            </h1>
            <CardMenu 
    onTeamMessagesClick={() => setView('chat')} 
    onEmailsClick={() => setView('emails')} 
/>
  

          </div>
          {/* search */}
          <div className="flex w-full items-center gap-2">
            {/* Search bar */}
            <div ref={searchContainerRef} className="flex h-[42px] w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white ">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400" />
              </p>
              {/* Render the search bar and the user list */}
              <input
                type="text"
                placeholder="Search users..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
              {searchTerm && (
                <div className="absolute bg-white shadow-md mt-1 rounded-md overflow-hidden w-64 max-h-60">
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map((user) => (
                      <div
                        key={user.id}
                        onClick={() => handleUserSelect(user)}
                        className="cursor-pointer px-3 py-1 hover:bg-gray-100"
                      >
                        {user.firstName} {user.lastName}
                      </div>
                    ))
                  ) : (
                    <div className="px-3 py-1 text-gray-500">No users found</div>
                  )}
                </div>
              )}
            </div>
            <button className="flex items-center justify-center rounded-full bg-brand-500 p-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-700 active:bg-blue-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90">
              <BsPencilSquare />
            </button>
          </div>
          <div className="mt-4" onClick={() => setOpen(true)}>
            { view === 'chat' && 
              chats.map((chat, index) => {
                const otherParticipantId = chat.participants.find((id: string) => id !== currentUser.uid);
                // Find the user associated with the other participant's ID
                const otherParticipant = findUserById(otherParticipantId);
                // Extract the name of the other participant
                const userName = otherParticipant ? `${otherParticipant.firstName} ${otherParticipant.lastName}` : '';
                // Extract the last message (if messages are stored in the chat object)
                const lastMessage = chat.messages ? chat.messages[chat.messages.length - 1] : null;
                // Convert the timestamp to a readable time format (if needed)
                const time = chat.time ? new Date(chat.time).toLocaleString() : 'N/A'; // Assuming you have a function to format the time
            

                return (
                  <Chat
                    key={index}
                    id={chat.id}
                    participants={chat.participants}
                    photo={'https://images.unsplash.com/photo-1628890923662-2cb23c2e0cfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80'} // Provide the photo URL if available
                    name={userName} // Use the user name
                    message={lastMessage ? lastMessage.message : chat.message} // Use the last message
                    senderId={chat.senderId}
                    time={time} // Use the formatted time
                    active={true}
                    onClick={() => {
                      console.log("Setting selected chat ID:", chat.id); // Debug line
                      dispatch(setSelectedChat(chat.id));
                    }}
                  />
                );
              })
            }
               {view === 'emails' && 
 <Emails subject={"I need money"} from={"Billy"} body={"Ive changed the variable name from msg to chat for clarity.Ive updated the key prop to use chat Ive provided default values for some of the props using the logical OR (||) operator, in case theyre undefined. You can adjust these default values as needed" } time={""} />
    }

          </div>
        </Card>
      </div>
      <div className="w-full lg:w-[64.5%]">
        {selectedChat && selectedUser && ( // Check if a chat and user are selected
          <SingleChat
            currentUser={currentUser}  // Pass the selected user
            open={open} onClose={() => setOpen(false)}
            messages={messages}  // Pass the messages
            receiverId={receiverId}
            otherParticipantName={otherParticipantName} // Pass the other participant's name
          />
        )}
      </div>
    </div>
  );
};

export default Messages;
