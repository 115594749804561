import { DataSnapshot, onValue, ref } from 'firebase/database';
import auth, { db } from '../../../../../../firebase';
import React, { useState, useEffect } from 'react';

import Volunteer from '..';
import axios from 'axios';
import {  Button ,Input, InputGroup, InputRightElement, Spinner} from '@chakra-ui/react';
import { preventDefault } from '@fullcalendar/react';



interface VolunteerProps {
 
    volunteerInfo: {
      volunteerName: string;
      volunteerAge: number;
      volunteerEmail: string;
      volunteerAddress:string;
      volunteerStatus: string;
      volunteerDate: string;
      volunteerRole: string;
      volunteerMissions:  string[] | number;
    };
  }
  
  type RowObj = {
    volunteerName: [string, string, string];
    volunteerDate: string;
    volunteerRole: string;
    volunteerStatus: string;
    edit?: any;
    volunteerMissions: string[] | number;
  };

  type RowObj2 = {
    missionDetails: {
      missionName: string;
      location: string;
      missionCategory: string;
      missionDescription: string;
      missionGoals: string;
      teamAndVolunteers: {
        teamContact: string;
        teamLeader: string;
        team: string;
        volunteerRequirements: string;
      };
      missionLogistics: {
        missionPartnerships: string;
        missionResources: string;
      };
    };
  };

const InviteVolunteerComponent = () => {
    const [companyKey, setCompanyKey] = useState<string | null>(null);
    const [volunteerData, setVolunteerData] = useState<RowObj[]>([]);
  const currentUser = auth.currentUser;
  const [userEmail, setUserEmail] = useState("");
  const [userKey, setUserKey] = useState("");
  const [volunteers, setVolunteers] = useState([]);
  const [missions, setMissions] = useState([]);
  const [selectedMission, setSelectedMission] = useState('');
  const [selectedVolunteers, setSelectedVolunteers] = useState([]);
  const [missionData, setMissionData] = useState<RowObj2[]>([]);
  // const sgMail = require("@sendgrid/mail");
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [message, setMessage] = useState('');
  const [inputValue, setInputValue] = useState('');
const [isLoading] = useState(false)
const [generatedMessage, setGeneratedMessage] = useState('');
const [searchTerm, setSearchTerm] = useState("");
const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
  setInputValue(e.target.value);
};

  
  // Toggle between light and dark mode
  const toggleDarkMode = () => {
    setIsDarkMode((prevMode) => !prevMode);
  };


  const handleSearch = async () => {
    preventDefault(Event);
    try {
      const response = await axios.post(
        'https://api.writesonic.com/v2/business/content/ans-my-ques?num_copies=1',
        {
          
          userData: {
            searchTerm: searchTerm,
          
            volunteerData,
                          missionData
          },
        },
        {
          headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'X-API-KEY': 'cefc3701-c731-4988-8f9c-371579877c7d',
          },
        }
      );
      const data = response.data;
      setGeneratedMessage(data[0].text);
    } catch (error) {
      console.error(error);
    }
  };
   
// Fetch user and accesss
  useEffect(() => {
    const email = currentUser.email || "";
    if (email) {
      setUserEmail(email);
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                setUserKey(userKey);
                localStorage.setItem("companyKey", companyKey);
              }
            }
          }
        }
      });
    }
  }, [currentUser, db]);

  useEffect(() => {
    // Fetch active volunteers from your database
    const fetchVolunteers = async () => {
     
            if (companyKey) {
              const volunteerRef = ref(db, `companies/${companyKey}/volunteers/`);
              onValue(volunteerRef, (snapshot: DataSnapshot) => {
                const volunteers = snapshot.val() as Record<string, VolunteerProps> | null;
                if (volunteers) {
                    const volunteerArray: RowObj[] = Object.entries(volunteers).map(([id, volunteerProps]) => {
                        const {
                          volunteerName: [name, email, profilePicture],
                          volunteerAge,
                          volunteerEmail,
                          volunteerAddress = '',
                          volunteerStatus = '',
                          volunteerDate = '',
                          volunteerRole = '',
                          volunteerMissions,
                        } = volunteerProps.volunteerInfo;
              
                        return {
                          id,
                          volunteerName:[name,email,profilePicture],
                          volunteerEmail,
                          volunteerRole,
                          volunteerAddress,
                          volunteerStatus,
                          volunteerDate,
                          volunteerAge,
                          volunteerMissions,
                        };
                      });
                      setVolunteerData(volunteerArray);
               
                } else {
                  setVolunteerData([]);
                  console.log("No data found");
                }
              });
            }
          };
         
          fetchVolunteers();
        }, [companyKey]);

    
 

    // Fetch available missions from your database
    useEffect(() => {
        const fetchMissionData = async () => {
          if (companyKey) {
            const missionsRef = ref(db, `companies/${companyKey}/missions/`);
            onValue(missionsRef, (snapshot: DataSnapshot) => {
              const missions = snapshot.val();
              if (missions) {
                const missionArray: RowObj2[] = Object.values(missions);
                setMissionData(missionArray);
             
              } else {
                setMissionData([]);
                console.log("No data found");
              }
            });
          }
        };
      
        fetchMissionData();
      }, [companyKey]);

  const handleMissionChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setSelectedMission(e.target.value);
  };

  const handleVolunteerSelection = (volunteerId: any) => {
    setSelectedVolunteers((prevSelectedVolunteers) => {
      if (prevSelectedVolunteers.includes(volunteerId)) {
        // If the volunteer is already selected, remove them
        return prevSelectedVolunteers.filter((id) => id !== volunteerId);
      } else {
        // If the volunteer is not selected, add them
        return [...prevSelectedVolunteers, volunteerId];
      }
    });
  };

  const handleInviteVolunteers = async () => {
    // Get the selected volunteers and mission details
    const selectedVolunteersData = volunteers.filter((volunteer) =>
      selectedVolunteers.includes(volunteer.id)
    );
    const selectedMissionData = missions.find(
      (mission) => mission.id === selectedMission
    );
    function IsValidEmail(email: string) {
      var reEmail = 'tim903@g.harvard.edu';
      if (!email.match(reEmail)) {
          return false;
      }
      return true;
  }
}




    // Prepare the email content
  //   const emailContent = `You are invited to join the mission: ${selectedMissionData.missionName}\n\n` +
  //     `Location: ${selectedMissionData.missionDetails.location}\n` +
  //     `Date: ${selectedMissionData.missionLogistics.missionDate}\n` +
  //     `Category: ${selectedMissionData.missionDetails.missionCategory}\n` +
  //     `Description: ${selectedMissionData.missionDetails.missionDescription}\n` +
  //     `Goals: ${selectedMissionData.missionDetails.missionGoals}\n`;

  //   // Send email invitations to the selected volunteers
  //   for (const volunteer of selectedVolunteersData) {
  //     const emailData = {
  //       to: volunteer.volunteerEmail,
  //       from: 'tim903@g.harvard.edu', // Replace with your email address
  //       subject: 'Mission Invitation',
  //       text: emailContent,
  //     };

  //     try {
  //       // Use your SendGrid API library to send emails
  //       await sgMail.send(emailData);
  //       console.log(`Invitation email sent to ${volunteer.volunteerEmail}`);
  //     } catch (error) {
  //       console.error(`Failed to send invitation email to ${volunteer.volunteerEmail}`, error);
  //     }
  //   }
  // };
 
  return (
<div className={`bg-${isDarkMode ? 'gray-900' : 'gray-100'} p-4`}>
      <h2 className={`text-${isDarkMode ? 'white' : 'black'} text-2xl font-bold mb-4`}>Invite Volunteers to a Mission</h2>
      <h3 className={`text-${isDarkMode ? 'white' : 'black'} text-lg font-bold mb-2`}>Active Volunteers</h3>
      <ul className="mb-4">
        {volunteerData.map((volunteer) => (
          <li key={volunteer.volunteerName.indexOf(volunteer.volunteerName[0])} className={`flex items-center mb-2 ${isDarkMode ? 'text-white' : 'text-black'}`}>
            <input
              type="checkbox"
              className="mr-2"
              checked={selectedVolunteers.includes(volunteer.volunteerName)}
              onChange={() => handleVolunteerSelection(volunteer.volunteerName)}
            />
            <span>{volunteer.volunteerName[0]}</span>
          </li>
        ))}
      </ul>
      <h3 className={`text-${isDarkMode ? 'white' : 'black'} text-lg font-bold mb-2`}>Available Missions</h3>
      <select
        value={selectedMission}
        onChange={handleMissionChange}
        className={`border border-${isDarkMode ? 'white' : 'gray-300'} rounded px-4 py-2 mb-4 bg-transparent ${isDarkMode ? 'text-white' : 'text-black'}`}
      >
        <option value="">Select a mission</option>
        {missionData.map((mission) => (
          <option key={mission.missionDetails.missionName} value={mission.missionDetails.missionName}>
            {mission.missionDetails.missionName}
          </option>
        ))}
      </select>
      <form onSubmit={undefined} className="mb-4">
        <InputGroup>
        <Input
        variant="search"
        fontSize="md" // Increased font size to "md"
        bg={isDarkMode ? 'gray.800' : 'gray.200'}
        color={isDarkMode ? 'white' : 'black'}
        fontWeight="500"
        _placeholder={{ color: 'gray.400', fontSize: '14px' }}
        borderRadius="30px"
        placeholder="Write your personalized message..."
        name="search"
        value={inputValue}
        onChange={handleChange}
        disabled={isLoading}
      />
      {generatedMessage && (
  <div className="bg-white rounded p-4 mb-4">
    <h4 className="text-lg font-bold mb-2">Generated Message</h4>
    <p className={`text-${isDarkMode ? 'white' : 'black'}`}>{generatedMessage}</p>
  </div>
)}
      <InputRightElement>
    {isLoading ? (
      <Spinner size="sm" color="gray.500" /> // Loading spinner
    ) : (
      <button onClick={handleSearch} disabled={isLoading}>
        Generate Message
      </button>
    )}
  </InputRightElement>
</InputGroup>
      </form>
      {message && (
        <div className="bg-white rounded p-4 mb-4">
          <h4 className="text-lg font-bold mb-2">Generated Message</h4>
          <p className={`text-${isDarkMode ? 'white' : 'black'}`}>{message}</p>
        </div>
      )}
      <div className="mt-4">
        <label className="flex items-center">
          <input
            type="checkbox"
            className="mr-2"
            checked={isDarkMode}
            onChange={toggleDarkMode}
          />
          <span className={`text-${isDarkMode ? 'white' : 'black'}`}>Toggle Dark Mode</span>
        </label>
      </div>
    </div>
  );
};

export default InviteVolunteerComponent;
