import React, { useEffect, useState } from 'react';
import Map, { Layer, Marker, Source } from 'react-map-gl';
import Card from 'components/card';
import "mapbox-gl/dist/mapbox-gl.css";
import { MdLocationOn } from "react-icons/md";
import { Center, Text, useToast } from '@chakra-ui/react';
import { collection, doc, getFirestore, setDoc, getDoc, updateDoc, getDocs, writeBatch, query, where } from 'firebase/firestore';
import { IoMdAddCircleOutline } from 'react-icons/io';
import auth from '../../../../../firebase';
import CommunityGoalsModal from './CommunityGoalsModal';
import '../../style/MapsStyle.css';
import { Legend } from './Legend';

interface Zone {
  id: string;
  activityLevel: string;
  longitude: number;
  latitude: number;
}

interface Measurement {
  parameter: string;
  value: number;
  lastUpdated: string;
  unit: string;
  sourceName: string;
}

interface AirQualityResult {
  location: string;
  city: string;
  country: string;
  measurements: Measurement[];
}

interface AirQualityInfo {
  meta: {
    found: number;
    license: string;
    website: string;
    page: number;
    limit: number;
    name: string;
  };
  results: AirQualityResult[];
}

const MAPBOX_TOKEN = "pk.eyJ1Ijoic2ltbW1wbGUiLCJhIjoiY2wxeG1hd24xMDEzYzNrbWs5emFkdm16ZiJ9.q9s0sSKQFFaT9fyrC-7--g";
const DARK_MODE_STYLE = "mapbox://styles/simmmple/cl0qqjr3z000814pq7428ptk5";
const LIGHT_MODE_STYLE = "mapbox://styles/simmmple/ckwxecg1wapzp14s9qlus38p0";

const defaultViewState = {
  latitude: 32.7767,
  longitude: -96.7970,
  zoom: 13
};

const MapCard = () => {
  const [mapStyle, setMapStyle] = useState(document.body.classList.contains("dark") ? DARK_MODE_STYLE : LIGHT_MODE_STYLE);
  const [viewState, setViewState] = useState(defaultViewState);
  const [userLocation, setUserLocation] = useState({ latitude: 32.7767, longitude: -96.7970 });
  const [zones, setZones] = useState<Zone[]>([]);
  const toast = useToast();
  const [showCommunityGoalsModal, setShowCommunityGoalsModal] = useState(false);
  const [communityGoals, setCommunityGoals] = useState([]);
  const [currentZipCode, setCurrentZipCode] = useState(null);
  const [latitude, setLatitude] = useState(32.7767);
  const [longitude, setLongitude] = useState(-96.7970);
  const [airQualityInfo, setAirQualityInfo] = useState<AirQualityInfo | null>(null);
  const currentUser = auth.currentUser;

  useEffect(() => {
    const observer = new MutationObserver(() => {
      setMapStyle(document.body.classList.contains("dark") ? DARK_MODE_STYLE : LIGHT_MODE_STYLE);
    });

    observer.observe(document.body, { attributes: true });

    const savedLocation = localStorage.getItem('userLocation');
    const locationPermissionGranted = localStorage.getItem('locationPermissionGranted');

    if (savedLocation && locationPermissionGranted === 'true') {
      const location = JSON.parse(savedLocation);
      setUserLocation(location);
      setViewState({ ...viewState, latitude: location.latitude, longitude: location.longitude });
      setLatitude(location.latitude);
      setLongitude(location.longitude);
    } else {
      navigator.geolocation.getCurrentPosition(position => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        setUserLocation(location);
        setViewState({ ...viewState, latitude: position.coords.latitude, longitude: position.coords.longitude });
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        localStorage.setItem('userLocation', JSON.stringify(location));
        localStorage.setItem('locationPermissionGranted', 'true');
      }, error => {
        console.error("Error fetching user's location: ", error);
        toast({
          title: "Location Access Denied",
          description: "Please enable location services for this app in your browser settings.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }, { enableHighAccuracy: true });
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const fetchZones = async () => {
      const firestore = getFirestore();
      const querySnapshot = await getDocs(collection(firestore, "zones"));
      const zonesData = querySnapshot.docs.map(doc => ({
        ...doc.data() as any,
        id: doc.id,
      }) as Zone);
      setZones(zonesData);
    };

    fetchZones();
  }, []);

  const handleMapClick = async (event: { lngLat: { lng: number; lat: number; }; }) => {
    const { lng, lat } = event.lngLat;
    const zipCode = await reverseGeocode(lat, lng);
    if (zipCode) {
      setCurrentZipCode(zipCode);
      setLatitude(lat);
      setLongitude(lng);
      setShowCommunityGoalsModal(true);
    }
  };

  const zonesGeoJSON: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    type: 'FeatureCollection',
    features: zones.map(zone => ({
      type: 'Feature',
      properties: {
        activityLevel: zone.activityLevel,
      },
      geometry: {
        type: 'Point',
        coordinates: [zone.longitude, zone.latitude],
      },
    })) as GeoJSON.Feature[],
  };

  const activityColor = (level: any) => {
    switch (level) {
      case 'high': return 'green';
      case 'medium': return 'yellow';
      case 'low': return 'red';
      default: return 'grey';
    }
  };

  const firestore = getFirestore();
  const userId = auth.currentUser?.uid;

  const createPrebuiltGoals = async (zipCode: any) => {
    const prebuiltGoals = [
      {
        title: "Improve Local Parks",
        description: "Aim to have cleaner and more accessible local parks.",
        zipCode: zipCode,
        milestones: [
          { description: "Organize monthly clean-up events", achieved: false },
          { description: "Install new playground equipment", achieved: false },
        ],
      },
    ];

    const batch = writeBatch(firestore);

    prebuiltGoals.forEach((goal) => {
      const goalRef = doc(collection(firestore, "goals"));
      batch.set(goalRef, {
        title: goal.title,
        description: goal.description,
        zipCode: zipCode,
      });

      goal.milestones.forEach((milestone) => {
        const milestoneRef = doc(collection(firestore, "milestones"));
        batch.set(milestoneRef, {
          description: milestone.description,
          achieved: milestone.achieved,
          goalId: goalRef.id,
        });
      });
    });

    await batch.commit();
  };

  const initializeCommunityGoals = async (zipCode: string) => {
    const goalsRef = collection(firestore, 'goals');
    const existingGoalsQuery = query(goalsRef, where('zipCode', '==', zipCode));
    const querySnapshot = await getDocs(existingGoalsQuery);

    if (!querySnapshot.empty) {
      console.log('Goals for this zip code already exist.');
      return;
    }

    const predefinedGoals = [
      {
        title: 'Community Clean Up',
        description: 'Organize a community clean-up every month.',
        milestones: [
          { description: 'First clean-up', achieved: false },
          { description: 'Clean-up with 50 volunteers', achieved: false },
        ],
      },
    ];

    const batch = writeBatch(firestore);

    predefinedGoals.forEach((goal) => {
      const goalDocRef = doc(goalsRef);
      batch.set(goalDocRef, {
        title: goal.title,
        description: goal.description,
        zipCode: zipCode,
      });

      goal.milestones.forEach((milestone) => {
        const milestoneDocRef = doc(collection(firestore, 'milestones'));
        batch.set(milestoneDocRef, {
          description: milestone.description,
          achieved: milestone.achieved,
          goalId: goalDocRef.id,
        });
      });
    });

    await batch.commit();
  }

  return (
    <>
      <Text style={{ fontSize: '1.5rem', marginBottom: 4, textAlign: 'center' }}>Near You</Text>
      <Card className="h-[600px] mb-4 map-container">
        <div className="toggle-button-container">
          <Legend />
        </div>
        <Map
          {...viewState}
          onClick={handleMapClick}
          onMove={evt => setViewState(evt.viewState)}
          style={{ borderRadius: "10px",  minHeight: "100%", height: "600px" }}
          mapboxAccessToken={MAPBOX_TOKEN}
          mapStyle={mapStyle}
        > 
          <Source id="zones" type="geojson" data={zonesGeoJSON}>
            <Layer
              id="zones-circle"
              type="circle"
              source="zones"
              paint={{
                'circle-radius': 125,
                'circle-color': [
                  'match',
                  ['get', 'activityLevel'],
                  'high', 'rgba(0, 255, 0, 0.5)',
                  'medium', 'rgba(255, 255, 0, 0.5)',
                  'low', 'rgba(255, 0, 0, 0.5)',
                  /* other */ 'rgba(204, 204, 204, 0.5)'
                ],
                'circle-opacity': 0.5,
              }}
            />
          </Source>
          <Marker
            longitude={userLocation.longitude}
            latitude={userLocation.latitude}
            anchor="bottom"
            onClick={() => setShowCommunityGoalsModal(true)}
          >
            <div className="marker">
              <div className="pulse">
                <MdLocationOn size={30} color="#9374FF" />
              </div>
              <div className="tooltip">Click for more info</div>
            </div>
          </Marker>
        </Map>
      </Card>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
        {showCommunityGoalsModal && (
          <CommunityGoalsModal
            onClose={() => setShowCommunityGoalsModal(false)}
            zipCode={currentZipCode}
            latitude={latitude}
            longitude={longitude}
          />
        )}
      </div>
    </>
  );
};

async function reverseGeocode(latitude: number, longitude: number): Promise<string | null> {
  try {
    const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${MAPBOX_TOKEN}`);
    const data = await response.json();
    const zipCode = data.features.find((feature: { place_type: string[]; }) => feature.place_type.includes('postcode'))?.text;
    console.log("Found zip code:", zipCode);
    return zipCode;
  } catch (error) {
    console.error("Error in reverseGeocode:", error);
    return null;
  }
}

async function updateOrCreateZone(zipCode: string, userId: string, latitude: number, longitude: number): Promise<void> {
  const firestore = getFirestore();
  const zoneRef = doc(firestore, `zones/${zipCode}`);
  try {
    const docSnap = await getDoc(zoneRef);

    if (docSnap.exists()) {
      const users = docSnap.data().users || [];
      if (!users.includes(userId)) {
        await updateDoc(zoneRef, {
          users: [...users, userId],
        });
      }
    } else {
      await setDoc(zoneRef, {
        users: [userId],
        activityLevel: 'low',
        latitude,
        longitude
      });
    }
  } catch (error) {
    console.error("Error updating or creating zone:", error);
  }
}

export default MapCard;
