import Information from "./components/Information";

type TeamAndVolunteers = {
  teamContact: string;
  teamLeader: string;
  team: string;
  volunteerRequirements: string;
};

type MissionLogistics = {
  missionPartnerships: string;
  missionResources: string;
};

type RowObj = {
  missionDetails: {
    missionName: string;
    location: string;
    missionCategory: string;
    missionDescription: string;
    missionGoals: string;
    teamAndVolunteers: TeamAndVolunteers;
    missionLogistics: MissionLogistics;
  };
};

type SettingsProductProps = {
  selectedMission: RowObj;
  handleEditMission: (mission: RowObj) => void;
};

const SettingsProduct = ({ selectedMission, handleEditMission }: SettingsProductProps) => {
  if (!selectedMission || !selectedMission.missionDetails) {
    return <div>Loading...</div>;
  }

  const handleUpdateMission = (updatedMission: Partial<RowObj['missionDetails']>) => {
    const newMission = {
      ...selectedMission,
      missionDetails: {
        ...selectedMission.missionDetails,
        ...updatedMission,
      },
    };
    handleEditMission(newMission);
  };

  return (
    <div className="mt-4 grid h-full w-full grid-cols-2 gap-5 xl:mt-3">
      {/* left side */}
      <div className="col-span-2 flex h-full w-full flex-col gap-5 xl:col-span-1">
        {/* Placeholder for potential future components */}
      </div>
      {/* right side */}
      <div className="col-span-2 flex h-full w-full flex-col gap-5 text-xl font-bold text-navy-700 dark:text-white">
        <Information
          missionName={selectedMission.missionDetails.missionName}
          location={selectedMission.missionDetails.location}
          missionCategory={selectedMission.missionDetails.missionCategory}
          missionDescription={selectedMission.missionDetails.missionDescription}
          missionGoals={selectedMission.missionDetails.missionGoals}
          teamAndVolunteers={selectedMission.missionDetails.teamAndVolunteers}
          missionLogistics={selectedMission.missionDetails.missionLogistics}
          onUpdateMission={handleUpdateMission}
        />
      </div>
    </div>
  );
};

export default SettingsProduct;
