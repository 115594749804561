import { Button, ButtonGroup, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card';
import React, { useEffect, useState } from 'react';
import { FiSearch } from "react-icons/fi";
import { To, useNavigate } from 'react-router-dom';
import CreateMissionButton from './CreateMissionButton';
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { getStorage, ref as storageRef2, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getDatabase, get, ref } from 'firebase/database';
import { addDoc, collection, doc, getDocs, updateDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import MissionsDisplay from './MissionsDisplay';
import MapCard from './MapCard';
import NavigationMenu from '../toolsComponents/NavigationMenu';
import Search from '../toolsComponents/Search';
import { Legend } from './Legend';
import { getAuth } from 'firebase/auth';

interface Mission {
  id: string;
  missionName: string;
  location: string;
  fileUrl: string;
  coAuthors?: { [key: string]: { status: string } };
}

interface Group {
  id: string;
  title: string;
  logo: string;
}

interface SearchProps {
  missions: Mission[];
  groups: Group[];
}

const IdeaGeneration = () => {
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  const [missionName, setMissionName] = useState('');
  const [location, setLocation] = useState('');
  const [missionCategory, setMissionCategory] = useState('');
  const [missionGoals, setMissionGoals] = useState('');
  const [missionDescription, setMissionDescription] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [filter, setFilter] = useState('all');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [filteredGroups, setFilteredGroups] = useState('');
  const db = getDatabase();
  const storage = getStorage();
  const navigate = useNavigate();
  const firestore = getFirestore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [mapCoordinates, setMapCoordinates] = useState({ latitude: 37.7749, longitude: -122.4194 });
  const [pendingVolunteers, setPendingVolunteers] = useState([]);
  const [missions, setMissions] = useState<Mission[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [volunteerBusinessName, setVolunteerBusinessName] = useState<string | null>(null);
  const toast = useToast();

  const [formState, setFormState] = useState({
    missionName: '',
    location: '',
    missionCategory: '',
    missionGoals: '',
    missionDescription: '',
    fileUrl: '',
    authorId: '',
    conservationType: '',
    roles: [],
    formFields: [],
    isOnlineMission: false,
    isTimeCommitmentRequired: false,
    urgencyLevel: '',
    startDate: new Date(),
    onlineUrl: '',
    skills: [],
    files: [],
    groups: '',
    participantsMetric: 0
  });

  const handleNavigation = (path: To) => {
    navigate(path);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setFile(file);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  
    const { files, ...dataToSaveWithoutFiles } = formState;
  
    const uploadPromises = files.map(async file => {
      const storageRef = storageRef2(storage, `missions/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      return await getDownloadURL(snapshot.ref);
    });
  
    try {
      const fileUrls = await Promise.all(uploadPromises);
      const dataToSave = {
        ...dataToSaveWithoutFiles,
        fileUrls,
      };
  
      const docRef = await addDoc(collection(firestore, 'missions'), dataToSave);
      console.log("Mission added successfully with files", docRef.id);
  
    } catch (error) {
      console.error("Error adding document with files: ", error);
    }
  };

  useEffect(() => {
    const fetchVolunteerBusinessName = async () => {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      if (currentUser) {
        const volunteerRef = ref(db, `volunteers/${currentUser.uid}/businessName`);
        const snapshot = await get(volunteerRef);
        if (snapshot.exists()) {
          setVolunteerBusinessName(snapshot.val());
        }
      }
    };

    fetchVolunteerBusinessName();
  }, [db]);

  useEffect(() => {
    const fetchMissions = async () => {
      const missionsCollectionRef = collection(firestore, 'missions');
      const missionsSnapshot = await getDocs(missionsCollectionRef);
      const allMissions = missionsSnapshot.docs.map(doc => ({
        id: doc.id,
        missionName: doc.data().missionName,
        location: doc.data().location,
        fileUrl: doc.data().fileUrl,
        coAuthors: doc.data().coAuthors || {},
        category: doc.data().category,
      }));
      setMissions(allMissions);
    };

    const fetchGroups = async () => {
      const groupsCollectionRef = collection(firestore, 'groups');
      const groupsSnapshot = await getDocs(groupsCollectionRef);
      const allGroups = groupsSnapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title,
        logo: doc.data().logo,
      }));
      setGroups(allGroups);
    };

    fetchMissions();
    fetchGroups();
  }, [firestore]);

  const handleAcceptCoAuthor = async (missionId: string, coAuthorUid: string) => {
    const missionRef = doc(firestore, 'missions', missionId);
    await updateDoc(missionRef, {
      [`coAuthors.${coAuthorUid}.status`]: 'accepted',
    });
    toast({
      title: 'Co-Author Accepted',
      description: 'The co-author request has been accepted.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
    // fetchMissions();
  };

  const handleDenyCoAuthor = async (missionId: string, coAuthorUid: string) => {
    const missionRef = doc(firestore, 'missions', missionId);
    await updateDoc(missionRef, {
      [`coAuthors.${coAuthorUid}.status`]: 'denied',
    });
    toast({
      title: 'Co-Author Denied',
      description: 'The co-author request has been denied.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    // fetchMissions();
  };

  return (
    <div className='className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row"'>
      <div className="idea-generation">
        <Card extra={"w-full h-full px-[30px] py-[21px] mb-4"}>
          <form onSubmit={handleSubmit}>
            <h1 style={{ textAlign: 'center', fontSize: '3rem' }}>Discover</h1>
            <Search missions={missions} groups={groups} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '10px', marginTop: 10 }}>
                <NavigationMenu openModal={openModal} handleNavigation={handleNavigation} />
                
              </div>
            </div>
            
          </form>
          
        </Card>
        {missions.filter(mission => mission.coAuthors && volunteerBusinessName && mission.coAuthors[volunteerBusinessName]?.status === 'requested').map(mission => (
            <Card key={mission.id} className="mb-4">
              <h4 className="pt-[5px] text-xl font-bold mb-2 text-navy-700 dark:text-white text-center"> Collaboration Requests</h4>
              <div className="flex items-center justify-between">
                <div>
                
                  <h2 className='pt-[5px] text-sm font-bold text-navy-700 dark:text-white text-center'>{mission.missionName}</h2>
                  <p>{mission.location}</p>
                </div>
                <div>
                  <ButtonGroup size="sm">
                    <Button onClick={() => handleAcceptCoAuthor(mission.id, volunteerBusinessName)} colorScheme="green">Accept</Button>
                    <Button onClick={() => handleDenyCoAuthor(mission.id, volunteerBusinessName)} colorScheme="red">Deny</Button>
                  </ButtonGroup>
                </div>
              </div>
            </Card>
          ))}
        <div className='col-span-11 w-full lg:col-span-3'>
          <Card extra={"w-full h-full px-[30px] py-[21px] mt-2"}>
            <MapCard />
          </Card>
        </div>
        <Card extra={"w-full h-full px-[30px] py-[21px] mt-4"}>
          <h1 style={{ textAlign: 'center', fontSize: '2rem', marginTop: 4 }} className='dark:text-white'>Join a mission</h1>

         

          <MissionsDisplay />
        </Card>
      </div>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size={'full'}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
                <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-black text-center">Mission Info</h4>
                <div className="mt-6 grid grid-cols-2 gap-3">
                  <div className="col-span-2 md:col-span-1">
                    <CreateMissionButton onOpenModal={function (): void {
                      throw new Error('Function not implemented.');
                    }} />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter />
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};

export default IdeaGeneration;
