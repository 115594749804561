import React from 'react';
import { Box } from '@chakra-ui/react';
import Notifications from 'components/notifacations';
import auth from '../../../../../firebase'
const NotificationsPage = () => {
  const userId = auth.currentUser?.uid;

  return (
    <Box p={4}>
      <Notifications userId={userId} />
    </Box>
  );
};

export default NotificationsPage;
