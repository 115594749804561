import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
  ModalHeader, ModalOverlay, Text, VStack, useDisclosure
} from '@chakra-ui/react';
import Card from 'components/card';
import { incrementVisitorCount } from 'functions/BusinessAnalytics';

interface ESRData {
  id?: string;
  name?: string;
  companyName?: string;
  title?: string;
  description?: string;
  profileImage?: string;
  impactStatement?: string;
  learnMoreLink?: string;
  website?: string;
}

interface BusinessCardProps {
  business: ESRData;
}

const BusinessCard: React.FC<BusinessCardProps> = ({ business }) => {
  const placeholderImage = "https://via.placeholder.com/150";
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedESR, setSelectedESR] = useState<ESRData | null>(null);

  const handleESRClick = () => {
    if (business) {
      const today = new Date().toLocaleDateString('en-US', { weekday: 'long' });
      setSelectedESR(business);
      incrementVisitorCount(business.name, today);
      onOpen();
    } else {
      navigate('/volunteer/profile/settings');
    }
  };

  if (!business) {
    return (
      <Card extra="mt-3 !z-5 overflow-hidden max-w-md mx-auto shadow-lg">
        <VStack spacing={4} align="stretch" p={4}>
          <Text fontWeight="bold" fontSize="xl">Best companies changing the world near you!</Text>
          <Button colorScheme="blue" onClick={handleESRClick}>Update Business Card</Button>
        </VStack>
      </Card>
    );
  }

  return (
    <Card extra="relative flex flex-col items-center justify-center rounded-[20px] bg-white p-4 shadow-lg transition-transform hover:scale-105 focus:scale-110 m-2" style={{ maxWidth: '300px', maxHeight: '400px' }}>
      <Image src={business.profileImage || placeholderImage} alt="Business Image" boxSize="50px" objectFit="cover" borderRadius="full" />
      <VStack spacing={2} align="stretch" mt={4} textAlign="center">
        <Text fontWeight="bold" fontSize="sm" isTruncated>{business.name}</Text>
        <Text fontSize="sm" noOfLines={2} lineHeight="tight">
          {business.description}
        </Text>
        <Button className='text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/7' onClick={handleESRClick}>Discover</Button>
      </VStack>

      {selectedESR && (
        <Modal isOpen={isOpen} onClose={onClose} isCentered size="xxl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedESR.companyName} {selectedESR.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <Image src={selectedESR.profileImage} alt={selectedESR.title} boxSize="200px" objectFit="cover" />
                <Text fontSize="lg">{selectedESR.description}</Text>
                <Text fontSize="sm" color="gray.500">{selectedESR.impactStatement}</Text>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button as="a" href={selectedESR.website} target="_blank">Learn More</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Card>
  );
};

export default BusinessCard;
