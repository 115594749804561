import { useEffect, useState } from "react";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import MissionDetails from "./components/steps/MissionDetails";
import TeamAndVolunteers from "./components/steps/TeamAndVolunteers";
import Card from "components/card";
import auth from "../../../../../firebase"; // Import your Firebase configuration
import { doc, setDoc, collection, addDoc, updateDoc, getDocs, getFirestore } from 'firebase/firestore'; // Import Firestore methods
import MissionLogistics from "./components/steps/missionLogistics";

interface MissionDetails {
  missionName: string;
  location: string;
  missionCategory: string;
  conservationType: string;
  missionDescription: string;
  roles: any[];
  formFields: any[];
  isOnlineMission: boolean;
  isTimeCommitmentRequired: boolean;
  urgencyLevel: string;
  startDate: Date;
  endDate: Date;
  onlineUrl: string;
  skills: any[];
  groups: string;
  participantsMetric: number;
  imageUrl?: string;
  fileUrls?: string[];
}

interface TeamAndVolunteers {
  teamContact: string;
  teamLeader: string;
  team: string;
  volunteerRequirements: string;
}

interface MissionLogistics {
  missionPartnerships: { name: string; id: number }[];
  missionResources: string;
}

const ProductNew = () => {
  const db = getFirestore();
  const [currentStep, setCurrentStep] = useState(1);
  const [companyKey, setCompanyKey] = useState<string | null>(null);
  const [missionId, setMissionId] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string>("");
  const [userKey, setUserKey] = useState<string | null>(null);
  const [missionDetails, setMissionDetails] = useState<MissionDetails>({
    missionName: "",
    location: "",
    missionCategory: "",
    conservationType: "",
    missionDescription: "",
    roles: [],
    formFields: [],
    isOnlineMission: false,
    isTimeCommitmentRequired: false,
    urgencyLevel: "",
    startDate: new Date(),
    endDate: new Date(),
    onlineUrl: "",
    skills: [],
    groups: "",
    participantsMetric: 0,
    imageUrl: "",
    fileUrls: []
  });
  const [teamDetails, setTeamDetails] = useState<TeamAndVolunteers>({
    teamContact: "",
    teamLeader: "",
    team: "",
    volunteerRequirements: "",
  });
  const [missionLogistics, setMissionLogistics] = useState<MissionLogistics>({
    missionPartnerships: [],
    missionResources: "",
  });

  useEffect(() => {
    // Retrieve current user's email from currentUser (modify this part according to your implementation)
    const currentUserEmail = auth.currentUser?.email || "";
    setUserEmail(currentUserEmail);

    if (currentUserEmail) {
      const usersRef = collection(db, "companies");
      getDocs(usersRef).then((snapshot) => {
        snapshot.forEach((doc) => {
          const company = doc.data();
          const companyKey = doc.id;
          const users = company.users;
          for (const userKey in users) {
            const user = users[userKey];
            if (user.email === currentUserEmail) {
              setUserKey(userKey);
              setCompanyKey(companyKey);
              return; // Exit the loop once the companyKey is found
            }
          }
        });
      });
    }
  }, [auth.currentUser, db]);

  useEffect(() => {
    console.log("Current Step:", currentStep);
  }, [currentStep]);

  const steps = [
    { stepNo: 1, name: "Mission Details" },
    { stepNo: 2, name: "Team and Volunteers" },
    { stepNo: 3, name: "Mission Logistics" },
  ];

  const displayStep = (step: { stepNo: number; name: string; highlighted?: boolean; selected?: boolean; completed?: boolean }) => {
    console.log("Displaying Step:", step.stepNo);
    switch (step.stepNo) {
      case 1:
        return (
          <MissionDetails
            missionDetails={missionDetails}
            setMissionDetails={setMissionDetails}
            companyKey={companyKey}
            saveMissionDetails={saveMissionDetails}
          />
        );
      case 2:
        return (
          <TeamAndVolunteers
            companyKey={companyKey}
            missionId={missionId}
            saveTeamDetails={saveTeamAndVolunteers}
            setTeamDetails={setTeamDetails}
            teamDetails={teamDetails}
          />
        );
      case 3:
        return (
          <MissionLogistics
            companyKey={companyKey}
            missionId={missionId}
            saveMissionLogistics={saveMissionLogistics}
            setMissionLogistics={setMissionLogistics}
            missionLogistics={missionLogistics}
          />
        );
      default:
        return null;
    }
  };

  const saveMissionDetails = async (data: MissionDetails) => {
    const missionDocRef = await addDoc(collection(db, `companies/${companyKey}/missions`), data);
    const missionId = missionDocRef.id;
    setMissionId(missionId);
    console.log("missionId:", missionId);
    return missionId;
  };

  const saveTeamAndVolunteers = async (missionId: string, data: TeamAndVolunteers) => {
    const teamAndVolunteersRef = doc(db, `companies/${companyKey}/missions/${missionId}`);
    await updateDoc(teamAndVolunteersRef, { teamAndVolunteers: data });
  };

  const saveMissionLogistics = async (missionId: string, data: MissionLogistics) => {
    const missionLogisticsRef = doc(db, `companies/${companyKey}/missions/${missionId}`);
    await updateDoc(missionLogisticsRef, { missionLogistics: data });
  };

  const handleClick = async (direction: string) => {
    let newStep = currentStep;
    direction === "next" ? (newStep += 1) : (newStep -= 1);

    // Check if newStep is within bounds
    if (newStep >= 1 && newStep <= steps.length) {
      setCurrentStep(newStep);
    }

    // Check if steps are within bounds
    if (direction === "next") {
      switch (currentStep) {
        case 1:
          const missionDetailsId = await saveMissionDetails(missionDetails);
          setMissionId(missionDetailsId);
          break;
        case 2:
          if (missionId) {
            await saveTeamAndVolunteers(missionId, teamDetails);
            console.log("Team and volunteers saved.");
          } else {
            console.log("missionId is undefined. Cannot save team and volunteers.");
          }
          break;
        case 3:
          if (missionId) {
            await saveMissionLogistics(missionId, missionLogistics);
            console.log("Mission logistics saved.");
          } else {
            console.log("missionId is undefined. Cannot save mission logistics.");
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[390px]" />
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3 3xl:w-7/12">
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper action={setCurrentStep} steps={steps} currentStep={currentStep} />
        </div>
        <Card extra={"h-full mx-auto pb-3"}>
          <div className="rounded-[20px]">
            <UseContextProvider>
              {displayStep(steps[currentStep - 1])}
            </UseContextProvider>
          </div>
          {/* navigation button */}
          <StepperControl handleClick={handleClick} currentStep={currentStep} steps={steps} />
        </Card>
      </div>
    </div>
  );
};

export default ProductNew;
