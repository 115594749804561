import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue, update, set } from 'firebase/database';
import { Input, Button, Box, Text, useToast, Select } from '@chakra-ui/react';

interface BusinessInfo {
  name: string;
  description: string;
  location: string;
  industry: string;
  website: string;
}

const defaultBusinessInfo: BusinessInfo = {
  name: '',
  description: '',
  location: '',
  industry: '',
  website: ''
};

const industries = [
  'Technology',
  'Healthcare',
  'Finance',
  'Education',
  'Hospitality',
  'Retail',
  'Manufacturing',
  'Construction',
  'Real Estate',
  'Transportation',
  'Energy',
  'Telecommunications',
  'Entertainment',
  'Agriculture',
  'Legal'
];

const BusinessNodeUpdateComponent = () => {
  const [businesses, setBusinesses] = useState<{ [key: string]: { info: BusinessInfo } }>({});
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const [businessData, setBusinessData] = useState<BusinessInfo>(defaultBusinessInfo);
  const [isNewBusiness, setIsNewBusiness] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const toast = useToast();
  const auth = getAuth();
  const database = getDatabase();

  useEffect(() => {
    const user = auth.currentUser;
    if (user && user.email === 'support@harmony-flow.com') {
      setAuthorized(true);
      const businessRef = ref(database, 'businesses/byName');
      onValue(businessRef, (snapshot) => {
        setBusinesses(snapshot.val() || {});
      });
    } else {
      setAuthorized(false);
    }
  }, [auth, database]);

  const handleBusinessSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const businessKey = event.target.value;
    setSelectedBusiness(businessKey);
    setIsNewBusiness(false);
    if (businessKey) {
      const businessRef = ref(database, `businesses/byName/${businessKey}/info`);
      onValue(businessRef, (snapshot) => {
        setBusinessData(snapshot.val() || defaultBusinessInfo);
      });
    } else {
      setBusinessData(defaultBusinessInfo);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setBusinessData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    if (businessData.name.trim() === '') {
      toast({
        title: 'Error',
        description: 'Business name is required.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const normalizedBusinessKey = businessData.name.trim();
    const businessRef = ref(database, `businesses/byName/${normalizedBusinessKey}/info`);
    try {
      if (isNewBusiness) {
        await set(businessRef, businessData);
        toast({
          title: 'Success',
          description: 'New business node created successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        await update(businessRef, businessData);
        toast({
          title: 'Success',
          description: 'Business node updated successfully.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update business node.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleNewBusiness = () => {
    setSelectedBusiness('');
    setIsNewBusiness(true);
    setBusinessData(defaultBusinessInfo);
  };

  if (!authorized) {
    return <Text>You do not have access to this page.</Text>;
  }

  return (
    <Box p={4} bg="white" shadow="md" borderRadius="md">
      <Text fontSize="2xl" mb={4}>Update Business Node</Text>
      <Select value={selectedBusiness} onChange={handleBusinessSelect}>
        <option value="">Select Business</option>
        {Object.keys(businesses).map((key) => (
          <option key={key} value={key}>
            {businesses[key].info.name || key}
          </option>
        ))}
      </Select>
      <Button colorScheme="blue" onClick={handleNewBusiness} mt={4}>Add New Business</Button>
      {(selectedBusiness || isNewBusiness) && (
        <Box mt={4}>
          <Input
            name="name"
            value={businessData.name}
            placeholder="Business Name"
            onChange={handleInputChange}
            mb={2}
            isDisabled={!isNewBusiness}
          />
          <Input
            name="description"
            value={businessData.description}
            placeholder="Business Description"
            onChange={handleInputChange}
            mb={2}
          />
          <Input
            name="location"
            value={businessData.location}
            placeholder="Business Location"
            onChange={handleInputChange}
            mb={2}
          />
          <Select
            name="industry"
            value={businessData.industry}
            placeholder="Select Industry"
            onChange={handleInputChange}
            mb={2}
          >
            {industries.map((industry) => (
              <option key={industry} value={industry}>{industry}</option>
            ))}
          </Select>
          <Input
            name="website"
            value={businessData.website}
            placeholder="Website"
            onChange={handleInputChange}
            mb={2}
          />
          <Button colorScheme="teal" onClick={handleSubmit}>
            {isNewBusiness ? 'Create' : 'Update'}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default BusinessNodeUpdateComponent;
