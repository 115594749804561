import React, { useEffect, useState } from "react";
import Card from "components/card";
import SessionBadge from "components/dataDisplay/SessionBadge";
import { getDatabase, ref, onValue, set } from "firebase/database";
import { getAuth } from "firebase/auth";

// Function to detect the browser
const detectBrowser = () => {
  const userAgent = navigator.userAgent;
  let browserName = "Unknown";

  if (userAgent.indexOf("Firefox") > -1) {
    browserName = "Firefox";
  } else if (userAgent.indexOf("SamsungBrowser") > -1) {
    browserName = "Samsung Internet";
  } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
    browserName = "Opera";
  } else if (userAgent.indexOf("Trident") > -1) {
    browserName = "Internet Explorer";
  } else if (userAgent.indexOf("Edge") > -1 || userAgent.indexOf("Edg") > -1) {
    browserName = "Microsoft Edge";
  } else if (userAgent.indexOf("Chrome") > -1) {
    browserName = "Chrome";
  } else if (userAgent.indexOf("Safari") > -1) {
    browserName = "Safari";
  }

  return browserName;
};

const Session = () => {
  const [sessions, setSessions] = useState([]);
  const auth = getAuth();
  const db = getDatabase();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const sessionsRef = ref(db, `sessions/${user.uid}`);
      onValue(sessionsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setSessions(Object.values(data));
        } else {
          setSessions([]);
        }
      });
    }
  }, [auth, db]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userSessionRef = ref(db, `sessions/${user.uid}`);
      set(userSessionRef, {
        browser: detectBrowser(),
        location: "Your current session",
        status: "enabled",
        lastLogin: new Date().toISOString(),
      });
    }
  }, [auth, db]);

  return (
    <Card extra={"w-full py-4 px-8"}>
      {/* Header */}
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Sessions
        </h4>
      </div>
      {/* content */}
      <div>
        {sessions.length > 0 ? (
          sessions.map((session, index) => (
            <SessionBadge
              key={index}
              name={session.browser}
              detail={session.location}
              status={session.status}
              py=" py-[27px]"
              border="border-b"
              bgTextColor={
                session.status === "enabled"
                  ? " bg-green-100 dark:bg-green-50 text-green-500"
                  : " bg-red-100 dark:bg-red-50 text-red-500"
              }
            />
          ))
        ) : (
          <p className="text-base text-gray-600">No sessions available</p>
        )}
      </div>
    </Card>
  );
};

export default Session;
