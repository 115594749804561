import React, { useEffect, useState } from 'react';
import { collection, getDocs, getFirestore, query, setDoc, where } from 'firebase/firestore';
import { doc, getDoc } from 'firebase/firestore';
import { useParams, useNavigate } from 'react-router-dom';
import Card from 'components/card';
import { MdVerified } from 'react-icons/md';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, get } from "firebase/database";
import { useToast, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { ref as firebaseRef, get as getFromRTDB } from 'firebase/database';

type VolunteerData = {
    uid: string;
    volunteerName: {
      firstName: string;
      lastName: string;
    }
}
type Member = {
    id: string;
    name: string;
    role: string;
  };

const GroupPage = ({ }) => {
    const [groupData, setGroupData] = useState(null);
    const [loading, setLoading] = useState(false);
    const { groupId } = useParams()
    const navigate = useNavigate();
    const db = getFirestore();
    const dbRT = getDatabase(); // Realtime Database instance
    const auth = getAuth(); // Authentication instance
    const [creatorName, setCreatorName] = useState('');
    const [membershipStatus, setMembershipStatus] = useState('none'); // 'none', 'requested', 'member'
    const [threads, setThreads] = useState([]);
    const [missions, setMissions] = useState([]);
    const toast = useToast();
    const [members, setMembers] = useState([]);
    const [isMembersModalOpen, setMembersModalOpen] = useState(false);


    useEffect(() => {
        const fetchGroupData = async () => {
            const docRef = doc(db, 'groups', groupId);
            const docSnap = await getDoc(docRef);
            const threadsQuery = query(collection(db, 'threads'), where('groupId', '==', groupId));
            const threadsSnapshot = await getDocs(threadsQuery);
            const threadsData = threadsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const missionsQuery = query(collection(db, 'missions'), where('groups', '==', groupId));
            const missionsSnapshot = await getDocs(missionsQuery);
            const missionsData = missionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            const membersRef = collection(db, `groups/${groupId}/members`);
            const querySnapshot = await getDocs(membersRef);
    

            if (docSnap.exists()) {
                setGroupData(docSnap.data());
                setThreads(threadsData);  
                setMissions(missionsData);
                // setMembers(membersList);

                // Fetch creator name if creatorId is available
                if (docSnap.data().creatorId) {
                    fetchCreatorName(docSnap.data().creatorId);
                }
            } else {
                console.log('No such document!');
            }
        };


        const fetchCreatorName = async (creatorId: any) => {
            const creatorRef = ref(dbRT, `volunteers/${creatorId}`);
            get(creatorRef).then((snapshot) => {
                if (snapshot.exists()) {
                    const data = snapshot.val();
                    setCreatorName(`${data.volunteerName.firstName} ${data.volunteerName.lastName}`);
                } else {
                    console.log("Creator does not exist.");
                }
            }).catch((error) => {
                console.error("Error fetching creator data:", error);
            }


            );
        };
        const fetchMembers = async () => {
            const membersRef = collection(db, `groups/${groupId}/members`);
            const querySnapshot = await getDocs(membersRef);
            const membersWithNames = await Promise.all(querySnapshot.docs.map(async (memberDoc) => {
              const userId = memberDoc.id; // UID from Firestore
              const userRef = firebaseRef(dbRT, `volunteers/${userId}`);
              const snapshot = await get(userRef);
              let memberName = 'Unknown Member';
              console.log("Fetching volunteer data for userId:", userId);
              if (snapshot.exists()) {
                const userData = snapshot.val();
                console.log("Fetched data for userId:", userId, userData);
                // Since you're directly accessing volunteerName, ensure the existence check for both firstName and lastName
                memberName = userData.volunteerName ? `${userData.volunteerName.firstName} ${userData.volunteerName.lastName}` : "Unknown Member";
                return {
                    id: userId,
                    name: memberName,
                    
                };
              } else {
                return {
                    id: userId,
                    name: "Unknown Member",
                    role: "Undefined", // Handle the case where volunteer data doesn't exist
                };
              }
            }));
          
            setMembers(membersWithNames);
          };
          
        
          
          
         
        
      fetchGroupData();
      fetchMembers();
    }, [groupId, db, dbRT]);

    const handleJoinGroup = async () => {
        setLoading(true);
        try {
            // Simulating the join process
            await new Promise(resolve => setTimeout(resolve, 2000)); // Simulate network request
            setMembershipStatus('requested'); // Update membership status to 'requested'
            toast({
                title: 'Join request sent.',
                description: "We've received your request to join the group.",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error joining group.',
                description: 'An error occurred while trying to join the group.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };
    const buttonText = {
        'none': 'Join Group',
        'requested': 'Cancel Request',
        'member': 'Leave Group'
    }[membershipStatus];
    const closeMembersModal = () => {
        setMembersModalOpen(false);
      };
    if (!groupData) return <div>Loading...</div>;

    return (
        <div className=' bg-white shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none' >

           

                <div
                    className="flex w-full flex-col rounded-[0px] bg-cover px-[30px] py-[30px] md:px-[64px] md:py-[56px] pr-4 pl-4"
                    style={{ backgroundImage: `url(${groupData.banner})` }}
                >

                    <div className="w-full">
                        <button className='linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 mb-4' onClick={() => navigate(-1)}>Back</button>
                        <h4 className="mb-[14px] max-w-full text-xl font-bold text-white md:w-[64%] md:text-3xl md:leading-[42px] lg:w-[46%] xl:w-[85%] 2xl:w-[75%] 3xl:w-[52%]">
                            {groupData.title}
                        </h4>
                        <p className="mb-[40px] max-w-full text-base font-medium text-white md:w-[64%] lg:w-[40%] xl:w-[72%] 2xl:w-[60%] 3xl:w-[45%]">

                        </p>

                        <div className="mt-[36px] flex items-center justify-between gap-4 sm:justify-start 2xl:gap-10">
                            <Button className="text-black linear rounded-xl bg-white px-4 py-2 text-center text-base font-medium transition duration-200 hover:!bg-white/80 active:!bg-white/70" onClick={handleJoinGroup}
                                isLoading={loading}
                                loadingText='Requesting...'>
                                {buttonText}
                            </Button>
                            <button className="text-base font-medium text-lightPrimary hover:text-lightPrimary 2xl:ml-2">
                                {groupData.category} group
                            </button>
                        </div>
                    </div>
                </div>
                <Card extra="w-full mt-3 px-[30px] py-[30px]">
                    <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                        Overview
                    </h4>
                    <div className="mt-4 flex items-center gap-2">
                        <p className="text-lg text-gray-600">Created by</p>
                        <p className=" text-base font-semibold text-[#3965FF] dark:text-white">
                            {creatorName}
                            {" "}
                        </p>
                        <div className="text-base text-[#3965FF]">
                            <MdVerified />
                        </div>
                    </div>
                    <p className="mt-4 text-lg font-normal leading-8 text-navy-700 dark:text-white">
                        {groupData.description}
                    </p>
                </Card>
                <Card extra="w-full mt-3 px-[30px] py-[30px]">
                <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                    Members
                </h4>
                <p className="text-lg text-gray-600">
                    {members.length} members
                </p>
                <Button onClick={() => setMembersModalOpen(true)}>
                    View All
                </Button>
            </Card>
               
            <Card extra="w-full mt-3 px-[30px] py-[30px]">
                        <h2 className='text-xl font-bold text-navy-700 dark:text-white'>Group Threads</h2>
                        {threads.length > 0 ? (
                            threads.map(thread => <div key={thread.id}>{thread.title}</div>)
                        ) : (
                            <p className="text-lg text-gray-600">No threads currently available.</p>
                        )}

                        <h2 className='text-xl font-bold text-navy-700 dark:text-white'>Group Missions</h2>
                        {missions.length > 0 ? (
                            missions.map(mission => <div key={mission.id}>{mission.missionName}</div>)
                        ) : (
                            <p className="text-lg text-gray-600">No missions currently available.</p>
                        )}
                    </Card>
               
                
        
            <Modal isOpen={isMembersModalOpen} onClose={closeMembersModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Group Members</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          {members.length > 0 ? (
        members.map((member, index) => (
          <p key={index}>{member.name}</p>
        ))
      ) : (
        <p>No members found.</p>
      )}
          </ModalBody>
        </ModalContent>
      </Modal>



            {/* Additional group details and discussions */}
        </div>
    );
};

export default GroupPage;
