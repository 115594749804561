import { SetStateAction, useEffect, useState } from "react";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import UserInfo from "./components/steps/UserInfo";
import Address from "./components/steps/Address";
import Profile from "./components/steps/Profile";
import Card from "components/card";
import auth, { ref, db } from "../../../../../firebase";
import { onValue, DataSnapshot, set,push } from "firebase/database";
import InputField from "components/fields/InputField";
interface NewUserProps {
  onClose: () => void; // Assuming onClose is a function that takes no arguments and returns void
    
  
}
interface UserProp{
  team: string;
    companyCode: string;
    email: string;
    firstName:string;
    lastName:string;
    job: string;
    name:string
    uid: string;
    userName: string;
    username: string;
    aboutMe: string;
    permissions: string;
    price: string;
}

const NewUser: React.FC<NewUserProps> = ({ onClose }) => {

  const [currentStep, setCurrentStep] = useState(1);
  const [companyKey, setCompanyKey] = useState<string | null>(null);
  const [userData, setUserData] = useState<UserProp[]>([]);
  const currentUser = auth.currentUser;
  const [userEmail, setUserEmail] = useState("");
  const [userKey, setUserKey] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState<keyof typeof roles | "">("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [price, setPrice] = useState("");
  const [username, setUserName] = useState("");
  const [team, setTeam] = useState<keyof typeof teams | "">("");

  
  const roles: Record<string, string> = {
    'IT Member': 'IT-Member',
    'Marketing Member': 'Marketing-Member',
    'Finance Member': 'Finance-Member',
    'Volunteer Member': 'Volunteer-Member',
    'HR Member': 'HR-Member',
    'Program Member': 'Program-Member',
    'Development Member': 'Development-Member'
  };
  const teams: Record<string, string> = {
    "INFORMATION TECHNOLOGY TEAM": "INFORMATION TECHNOLOGY TEAM",
    "MARKETING & COMMUNICATIONS TEAM": "MARKETING & COMMUNICATIONS TEAM",
    "FINANCE & ADMINISTRATION TEAM": "FINANCE & ADMINISTRATION TEAM",
    "HUMAN RESOURCES TEAM": "HUMAN RESOURCES TEAM",
    "PROGRAMS & SERVICES TEAM": "PROGRAMS & SERVICES TEAM",
    "VOLUNTEER COORDINATION TEAM": "VOLUNTEER COORDINATION TEAM",
    "DEVELOPMENT & FUNDRAISING TEAM": "DEVELOPMENT & FUNDRAISING TEAM",
    "EVENTS & OUTREACH TEAM": "EVENTS & OUTREACH TEAM",
    "RESEARCH & ADVOCACY TEAM": "RESEARCH & ADVOCACY TEAM",
    "PARTNERSHIPS & COLLABORATIONS TEAM": "PARTNERSHIPS & COLLABORATIONS TEAM",
    "BOARD OF DIRECTORS": "BOARD OF DIRECTORS",
    "OPERATIONS TEAM": "OPERATIONS TEAM",
};


  
    // Fetch User Data


    useEffect(() => {
      const email = currentUser.email || "";
      if (email) {
        setUserEmail(email);
        const usersRef = ref(db, "companies");
        onValue(usersRef, (snapshot) => {
          if (snapshot.exists()) {
            const companies = snapshot.val();
            for (const companyKey in companies) {
              const company = companies[companyKey];
              for (const userKey in company.users) {
                const user = company.users[userKey];
                if (user.email === email) {
                  setCompanyKey(companyKey);
                  setUserKey(userKey);
                  localStorage.setItem("companyKey", companyKey);
                  console.log(companyKey);
                }
              }
            }
          }
        });
      }
    }, [currentUser, db]);
  
    useEffect(() => {
      const fetchUserData = async () => {
    if (companyKey) {
      const userRef = ref(db, `companies/${companyKey}/users/`);
      onValue(userRef, (snapshot: DataSnapshot) => {
    
      });
    }
  };
  fetchUserData();
  },[companyKey]);
  const handleFormSubmit = async () => {
    if(!firstName || !lastName || !role || !email || !password) {
      console.error("All fields are required!");
      return;
    }
  

    
  
    if(companyKey) {
      const name = [firstName,lastName]
      const newUser = {
       name,
        permissions: roles[role],
        team: teams[team],
        email,
        password,
        price,
        username
        // Storing password in plain text is not recommended. Consider using Firebase authentication for better security.
      };
  
      const userRef = ref(db, `companies/${companyKey}/users`);
      const newUserRef = push(userRef);
      await set(newUserRef, newUser);
  
      console.log("User added successfully!");
      // You might want to reset the state or navigate the user somewhere after this.
    }
  };
  
  return (
    <div className="mt-3 h-full w-full">
        
         <h4 className="pt-[5px] text-3xl font-bold text-grey-100 dark:text-white text-center">
        New User
      </h4>
      
      <div className="w-md:2/3 mx-auto h-full w-5/6 md:px-3  3xl:w-7/12">
        
      <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8 ">
      
      {/* content */}

      <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2 text-white">
        <InputField
          extra="mb-3"
          label="First Name"
          placeholder="eg. Esthera"
          id="name"
          type="text"
          
          onChange={(e: { target: { value: SetStateAction<string>; }; }) => setFirstName(e.target.value)}
        />
        <InputField
          extra="mb-3"
          label="Last Name"
          placeholder="eg. Paterson"
          id="lastname"
          type="text"
          onChange={(e: { target: { value: SetStateAction<string>; }; }) => setLastName(e.target.value)}
        />
        <InputField
          extra="mb-3"
          label="Username"
          placeholder="eg. tim901"
          id="userName"
          type="text"
          onChange={(e: { target: { value: SetStateAction<string>; }; }) => setUserName(e.target.value)}
        />
         <InputField
          extra="mb-3"
          label="Salary"
          placeholder="eg. 30,000"
          id="price"
          type="text"
          onChange={(e: { target: { value: SetStateAction<string>; }; }) => setPrice(e.target.value)}
        />
        <InputField
          extra="mb-3"
          label="Email Address"
          placeholder="eg. hello@harmonyflow.com"
          id="email"
          type="text"
          onChange={(e: { target: { value: SetStateAction<string>; }; }) => setEmail(e.target.value)}
        />
        <InputField
          extra="mb-3"
          label="Password"
          placeholder="eg. 4043120241"
          id="password"
          type="text"
          onChange={(e: { target: { value: SetStateAction<string>; }; }) => setPassword(e.target.value)}
        />
           <div className="mb-3">
  <label htmlFor="role" className="block text-sm font-medium text-gray-700">Role</label>
  <select 
    id="role" 
    name="role" 
    value={role}
    onChange={(e) => setRole(e.target.value)}
    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-700"
  >
    <option value="" disabled>Select a Role</option>
    {Object.keys(roles).map((roleName) => (
      <option key={roleName} value={roleName}>
        {roleName}
      </option>
    ))}
  </select>
</div>

<div className="mb-3">
  <label htmlFor="role" className="block text-sm font-medium text-gray-700">Team</label>
  <select 
    id="team" 
    name="team" 
    value={team}
    onChange={(e) => setTeam(e.target.value)}
    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-none rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-gray-700"
  >
    <option value="" disabled>Select a Team</option>
    {Object.keys(teams).map((teamName) => (
      <option key={teamName} value={teamName}>
        {teamName}
      </option>
    ))}
  </select>
</div>
        
      </div>
      <button 
  onClick={handleFormSubmit} 
  className="linear rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
>
  Submit
</button>
<button className=" mb-4 linear rounded-xl  px-5 py-3 text-base font-medium  transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 text-red-400" onClick={onClose}>Close</button>

    </div>
    
    </div>
    
    </div>
  );
};

export default NewUser;
