import profile from "assets/img/crm/vbz.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { AuthContext } from "contexts/AuthContext";

import { ref,get,getDatabase } from "firebase/database";
import React, { useContext, useEffect } from "react";
import { useState } from "react";

// Import your assets a

// Define types for the account type and images
type AccountType = "Personal" | "Limited Business";
type ImageType = string; // Assuming URLs to the images

interface BannerProps {
  profileImage: string;
  bannerImage: string;
  onProfileImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBannerImageChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userName: string;
  updateUserProfile: (updates: object) => void;
}

// Additional state type for managing the account type
interface AccountInfo extends BannerProps {
  accountType: AccountType;
  businessName?: string; // Optional field for business name
  businessDescription?: string; // Optional field for business description
}




const Banner = ({profileImage, bannerImage, onProfileImageChange, onBannerImageChange,userName ,updateUserProfile}: BannerProps) => {
  const [volunteerInfo, setVolunteerInfo] = useState<BannerProps | null>(null);
  const { currentUser,userRole } = useContext(AuthContext);
  const profileInputRef = React.useRef<HTMLInputElement>(null);
  const bannerInputRef = React.useRef<HTMLInputElement>(null);
  const db = getDatabase();
  const [accountInfo, setAccountInfo] = useState({
    userType: "volunteer", // Default account type
    businessName: '',
    businessDescription: '',
  });
  const [businessInfo, setBusinessInfo] = useState({
    businessName: '',
    businessDescription: '',
  });
  useEffect(() => {
    if (!currentUser) return;
    const userRef = ref(db, `volunteers/${currentUser.uid}`);

    get(userRef).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setAccountInfo(data);

        // For business users, fetch additional business data
        if (data.userType === "business") {
          const businessRef = ref(db, `businesses/byName/${data.username.toLowerCase()}`);
          get(businessRef).then((businessSnapshot) => {
            if (businessSnapshot.exists()) {
              const businessData = businessSnapshot.val();
              setAccountInfo(prev => ({
                ...prev,
                businessName: businessData.name,
                businessDescription: businessData.description
              }));
            }
          });
        }
      }
    });
  }, [currentUser, db]);
  
  const handleProfileClick = () => {
    profileInputRef.current?.click();
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountInfo({ ...accountInfo, [e.target.name]: e.target.value });
  };

  
  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setBusinessInfo(prev => ({ ...prev, [name]: value }));
  };
  
  const handleBannerClick = () => {
    bannerInputRef.current?.click();
  };
  const handleAccountTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setAccountInfo({ ...accountInfo, userType: e.target.value });
  };
  const handleBusinessInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setBusinessInfo(prev => ({ ...prev, [name]: value }));
  };
  const saveBusinessInfo = () => {
    if (accountInfo.userType === "Limited Business") {
      updateUserProfile({
        businessName: accountInfo.businessName,
        businessDescription: accountInfo.businessDescription,
        accountType: accountInfo.userType
      });
    }
  };
  return (
    <Card extra={"items-center pt-[16px] pb-10 px-[16px] bg-cover"}>
      {/* background and profile */}
      <div
        className="jsu relative mt-1 flex h-28 w-full justify-center rounded-[20px] bg-cover"
        style={{ backgroundImage: `url(${bannerImage || banner})` }}
      >
      
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full bg-pink-400">
        <img className="h-full w-full rounded-full" src={profileImage || profile} alt="Profile" onClick={handleProfileClick} />
        
        </div>
        <input ref={profileInputRef} type="file" hidden onChange={onProfileImageChange} />
        <input ref={bannerInputRef} type="file" hidden onChange={onBannerImageChange} />
      </div>
      

      {/* name and position */}
      <div className="mt-14 flex flex-col items-center">
        <h4 className="mt-1 text-xl font-bold text-navy-700 dark:text-white">
        {userName || 'Vlad Mihalache'}
        </h4>
        
        <div className="mt-2 flex items-center justify-center">
        <h6 className="mt-2 mr-2 text-sm font-normal text-gray-600">
          Account type
        </h6>
        <select onChange={handleAccountTypeChange} value={accountInfo.userType}>
          <option value="volunteer">Volunteer</option>
          <option value="business">Business Representative</option>
        </select>

       
        </div>

        <button onClick={handleBannerClick} className="linear rounded-xl bg-brand-500 px-8 py-2 mt-8 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">Change Banner</button>
      </div>
    </Card>
  );
};

export default Banner;
