import Card from "components/card";
import React from "react";
import CircularProgress from "components/charts/CircularProgress";
import "react-circular-progressbar/dist/styles.css";
// Define the props for Conversion component
interface ConversionProps {
  volunteerData: {
    volunteerMissions: any[]; // Adjust the type according to your data structure
    // Add other properties of volunteerData if needed
  };
}

function Conversion({ volunteerData }: ConversionProps) {
  // Ensure volunteerMissions is an array and calculate the conversion rate
  const conversionRate = Array.isArray(volunteerData.volunteerMissions)
    ? (volunteerData.volunteerMissions.length / 5) * 100
    : 0;

  return (
    <Card extra={"w-full py-4 px-8"}>
      <div className="mt-2 flex flex-col px-4 text-center">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Volunteer Involvment
        </p>
        <p className="mt-2 px-4 text-sm font-medium text-gray-600">
          Discover how much this volunteer has helped
        </p>
      </div>
      {/* Circular progressbar */}
      <div className="mx-auto mt-3 flex h-[140px] w-[130px] items-center justify-center">
      <CircularProgress title="Conversion" percentage={conversionRate} />
      </div>

      {/* profit */}
      <div className="mt-2 flex h-fit w-full items-center justify-center rounded-[20px] px-4 py-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <p className="ml-1 text-xs font-normal text-gray-600">Est. Missions</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {volunteerData.volunteerMissions.length}
          </p>
        </div>
      </div>
    </Card>
  );
}

export default Conversion;
