import React from 'react';

interface Profile {
  firstName: string;
  lastName: string;
  profileURL?: string;
  username?: string;
  job?: string;
  aboutMe?: string;
}

const ProfileView: React.FC<{ profile: Profile }> = ({ profile }) => {
  return (
    <div className="w-full rounded-xl bg-lightPrimary px-[20px] py-[20px] dark:bg-navy-700">
      <h4 className="text-xl font-bold text-navy-700 dark:text-white">
        {profile.firstName}{" " +profile.lastName}
      </h4>
      <div className="mt-[14px] flex items-center gap-[17px]">
        <div className="flex h-[52px] w-[52px] items-center justify-center rounded-full">
          <img
            className="h-full w-full rounded-full"
            src={profile.profileURL || 'default-avatar-url'}
            alt={profile.username || "Anonymous User"}
          />
        </div>
        <div>
          <h5 className="text-base font-bold leading-6 text-navy-700 dark:text-white">
            {profile.firstName || "First Name"}
          </h5>
          <p className="text-sm font-medium text-gray-600">
            {profile.job || "No job information"}
          </p>
        </div>
      </div>
      <p className="mt-[18px] leading-6 text-navy-700 dark:text-white">
        {profile.aboutMe || "No about me information provided."}
      </p>
    </div>
  );
};

export default ProfileView;
