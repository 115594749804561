import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import Search from './components/Search';
import GroupCard from 'components/card/GroupCard';
import GroupSettingsForm from './components/GroupSettingsForm';
import auth from '../../../../firebase'
import { getDatabase, ref as firebaseRef, get } from "firebase/database"; // Make sure you're importing 'ref' as 'firebaseRef' to avoid naming conflicts

// Import Chakra UI components
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useDisclosure,
  Button,
} from '@chakra-ui/react';
import CreateGroupForm from './components/CreateGroupForm';
import { MdSettings } from 'react-icons/md';




const Groups = () => {
  const db = getFirestore();
  const [groups, setGroups] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure(); // Chakra UI hook for modal
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const fetchGroupsAndStatus = async () => {
    const user = auth.currentUser;
    const userId = user ? user.uid : null;
    const groupsSnapshot = await getDocs(collection(db, 'groups'));
    const groupsWithStatus = await Promise.all(
      groupsSnapshot.docs.map(async (doc) => {
        const groupId = doc.id;
        const status = await checkMembershipStatus(groupId, userId);
        return { ...doc.data(), id: doc.id, membershipStatus: status };
      })
    );
    setGroups(groupsWithStatus);
    console.log(selectedGroupId)
  };
  
  useEffect(() => {
    if (auth.currentUser) {
      fetchGroupsAndStatus();
    }
  }, []);

  

  const addMemberToGroup = async (groupId: any, userId: any, role: any) => {
    await setDoc(doc(db, `groups/${groupId}/members/${userId}`), { role });
  };

  const canManageGroup = (userId: string, group: any) => {
    // Check if the group and group.members are defined
    if (!group || !group.members) {
      return false; // If not defined, the user cannot manage the group
    }
  
    // Check if the user is the creator or has an admin role
    return group.creatorId === userId || group.members[userId]?.role === 'admin';
  };
  const checkUserMembership = async (groupId: string, userId: string) => {
    const membersRef = collection(db, `groups/${groupId}/members`);
    const memberSnapshot = await getDocs(membersRef);
    
    // Iterate over the member documents to find the current user's membership
    let canManage = false;
    memberSnapshot.forEach((doc) => {
      if (doc.id === userId && doc.data().role === 'admin') {
        canManage = true;
      }
    });
  
    return canManage;
  };
  const openGroupSettings = (groupId: any) => {
    setSelectedGroupId(groupId);
    onOpen();
  };
  const handleSettingsClick = (groupId: any) => {
    setSelectedGroupId(groupId);
    onOpen();
  };

  const handleJoinGroupRequest = async (groupId: string) => {
    if (!auth.currentUser) return;
    const requestRef = doc(db, `groups/${groupId}/requests/${auth.currentUser.uid}`);
    await setDoc(requestRef, {
      userId: auth.currentUser.uid,
      requestedAt: new Date(),
      status: 'pending'
    });
  };
  const handleCancelRequest = async (groupId: string) => {
    if (!auth.currentUser) return;
    await deleteDoc(doc(db, `groups/${groupId}/requests/${auth.currentUser.uid}`));
  };
  const handleLeaveGroup = async (groupId: string) => {
    if (!auth.currentUser) return;
    await deleteDoc(doc(db, `groups/${groupId}/members/${auth.currentUser.uid}`));
  };
  
  
  const checkMembershipStatus = async (groupId: string, userId: string) => {
    if (!userId) return 'none';
    const memberRef = doc(db, `groups/${groupId}/members/${userId}`);
    const requestRef = doc(db, `groups/${groupId}/requests/${userId}`);
    const [memberSnap, requestSnap] = await Promise.all([
      getDoc(memberRef),
      getDoc(requestRef),
    ]);
  
    if (memberSnap.exists()) {
      return 'member';
    } else if (requestSnap.exists()) {
      return 'requested';
    }
    return 'none';
  };
  

  const handleActionBasedOnStatus = async (groupId: string, status: any) => {
    switch (status) {
      case 'none':
        await handleJoinGroupRequest(groupId);
        break;
      case 'requested':
        await handleCancelRequest(groupId);
        break;
      case 'member':
        await handleLeaveGroup(groupId);
        break;
      default:
        console.log('Unhandled status:', status);
    }
    // Optionally, refresh group status here
  };

  

  return (
    <div className="min-h-screen flex flex-col">
    <section className="flex-1">
      <div className="pt-4 flex justify-center">
        {/* <button onClick={onOpen} className="settings-button bg-brand-900 text-white font-medium py-2 px-4 rounded-[20px] hover:bg-brand-800 transition duration-200"><MdSettings/>Settings</button> */}
      </div>
      <h4 className="text-2xl font-bold text-navy-700 dark:text-white text-center mt-4">Discover a community</h4>
      <div className="mt-10 px-4 md:px-6 lg:px-8">
        <Search groups={groups} />
      </div>
      {/* Grid for cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 place-items-center mt-4">

        {groups.map((group) => (
          <GroupCard
            key={group.id}
            groupId={group.id}
            image={group.logo || 'defaultImagePath'}
            title={group.title}
            banner={group.banner}
            description={group.description}
            events={group.events || ['No upcoming events']}
            extra="m-4"
            membershipStatus={group.membershipStatus}
            onJoinLeaveCancel={handleActionBasedOnStatus}
            creatorId={group.creatorId}
            currentUserUid={auth.currentUser?.uid}
            onSettingsClick={handleSettingsClick}
          />
        ))}
   </div>
   <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Group Settings</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      {selectedGroupId && <GroupSettingsForm groupId={selectedGroupId} />}
      {!selectedGroupId && <CreateGroupForm />}
    </ModalBody>
  </ModalContent>
</Modal>

        {/* Chakra UI Modal for CreateGroupForm */}
      
      </section>
      {/* Additional Sections */}
    </div>
  );
};

export default Groups;
