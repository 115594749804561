import { useState } from 'react';
import { Input, Textarea, Button, Box, Text, VStack, HStack, Select } from '@chakra-ui/react';
import Card from 'components/card';

const ImpactReports = ({ companyName }) => {
    const [report, setReport] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const generateReport = () => {
        // Mock function to simulate report generation
        setReport(`Report for ${companyName} from ${startDate} to ${endDate}: \n\nSummary of CSR activities...`);
    };

    return (
        <><Text fontSize="2xl" fontWeight="bold">Impact Reports for {companyName}</Text>
        <Card extra={"w-full mt-3 px-6 py-6"}>
            <VStack spacing={4}>
                <Box w="full">
                    <Text mb={2}>Generate a new report:</Text>
                    <HStack mb={4}>
                        <VStack align="start">
                            <Text>Start Date:</Text>
                            <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                        </VStack>
                        <VStack align="start">
                            <Text>End Date:</Text>
                            <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                        </VStack>
                    </HStack>
                    <Button onClick={generateReport}>Generate Report</Button>
                </Box>
                {report && (
                    <Textarea readOnly value={report} placeholder="Generated report will appear here" mt={4} h="200px" />
                )}
            </VStack>
        </Card></>
    );
};

export default ImpactReports;
