import React, { useState, useEffect } from 'react';
import {
  Button, VStack, useToast, useDisclosure, Drawer, DrawerBody, DrawerHeader,
  DrawerOverlay, DrawerContent, DrawerCloseButton, Grid, Box, Text
} from '@chakra-ui/react';
import { AddIcon, InfoOutlineIcon, DeleteIcon } from '@chakra-ui/icons';
import { getDatabase, ref, push, onValue } from 'firebase/database';
import Card from 'components/card';
import AccomplishmentCardForm from '../businessTools/AccomplishmentCardForm';
import UpdateCardForm from '../businessTools/UpdateCardForm';
import PollCardForm from './PollCardForm';

interface PollOption {
  label: string;
  votes: number;
}

interface Card {
  type: string;
  question?: string;
  title?: string;
  description?: string;
  options?: Record<string, PollOption>;
}

const CardsManager = ({ companyName }) => {
  const [cards, setCards] = useState<Card[]>([]);
  const [activeCardType, setActiveCardType] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  useEffect(() => {
    const db = getDatabase();
    const cardsRef = ref(db, `businesses/byName/${companyName}/cards`);
    onValue(cardsRef, (snapshot) => {
      const cardsData = snapshot.val();
      const loadedCards = cardsData ? Object.keys(cardsData).map(key => ({
        id: key,
        ...cardsData[key]
      })) : [];
      setCards(loadedCards);
    });
  }, [companyName]);

  const addNewCard = (type: string) => {
    setActiveCardType(type);
    onOpen();
  };

  const handleSave = (newCardData: Card) => {
    const db = getDatabase();
    const cardRef = ref(db, `businesses/byName/${companyName}/cards`);
    push(cardRef, newCardData).then(() => {
      setCards(prev => [...prev, newCardData]);
      onClose();
      toast({
        title: 'Card Created',
        description: 'Your new card has been added successfully to Firebase.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }).catch(error => {
      toast({
        title: 'Error',
        description: `Failed to create card: ${error.message}`,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    });
  };

  const renderForm = () => {
    switch(activeCardType) {
      case 'update':
        return <UpdateCardForm onSave={handleSave} />;
      case 'accomplishment':
        return <AccomplishmentCardForm onSave={handleSave} />;
      case 'poll':
        return <PollCardForm onSave={handleSave} />;
      default:
        return <p>Select a card type to create.</p>;
    }
  };

  const renderCardContent = (card: Card) => {
    if (card.type === 'poll' && card.options) {
      return (
        <>
          <Text fontSize="xl" fontWeight="bold">{card.question}</Text>
          <VStack spacing={2}>
            {Object.entries(card.options).map(([optionKey, option], idx) => (
              <Text key={idx}>{option.label} - {option.votes} votes</Text>
            ))}
          </VStack>
        </>
      );
    } else {
      return (
        <>
          <Text fontSize="xl" fontWeight="bold">{card.title}</Text>
          <Text mt={4}>{card.description}</Text>
        </>
      );
    }
  };

  return (
    <>
      <Text fontSize='2xl' fontWeight='bold' mb={4}>CSR Details</Text>
      <VStack spacing={4}>
        <Button leftIcon={<AddIcon />} colorScheme="teal" onClick={() => addNewCard('update')}>
          Add Update
        </Button>
        <Button leftIcon={<InfoOutlineIcon />} colorScheme="blue" onClick={() => addNewCard('accomplishment')}>
          Add Accomplishment
        </Button>
        <Button leftIcon={<DeleteIcon />} colorScheme="red" onClick={() => addNewCard('poll')}>
          Add Poll
        </Button>

        <Text fontSize='2xl' fontWeight='bold' mb={4} mt={4}>Your Cards</Text>
        <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6} w="full">
          {cards.map((card, index) => (
            <Card key={index} extra={"w-full mt-3 px-6 py-6"}>
              {renderCardContent(card)}
              <Text color="gray.500">Type: {card.type}</Text>
            </Card>
          ))}
        </Grid>

        <Drawer isOpen={isOpen} placement="right" onClose={() => { onClose(); setActiveCardType(null); }} size="full">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Create New Card: {activeCardType?.charAt(0).toUpperCase() + activeCardType?.slice(1)}</DrawerHeader>
            <DrawerBody>
              {renderForm()}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </VStack>
    </>
  );
};

export default CardsManager;
