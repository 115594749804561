// actions.js
import { getDatabase, ref, push } from "firebase/database";
import { Dispatch } from 'redux';
import { get, child, set } from "firebase/database";
export const SELECT_USER = 'SELECT_USER';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const SET_MESSAGE_TEXT = 'SET_MESSAGE_TEXT';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SELECT_CHAT = 'SELECT_CHAT'; // Add this constant

export const selectUser = (user) => ({
  type: SELECT_USER,
  payload: user
});

export const setCurrentUser = (user) => {
  if (!user) {
    return {
      type: SET_CURRENT_USER,
      payload: null,  // or whatever you want to set as default when user is not available
    };
  }
  return {
    type: SET_CURRENT_USER,
    payload: {
      email: user.email,
      uid: user.uid,
      companyKey: user.companyCode
    },
  };
};




export const sendMessage = (receiverId, messageText) => async (dispatch, getState) => {
  const state = getState();
  const senderId = state.user.uid;
  const db = getDatabase();

  // Retrieve all companies
  const companiesRef = ref(db, 'companies');
  const snapshot = await get(companiesRef);
  if (snapshot.exists()) {
    const companiesData = snapshot.val();

    for (let company in companiesData) {
      // Check if our user exists in the current company
      if (companiesData[company]?.users?.[senderId]) {
        const companyKey = company; // Get the company key
        
        // Retrieve the selected chat ID
        const selectedChatId = state.chat.selectedChat;

        // Check if chat ID exists
        if (selectedChatId) {
          const chatRef = ref(db, `companies/${companyKey}/data/chats/${selectedChatId}/messages`);
          const newMessage = {
            senderId,
            receiverId,
            time: new Date().getTime(),
            message: messageText,
          };
          push(chatRef, newMessage);
        } else {
          console.log("No selected chat available");
        }
        
        break; // Exit the loop once the message is sent
      }
    }
  } else {
    console.log("No data available");
  }
};




export const setMessageText = (text) => ({
  type: SET_MESSAGE_TEXT,
  payload: text
});

export const ADD_MESSAGE = 'ADD_MESSAGE';

export const ADD_SINGLE_MESSAGE = 'ADD_SINGLE_MESSAGE';


export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';

export const addMessage = (messages) => ({
  type: ADD_MESSAGE,
  payload: messages
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES
});

export const setSelectedChatelectChat = (chatId) => ({ // Add this action to handle the selected chat ID
  type: SELECT_CHAT,
  payload: chatId
});

export const addSingleMessage = (message) => ({
  type: ADD_SINGLE_MESSAGE,
  payload: message
});

