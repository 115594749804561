import React, { useEffect, useState } from 'react';
import {
  Box, Heading, VStack, Text, HStack, useColorModeValue,Image, Avatar, Badge, Stack
} from '@chakra-ui/react';
import { collection, query, orderBy, limit, getDocs, Firestore, DocumentData } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import Card from 'components/card';
import { FaRegHeart, FaRegCommentDots } from 'react-icons/fa';

interface Thread {
  id: string;
  title: string;
  createdAt: Date; // Consider using Firestore Timestamp and converting as needed
  createdBy: string;
  imageUrl?: string; // Assuming imageUrl might be optional
  content: string;
  category?: string; // Assuming you have a category field
   replyCount: number
}
interface ReplyType {
   id: string;
   content: string;
   createdAt: Date; // or firebase.firestore.Timestamp depending on your setup
   createdBy: string;
 }
 interface ThreadWithReplies{
   replyCount: number;
 }
// Generalized function to fetch threads with explicit types
const fetchThreads = async (criteria: keyof Thread, db: Firestore, setFunction: React.Dispatch<React.SetStateAction<Thread[]>>) => {
  const q = query(collection(db, "threads"), orderBy(criteria, "desc"), limit(5));
  const querySnapshot = await getDocs(q);
  const fetchedThreads: Thread[] = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...(doc.data() as DocumentData) as Thread,
  }));
  setFunction(fetchedThreads);
};

const ForYouComponent: React.FC = () => {
  const [engagedThreads, setEngagedThreads] = useState<Thread[]>([]);
  const [newestThreads, setNewestThreads] = useState<Thread[]>([]);
  
  const db = getFirestore();
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.50');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    fetchThreads("content", db, setEngagedThreads); // Example criteria: likes
    fetchThreads("createdAt", db, setNewestThreads); // Example criteria: createdAt
  }, [db]);

  return (
   <VStack spacing={6} align="stretch" p={4}  borderRadius="lg">
   <Heading size="lg"  className='dark:text-white'>For You</Heading>
   {engagedThreads.map((thread) => (
     <Card key={thread.id} p={4} shadow="md" extra='dark:text-white p-4' borderRadius="md" border="2px" borderColor={borderColor}>
       <Stack direction={['column', 'row']} spacing={4} align="center">
         {/* <Avatar name={thread.createdBy} src={thread.imageUrl} size="lg" /> */}
         {thread.category && <Badge colorScheme="purple" ml={1}>{thread.category}</Badge>}
         <VStack align="start" spacing={2} flex="1">
           <Heading size="md" >{thread.title}</Heading>
           <Text  noOfLines={4}>{thread.content}</Text>
           {/* <HStack>
             <FaRegHeart />
             
             <FaRegCommentDots />
             <Text fontSize="sm">{thread.replyCount} Replies</Text>
           </HStack> */}
         </VStack>
       </Stack>
     </Card>
   ))}
 </VStack>
  )
}

export default ForYouComponent;
