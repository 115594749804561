import { getDatabase, push, ref, runTransaction } from 'firebase/database';

export const incrementVisitorCount = (companyId, dayOfWeek) => {
  const db = getDatabase();
  const today = dayOfWeek || new Date().toLocaleDateString("en-US", { weekday: 'long' });
  const visitorCountRef = ref(db, `businesses/byName/${companyId}/analytics/dailyVisits/${today}`);

  // Transaction to increment today's visitor count
  runTransaction(visitorCountRef, (currentCount) => {
    return (currentCount || 0) + 1;
  }).then(() => {
    console.log(`Visitor count for ${today} incremented.`);
  }).catch((error) => {
    console.error("Failed to increment visitor count: ", error);
  });
};

export const logCompletedActivity = (companyId, missionId, activity) => {
  const db = getDatabase();
  const activityLogRef = ref(db, `businesses/byName/${companyId}/analytics/activities`);

  const activityLog = {
    missionId,
    activityId: activity.id,
    activityLabel: activity.label,
    completionDate: new Date().toISOString(),
    description: activity.description || ''
  };

  push(activityLogRef, activityLog)
    .then(() => {
      console.log(`Activity ${activity.id} logged for mission ${missionId}.`);
    })
    .catch((error) => {
      console.error("Failed to log completed activity: ", error);
    });
};

// Function to calculate completion rate of activities
export const calculateCompletionRate = (activities) => {
  const totalActivities = activities.length;
  const completedActivities = activities.filter(activity => activity.completed).length;
  return totalActivities > 0 ? (completedActivities / totalActivities) * 100 : 0;
};


// Function to calculate engagement rate
export const calculateEngagementRate = (engagements, totalParticipants) => {
  return totalParticipants > 0 ? (engagements / totalParticipants) * 100 : 0;
};
