import Card from "components/card";
import LineChart from "components/charts/LineChart";
import { useImpactMetrics } from "functions/useImpactMetrics";
import { MdArrowDropUp } from "react-icons/md";

function OverallFlow() {
  const { lineChartData, lineChartOptions } = useImpactMetrics();

  return (
    <Card extra={"h-[381px] pb-8 px-6 pt-6"}>
      <div className="flex justify-between px-3 pt-1">
        <div className="flex items-center">
          <div className="flex flex-col">
            <p className="text-[34px] font-bold text-navy-700 dark:text-white">
              Community Impact
            </p>
            <p className="text-sm font-medium text-gray-600">
              Tracking mission completions and engagement
            </p>
          </div>
          <div className="ml-4 flex items-end pb-2">
            {/* <MdArrowDropUp className="font-medium text-green-500" />
            <span className="text-sm font-bold text-green-500">+5.30%</span> */}
          </div>
        </div>

        <div className="flex items-center justify-center">
          <select className="mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white">
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
            <option value="weekly">Weekly</option>
          </select>
        </div>
      </div>

      <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
        <div className="h-full w-full">
          <LineChart
            chartData={lineChartData}
            chartOptions={lineChartOptions}
          />
        </div>
      </div>
    </Card>
  );
}

export default OverallFlow;
