import React from 'react';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import SearchTableOrders from "./components/SearchTableOrders";
import Conversion from "./components/Conversion";
import SettingsProduct from "../settingsProduct";
import ProductNew from "../missions";
import { useFirebaseMissions } from 'hooks/useFirebaseMissions';

type TeamAndVolunteers = {
  teamContact: string;
  teamLeader: string;
  team: string;
  volunteerRequirements: string;
};

type MissionLogistics = {
  missionPartnerships: string;
  missionResources: string;
};

type RowObj = {
  missionDetails: {
    missionName: string;
    location: string;
    missionCategory: string;
    missionDescription: string;
    missionGoals: string;
    teamAndVolunteers: TeamAndVolunteers;
    missionLogistics: MissionLogistics;
  };
};

const OrderList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isNewOpen,
    onOpen: onNewOpen,
    onClose: onNewClose
  } = useDisclosure(); // Handling the new mission modal

  const {
    missions,
    selectedMission,
    selectMission,
    handleEditMission,
    addMission,
    loading,
    error
  } = useFirebaseMissions();

  const handleEditMissionWrapper = (mission: RowObj) => {
    if (selectedMission) {
      handleEditMission(selectedMission.id, mission.missionDetails);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="col-span-11 h-full w-full rounded-[20px]">
      <Conversion />
      <button
        className="bg-blue-600 text-white px-4 py-2 mb-4 mt-4 rounded-md hover:bg-blue-700 transition duration-300"
        onClick={onNewOpen}
      >
        Add Mission
      </button>
      <SearchTableOrders tableData={missions} handleEditMission={handleEditMission} handleModal={(mission) => {
        onOpen();
        selectMission(mission);
      }} />
      {selectedMission && (
        <Modal isOpen={isOpen} onClose={onClose} size="5xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody>
              <SettingsProduct 
                selectedMission={selectedMission} 
                handleEditMission={handleEditMissionWrapper}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <Modal isOpen={isNewOpen} onClose={onNewClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ProductNew />
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default OrderList;
