import { useState, useEffect, SetStateAction } from "react";
import {
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
} from "@chakra-ui/react";
import { Select, Option } from "@material-tailwind/react";
import {  ref, push, child, onValue } from "firebase/database";
import { db } from "../../../../../../firebase";
import auth from "../../../../../../firebase";
import InputField from "components/fields/InputField";

interface Donation {
    dollars: string;
    cents: string;
    date?: string;  // Assuming you may want to also track the date of each donation.
}

interface MailchimpAttributes {
    subscribed: boolean;        // Whether the member is subscribed to newsletters.
    lastCampaignDate?: string; 
    account_id: string;
    
    // Date of the last campaign they were involved in.

}

interface KindfulAttributes {
    eventsAttended?: string[];  // List of events attended.
 
}

interface Member {
    id: string;
    firstName: string;  // Splitting the name array into two distinct fields.
    lastName: string;
    email: string;
    donations: Donation[];
    status: string;
    date: string;
    edit?: any;
    profileType: string | number | readonly string[];
    communication: string;
    memberMissions: string[];  // Adjusted to string array based on previous discussions.
    priceNumeric?: number;
    mailchimp?: MailchimpAttributes;  // Optional attributes specific to Mailchimp.
    kindful?: KindfulAttributes;      // Optional attributes specific to Kindful.
}







const AddMemberForm = () => {
    const [firstName, firstSetName] = useState("");
    const [lastName, lastSetName] = useState("");
    const [email, setEmail] = useState("");
    const [kindful, setKindful] = useState("");
    const [mailchimp, setMailchimp] = useState("");
    const currentUser = auth.currentUser
    const [userEmail, setUserEmail] = useState("");
    const [companyKey, setCompanyKey] = useState("");
    const [userKey, setUserKey] = useState("");
    const [date, setDate] = useState("");
    const [status, setStatus] = useState("");
    const [cPhoto, setCphoto] = useState("");
    const [communication,setCommuni] = useState("");
    const [profileType,setProfileType] = useState("");
    const [dollars, setDollars] = useState("");
    const [cents, setCents] = useState("");


    useEffect(() => {

        const email = currentUser.email || "";
        if (email) {
            setUserEmail(email);
            const usersRef = ref(db, "companies");
            onValue(usersRef, (snapshot) => {
                if (snapshot.exists()) {
                    const companies = snapshot.val();
                    for (const companyKey in companies) {
                        const company = companies[companyKey];
                        for (const userKey in company.users) {
                            const user = company.users[userKey];
                            if (user.email === email) {
                                setCompanyKey(companyKey);
                                setUserKey(userKey);
                                console.log(companyKey);
                            }
                        }
                    }
                }
            });
        }
    }, [currentUser, db])



    const handleSubmit = (e: any) => {
        e.preventDefault();
        const price: Donation = {
            dollars,
            cents: cents.padEnd(2, "0"),
          };
          const nameData = [
            firstName,
            lastName,
            email,
            cPhoto
          ];

        const dbRef = ref(db, `companies/${companyKey}/members/`);
        const obj = {
            name: [nameData],
            email,
            donations: [price],
            priceNumeric: 0,
            date,
            status,
            actions: "Edit/Delete",
            communication:'email',
            profileType: 'customer',
            kindful:'empty',
            mailchimp:'empty'

        };

        push(dbRef, obj); // push the object directly
        firstSetName("");
        lastSetName("");
        setEmail("");
        setDollars("");
        setCents("");
        setDate("");
        setStatus("");
    }

    return (
        <form onSubmit={handleSubmit}>
        <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
            <div className="mt-7 grid grid-cols-1 gap-3 md:grid-cols-2">
                <InputField
                    extra="mb-3"
                    label="First Name"
                    placeholder="Enter name"
                    id="firstName"
                    type="text"
                    value={firstName}
                    onChange={(e: { target: { value: SetStateAction<string>; }; }) => firstSetName(e.target.value)}
                />
                 <InputField
                    extra="mb-3"
                    label="Last Name"
                    placeholder="Enter name"
                    id="lastName"
                    type="text"
                    value={lastName}
                    onChange={(e: { target: { value: SetStateAction<string>; }; }) => lastSetName(e.target.value)}
                />
                <InputField
                    extra="mb-3"
                    label="Email"
                    placeholder="Enter email"
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e: { target: { value: SetStateAction<string>; }; }) => setEmail(e.target.value)}
                />
                <InputField
                    extra="mb-3"
                    label="Price"
                    placeholder="Enter price"
                    id="priceDollars"
                    type="text"
                    value={dollars}
                    onChange={(e: { target: { value: SetStateAction<string>; }; }) => setDollars(e.target.value)}
                />
                <InputField
                    extra="mb-3"
                    label="Date"
                    placeholder="Enter date"
                    id="date"
                    type="date"
                    value={date}
                    onChange={(e: { target: { value: SetStateAction<string>; }; }) => setDate(e.target.value)}
                />
                <FormControl id="status">
                    <FormLabel>
                        <div className="w=100% h=100%">
                            <Select animate={{ mount: { y: 0 }, unmount: { y: 25 }, }} variant="standard" label="Select Status" size="lg" value={status} onChange={(value: SetStateAction<string>) => setStatus(value)}  >
                                <Option value="Active">Active</Option>
                                <Option value="Prospective">Prospective</Option>
                                <Option value="Pending">Pending</Option>
                                <Option value="Lapsed">Lapsed</Option>
                                <Option>Not sure</Option>
                            </Select>
                        </div>
                    </FormLabel>
                </FormControl>
                <Button type="submit" colorScheme="blue" mt={4}>
                    Add Member
                </Button>
            </div>
        </div>
    </form>
    
    );
};

export default AddMemberForm;
