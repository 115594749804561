import React, { useState } from "react";

function EventForm({ onSubmit }) {
  // State variables to store form input values
  const [title, setTitle] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Construct the eventDetails object from state variables
    const eventDetails = {
      title: title,
      start: start,
      end: end,
      // Add other fields as necessary
    };
    onSubmit(eventDetails); // Call the onSubmit prop passed down from the parent
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <input
        name="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Event Title"
      />
      <input
        name="start"
        value={start}
        onChange={(e) => setStart(e.target.value)}
        type="date" // Assuming it's a date input
      />
      <input
        name="end"
        value={end}
        onChange={(e) => setEnd(e.target.value)}
        type="date" // Assuming it's a date input
      />
      {/* Include other fields as necessary */}
      <button type="submit">Save Event</button>
    </form>
  );
}
export default EventForm;