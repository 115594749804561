import React, { useEffect, useState } from 'react';

import { Box, Button, HStack, Select, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import Card from 'components/card';

interface AvailabilityProps {
  initialAvailability: Record<string, string>; // Define more specific types as needed
  onSave: (day: string, timeSlot: string) => void;
}
// Helper function to generate time slots
const generateTimeSlots = (startTime: string, endTime: string, interval: number) => {
  const slots = [];
  let current = new Date(`2022-01-01T${startTime}:00`);
  const end = new Date(`2022-01-01T${endTime}:00`);

  while (current <= end) {
    slots.push(current.toLocaleTimeString([], { timeStyle: 'short' }));
    current = new Date(current.getTime() + interval * 60000);
  }

  return slots;
};
const cardStyles = {
  bg: 'purple.100', // Adjust the background color to match your design
  borderRadius: 'lg',
  p: 5,
  boxShadow: 'base',
};

const dayButtonStyles = (isSelected: any) => ({
  bg: isSelected ? 'purple.500' : 'transparent', // Adjust the selected background color
  color: isSelected ? 'white' : 'gray.600', // Adjust the selected text color
  fontSize: 'sm',
  _hover: { bg: isSelected ? 'purple.600' : 'gray.100' }, // Adjust hover styles
  borderRadius: 'md',
});

const timeSlotButtonStyles = (isSelected: any) => ({
  bg: isSelected ? 'purple.500' : 'transparent', // Adjust the selected background color
  color: isSelected ? 'white' : 'gray.600', // Adjust the selected text color
  fontSize: 'sm',
  _hover: { bg: isSelected ? 'purple.600' : 'gray.100' }, // Adjust hover styles
  borderRadius: 'md',
});

const AvailabilityCalendar: React.FC<AvailabilityProps> = ({ initialAvailability, onSave }) => {
  // Assuming each day starts at 9:00 and ends at 17:00, with 60-minute intervals
  const timeSlots = generateTimeSlots("09:00", "17:00", 60);

  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');

  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  useEffect(() => {
    if (initialAvailability) {
      setSelectedDay(Object.keys(initialAvailability)[0]); // Select the first day with availability
      setSelectedTimeSlot(Object.values(initialAvailability)[0]); // Select the first time slot available
    }
  }, [initialAvailability]);

  const handleDaySelect = (day: string) => {
    setSelectedDay(day);
    setSelectedTimeSlot(''); // Reset time slot selection when a new day is selected
  };

  const handleTimeSlotSelect = (slot: React.SetStateAction<string>) => {
    setSelectedTimeSlot(slot);
  };
  const handleSave = () => {
    onSave(selectedDay, selectedTimeSlot);
    setSelectedTimeSlot('');
    setSelectedDay('');
    // Reset time slot selection when a new day is selected
  };

  const responsiveGridColumns = { base: 2, sm: 3, md: 7 }; // 2 columns for base, 3 for sm, 7 for md and above
  const responsiveSpacing = { base: 1, sm: 2 }; // Tighter spacing for smaller screens


  return (
    <Box {...cardStyles}>
    <Text fontSize="xl" fontWeight="bold" mb={4}>What time are you free?</Text>
    <SimpleGrid columns={responsiveGridColumns} spacing={responsiveSpacing}>
      {daysOfWeek.map((day, index) => (
        <Button
          key={index}
          {...dayButtonStyles(selectedDay === day)}
          onClick={() => setSelectedDay(day)}
        >
          {day}
        </Button>
      ))}
    </SimpleGrid>

    {selectedDay && (
      <VStack align="stretch" mt={4}>
        <Text fontSize="lg" fontWeight="semibold" mb={2}>
          Select a time slot
        </Text>
        <HStack justify="space-between">
          <Select placeholder="Europe/Amsterdam Time (20:05)" size="sm">
            {/* Timezone options */}
          </Select>
          <Button size="sm">1 Hour Block</Button>
        </HStack>
        <SimpleGrid columns={3} spacing={2} mt={2}>
          {timeSlots.map((slot, index) => (
            <Button
              key={index}
              {...timeSlotButtonStyles(selectedTimeSlot === slot)}
              onClick={() => setSelectedTimeSlot(slot)}
            >
              {slot}
            </Button>
          ))}
        </SimpleGrid>
        <Button onClick={handleSave} colorScheme="purple" mt={4}>Save Availability</Button>
      </VStack>
    )}
  </Box>
  );
};

export default AvailabilityCalendar;
