import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { User } from 'firebase/auth';
import { sendMessage, setMessageText } from 'store/Actions/action';
import { selectMessages, selectMessageText } from 'store/Reducers/messageReducer';
import { setSelectedChat } from 'store/Reducers/chatReducer';
import { AppDispatch, RootState } from 'contexts/store';
import { BiSearch } from 'react-icons/bi';
import { MdChevronLeft, MdImage, MdOutlineAttachment, MdOutlineTagFaces } from 'react-icons/md';
import { IoPaperPlane } from 'react-icons/io5';
import { renderThumbMessages, renderTrackMessages, renderViewMessages } from 'components/scrollbar/Scrollbar';
import Card from 'components/card';
import CardMenu from 'components/card/CardMenu';
import SingleMessage from './SingleMessage';

import Messages, { TeamMessage } from '..';




function SingleChat(props: { open: boolean; onClose: React.MouseEventHandler<HTMLSpanElement>; currentUser: User | null; messages: TeamMessage[]; receiverId: string | null; otherParticipantName: any; }) {
  const { open, onClose, currentUser, receiverId, otherParticipantName } = props;
  const messages = useSelector((state: RootState) => state.messages);
  const messageText = useSelector(selectMessageText);
  const state = useSelector((state: RootState) => state);
  const dispatch: AppDispatch = useDispatch();
  const selectedChatId = useSelector((state: RootState) => state.chat.selectedChat);


  // Now filter your messages based on the selected chat
  const chatMessages = messages.messages.filter(
    (msg) => msg.chatId === selectedChatId
  );
  console.log("Selected Chat ID:", selectedChatId);
  console.log("All Messages:", messages);
  console.log("Filtered Messages for Selected Chat:", chatMessages);
  



  const handleSendMessage = (receiverId: string) => {
    console.log(`Sending message: ${messageText} to: ${receiverId}`);
    if (messageText.trim() !== "") {
      // Dispatch the thunk
      dispatch(sendMessage(receiverId, messageText));
      dispatch(setMessageText(""));
    } else {
      console.log(`Message not sent. Either messageText is empty or receiverId is undefined.`);
    }
  };


  const handleChangeMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMessageText(event.target.value));
  };
  console.log("Is chat window open?", open);


  
  return (
    <div
      className={`duration-175 !fixed left-0 top-[20px] !z-[50] h-[calc(100vh-40px)] w-[calc(100vw-40px)] flex-col !font-dm transition-all md:h-[calc(100vh-40px)] lg:!relative lg:left-[unset] lg:top-[unset] lg:!z-[unset] lg:my-0 lg:flex lg:h-full lg:w-[unset] ${open
          ? "translate-x-[20px] lg:translate-x-[0px]"
          : "-translate-x-[120%] lg:translate-x-[0px]"
        }`}
    >
      <Card extra={"w-full h-full p-4 !font-dm"}>
        {/* header */}
        <div className="mb-auto flex  items-center justify-between border-b border-gray-200 pb-3 dark:!border-navy-700">
          <div className="flex items-center gap-3">
            <div className="flex items-center justify-center gap-2">
              <div onClick={onClose} className={"block lg:hidden"}>
                <MdChevronLeft className="h-5 w-5 text-gray-500 dark:text-white" />
              </div>
              <div className="h-[50px] w-[50px] rounded-full">
                <img
                  src={'https://images.unsplash.com/photo-1628890923662-2cb23c2e0cfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80'}
                  className="h-full w-full rounded-full"
                  alt=""
                />
              </div>
            </div>
            <div>
              <h1 className="font-dm text-[20px] font-bold text-navy-700 dark:text-white">
                {otherParticipantName}
                { }
              </h1>
              <div className="flex items-center gap-2">
                <div className="h-2 w-2 rounded-full bg-green-600"></div>
                <h4 className="text-base text-navy-700 dark:text-white">
                  Active
                </h4>
              </div>
            </div>
          </div>
          <div className="flex">
            <p className="flex w-max items-center text-[24px] hover:cursor-pointer">
              <BiSearch />
            </p>
            <CardMenu vertical />
          </div>
        </div>
        {/* message content */}
        <div className="relative h-[calc(100%-70px)] pt-2">
          <div className="flex h-[calc(100%-74px)] w-full">
            <Scrollbars
              renderTrackVertical={renderTrackMessages}
              renderThumbVertical={renderThumbMessages}
              renderView={renderViewMessages}
            >
              <div className="relative max-h-max overflow-hidden pb-[30px] lg:max-h-[unset] lg:overflow-visible">
  {chatMessages.map((message: TeamMessage, index) => (
    <div
      style={{
        content: "",
        display: "table",
        clear: "both",
      }}
    >
      <SingleMessage
        key={message.id}
        message={message.message}
        time={new Date(message.time).toLocaleString()}
        senderId={message.senderId}
        receiverId={receiverId}
        currentUser={currentUser}
      />
    </div>
  ))}
</div>

            </Scrollbars>
          </div>

          {/*  */}
          <div className="mt-6 flex items-center gap-2">
            <div className=" flex h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3 dark:bg-navy-700">
              <p className="text-lightSecondary pl-3 text-xl hover:cursor-pointer">
                <MdOutlineTagFaces />
              </p>
              <input
                placeholder="Write your message...."
                className="h-full w-full rounded-full bg-lightPrimary px-2 text-sm text-navy-700 outline-none dark:bg-navy-700 dark:text-white"
                type="text"
                value={messageText}
                onChange={handleChangeMessage}
              />

              <div className="flex items-center gap-4">
                <p className="text-lightSecondary text-xl hover:cursor-pointer">
                  <MdOutlineAttachment />
                </p>

                <p className="text-lightSecondary pr-2 text-xl hover:cursor-pointer">
                  <MdImage />
                </p>
              </div>
            </div>
            {/* button */}
            <button
              className="linear flex items-center justify-center rounded-full bg-brand-500 p-3 text-base text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              onClick={() => handleSendMessage(receiverId)}
            >
              <IoPaperPlane />
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}


export default SingleChat

