import { Flex, Box, Button, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import CampaignEditor from "../steps/Assets";
import { useRef, useState } from "react";
import RealTimePreview from "./RealTimePreview";
import CampaignFlow from "./CampaignFlow";
import React from "react";
import TemplateSelector from "./TemplateSelector";
import ContentImporter from "./ContentImporter";
import RichTextEditor from "./RichTextEditor";
import EmailEditor from 'react-email-editor'; // Assuming you're using 'react-email-editor'
import sample from './sample.json'




const NewCampaignLayout = () => {
    const [showCampaignFlow, setShowCampaignFlow] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [editorContent, setEditorContent] = useState('');
    const editorModal = useDisclosure();
    const contentImportModal = useDisclosure();
    const emailEditorRef = useRef(null);
    const [preview, setPreview] = useState(false);
  
    const stepsInfo = [
        { title: 'Email', description: 'Set up basic campaign info', data: 'Campaign Name: Summer Sale' },
        { title: 'Follow Up', description: 'Design your campaign content' },
        { title: 'Response', description: 'Respond to your campaign' },
    ];
  


    const handleTemplateSelect = (templateContent: string) => {
        setEditorContent(templateContent);
    };


    const handleContentImport = (importedContent: string) => {
        setEditorContent(importedContent);
    };
  
    const onLoad = (unlayer: { addEventListener: (arg0: string, arg1: (data: any) => void) => void; loadDesign: (arg0: any) => void; }) => {
        console.log('onLoad', unlayer);
        unlayer.addEventListener('design:loaded', onDesignLoad);
        unlayer.loadDesign(sample);
    };

    const onDesignLoad = (data: any) => {
        console.log('onDesignLoad', data);
    };

    const onReady = (unlayer: any) => {
        console.log('onReady', unlayer);
    };

    return (
        <Flex direction={["column", "row"]} h={["auto", "auto", "100%"]} >

        <Box >
                {/* ... (rest of the existing code) */}
            </Box>
            <Box  >
   <Box >
                <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady}  style={{ width: '100%', height: '500px', overflow: 'hidden' }} />
            </Box>
        </Box>
            {/* ... (rest of the existing code) */}
        </Flex>
    );
};

export default NewCampaignLayout;
