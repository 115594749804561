import { useState, useEffect, useContext } from "react";
import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { getAuth } from "firebase/auth";
import { AuthContext } from "contexts/AuthContext";
import { getDatabase, ref, onValue, set, update, get } from "firebase/database";


const Information = () => {
  const { currentUser } = useContext(AuthContext);
  const [userEmail, setUserEmail] = useState("");
  const [companyKey, setCompanyKey] = useState("");
  const [userKey, setUserKey] = useState("");
  const [userInfo, setUserInfo] = useState({
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    job: "",
    aboutMe: "",
  });
  const db = getDatabase();

  useEffect(() => {
    const email = currentUser?.email || "";
    if (email) {
      setUserEmail(email);

      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                setUserKey(userKey);
                setUserInfo({
                  username: user.username,
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  job: user.job,
                  aboutMe: user.aboutMe,
                });
                break;
              }
            }
          }
        }
      });
    }
  }, [currentUser, db]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      [id]: value,
    }));
  };

  const handleSaveChanges = (event) => {
    event.preventDefault();
    const userRef = ref(db, `companies/${companyKey}/users/${userKey}`);
    update(userRef, userInfo)
      .then(() => {
        console.log("User information updated successfully.");
      })
      .catch((error) => {
        console.error("Error updating user information: ", error);
      });
  };

  return (
    <Card extra={"w-full !p-5"}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Account Settings
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change user account information
        </p>
      </div>
      {/* Form */}
      <form onSubmit={handleSaveChanges}>
        {/* inputs */}
        <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
          <InputField
            label='Username'
            placeholder={userInfo.username}
            id="username"
            type="text"
            onChange={handleInputChange}
          />
          <InputField
            label='Email'
            placeholder={currentUser.email}
            id="email"
            type="text"
            value={currentUser.email}
            disabled
          />
          <InputField
            label='First Name'
            placeholder={userInfo.firstName}
            id="firstName"
            type="text"
            value={userInfo.firstName}
            onChange={handleInputChange}
          />
          <InputField
            label='Last Name'
            placeholder={userInfo.lastName}
            id="lastName"
            type="text"
            value={userInfo.lastName}
            onChange={handleInputChange}
          />
        </div>
        {/* full width inputs */}
        <InputField
          label='Job'
          placeholder={userInfo.job}
          id="job"
          type="text"
          extra="mb-2"
          value={userInfo.job}
          onChange={handleInputChange}
        />
        <InputField
          label='About Me'
          placeholder={userInfo.aboutMe}
          id="aboutMe"
          type="text"
          value={userInfo.aboutMe}
          onChange={handleInputChange}
        />
        <button type="submit" className="btn btn-primary">
          Save Changes
        </button>
      </form>
      {/* Additional Settings */}
      {/* <NotificationSettings />
      <IntegrationManagement /> */}
    </Card>
  );
};

export default Information;
