import React, { useEffect, useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Spinner,
  RadioGroup,
  Radio,
  Stack,
  Image,
  Box,
} from "@chakra-ui/react";
import { getDatabase, ref, onValue, push, set, update } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "contexts/AuthContext";
import home from "assets/img/dashboards/home.png";
import Card from "components/card";

interface Item {
  name: string;
  description: string;
  photo: string;
  type: string;
  cost: string;
  key?: string;
}

const Home = () => {
  const { currentUser } = useAuth();
  const db = getDatabase();
  const storage = getStorage();
  const [companyKey, setCompanyKey] = useState<string | null>(null);
  const [userKey, setUserKey] = useState<string | null>(null);
  const [items, setItems] = useState<Item[]>([]);
  const [uploading, setUploading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [newItem, setNewItem] = useState<Item>({ name: "", description: "", photo: "", type: "product", cost: "" });
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);

  useEffect(() => {
    const email = currentUser?.email || "";
    if (email) {
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                setUserKey(userKey);
                localStorage.setItem("companyKey", companyKey);
              }
            }
          }
        }
      });
    }
  }, [currentUser, db]);

  useEffect(() => {
    if (companyKey) {
      const itemsRef = ref(db, `companies/${companyKey}/items`);
      onValue(itemsRef, (snapshot) => {
        if (snapshot.exists()) {
          const itemsData = snapshot.val();
          const itemsArray = Object.keys(itemsData).map((key) => ({
            ...itemsData[key],
            key,
          }));
          setItems(itemsArray);
        }
      });
    }
  }, [companyKey, db]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewItem((prevItem) => ({
      ...prevItem,
      [name]: value,
    }));
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleAddItem = async () => {
    if (!imageFile) {
      alert("Please select an image to upload");
      return;
    }

    setUploading(true);

    const imageRef = storageRef(storage, `images/${companyKey}/${Date.now()}_${imageFile.name}`);
    await uploadBytes(imageRef, imageFile);
    const imageUrl = await getDownloadURL(imageRef);

    const newItemRef = push(ref(db, `companies/${companyKey}/items`));
    await set(newItemRef, { ...newItem, photo: imageUrl });

    setNewItem({ name: "", description: "", photo: "", type: "product", cost: "" });
    setImageFile(null);
    setUploading(false);
    onAddClose();
  };

  const handleEditItem = async () => {
    if (selectedItem) {
      const updates = { ...newItem };
      if (imageFile) {
        const imageRef = storageRef(storage, `images/${companyKey}/${Date.now()}_${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        const imageUrl = await getDownloadURL(imageRef);
        updates.photo = imageUrl;
      }

      await update(ref(db, `companies/${companyKey}/items/${selectedItem.key}`), updates);
      setNewItem({ name: "", description: "", photo: "", type: "product", cost: "" });
      setImageFile(null);
      setSelectedItem(null);
      onEditClose();
    }
  };

  const handleSelectItem = (item: Item) => {
    setSelectedItem(item);
    setNewItem(item);
    onEditOpen();
  };

  return (
    <Card
      extra={
        "bg-cover bg-no-repeat min-h-[320px] md:w-full md:h-full xl:w-full xl:h-full relative"
      }
      style={{ backgroundImage: `url(${home})` }}
    >
      <button
        onClick={onOpen}
        className="absolute left-5 bottom-8 flex items-center justify-center rounded-full bg-homeButton px-4 py-2 text-base font-medium text-white backdrop-blur-md"
      >
        View Items
      </button>
      <button
        onClick={onAddOpen}
        className="absolute right-5 bottom-8 flex items-center justify-center rounded-full bg-homeButton px-4 py-2 text-base font-medium text-white backdrop-blur-md"
      >
        Add Item
      </button>

      {/* Display the uploaded image outside the modal */}
      {imageFile && (
        <Box mt={4} textAlign="center">
          <Image src={URL.createObjectURL(imageFile)} alt="Uploaded Image" boxSize="150px" objectFit="cover" mx="auto" borderRadius="full" />
        </Box>
      )}

      {/* Modal for viewing items */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Items</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {items.length > 0 ? (
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                {items.map((item) => (
                  <div key={item.key} className="w-full h-auto rounded-lg bg-gray-100 p-4">
                    {item.photo && (
                      <img
                        src={item.photo}
                        alt={item.name}
                        className="w-full h-auto mb-2 rounded-lg"
                      />
                    )}
                    <h3 className="font-bold">{item.name}</h3>
                    <p>{item.description}</p>
                    <p>{item.type === "product" ? "Product" : "Service"}</p>
                    <p>Estimated Cost: {item.cost}</p>
                    <Button mt={2} colorScheme="teal" onClick={() => handleSelectItem(item)}>
                      Edit
                    </Button>
                  </div>
                ))}
              </div>
            ) : (
              <p>No items available.</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for adding new item */}
      <Modal isOpen={isAddOpen} onClose={onAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add New Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Name"
                name="name"
                value={newItem.name}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Input
                placeholder="Description"
                name="description"
                value={newItem.description}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Photo</FormLabel>
              <Input type="file" onChange={handleImageChange} rounded="full" />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Type</FormLabel>
              <RadioGroup
                name="type"
                value={newItem.type}
                onChange={(value) =>
                  setNewItem((prevItem) => ({
                    ...prevItem,
                    type: value,
                  }))
                }
              >
                <Stack direction="row">
                  <Radio value="product" rounded="full">
                    Product
                  </Radio>
                  <Radio value="service" rounded="full">
                    Service
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Estimated Cost</FormLabel>
              <Input
                placeholder="Estimated Cost"
                name="cost"
                value={newItem.cost}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            {uploading ? (
              <Spinner />
            ) : (
              <>
                <Button colorScheme="blue" mr={3} onClick={handleAddItem}>
                  Add
                </Button>
                <Button variant="ghost" onClick={onAddClose}>
                  Cancel
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for editing an item */}
      <Modal isOpen={isEditOpen} onClose={onEditClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Item</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Name"
                name="name"
                value={newItem.name}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Input
                placeholder="Description"
                name="description"
                value={newItem.description}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Photo</FormLabel>
              <Input type="file" onChange={handleImageChange} rounded="full" />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Type</FormLabel>
              <RadioGroup
                name="type"
                value={newItem.type}
                onChange={(value) =>
                  setNewItem((prevItem) => ({
                    ...prevItem,
                    type: value,
                  }))
                }
              >
                <Stack direction="row">
                  <Radio value="product" rounded="full">
                    Product
                  </Radio>
                  <Radio value="service" rounded="full">
                    Service
                  </Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Estimated Cost</FormLabel>
              <Input
                placeholder="Estimated Cost"
                name="cost"
                value={newItem.cost}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            {uploading ? (
              <Spinner />
            ) : (
              <>
                <Button colorScheme="blue" mr={3} onClick={handleEditItem}>
                  Save
                </Button>
                <Button variant="ghost" onClick={onEditClose}>
                  Cancel
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default Home;
