import React, { useContext, useRef, useState } from "react";
import Dropdown from "components/dropdown";
import { AiOutlineUser } from "react-icons/ai";
import { BsThreeDots } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { AiOutlineShop } from "react-icons/ai";
import { TiLightbulb } from "react-icons/ti";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import InviteVolunteerComponent from "./InviteVolunteerComponent";
import NewVolunteer from "./NewVolunteer";
import AddVolunteerComponent from "./addMissionToVolunteer";
import { AuthContext } from "contexts/AuthContext";
import auth, { ref, db, storage } from '../../../../../../firebase'
import { push } from "firebase/database";

interface VolunteerInfo {
  volunteerName: string;
  volunteerAge: number;
  volunteerEmail: string;
  volunteerAddress: string;
  volunteerStatus: string; // e.g., 'Active', 'Inactive'
  volunteerDate: string; // Date of joining or last activity
  volunteerRole: string;
  volunteerMissions: []; // Array of mission or task IDs
  preferredContactMethod: string; // e.g., 'Email', 'Text'
  optInStatus: boolean; // true if opted in for communications
  availabilitySchedule: {
    days: string[]; // e.g., ['Monday', 'Wednesday']
    times: string; // e.g., '9AM - 5PM'
  };
  immediateAvailability: boolean;
  skillSet: string[]; // e.g., ['First Aid', 'Event Planning']
  interestAreas: string[]; // e.g., ['Childcare', 'Environment']
  pastVolunteerExperience: {
    pastRoles: string[];
    pastOrganizations: string[];
  };
  languagesSpoken: string[]; // e.g., ['English', 'Spanish']
  educationLevel: string; // e.g., 'Bachelor's Degree'
  emergencyContact: {
    name: string;
    relationship: string;
    contactDetails: string;
  };
  personalNotes: string;
  communicationHistory: string[]; // e.g., ['Email sent on 01/01/2023']
  engagementLevel: string; // e.g., 'High', 'Moderate'
  geographicPreferences: string[]; // e.g., ['Downtown', 'Suburbs']
  accessibilityNeeds: string;
}


function CardMenu(props: { transparent?: boolean; vertical?: boolean }) {
  const { transparent, vertical } = props;
  const [open, setOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // Function to handle opening the modal for adding a volunteer manually
  const openModal = () => {
    setIsModalOpen(true);
  };
  // Function to handle closing the modal for adding a volunteer manually
  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Function to handle opening the modal for inviting a volunteer
  const openInviteModal = () => {
    setIsInviteModalOpen(true);
  };

  // Function to handle closing the modal for inviting a volunteer
  const closeInviteModal = () => {
    setIsInviteModalOpen(false);
  };
  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  // Function to handle closing the modal for inviting a volunteer
  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };
  return (
    <div>
      <Dropdown
        button={
          <button
            onClick={() => setOpen(!open)}
            className={`flex items-center text-xl hover:cursor-pointer ${
              transparent
                ? "bg-none text-white hover:bg-none active:bg-none"
                : vertical
                ? "bg-none text-navy-700 dark:text-white"
                : "bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            {vertical ? (
              <p className="text-[24px] hover:cursor-pointer">
                <BsThreeDotsVertical />
              </p>
            ) : (
              <BsThreeDots className="h-6 w-6" />
            )}
          </button>
        }
        animation="origin-top-right transition-all duration-300 ease-in-out"
        classNames={`${transparent ? "top-8" : "top-11"} right-0 w-max`}
        children={
          <div className="z-50 w-max rounded-xl bg-white py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p
              className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium"
              onClick={openModal}
            >
              <span>
                <AiOutlineUser />
              </span>
              Add Volunteer Manually
            </p>
            <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium"
            onClick={openAddModal}>
              <span>
              <TiLightbulb />
              </span>
              Add Volunteer to Mission
            </p>

          </div>
        }
      />

      {/* Modal for adding a volunteer manually */}
      <Modal isOpen={isModalOpen} onClose={closeModal} size="3xl">
        <ModalOverlay />
        <ModalContent className="w-auto h-auto px-8 pb-8 sm:overflow-x-auto bg-white dark:text-white dark:!bg-navy-700 bg-navy-700 linear justify-center rounded-lg font-bold transition duration-200">
          <ModalHeader>Add Volunteer Manually</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <NewVolunteer/>
          </ModalBody>
          <ModalFooter>
          {/* <button type="submit" className="bg-brand-500 hover:bg-brand-600 text-white py-2 px-4 rounded-md mr-3" onClick={closeInviteModal}>
              Cancel
            </button>
            <button type="submit" className="bg-brand-500 hover:bg-brand-600 text-white py-2 px-4 rounded-md" onClick={() => formRef.current && formRef.current.dispatchEvent(new Event('submit'))} >
              Add Volunteer
            </button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for inviting a volunteer */}
      <Modal isOpen={isInviteModalOpen} onClose={closeInviteModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite Volunteer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InviteVolunteerComponent />
          </ModalBody>
          <ModalFooter>
            <button type="submit" className="bg-brand-500 hover:bg-brand-600 text-white py-2 px-4 rounded-md " onClick={closeInviteModal}>
              Cancel
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Modal for adding volunteer to mission */}
      <Modal isOpen={isAddModalOpen} onClose={closeAddModal} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite Volunteer</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <AddVolunteerComponent/>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeAddModal}>
              Cancel
            </Button>
            {/* Add other footer buttons if needed */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default CardMenu;
