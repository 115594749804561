import {
  MdDashboard,
  MdHome,
  MdLock,
  MdOutlineBook,
  MdOutlineShoppingCart,
  MdVolunteerActivism,
} from "react-icons/md";

// Admin Imports
import DashboardsDefault from "views/admin/dashboards/default";
import DashboardsRTLDefault from "views/admin/dashboards/rtl";
import DashboardsCarInterface from "views/admin/dashboards/carInterface";
import DashboardsSmartHome from "views/admin/dashboards/smartHome";
import DashboardAnalytics from 'views/admin/dashboards/analytics'
// NFT Imports
import NFTMarketplace from "views/admin/nfts/marketplace";
import NFTPage from "views/admin/nfts/page";
import NFTCollection from "views/admin/nfts/collection";
import NFTProfile from "views/admin/nfts/profile";
import HarmonyMarketplace from "views/volunteer/volunteerDashboard/businessMarket/marketplace";
// Main Imports
import AccountBilling from "views/admin/main/account/billing";
import AccountApplications from "views/admin/main/account/application";
import AccountInvoice from "views/admin/main/account/invoice";
import AccountSettings from "views/admin/main/account/settings";
import AccountAllCourses from "views/admin/main/account/courses";
import AccountCoursePage from "views/admin/main/account/coursePage";

import UserNew from "views/admin/main/users/newUser";
import UsersOverview from "views/admin/main/users/overview";
import UsersReports from "views/admin/main/users/reports";
import Setting from "views/admin/main/users/mySettings";
import ProfileSettings from "views/admin/main/profile/settings";
import ProfileOverview from "views/admin/main/profile/overview";
import ProfileNewsfeed from "views/admin/main/profile/newsfeed";

// import Kanban from "views/admin/main/applications/kanban/index";
import ApplicationsDataTables from "views/admin/main/applications/dataTables";
import ApplicationsCalendar from "views/admin/main/applications/calendar";
import NewCampaign from "views/admin/main/ecommerce/newCampaign";
import EcommerceNewProduct from "views/admin/main/ecommerce/missions";
import EcommerceProductSettings from "views/admin/main/ecommerce/settingsProduct";
import EcommerceProductPage from "views/admin/main/ecommerce/productPage";
import EcommerceOrderList from "views/admin/main/ecommerce/missionList";
import Members from "views/admin/main/ecommerce/members";
import EcommerceOrderDetails from "views/admin/main/ecommerce/orderDetails";
import EcommerceReferrals from "views/admin/main/ecommerce/referrals";
import CustomerReports from "views/admin/main/ecommerce/customerReports";
import Volunteer from "views/admin/main/ecommerce/volunteer";
// Others
import OthersNotifications from "views/admin/main/others/notifications";
//import OthersPricing from 'views/admin/main/others/pricing';
import OthersPricing from "views/admin/main/others/pricing";
import OthersError from "views/admin/main/others/404";
import Buttons from "views/admin/main/others/buttons";
import Messages from "views/admin/main/others/messages";

// Auth Imports
import ForgotPasswordCentered from "views/auth/forgotPassword/ForgotPasswordCenter";
import ForgotPasswordDefault from "views/auth/forgotPassword/ForgotPasswordDefault";
import LockCentered from "views/auth/lock/LockCenter";
import LockDefault from "views/auth/lock/LockDefault";
import SignInCentered from "views/auth/signIn/SignInCenter";
import SignInDefault from "views/auth/signIn/SignInDefault";
import SignUpCentered from "views/auth/signUp/SignUpCenter";
import SignUpDefault from "views/auth/signUp/SignUpDefault";
import VerificationCentered from "views/auth/verification/VerificationCenter";
import VerificationDefault from "views/auth/verification/VerificationDefault";
import LandingPageMain from "views/auth/signUp/LandingPageMain";
import ResourceManager from "views/admin/main/applications/resourceManager";
import LearnMore from "views/auth/signUp/LearnMore";
// Volunteer Imports
import SignUpVolunteer from "views/auth/signUp/SignUpVolunteer";
import VolunteerDashboard from "views/volunteer/volunteerDashboard";
import VolunteerProfileSetting from "views/volunteer/volunteerDashboard/settings";
import ImpactWaveComponent from "views/volunteer/volunteerDashboard/impactWave";
import Group from "views/volunteer/volunteerDashboard/groups";
import VolunteerForum from "views/volunteer/volunteerDashboard/forums";
import VolunteerSocialMediaLanding from "views/auth/signUp/VolunteerSocialMediaLanding";
import VolunteerBilling from "views/volunteer/volunteerDashboard/billing";
import NotificationsPage from "views/volunteer/volunteerDashboard/settings/components/NotificationsPage";
import BusinessLandingPage from "views/auth/signUp/BusinessLandingPage";
// import Kanban from "views/admin/main/applications/kanban";


const routes = [
  

  {
    name: "Dashboards",
    path: "/dashboards",
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: "Main Dashboard",
        layout: "/admin",
        path: "/dashboards/default",
        component: <DashboardsDefault />,
      },
      {
        name: "Analytics Dashboard",
        layout: "/admin",
        path: "/dashboards/analytics",
        component: <DashboardAnalytics />,
      },
      {
        name: "Resource Dashboard",
        layout: "/admin",
        path: "/dashboards/mission-dashboard",
        component: <DashboardsSmartHome />,
      },
      // {
      //   name: "Integrations",
      //   layout: "/admin",
      //   path: "/dashboards/integrations",
      //   component: <DashboardsCarInterface />,
      // },
    ],
  },
  // Volunteer Dashboard 
  {
    name: "Volunteer",
    path: "/volunteer",
    icon: <MdVolunteerActivism className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: "Volunteer Dashboard",
        layout: "/volunteer",
        path: "/contribute",  // Updated path
        component: <VolunteerDashboard />,
      },
      {
        name: "Profile",
        layout: "/volunteer",
        path: "/profile/settings",  // Updated path
        component: <VolunteerProfileSetting />,
      },  
      {
        name: "ImpactWave",
        layout: "/volunteer",
        path: "/impactWave",  // Updated path
        component: <ImpactWaveComponent/>
        } ,
        {
          name: "Groups",
          layout: "/volunteer",
          path: "/groups",  // Updated path
          component: <Group/>

        },
        
        {
          name: "Forums",
          layout: "/volunteer",
          path: "/forums",  // Updated path
          component: <VolunteerForum/>

        },
        {
          name: "MarketPlace",
          layout: "/volunteer",
          path: "/social-responsibility/market",  // Updated path
          component: <HarmonyMarketplace/>

        },
        {
          name: "ImpactWave",
          layout: "/volunteer",
          path: "/billing",  // Updated path
          component: <VolunteerBilling/>
          } ,
          {
            name: "ImpactWave",
            layout: "/volunteer",
            path: "/notifications",  // Updated path
            component: <NotificationsPage/>
            } ,

     
    ],
  },


  // --- NFTs ---
  // {
    // name: "Collaboration",
    // path: "/nfts",
    // icon: <MdOutlineBook className="text-inherit h-5 w-5" />,
    // collapse: true,
    // items: [
    //   {
    //     name: "Profile",
    //     layout: "/admin",
    //     path: "/collab/profile",
    //     component: <NFTProfile />,
    //     secondary: true,
    //   }, 
    //   {
    //     name: "Collection",
    //     layout: "/admin",
    //     path: "/collab/collection",
    //     component: <NFTCollection />,
    //     secondary: true,
    //   },
    //   {
    //     name: "CollabHub",
    //     layout: "/admin",
    //     path: "/collab/collabHub",
    //     component: <NFTMarketplace />,
    //     secondary: true,
    //   },
    //   {
    //     name: "Details",
    //     layout: "/admin",
    //     path: "/collab/page",
    //     component: <NFTPage />,
    //     secondary: true,
    //   },
    //   {
    //     name: "Referrals",
    //     layout: "/admin",
    //     path: "/collab/ecommerce/referrals",
    //     exact: false,
    //     component: <EcommerceReferrals />,
    //   },
    // ],
  // },
  // --- Main pages ---
  {
    name: "Main Pages",
    path: "/main",
    icon: <MdDashboard className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: "Company",
        path: "/main/company-account",
        collapse: true,
        items: [
          {
            name: "Company Overview",
            layout: "/admin",
            path: "/main/company-account/company-overview",
            exact: false,
            component: <ProfileOverview />,
          },
          //  {
          //   name: "Task List",
          //   layout: "/admin",
          //   path: "/main/projects/kanban",
          //   exact: false,
          //   component: <Kanban />,
          // },
          // {
          //   name: "Billing",
          //   layout: "/admin",
          //   path: "/main/company-account/billing",
          //   exact: false,
          //   component: <AccountBilling />,
          // },
          {
            name: "Team Manager",
            layout: "/admin",
            path: "/main/company-account/application",
            exact: false,
            component: <AccountApplications />,
          },
          // {
          //   name: "Invoice",
          //   layout: "/admin",
          //   path: "/main/account/invoice",
          //   exact: false,
          //   component: <AccountInvoice />,
          // },
          // {
          //   name: "Settings",
          //   layout: "/admin",
          //   path: "/main/account/settings",
          //   exact: false,
          //   component: <AccountSettings />,
          // },
          // {
          //   name: "All Courses",
          //   layout: "/admin",
          //   path: "/main/company-account/all-courses",
          //   exact: false,
          //   component: <AccountAllCourses />,
          // },
          // {
          //   name: "Course Page",
          //   layout: "/admin",
          //   path: "/main/company-account/course-page",
          //   exact: false,
          //   component: <AccountCoursePage />,
          // },
          // {
          //   name: "Users Reports",
          //   layout: "/admin",
          //   path: "/main/users/users-reports",
          //   exact: false,
          //   component: <UsersReports />,
          // },
        ],
      },
      {
        
        name: "Contacts",
        path: "/ecommerce",
        collapse: true,
        items: [
          // {
          //   name: "Intake Reports",
          //   layout: "/admin",
          //   path: "/main/managment/IntakeReports",
          //   exact: false,
          //   component: <CustomerReports />,
          // },
          {
            name: "Clients",
            layout: "/admin",
            path: "/main/ecommerce/clients",
            exact: false,
            component: <Members />,
          },
          {
            name: "Volunteers",
            layout: "/admin",
            path: "/main/ecommerce/volunteer",
            exact: false,
            component: <Volunteer />,
          },
        
          // {
          //   name: "Product Page",
          //   layout: "/admin",
          //   path: "/main/ecommerce/page-example",
          //   exact: false,
          //   component: <EcommerceProductPage />,
          // },
          // {
          //   name: "Order Details",
          //   layout: "/admin",
          //   path: "/main/ecommerce/order-details/:id",
          //   exact: false,
          //   component: <EcommerceOrderDetails />,
          // }, 
        
        ],
      },
      {
        name: "Teams",
        path: "/main/users",
        collapse: true,
        items: [
          
          {
            name: "Users Overview",
            layout: "/admin",
            path: "/main/users/users-overview",
            exact: false,
            component: <UsersOverview />,
          },
          {
            name: "Messages",
            layout: "/admin",
            path: "/main/others/messages",
            exact: false,
            component: <Messages />,
          },
          {
            name: "My Account",
            layout: "/admin",
            path: "/main/others/myAccount",
            exact: false,
            component: <Setting/>,
          },
          
        ],
      },
      {
        name: "Tools",
        path: "/main/applications",
        collapse: true,
        items: [
         
          // {
          //   name: "Resource Manager",
          //   layout: "/admin",
          //   path: "/main/applications/resource-manager",
          //   exact: false,
          //   component: <ResourceManager />,
          // },
          // {
          //   name: "Data Tables",
          //   layout: "/admin",
          //   path: "/main/projects/data-tables",
          //   exact: false,
          //   component: <ApplicationsDataTables />,
          // },
          {
            name: "Calendar",
            layout: "/admin",
            path: "/main/projects/calendar",
            exact: false,
            component: <ApplicationsCalendar />,
          },
          {
            name: "Missions",
            layout: "/admin",
            path: "/main/projects/missions",
            exact: false,
            component: <EcommerceOrderList />,
          },
          // {
          //   name: "New Mission",
          //   layout: "/admin",
          //   path: "/main/projects/new-mission",
          //   exact: false,
          //   component: <EcommerceNewProduct />,
          // },
          {
            name: "Campaigns",
            layout: "/admin",
            path: "/main/ecommerce/new-mission",
            exact: false,
            component: <NewCampaign />,
          },

        ],
      },
      // Profile Settings
      // {
      //   name: "Profile",
      //   path: "/main/profile",
      //   collapse: true,
      //   items: [
         
      //     {
      //       name: "Profile Settings",
      //       layout: "/admin",
      //       path: "/main/profile/settings",
      //       exact: false,
      //       component: <ProfileSettings />,
      //     },
      //     // {
      //     //   name: "News Feed",
      //     //   layout: "/admin",
      //     //   path: "/main/profile/newsfeed",
      //     //   exact: false,
      //     //   component: <ProfileNewsfeed />,
      //     // },
      //   ],
      // },
      // {
      //   name: "Others",
      //   path: "/main/others",
      //   collapse: true,
      //   items: [
      //     {
      //       name: "Notifications",
      //       layout: "/admin",
      //       path: "/main/others/notifications",
      //       exact: false,
      //       component: <OthersNotifications />,
      //     },
      //     {
      //       name: "404",
      //       layout: "/admin",
      //       path: "/main/others/404",
      //       exact: false,
      //       component: <OthersError />,
      //     },
      //     {
      //       name: "Buttons",
      //       layout: "/admin",
      //       path: "/main/others/buttons",
      //       exact: false,
      //       component: <Buttons />,
      //     },
          
      //   ],
      // },
    ],
  },
  // --- Authentication ---
  {
    name: "Authentication",
    path: "/auth",
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: "Sign In",
        path: "/sign-in",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/sign-in/default",
            component: <SignInDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/sign-in/centered",
            component: <SignInCentered />,
          },
           {
            name: "Home",
            layout: "/auth",
            path: "/default",
            component: <LandingPageMain />,
          },
          {
            name: "VolunteerLanding",
            layout: "/auth",
            path: "/volunteer",
            component: <VolunteerSocialMediaLanding />,
          },
          {
            name: "BusinessLanding",
            layout: "/auth",
            path: "/business-harmony",
            component: <BusinessLandingPage />,
          },
          {
            name: "Home",
            layout: "/auth",
            path: "default/pricing",
            component: <OthersPricing />,
          },

        ],
      },
      // --- Sign Up ---
      {
        name: "Sign Up",
        path: "/sign-up",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/sign-up/default",
            component: <SignUpDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/sign-up/centered",
            component: <SignUpCentered />,
          },
          {
            name: "Volunteer DB",
            layout: "/auth",
            path: "/sign-up/volunteer",
            component: <SignUpVolunteer />,
          },
          {
            name: "Learn More",
            layout: "/auth",
            path: "/auth/pricing",
            component: <LearnMore />,
          },
        ],
      },
      // --- Verification ---
      {
        name: "Verification",
        path: "/verification",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/verification/default",
            component: <VerificationDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/verification/centered",
            component: <VerificationCentered />,
          },
        ],
      },
      // --- Lock ---
      {
        name: "Lock",
        path: "/lock",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/lock/default",
            component: <LockDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/lock/centered",
            component: <LockCentered />,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: "Forgot Password",
        path: "/forgot-password",
        collapse: true,
        items: [
          {
            name: "Default",
            layout: "/auth",
            path: "/forgot-password/default",
            component: <ForgotPasswordDefault />,
          },
          {
            name: "Centered",
            layout: "/auth",
            path: "/forgot-password/centered",
            component: <ForgotPasswordCentered />,
          },
        ],
      },
    ],
  },
  // {
  // 	name: 'RTL Admin',
  // 	layout: '/rtl',
  // 	path: 'rtl-default',
  // 	// icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  // 	component: () => <MainDashboard /> RTL
  // }
];

export default routes;
