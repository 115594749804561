import { useState, useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";
import { getDatabase, ref, onValue, set, push } from "firebase/database";
import { useAuth } from "contexts/AuthContext";
import Card from "components/card";
import { MdOutlineLocationOn } from "react-icons/md";

const ResourceAllocation = () => {
  const { currentUser } = useAuth();
  const db = getDatabase();
  const [companyKey, setCompanyKey] = useState(null);
  const [items, setItems] = useState([]);
  const [members, setMembers] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedMember, setSelectedMember] = useState("");
  const [itemDetails, setItemDetails] = useState({
    name: "",
    quantity: 0,
    allocated: 0,
    utilization: 0,
    description: "",
    cost: 0,
    type: "",
    photo: ""
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const email = currentUser?.email || "";
    if (email) {
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const key in companies) {
            const company = companies[key];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(key);
                localStorage.setItem("companyKey", key);
              }
            }
          }
        }
      });
    }
  }, [currentUser, db]);

  useEffect(() => {
    if (companyKey) {
      const itemsRef = ref(db, `companies/${companyKey}/items`);
      onValue(itemsRef, (snapshot) => {
        if (snapshot.exists()) {
          setItems(Object.values(snapshot.val()));
        }
      });

      const membersRef = ref(db, `companies/${companyKey}/members`);
      onValue(membersRef, (snapshot) => {
        if (snapshot.exists()) {
          setMembers(Object.values(snapshot.val()));
        }
      });
    }
  }, [companyKey, db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAddItem = async () => {
    if (companyKey) {
      const itemsRef = ref(db, `companies/${companyKey}/items`);
      const newItemRef = push(itemsRef);
      await set(newItemRef, itemDetails);
      setItemDetails({ name: "", quantity: 0, allocated: 0, utilization: 0, description: "", cost: 0, type: "", photo: "" });
      onClose();
    }
  };

  const handleSelectItem = (e) => {
    const selectedItemName = e.target.value;
    setSelectedItem(selectedItemName);
    const item = items.find((i) => i.name === selectedItemName);
    if (item) {
      setItemDetails(item);
    }
  };

  const handleSelectMember = (e) => {
    const selectedMemberName = e.target.value;
    setSelectedMember(selectedMemberName);
  };

  const calculateUtilization = () => {
    const utilization = (itemDetails.allocated / itemDetails.quantity) * 100;
    setItemDetails((prevDetails) => ({
      ...prevDetails,
      utilization,
    }));
  };

  useEffect(() => {
    if (itemDetails.quantity && itemDetails.allocated) {
      calculateUtilization();
    }
  }, [itemDetails.quantity, itemDetails.allocated]);

  return (
    <Card extra={"flex flex-col w-full h-full relative p-4"}>
      <p className="mt-[2px] ml-px text-lg font-bold leading-none text-navy-700 dark:text-white">
        Resource Allocation
      </p>
      <div className="mt-[10px] flex items-center gap-1">
        <p className="text-gray-600">
          <MdOutlineLocationOn />
        </p>
        <p className="bg-none text-sm font-bold text-gray-600 dark:bg-navy-800">
          Company Resources
        </p>
      </div>

      <div className="mt-4">
        <button onClick={onOpen} className="linear ml-auto mt-[5px] rounded-full bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Edit Resource
        </button>
      </div>

      <div className="mt-4">
        {items.length > 0 ? (
          items.map((item, index) => (
            <div key={index} className="p-4 bg-white dark:bg-navy-700 rounded-lg shadow-md mb-4">
              <h4 className="font-bold text-navy-700 dark:text-white">{item.name}</h4>
              <p className="text-gray-600 dark:text-white">Quantity: {item.quantity}</p>
              <p className="text-gray-600 dark:text-white">Allocated: {item.allocated}</p>
              <p className="text-gray-600 dark:text-white">Utilization: {item.utilization}%</p>
            </div>
          ))
        ) : (
          <p>No resources available.</p>
        )}
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Resource</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Select Resource</FormLabel>
              <Select
                placeholder="Select resource"
                value={selectedItem}
                onChange={handleSelectItem}
                rounded="full"
              >
                {items.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Name</FormLabel>
              <Input
                placeholder="Name"
                name="name"
                value={itemDetails.name}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Quantity</FormLabel>
              <Input
                placeholder="Quantity"
                name="quantity"
                type="number"
                value={itemDetails.quantity}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Allocated</FormLabel>
              <Input
                placeholder="Allocated"
                name="allocated"
                type="number"
                value={itemDetails.allocated}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Description</FormLabel>
              <Input
                placeholder="Description"
                name="description"
                value={itemDetails.description}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Cost</FormLabel>
              <Input
                placeholder="Cost"
                name="cost"
                type="number"
                value={itemDetails.cost}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Type</FormLabel>
              <Input
                placeholder="Type"
                name="type"
                value={itemDetails.type}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Photo URL</FormLabel>
              <Input
                placeholder="Photo URL"
                name="photo"
                value={itemDetails.photo}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Select Client</FormLabel>
              <Select
                placeholder="Select member"
                value={selectedMember}
                onChange={handleSelectMember}
                rounded="full"
              >
                {members.map((member, index) => (
                  <option key={index} value={member.name}>
                    {member.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleAddItem}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default ResourceAllocation;
