import React from "react";
import NavbarAuth from "components/navbar/NavbarAuth";
import Footer from "components/footer/FooterAuthCentered";
import Sidebar from "components/sidebar";

const Centered = (props: { maincard: JSX.Element }) => {
  const [open, setOpen] = React.useState(true);
  const { maincard } = props;
  return (
    <div className="flex min-h-screen w-full flex-col items-center overflow-hidden">
      <Sidebar variant="auth" open={open} onClose={() => setOpen(false)} />
      <NavbarAuth onOpenSidenav={() => setOpen(!open)} />
      <div className="relative w-full bg-gradient-to-br from-brandLinear to-blueSecondary h-[48vh] md:h-[48vh] lg:h-[48vh]">
        <div className="absolute inset-0 w-full h-full bg-cover bg-no-repeat" />
      </div>
      <div className="flex flex-col items-center justify-center w-full max-w-screen-xl px-4 py-8">
        {maincard}
      </div>
      <Footer />
    </div>
  );
};

export default Centered;
