import Pricing from "layouts/auth/types/Pricing";
import React, { useState } from "react";
import zoho from "assets/svg/zoho-logo.svg";
import deloite from "assets/svg/deloitte-logo.svg";
import georgia from "assets/svg/georgia-logo.svg";
import google from "assets/svg/google-logo.svg";
import microsoft from "assets/svg/microsoft-logo.svg";
import msn from "assets/svg/msn-logo.svg";
import Footer from "components/footer/Footer";
import Card from "components/card";
import NavbarAuth from "../../../../auth/signUp/NavbarAuth";

const PricingPage = () => {
  const [activeButton, setActiveButton] = useState("monthly");

  return (
    <>
      <div className="dark:bg-navy-900 bg-gradient-to-br from-brandLinear to-blueSecondary p-4 rounded">
        <NavbarAuth />
        <div className="mx-auto mt-[96px] flex w-full max-w-screen-sm flex-col items-center justify-center text-center md:px-3 mb-8">
          <h2 className="text-[28px] font-bold text-white md:text-[44px]">
            Empower Your Business with Our Specialized AI Tools
          </h2>
          <p className="mt-[8px] px-8 text-sm text-white md:px-9 md:text-base">
            Efficiently manage donors, volunteers, tasks, and events. Acquire up to 100 clients in a month with zero marketing costs.
          </p>
          <div className="mt-7 rounded-lg bg-[#ffffff1f] py-[4px] px-[10px] text-sm font-bold text-white">
            1 month free trial on every purchase
          </div>

          {/* Monthly / Yearly */}
          <div className="mt-12 flex h-[50px] w-[280px] items-center rounded-full bg-navy-800 p-1.5">
            <button
              className={`linear flex h-full w-1/2 cursor-pointer select-none items-center justify-center rounded-[20px] text-xs font-bold uppercase text-brand-500 transition duration-200 ${activeButton === "monthly"
                ? "bg-white text-brand-500"
                : "bg-transparent text-white"} `}
              onClick={() => setActiveButton("monthly")}
            >
              Monthly
            </button>
            <button
              className={`linear flex h-full w-1/2 cursor-pointer select-none items-center justify-center rounded-[20px] text-xs font-bold uppercase text-brand-500 transition duration-200 ${activeButton === "yearly"
                ? "bg-white text-navy-700"
                : "bg-transparent text-white"}`}
              onClick={() => setActiveButton("yearly")}
            >
              Yearly
            </button>
          </div>
        </div>
      </div>

      <div className="relative mx-auto mt-12 mb-20 grid h-fit w-full max-w-[375px] grid-cols-1 gap-3 px-3 md:mb-[160px] xl:mt-16 xl:max-w-full xl:grid-cols-3 2xl:max-w-max">
        {/* 1st card */}
        <Card extra="w-full h-full rounded-[20px] pb-6 pt-8 px-[20px]">
          {/* Card header */}
          <h5 className="font-dm text-3xl font-bold text-navy-700 dark:text-white">
            Small Business
          </h5>
          <p className="mt-1 font-dm text-base font-medium text-gray-600">
            Hit the ground running.
          </p>
          <button className="linear mt-[32px] w-full rounded-xl bg-brand-50 py-2 font-dm text-base font-medium text-brand-500 transition duration-200 hover:opacity-90 active:opacity-80 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
            Learn More
          </button>
          {/* Amount */}
          <div className="mt-8 w-full">
            <h5 className="font-dm text-4xl font-bold text-navy-700 dark:text-white">
              {activeButton === "monthly" ? "$250" : "$3,000"}
              <span className="text-gray-600">
                /{activeButton === "monthly" ? "mo" : "yr"}
              </span>
            </h5>
            <p className="mt-[8px] text-base font-medium text-gray-600">
              (Per subscriber per month)
            </p>
          </div>
          {/* Bullet points */}
          <div className="mt-6">
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Sell on your own terms
              </h5>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Website, marketing tools & automations
              </h5>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Bandwidth & storage included
              </h5>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                We’ll get you onboarded
              </h5>
            </div>
          </div>
        </Card>

        {/* 2nd card */}
        <Card extra="w-full h-fit rounded-[20px] !pb-10 mt-2 xl:!-mt-8 pt-8 px-[20px]">
          {/* Popular button */}
          <div className="-mt-[45px] mb-[38px] flex w-full justify-center">
            <div className="rounded-[20px] bg-[#FFF6DA] px-3 py-[6px] text-center text-sm font-bold text-yellow-500">
              Most popular plan
            </div>
          </div>
          {/* Card header */}
          <div className="mt-2">
            <h5 className="font-dm text-3xl font-bold text-navy-700 dark:text-white">
              Large Company
            </h5>
            <p className="mt-1 font-dm text-base font-medium text-gray-600">
              Power-up your business.
            </p>
            <button className="linear mt-[30px] w-full rounded-xl bg-brand-500 py-2 font-dm text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Learn More
            </button>
          </div>
          {/* Amount */}
          <div className="mt-[36px] w-full">
            <h5 className="font-dm text-4xl font-bold text-navy-700 dark:text-white">
              {activeButton === "monthly" ? "$500" : "$6,000"}
              <span className="text-gray-600">
                /{activeButton === "monthly" ? "mo" : "yr"}
              </span>
            </h5>
            <p className="text-base font-medium text-gray-600">
              (Per subscriber per month)
            </p>
          </div>
          {/* Bullet points */}
          <div className="mt-6">
            <div className="mb-[20px] flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Live chat & countdowns
              </h5>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Website, marketing tools & automations
              </h5>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Bandwidth & storage included
              </h5>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                We’ll get you onboarded
              </h5>
            </div>
          </div>
        </Card>

        {/* 3rd card */}
        <Card extra="w-full h-full rounded-[20px] pb-6 pt-8 px-[20px]">
          {/* Card header */}
          <h5 className="font-dm text-3xl font-bold text-navy-700 dark:text-white">
            Enterprise
          </h5>
          <p className="mt-1 text-base font-medium text-gray-600">
            The ultimate solution for your business.
          </p>
          <button className="linear mt-[32px] w-full rounded-xl bg-brand-50 py-2 font-dm text-base font-medium text-brand-500 transition duration-200 hover:opacity-90 active:opacity-80 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
            Learn More
          </button>
          {/* Amount */}
          <div className="mt-[35px] w-full">
            <h5 className="font-dm text-4xl font-bold text-navy-700 dark:text-white">
              {activeButton === "monthly" ? "$1,230" : "$14,760"}
              <span className="text-gray-600">
                /{activeButton === "monthly" ? "mo" : "yr"}
              </span>
            </h5>
            <p className="mt-[8px] text-base font-medium text-gray-600">
              (Per subscriber per month)
            </p>
          </div>
          {/* Bullet points */}
          <div className="mt-6">
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Advanced AI tools
              </h5>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Custom integrations
              </h5>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Bandwidth & storage included
              </h5>
            </div>
            <div className="mb-4 flex items-center gap-2">
              <div className="flex h-2.5 w-2.5 items-end justify-center rounded-full bg-navy-700 dark:bg-white" />
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                We’ll get you onboarded
              </h5>
            </div>
          </div>
        </Card>
      </div>

      {/* <div className="mx-auto mb-20 flex w-full max-w-screen-xl flex-col items-center justify-center pb-3 font-medium md:mb-[150px]">
        <h2 className="mb-8 text-center text-[35px] font-bold text-navy-700 dark:text-white md:mb-6 md:text-left">
          More than 25,000 people trust Safehaven
        </h2>
        <div className="grid grid-cols-3 place-items-center justify-items-center gap-16 xl:grid-cols-6">
          <img src={google} alt="" />
          <img src={msn} alt="" />
          <img src={microsoft} alt="" />
          <img src={zoho} alt="" />
          <img src={georgia} alt="" />
          <img src={deloite} alt="" />
        </div>
      </div> */}

      <div className="mx-auto flex max-w-screen-xl flex-col items-center pb-24">
        <h2 className="mb-2 text-center text-[35px] font-bold text-navy-700 dark:text-white">
          Frequently Asked Questions
        </h2>
        <div className="mt-16 grid w-full grid-cols-1 gap-12 px-3 lg:grid-cols-2">
          {/* Left side */}
          <div>
            <div className="mb-[58px]">
              <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                How secure is our donor data?
              </h3>
              <p className="mt-3 text-base font-medium text-gray-600">
                Our CRM uses advanced encryption and security practices to ensure that all donor data is stored securely and protected from unauthorized access.
              </p>
            </div>
            <div className="mb-[58px]">
              <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                Can we integrate this CRM with our website?
              </h3>
              <p className="mt-3 text-base font-medium text-gray-600">
                Yes, our CRM can be integrated with most popular website platforms. If you have specific requirements, our team can assist in ensuring smooth integration.
              </p>
            </div>
            <div className="mb-[58px]">
              <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                How does the event management feature work?
              </h3>
              <p className="mt-3 text-base font-medium text-gray-600">
                Our event management feature allows you to schedule, track, and communicate about upcoming events. You can manage RSVPs, send out reminders, and even handle ticketing and tasks if required.
              </p>
            </div>
          </div>

          {/* Right side */}
          <div>
            <div className="mb-[58px]">
              <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                Is there a mobile app available?
              </h3>
              <p className="mt-3 text-base font-medium text-gray-600">
                At the moment, we do not have a dedicated mobile app. However, our CRM is fully responsive and can be accessed from any device with an internet connection.
              </p>
            </div>
            <div className="mb-[58px]">
              <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                How can I train my team to use this CRM?
              </h3>
              <p className="mt-3 text-base font-medium text-gray-600">
                We provide comprehensive documentation and training resources. Additionally, we offer onboarding sessions to help your team get acquainted with all the features.
              </p>
            </div>
            <div className="mb-[58px]">
              <h3 className="text-xl font-medium text-navy-700 dark:text-white">
                Are there any additional costs for updates or support?
              </h3>
              <p className="mt-3 text-base font-medium text-gray-600">
                Our subscription fee includes regular updates and support. There are no hidden charges. We believe in transparency and aim to provide the best service possible.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto max-w-screen-xl">
        <Footer />
      </div>
    </>
  );
};

export default PricingPage;
