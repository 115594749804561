import React, { useState } from "react";
import Card from "components/card";
import { MdAdd, MdOutlineCalendarToday } from "react-icons/md";
import ReactApexChart from 'react-apexcharts';
import MyChart from "./MyChart";
import Chart from 'chart.js/auto';
// data
import { barChartDataDailyTraffic } from "variables/charts";
import { lineChartDataProjectStatus } from "variables/charts";
import { lineChartDataOverallRevenue } from "variables/charts";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton, useDisclosure,
  Popover,
  Spinner
} from "@chakra-ui/react";
import axios from "axios";
import { Nango } from '@nangohq/node';

 function Synthesis() {
  const chartData = {
    barChartDataDailyTraffic,
    lineChartDataProjectStatus,
    lineChartDataOverallRevenue
  };

  const [chartOptions, setChartOptions] = useState({});
  const [chartSeries, setChartSeries] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let nango = new Nango({ secretKey: '5fb05aaf-e29f-4664-a1f8-164c734cb41b' });
  let creds = nango.getToken('google', 'test-connection-id-google');
  console.log(creds)
  const { onClose } = useDisclosure();
  // Move to ENV
  const ApiKey = process.env.REACT_APP_OPENAI_API_KEY;


  async function generateCompletions(prompt: any, model: any, apiKey: string) {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      },
      body: JSON.stringify({
        prompt: prompt,
        model: model,
        max_tokens: 1250,
        temperature: 0
      })
    };

    const response = await fetch('https://api.openai.com/v1/completions', requestOptions);
    const data = await response.json();
    return data.choices[0].text;
  }


  function generateChartData(chartData: any) {
    const categories = Object.keys(chartData.mean);
    const seriesData = [];

    // Loop over each chart data property and generate a series object
    for (const property in chartData) {
      const seriesObj: { name: string, data: number[] } = {
        name: property,
        data: []
      };

      // Loop over the key-value pairs in the current chart data property and add to series data array
      for (const key in chartData[property]) {
        seriesObj.data.push(chartData[property][key]);
      }

      // Add the series object to the seriesData array
      seriesData.push(seriesObj);
    }
    console.log("Series data:", seriesData);
    const chartOptions = {
      chart: {
        type: 'bar',
        height: 350
      },
      stroke: {
        width: 5,
        curve: 'smooth'
      },
      series: seriesData,
      xaxis: {
        categories: categories
      },
      yaxis:{
        show: true,
      },
      grid: {
        show: false,
      }
    };

    return chartOptions;
  }

  const handleSearch = async (question: string, chartData: any) => {
    setIsLoading(true);
    try {
      const prompt = `${question}\nBar chart data daily traffic: ${JSON.stringify(chartData.barChartDataDailyTraffic)}\nLine chart data project status: ${JSON.stringify(chartData.lineChartDataProjectStatus)}\nLine chart data overall revenue: ${JSON.stringify(chartData.lineChartDataOverallRevenue)}\n`;
      const resultText = await generateCompletions(prompt, "text-davinci-003", ApiKey);
      const resultIndex = resultText.indexOf("results");
      const resultDisplayText = resultText.replace(/Results in JSON Format:.*}/s, "");
      const jsonObject = JSON.parse(resultText.substring(resultText.indexOf('{'), resultText.lastIndexOf('}') + 1));


      setSearchResults([resultDisplayText]);

      console.log(jsonObject);
      const chartOptions = generateChartData(jsonObject);
      setChartOptions(chartOptions);
      setChartSeries(chartOptions.series);


      setShowResults(true);
      setIsModalOpen(false);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);


  return (
    <Card extra={""}>
      <div className="flex flex-col h-full">
        <div className="flex h-[205px] w-full items-center justify-center rounded-[20px] border border-dashed border-gray-200 bg-gray-100 hover:cursor-pointer dark:border-white/10 dark:!bg-navy-700">
          <button className="flex items-center justify-center rounded-[20px] bg-brand-500 p-3 text-4xl font-bold text-white shadow-2xl shadow-brand-500 dark:bg-brand-400 dark:shadow-brand-400" onClick={handleOpenModal}>
            <MdAdd />
          </button>
        </div>
        <div className="flex-grow flex items-center justify-center text-center xl:px-1 2xl:px-4">
          <div className="flex flex-col w-full h-full">
            <h4 className="text-2xl font-bold text-navy-700 dark:text-white ">
              Generate
            </h4>
            <div className="h-full flex items-center justify-center">
              <Card className='w-full h-full mt-[20px] ml-[15px] text-lg font-bold text-navy-700 dark:text-white'>
                {showResults ? (
                  <div className="h-full flex flex-col items-center justify-center">
                   
                    <ReactApexChart options={chartOptions} series={chartSeries} height={300} width={700} />
                    <p className="text-base font-extra-large text-darkgray-600">{searchResults[0]}</p>
                  </div>
                ) : (
                  <h2 className="mt-1 text-center text-sm font-medium text-darkgray-600">
                    Evolve your business with Safehaven Data Synthesis
                  </h2>
                )} 
              </Card>
            </div>
          </div>
        </div>
      </div>


      {/* Modal */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-max min-w-[350px] !max-w-[85%] md:top-[12vh] rounded-[20px] border border-dashed border-gray-200 bg-gray-100 hover:cursor-pointer dark:border-white/10 dark:!bg-navy-700">
          <ModalBody>
            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
              <h1 className="mb-[20px] text-2xl font-bold text-center">Safehaven Data Synthesis</h1>
              <div className="flex flex-col gap-4">
              {isLoading ? (
        <Spinner />
      ) : (

                <button className="linear rounded-xl border-2 border-blue-500 px-5 py-3 text-base font-medium text-blue-500 transition duration-200 hover:bg-blue-600/5 active:bg-blue-700/5 dark:border-blue-400 dark:bg-blue-400/10 dark:text-white dark:hover:bg-blue-300/10 dark:active:bg-blue-200/10" onClick={() => handleSearch('can you identify patterns can you please provide me with an example of analyzing Direct Login and Ad Links data using statistical methods such as mean, median, standard deviation, and regression analysis? Specifically, Im interested in learning how regression analysis can reveal consistency, predictability, and relationships between variables. Please include the results in a JSON format. Thank you!', chartData)}>
                  Are there any trends in the data that suggest potential areas for growth or improvement in the organization's fundraising and outreach efforts?
                </button>
  )}
           {isLoading ? (
        <Spinner />
      ) : (
                <button className="linear rounded-xl border-2 border-blue-500 px-5 py-3 text-base font-medium text-blue-500 transition duration-200 hover:bg-blue-600/5 active:bg-blue-700/5 dark:border-blue-400 dark:bg-blue-400/10 dark:text-white dark:hover:bg-blue-300/10 dark:active:bg-blue-200/10" onClick={() => handleSearch('.Are there any obstacles or challenges that are hindering our progress towards our weekly goals, and how can I overcome them today?can you please provide me with an example of analyzing Direct Login and Ad Links data using statistical methods such as mean, median, standard deviation, and regression analysis? Specifically, Im interested in learning how regression analysis can reveal consistency, predictability, and relationships between variables. Please include the results in a JSON format. Thank you!', chartData)}>
                  Are there any obstacles or challenges that are hindering our progress towards our weekly goals, and how can I overcome them today?
                </button>
      )}
           {isLoading ? (
        <Spinner />
      ) : (
                <button
                  onClick={() => handleSearch('How can we improve our impact and efficiency.can you please provide me with an example of analyzing Direct Login and Ad Links data using statistical methods such as mean, median, standard deviation, and regression analysis? Specifically, Im interested in learning how regression analysis can reveal consistency, predictability, and relationships between variables. Please include the results in a JSON format. Thank you!', chartData)}
                  className="linear rounded-xl border-2 border-blue-500 px-5 py-3 text-base font-medium text-blue-500 transition duration-200 hover:bg-blue-600/5 active:bg-blue-700/5 dark:border-blue-400 dark:bg-blue-400/10 dark:text-white dark:hover:bg-blue-300/10 dark:active:bg-blue-200/10"
                >
                 
                  How can we improve our impact and efficiency?
                </button>
      )}
      
                <button
                  onClick={handleCloseModal}
                  className="linear rounded-xl border-2 border-red-500 px-5 py-3 text-base font-medium text-red-500 transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                >Close
                </button>


              </div>
  
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
}
export default Synthesis
