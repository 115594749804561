import React, { useEffect, useState } from 'react';
import { ref, onValue } from 'firebase/database';
import { db } from '../../../../../firebase';
import ManagementTable from './components/ManagementTable';
import Conversion from './components/volunteerDashboard/Conversion';
import UserActivity from './components/volunteerDashboard/UserActivity';

// Assuming auth is initialized correctly
import auth from '../../../../../firebase';

interface CompanyDetails {
  contributeCode: string;
}

interface Company {
  data: CompanyDetails;
  users: Record<string, { email: string }>;
}

// Define the expected structure of a volunteer
interface GeneralVolunteer {
  contributeCode: string;
  email: string;
  firstName: string;
  lastName: string;
  job: string;
  username: string;
  volunteerEmail: string;
  skills?: Skill[];
  interests?: string[];
}


// Update the RowObj type to use Skill[] for the skills property
type Skill = {
  id: number;
  name: string;
};

export interface Availability {
  [day: string]: string;
}
// Update the RowObj type to use Skill[] for the skills property


type RowObj = Omit<GeneralVolunteer, 'contributeCode'> & { skills: Skill[]; interests: string[]; };

const Volunteer = () => {
  const [companyKey, setCompanyKey] = useState<string | null>(null);
  const [volunteerData, setVolunteerData] = useState<RowObj[]>([]);
  const currentUser = auth.currentUser;
  const [availabilityData, setAvailabilityData] = useState<Record<string, Availability>>({});
  useEffect(() => {
    const fetchCompanyAndVolunteers = async () => {
      const email = currentUser?.email;
      if (!email) {
        console.log("No user email found.");
        return;
      }
  
      const companiesRef = ref(db, 'companies');
      onValue(companiesRef, (snapshot) => {
        const companies = snapshot.val();
        if (!companies) {
          console.log("No companies found.");
          return;
        }
        
        const companyEntries = Object.entries(companies as Record<string, Company>);
        const matchedCompanyEntry = companyEntries.find(([_, company]) =>
          Object.values(company.users ?? {}).some(user => user.email === email)
        );
  
        if (!matchedCompanyEntry) {
          console.log("No matching company found for the user.");
          return;
        }
  
        const [key, company] = matchedCompanyEntry;
        setCompanyKey(key);
  
        // Fetch and filter volunteers
        const volunteersRef = ref(db, 'volunteers');
        onValue(volunteersRef, (volSnapshot) => {
          const volunteers = volSnapshot.val();
          if (!volunteers) {
            console.log("No volunteers found.");
            return;
          }
  
          const matchedVolunteers = Object.values(volunteers as Record<string, GeneralVolunteer>)
            .filter(volunteer => volunteer.contributeCode === company.data.contributeCode)
            .map(({ skills = [], interests = [], ...rest }) => ({
              skills,
              interests,
              ...rest,
            }));
  
          setVolunteerData(matchedVolunteers);
        });
      });
    };
  
    fetchCompanyAndVolunteers();
  }, [currentUser]);
  
  useEffect(() => {
    const fetchAvailability = async () => {
      const availabilityRef = ref(db, 'volunteerAvailability');
      onValue(availabilityRef, (snapshot) => {
        if (snapshot.exists()) {
          const data: Record<string, Availability> = snapshot.val();
          setAvailabilityData(data);
        }
      });
    };
  
    fetchAvailability();
  }, []);
  


  return (
    <div className="grid grid-cols-2 gap-4 mt-4">
      <div className="col-span-1 h-full w-full rounded-[20px]">
        <Conversion />
      </div>
      <div className="col-span-1 h-full w-full rounded-[20px]">
      <UserActivity availability={availabilityData} />
      </div>
      <div className="col-span-2 h-full w-full rounded-[20px]">
      
          <ManagementTable tableData={volunteerData} />
 
      </div>
    </div>
  );
};

export default Volunteer;
