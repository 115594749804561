import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import RequireAuth from "./contexts/require-auth";
import App from "./App";
import React from "react";
import { AuthProvider } from "./contexts/AuthContext";
import store from "contexts/store";
import { Provider } from "react-redux";

ReactDOM.render(
  
    <BrowserRouter>
    <Provider store={store}>
      <AuthProvider>
          <App />
      </AuthProvider>
      </Provider>
    </BrowserRouter>,
  document.getElementById("root")
);
