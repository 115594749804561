import React, { useState } from 'react';
import { Button, Input, useToast } from '@chakra-ui/react';

const PollCardForm = ({ onSave }) => {
  const [question, setQuestion] = useState('');
  const [options, setOptions] = useState([{ label: '', votes: 0 }, { label: '', votes: 0 }]);
  const toast = useToast();

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index].label = value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, { label: '', votes: 0 }]);
  };

  const handleSubmit = () => {
    if (options.some(option => option.label.trim() === '')) {
      toast({
        title: 'Error',
        description: 'Please fill in all options.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    onSave({ question, options, type: 'poll' });
    toast({
      title: 'Poll Saved',
      description: 'The poll has been successfully saved.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <div>
      <Input
        placeholder="Poll Question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      {options.map((option, index) => (
        <Input
          key={index}
          placeholder={`Option ${index + 1}`}
          value={option.label}
          onChange={(e) => handleOptionChange(index, e.target.value)}
        />
      ))}
      <Button onClick={addOption}>Add Option</Button>
      <Button onClick={handleSubmit}>Save Poll</Button>
    </div>
  );
};

export default PollCardForm;
