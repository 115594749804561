import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useToast,
  Box,
  VStack,
  HStack,
  Text,
  Badge,
  Button,
  ModalFooter,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Image,
  IconButton,
  Progress,
  Input,
} from '@chakra-ui/react';
import { FaCamera } from 'react-icons/fa';
import { collection, query, where, getDocs, getFirestore, doc, onSnapshot, runTransaction, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Card from 'components/card';
import BarChart from 'components/charts/BarChart';

interface Milestone {
  id: string;
  description: string;
  achieved: boolean;
  goalId: string;
}

interface Goal {
  id: string;
  title: string;
  description: string;
  zipCode: string;
}

interface ChartSeries {
  name: string;
  data: number[];
}

interface CommunityProps {
  onClose: () => void;
  zipCode?: string;
  latitude: number;
  longitude: number;
}

const AQI_LEVELS = {
  good: { label: 'Good', color: 'green.500', range: [0, 50] },
  moderate: { label: 'Moderate', color: 'yellow.500', range: [51, 100] },
  unhealthyForSensitiveGroups: { label: 'Unhealthy for Sensitive Groups', color: 'orange.500', range: [101, 150] },
  unhealthy: { label: 'Unhealthy', color: 'red.500', range: [151, 200] },
  veryUnhealthy: { label: 'Very Unhealthy', color: 'purple.500', range: [201, 300] },
  hazardous: { label: 'Hazardous', color: 'maroon.500', range: [301, Infinity] },
};

const getAQILevel = (aqi) => {
  for (const [level, { range }] of Object.entries(AQI_LEVELS)) {
    if (aqi >= range[0] && aqi <= range[1]) {
      return level;
    }
  }
};

const convertKelvinToCelsius = (kelvin) => (kelvin - 273.15).toFixed(2);
const convertKelvinToFahrenheit = (kelvin) => ((kelvin - 273.15) * 9/5 + 32).toFixed(2);

const CommunityGoalsModal: React.FC<CommunityProps> = ({ onClose, zipCode }) => {
  const [goals, setGoals] = useState<Goal[]>([]);
  const [milestones, setMilestones] = useState<Milestone[]>([]);
  const [airQuality, setAirQuality] = useState<any>(null);
  const [recommendedActions, setRecommendedActions] = useState<string[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});
  const toast = useToast();
  const firestore = getFirestore();
  const storage = getStorage();
  const [chartData, setChartData] = useState<ChartSeries[]>([{ name: 'Total Carbon Savings', data: [] }]);
  const [leaderboard, setLeaderboard] = useState([]);
  const [points, setPoints] = useState<number>(0);
  const [currentActionIndex, setCurrentActionIndex] = useState<number>(0);

  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: goals.map(goal => goal.title),
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "11px",
          fontWeight: "500",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#CBD5E0",
          fontSize: "11px",
        },
      },
    },
    grid: {
      show: false,
      strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true,
        },
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: "#4318FF",
            opacity: 1,
          },
          {
            offset: 100,
            color: "rgba(67, 24, 255, 1)",
            opacity: 0.28,
          },
        ],
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "40px",
      },
    },
    title: {
      text: 'Goals Progress',
      align: 'center',
    },
  };

  const handleActionSubmit = async (actionKey: string, file: File | null) => {
    const impact = actionsImpact[actionKey];
    const docRef = doc(firestore, 'communityActions', zipCode);
    let photoUrl = '';

    if (file) {
      const storageRef = ref(storage, `actions/${zipCode}/${actionKey}/${file.name}`);
      setUploading(true);
      try {
        await uploadBytes(storageRef, file);
        photoUrl = await getDownloadURL(storageRef);
        setImageUrls(prevState => ({ ...prevState, [actionKey]: photoUrl }));
      } catch (error) {
        toast({
          title: "Error",
          description: "Failed to upload photo.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setUploading(false);
        return;
      }
      setUploading(false);
    }

    try {
      await runTransaction(firestore, async (transaction) => {
        const docSnapshot = await transaction.get(docRef);
        if (!docSnapshot.exists()) {
          transaction.set(docRef, { totalSavings: impact, actions: { [actionKey]: { photoUrl, likes: 0, verified: false } } });
        } else {
          const currentData = docSnapshot.data();
          const currentSavings = currentData.totalSavings || 0;
          const currentActions = currentData.actions || {};
          transaction.update(docRef, {
            totalSavings: currentSavings + impact,
            actions: {
              ...currentActions,
              [actionKey]: { photoUrl, likes: 0, verified: false }
            }
          });
        }
      });
      toast({
        title: "Success",
        description: "Action successfully recorded.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setPoints(points + Math.abs(impact));  // Increment points
      setCurrentActionIndex(currentActionIndex + 1);  // Move to the next action
    } catch (e) {
      toast({
        title: "Error",
        description: "Failed to record action.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.log('Transaction failed: ', e);
    }
  };

  const fetchAirQualityData = async (lat, lon) => {
    try {
      const response = await fetch(`https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lon}&exclude=minutely,hourly,daily,alerts&appid=274bf18dde86c54cfe9bb2b9cb38a679`);
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      if (data && data.current) {
        setAirQuality(data);
        recommendActions(data);
      } else {
        console.error('No air quality data available');
        setAirQuality(null);
      }
    } catch (error) {
      console.error('Error fetching air quality data:', error);
      toast({
        title: "Error",
        description: "Failed to fetch air quality data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const recommendActions = (data) => {
    const recommendations = [];

    // Climate Action (SDG 13)
    if (data.current.temp > 300) {
      recommendations.push('plantTree');
      recommendations.push('promoteEnergyEfficiency');
    }

    // Clean Water and Sanitation (SDG 6)
    if (data.current.humidity > 60) {
      recommendations.push('usePublicTransportation');
      recommendations.push('waterConservation');
    }

    // Responsible Consumption and Production (SDG 12)
    if (data.current.pressure < 1010) {
      recommendations.push('recycle');
      recommendations.push('reducePlasticUse');
    }

    // Affordable and Clean Energy (SDG 7)
    recommendations.push('installSolarPanels');

    // Good Health and Well-being (SDG 3)
    recommendations.push('organizeCommunityCleanups');
    recommendations.push('promoteMentalHealthAwareness');

    // Sustainable Cities and Communities (SDG 11)
    recommendations.push('supportLocalBusinesses');
    recommendations.push('promotePublicTransport');

    // No Poverty (SDG 1)
    recommendations.push('volunteerForHomelessShelter');
    recommendations.push('donateClothes');

    // Zero Hunger (SDG 2)
    recommendations.push('communityGardening');
    recommendations.push('foodDonationDrives');

    // Quality Education (SDG 4)
    recommendations.push('tutoringPrograms');
    recommendations.push('donateBooks');

    // Gender Equality (SDG 5)
    recommendations.push('supportWomenEntrepreneurs');
    recommendations.push('organizeEqualityWorkshops');

    setRecommendedActions(recommendations);
  };

  // Action descriptions and impacts
  const actionsImpact = {
    plantTree: -15,
    promoteEnergyEfficiency: -20,
    usePublicTransportation: -10,
    waterConservation: -5,
    recycle: -5,
    reducePlasticUse: -5,
    installSolarPanels: -30,
    organizeCommunityCleanups: -10,
    promoteMentalHealthAwareness: -5,
    supportLocalBusinesses: -5,
    promotePublicTransport: -10,
    volunteerForHomelessShelter: -5,
    donateClothes: -5,
    communityGardening: -15,
    foodDonationDrives: -10,
    tutoringPrograms: -5,
    donateBooks: -5,
    supportWomenEntrepreneurs: -5,
    organizeEqualityWorkshops: -5,
  };

  const actionRecommendations = {
    plantTree: { description: "Plant a Tree to improve air quality and provide shade", color: "green" },
    promoteEnergyEfficiency: { description: "Promote energy efficiency in your home to save energy", color: "blue" },
    usePublicTransportation: { description: "Ride the Bus to reduce carbon emissions", color: "blue" },
    waterConservation: { description: "Implement water conservation practices to save water", color: "blue" },
    recycle: { description: "Recycle to reduce waste and conserve resources", color: "yellow" },
    reducePlasticUse: { description: "Reduce plastic use to prevent pollution", color: "yellow" },
    installSolarPanels: { description: "Install solar panels to use renewable energy", color: "yellow" },
    organizeCommunityCleanups: { description: "Organize community cleanups to keep the environment clean", color: "green" },
    promoteMentalHealthAwareness: { description: "Promote mental health awareness and support", color: "green" },
    supportLocalBusinesses: { description: "Support local businesses to strengthen the economy", color: "green" },
    promotePublicTransport: { description: "Promote public transport to reduce traffic and pollution", color: "green" },
    volunteerForHomelessShelter: { description: "Volunteer at a homeless shelter to support those in need", color: "blue" },
    donateClothes: { description: "Donate clothes to help those in need", color: "blue" },
    communityGardening: { description: "Start or join a community garden to grow local produce", color: "green" },
    foodDonationDrives: { description: "Organize food donation drives to help fight hunger", color: "blue" },
    tutoringPrograms: { description: "Volunteer for tutoring programs to support education", color: "yellow" },
    donateBooks: { description: "Donate books to support education and literacy", color: "yellow" },
    supportWomenEntrepreneurs: { description: "Support women entrepreneurs to promote gender equality", color: "blue" },
    organizeEqualityWorkshops: { description: "Organize workshops on gender equality and inclusivity", color: "blue" },
  };

  const fetchLeaderboard = async () => {
    try {
      const leaderboardRef = collection(firestore, 'leaderboard');
      const leaderboardSnapshot = await getDocs(leaderboardRef);
      const fetchedLeaderboard = leaderboardSnapshot.docs.map(docSnapshot => docSnapshot.data());
      setLeaderboard(fetchedLeaderboard);
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
      toast({
        title: "Error",
        description: "Failed to fetch leaderboard data.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    const fetchGoalsAndMilestones = async () => {
      if (!zipCode) return;

      const goalsRef = collection(firestore, 'goals');
      const goalsQuery = query(goalsRef, where('zipCode', '==', zipCode));
      const goalsSnapshot = await getDocs(goalsQuery);
      const fetchedGoals: Goal[] = goalsSnapshot.docs.map(docSnapshot => ({
        id: docSnapshot.id,
        ...docSnapshot.data(),
      } as Goal));
      setGoals(fetchedGoals);

      const milestonesRef = collection(firestore, 'milestones');
      const milestonesSnapshot = await getDocs(milestonesRef);
      const fetchedMilestones: Milestone[] = [];
      milestonesSnapshot.forEach(docSnapshot => {
        const milestone = {
          id: docSnapshot.id,
          ...docSnapshot.data(),
        } as Milestone;
        if (fetchedGoals.some(goal => goal.id === milestone.goalId)) {
          fetchedMilestones.push(milestone);
        }
      });
      setMilestones(fetchedMilestones);

      const lat = 37.7749; // Replace with actual latitude
      const lon = -122.4194; // Replace with actual longitude
      fetchAirQualityData(lat, lon);

      fetchLeaderboard();
    };

    fetchGoalsAndMilestones();
  }, [zipCode, firestore]);

  useEffect(() => {
    const docRef = doc(firestore, 'communityActions', zipCode || 'default');
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setChartData([{ name: 'Total Carbon Savings', data: [data.totalSavings || 0] }]);
      }
    });
    return unsubscribe;
  }, [zipCode]);

  const MilestoneBadge = ({ achieved }: { achieved: boolean }) => (
    <Badge colorScheme={achieved ? 'green' : 'red'}>
      {achieved ? 'Achieved' : 'Pending'}
    </Badge>
  );

  const sortedActions = recommendedActions.sort((a, b) => Math.abs(actionsImpact[b]) - Math.abs(actionsImpact[a]));

  const handleSkipAction = () => {
    setCurrentActionIndex(currentActionIndex + 1);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, actionKey: string) => {
    if (e.target.files) {
      handleActionSubmit(actionKey, e.target.files[0]);
    }
  };

  return (
    <Modal onClose={onClose} isOpen={true} size="full" >
      <ModalOverlay />
      <ModalContent className="bg-white dark:bg-gray-800 rounded-lg shadow-lg transform transition-all duration-300 ease-in-out">
        <ModalCloseButton className="text-black" />
        <ModalBody className="p-6">
          <Tabs isFitted variant="enclosed" colorScheme="brand">
            <TabList mb="4">
              <Tab className="focus:outline-none">Details</Tab>
              <Tab className="focus:outline-none">Community Impact</Tab>
              <Tab className="focus:outline-none">Milestones</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Text className="text-xl font-bold mb-4">Sustainability Goals Overview</Text>
                <VStack spacing={4}>
                  <Card className="">
                    <Text className="text-sm font-semibold mb-4">Recommended Actions</Text>
                    {sortedActions.length > 0 && currentActionIndex < sortedActions.length && (
                      <Box key={sortedActions[currentActionIndex]}>
                       
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleFileChange(e, sortedActions[currentActionIndex])}
                            style={{ display: 'none' }}
                            id={`file-input-${sortedActions[currentActionIndex]}`}
                          />
                          <IconButton
                            icon={<FaCamera />}
                            aria-label="Upload Photo"
                            as="label"
                            htmlFor={`file-input-${sortedActions[currentActionIndex]}`}
                          />
                          {uploading && <Text>Uploading...</Text>}
                          {imageUrls[sortedActions[currentActionIndex]] && <Image src={imageUrls[sortedActions[currentActionIndex]]} alt="Uploaded Proof" />}
                          <Button
                            onClick={handleSkipAction}
                            className={`mb-4 hover:bg-${actionRecommendations[sortedActions[currentActionIndex]].color}-600 text-black text-sm transition-colors`}
                          >
                            {actionRecommendations[sortedActions[currentActionIndex]].description}
                          </Button>
                        
                      </Box>
                    )}
                    {currentActionIndex >= sortedActions.length && (
                      <Text className="text-lg font-semibold mt-4">Congratulations! You have completed all recommended actions.</Text>
                    )}
                    <Text className="text-lg font-semibold mt-4">Points: {points}</Text>
                    <Progress value={(currentActionIndex / sortedActions.length) * 100} />
                  </Card>
                  <Card className="p-4">
                    <Text className="text-xl font-semibold mb-4">Goals Progress</Text>
                    <BarChart chartData={chartData} chartOptions={chartOptions} />
                  </Card>
                </VStack>
                <VStack spacing={4} className="mt-4">
                  {goals.map(goal => (
                    <Card key={goal.id} className="p-4 dark:bg-gray-700 rounded-md shadow-md">
                      <Text className="text-lg font-semibold">{goal.title}</Text>
                      <Text className="mt-2">{goal.description}</Text>
                    </Card>
                  ))}
                </VStack>
              </TabPanel>
              <TabPanel>
                <Text className="text-2xl font-bold mb-4">Community Impact</Text>
                {airQuality && airQuality.current ? (
                  <Card className="p-4">
                    <Text className="text-xl font-semibold">Air Quality</Text>
                    <Text className="mt-2">
                      <strong>Temperature:</strong> {convertKelvinToCelsius(airQuality.current.temp)} °C / {convertKelvinToFahrenheit(airQuality.current.temp)} °F
                      <br />
                      <span className="text-sm text-gray-500">Ideal temperature for comfort is around 20-25°C (68-77°F).</span>
                    </Text>
                    <Text className="mt-2">
                      <strong>Humidity:</strong> {airQuality.current.humidity} %
                      <br />
                      <span className="text-sm text-gray-500">Comfortable indoor humidity levels are between 30-50%.</span>
                    </Text>
                    <Text className="mt-2">
                      <strong>Pressure:</strong> {airQuality.current.pressure} hPa
                      <br />
                      <span className="text-sm text-gray-500">Normal atmospheric pressure is around 1013 hPa.</span>
                    </Text>
                    <Text className="mt-2">
                      <strong>Wind Speed:</strong> {airQuality.current.wind_speed} m/s
                      <br />
                      <span className="text-sm text-gray-500">A gentle breeze is around 3.4-5.4 m/s.</span>
                    </Text>
                    <Text className="mt-2">
                      <strong>Wind Direction:</strong> {airQuality.current.wind_deg} °
                      <br />
                      <span className="text-sm text-gray-500">Wind direction in degrees (0° = North, 90° = East).</span>
                    </Text>
                    <Text className="mt-2">
                      <strong>Visibility:</strong> {airQuality.current.visibility} m
                      <br />
                      <span className="text-sm text-gray-500">Visibility range: 10,000 meters (10 km) is very clear.</span>
                    </Text>
                    {airQuality.current.weather && airQuality.current.weather.length > 0 && (
                      <Text className="mt-2"><strong>Weather:</strong> {airQuality.current.weather[0].description}</Text>
                    )}
                  </Card>
                ) : (
                  <Text className="text-lg font-semibold mt-2">No air quality data available.</Text>
                )}
                <Text className="text-2xl font-bold mb-4 mt-6">Leaderboard</Text>
                <VStack spacing={4}>
                  {leaderboard.map((entry, index) => (
                    <HStack key={index} justify="space-between" className="p-4 dark:bg-gray-700 rounded-md shadow-md w-full">
                      <Text className="text-lg">{entry.name}</Text>
                      <Text className="text-lg">{entry.points} points</Text>
                    </HStack>
                  ))}
                </VStack>
              </TabPanel>
              <TabPanel>
                <Text className="text-2xl font-bold mb-4">Milestones</Text>
                {goals.map(goal => (
                  <VStack align="stretch" spacing={4} key={goal.id}>
                    <Text className="text-xl font-semibold">{goal.title}</Text>
                    {milestones.filter(milestone => milestone.goalId === goal.id).map(milestone => (
                      <HStack justify="space-between" key={milestone.id} className="p-2 dark:bg-gray-600 rounded-md shadow-sm">
                        <Text className="text-md">{milestone.description}</Text>
                        <MilestoneBadge achieved={milestone.achieved} />
                      </HStack>
                    ))}
                  </VStack>
                ))}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter className="p-4">
          <Button onClick={onClose} className="bg-brand-500 text-white hover:bg-brand-600 transition-colors">Close</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CommunityGoalsModal;
