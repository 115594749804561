
import { useEffect, useState, Key } from "react";
import { getDatabase, ref, onValue, remove, update, off, DataSnapshot, push, set } from "firebase/database";
import { db } from "../../../../../firebase";
import auth from "../../../../../firebase";
import {
  Grid,
  Box,
  VStack,
  Heading,
  List,
  ListItem,
  Button,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  IconButton,
  Flex,
  useBreakpointValue,
  position,
} from '@chakra-ui/react';
import ListsComponent from "./components/ListComponent";
import ManagementTable from './components/ManagementTable'
import SearchComponent from "./components/SearchComponent";


interface Activity {
  timestamp: Date;
  description: string;
  type: string; // e.g., 'email', 'call', 'donation'
}

interface Member {
  address: any;
  id: string;
  name: [string, string, string];
  email: string;
  profileType: string;
  communication: string;
  donations: Donation[];
  status: string;
  date: string;
  edit?: any;
  memberMissions: string[];  // Adjusted to string array based on previous discussions.
  priceNumeric?: number;
  activities: Activity[];
  companyName: string; // New field for business information
  position: string;    // New field for business information
  businessPhone: string; // New field for business information
}

interface Donation {
  dollars: string;
  cents: string;
  date: string;
}
interface CategorizedMembers {
  Active: Member[];
  Prospective: Member[];
  Pending: Member[];
  Lapsed: Member[];
  HighDonors: Member[];
  RecentJoins: Member[];
  // Add more categories as needed
}

const Members = () => {
  const bg = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const [tableDataMembers, setTableDataMembers] = useState<Member[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [userData, setUserData] = useState<Member | null>(null);
  const [selectedUserData, setSelectedUserData] = useState<Member | null>(null);
  const [id] = useState("");
  const [toggleState, setToggleState] = useState(1);
  const categories = ["Active", "Prospective", "Pending", "Lapsed", "High Donors", "Recent Joins"];
  

  
  const currentUser = auth.currentUser
  const [userEmail, setUserEmail] = useState("");
  const [companyKey, setCompanyKey] = useState("");
  const [userKey, setUserKey] = useState("");
  const tableVariant = useBreakpointValue({ base: "simple", md: "striped" });
  const [userInfo, setUserInfo] = useState({
    email: "",
    aboutMe: "",
  });

  // Current User
  useEffect(() => {
    const email = currentUser.email || "";
    if (email) {
      setUserEmail(email);
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                setUserKey(userKey);
                localStorage.setItem('companyKey', companyKey);
              }
            }
          }
        }
      });
    }
  }, [currentUser, db])

  const companyKeySaved = localStorage.getItem('companyKey');


  // Set Table Data
  useEffect(() => {
    const db = getDatabase();
    const membersRef = ref(db, `companies/${companyKeySaved}/members/${id}`);
    const handleData = (snapshot: DataSnapshot) => {
      const data = snapshot.val();
      if (data) {
        const memberList = Object.entries<Member>(data).map(([id, values]) => {
          const customerName = values.name[0];
          const { dollars, cents } = values.donations[0];
          const total = parseFloat(dollars) + parseFloat(cents) / 100;
          const priceNumeric = parseFloat(total.toFixed(2));
          return { id, ...values, priceNumeric, customerName };
        });
        setTableDataMembers(memberList);
      } else {
        setTableDataMembers([]);
      }
    };

    onValue(membersRef, handleData);

    return () => {
      off(membersRef, "value", handleData);
    };
  }, []);

  // Select user from table
  useEffect(() => {
    if (selectedUserId) {
      const db = getDatabase();
      const membersRef = ref(db, `companies/${companyKeySaved}/members/${id}`);
      const handleData = (snapshot: DataSnapshot) => {
        const data = snapshot.val();
        if (data) {
          const currentMember = Object.fromEntries(
            Object.entries<Member>(data).map(([id, values]) => [id, { id, ...values }])
          );
          const user = currentMember[selectedUserId];
          setSelectedUserData(user);
        }
      };
      onValue(membersRef, handleData);

      return () => {
        off(membersRef, "value", handleData);
      };
    }
  }, [selectedUserId]);
  // Income table

  const categorizeMembers = (members: Member[]): CategorizedMembers => {
    const categorized = {
      Active: [],
      Prospective: [],
      Pending: [],
      Lapsed: [],
      HighDonors: [],
      RecentJoins: [],
    };

    members.forEach(member => {
      const statusKey = member.status as keyof typeof categorized;
      if (categorized[statusKey]) {
        categorized[statusKey].push(member);
      }
    });

    return categorized;
  };
  const addNewList = () => {
    // Implement your logic to add a new list
    console.log("Add new list logic goes here");
  };

  const addMember = async (newMemberData: any) => {
    const membersRef = ref(db, `companies/${companyKeySaved}/members`);
    const newMemberRef = push(membersRef);
    await set(newMemberRef, newMemberData);
    setTableDataMembers([...tableDataMembers, { id: newMemberRef.key, ...newMemberData }]);
  };

  // Delete Member
  const deleteMember = (memberId: Key) => {
    const memberRef = ref(db, `companies/${companyKeySaved}/members/${memberId}`);
    remove(memberRef);
  };


  const addNewFirebaseRecord = async (data: Omit<Member, 'id'> ): Promise<string> => {
    const membersRef = ref(db, `companies/${companyKeySaved}/members`);
    const newMemberRef = push(membersRef);
    await set(newMemberRef, data);
    return newMemberRef.key;
  };
  



  const handleImportedData = async (importedData: any[]) => {
    if (Array.isArray(importedData)) {
      let updatedMembers = [...tableDataMembers];
  
     
      for (const user of importedData) {
        const { email_address, full_name } = user;
    
        let nameParts = full_name.split(' ');
    
        let formattedName: [string, string, string]; // Explicitly declaring the type
        if (nameParts.length === 1) {
          formattedName = [nameParts[0], '', ''];
        } else if (nameParts.length >= 2) {
          formattedName = [nameParts[0], '', nameParts[nameParts.length - 1]];
        } else {
          formattedName = ['', '', '']; // Fallback for unexpected cases
        }
        let memberIndex = updatedMembers.findIndex(member => member.email === email_address);
  
        if (memberIndex === -1) {
          // Define default values for the new member
          const newMemberData = {
            email: email_address,
            name: formattedName,
            status: "Pending",
            donations: [{
              dollars: "0",
              cents: "0",
              date: new Date().toISOString()
            }],
            activities: [{
              timestamp: new Date(),
              description: "Initial activity",
              type: "email"
            }],
            profileType: "member",
            communication: "email",
            memberMissions: [''],
            date: new Date().toISOString(),
            address: {},
            priceNumeric: 0,
            businessPhone: "",
            position: "",
            companyName:"",
          };
          
          const newMemberId = await addNewFirebaseRecord(newMemberData);    
        } else {
          // Logic to update existing member...
        }
      }
  
      setTableDataMembers(updatedMembers);
    } else {
      console.error('Data is not in expected format:', importedData);
    }
  };
  
  const categorizedMembers = categorizeMembers(tableDataMembers);

  
  return (
    <Grid
      templateColumns={["repeat(1, 1fr)", null, "300px 1fr"]}
      gap={4}
      mt={5}
      h="full" // Ensure full height
    >
      <Box
        as="aside"
        gridColumn={["1", null, "1 / 2"]}
        overflowY="auto"
        h="full" // Make the list's container take up full height
        mt={15}
      >
        <ListsComponent
          categorizedMembers={categorizedMembers}
          deleteMember={deleteMember}
          addNewList={addNewList}
          textColor={textColor}
        />
      </Box>
      {/* Table Section - ensure it takes up the remaining space */}
      <Box
        as="main"
        gridColumn={["1", null, "2 / 3"]}
        overflowY="auto"
        h="full" // Make the table's container take up full height
      >
         <SearchComponent addMember={addMember} />
        <ManagementTable tableData={tableDataMembers}
           onImportedData={handleImportedData}
        />
      </Box>
    </Grid>
  );
}

export default Members;