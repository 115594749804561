import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Portal } from "@chakra-ui/portal";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes";
import RequireAuth from "contexts/require-auth";
import VolunteerDashboard  from "views/volunteer/volunteerDashboard";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import NavbarVolunteer from "components/navbar/NavbarVolunteer";
import Group from "views/volunteer/volunteerDashboard/groups";

export default function Volunteer(props: { [x: string]: any; }) {
    const { ...rest } = props;
    const location = useLocation();
    const [open, setOpen] = React.useState(false);
    const [currentRoute, setCurrentRoute] = React.useState("Volunteer");
    const volunteerRoutes = [
        { },
        // { name: "Missions", path: "/volunteer/missions" },
        // ... other volunteer routes
      ];


    React.useEffect(() => {
      window.addEventListener("resize", () =>
        window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
      );
    }, []);
    React.useEffect(() => {
      getActiveRoute(routes);
      // eslint-disable-next-line
    }, [location.pathname]);
    // functions for changing the states from components
    const getActiveRoute = (routes: RoutesType[]): string => {
      let activeRoute = "Default Brand Text";
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveRoute = getActiveRoute(routes[i].items);
          if (collapseActiveRoute !== activeRoute) {
            return collapseActiveRoute;
          }
        } else {
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
          ) {
            setCurrentRoute(routes[i].name);
          }
        }
      }
      return activeRoute;
    };
    const getActiveNavbar = (routes: RoutesType[]): boolean => {
      let activeNavbar = false;
      for (let i = 0; i < routes.length; i++) {
        if (routes[i].collapse) {
          let collapseActiveNavbar = getActiveNavbar(routes[i].items);
          if (collapseActiveNavbar !== activeNavbar) {
            return collapseActiveNavbar;
          }
        } else {
          if (
            window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
          ) {
            return routes[i].secondary;
          }
        }
      }
      return activeNavbar;
    };
    const getRoutes = (routes: RoutesType[]): any => {
        return routes.map((prop, key) => {
          if (prop.layout === "/volunteer") {
            return (
              <Route path={`${prop.path}`} element={prop.component} key={key} />
            );
          }
          if (prop.collapse) {
            return getRoutes(prop.items);
          }
          return null;
        });
      };
      document.documentElement.dir = "ltr";

  return (
   
    <div className="flex h-full w-full">
      {/* <Sidebar open={open} onClose={() => setOpen(true)} /> */}
      {/* Navbar & Main Content */}
      <div className="h-full w-full font-dm dark:bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-2.5 flex-none transition-all dark:bg-navy-900]`}
        >
          {/* Routes */}
          <Portal>
          <NavbarVolunteer routes={volunteerRoutes} onOpenSidenav={() => setOpen(!open)} brandText={currentRoute}/>
          </Portal>
          
            <div className="mx-auto min-h-screen p-2 !pt-[100px] md:p-2">
            <FixedPlugin />
              <Routes>
                {getRoutes(routes)}
                <Route
                  path="/"
                  element={<Navigate to="/volunteer/contribute" replace />}
                />
                  <Route
                  path="/"
                  element={<Navigate to="/volunteer/main/profile/settings" replace />}
                />
                   <Route
                  path="/"
                  element={<Navigate to="/volunteer/forums" replace />}
                />
                   <Route
                  path="/"
                  element={<Navigate to="/volunteer/social-responsibility/market" replace />}
                />
                
 <Route path="/volunteer/groups" element={<Group />} />
              </Routes>
              
              
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
      </div>
      
    </div>
  );
}
