import { useState } from 'react';

export const Legend = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleLegend = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg mt-4">
      <h4
        onClick={toggleLegend}
        className="text-sm font-semibold mb-2 text-gray-800 dark:text-white cursor-pointer"
      >
        Legend {isExpanded ? '▲' : '▼'}
      </h4>
      {isExpanded && (
        <div className="space-y-2">
          <div className="flex items-center">
            <span className="w-4 h-4 mr-2 rounded-full" style={{ backgroundColor: 'rgba(0, 255, 0, 0.5)' }}></span>
            <span className="text-gray-700 dark:text-gray-300">Sustainable Area</span>
          </div>
          <div className="flex items-center">
            <span className="w-4 h-4 mr-2 rounded-full" style={{ backgroundColor: 'rgba(255, 255, 0, 0.5)' }}></span>
            <span className="text-gray-700 dark:text-gray-300">Moderate Sustainability</span>
          </div>
          <div className="flex items-center">
            <span className="w-4 h-4 mr-2 rounded-full" style={{ backgroundColor: 'rgba(255, 0, 0, 0.5)' }}></span>
            <span className="text-gray-700 dark:text-gray-300">Low Sustainability</span>
          </div>
          <div className="flex items-center">
            <span className="w-4 h-4 mr-2 rounded-full" style={{ backgroundColor: 'rgba(204, 204, 204, 0.5)' }}></span>
            <span className="text-gray-700 dark:text-gray-300">Other</span>
          </div>
        </div>
      )}
    </div>
  );
};
