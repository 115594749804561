import React from 'react';
import { useNavigate } from 'react-router-dom';
import NavbarAuth from './NavbarAuth';
import Footer from 'components/footer/Footer';
import DemoImage1 from 'assets/svg/iphoneDemo.png';
import DemoImage2 from 'assets/svg/DemoImage2.png';
import { FaComments, FaUsers, FaSearch, FaShieldAlt } from 'react-icons/fa';
import ScrollVideo from './ScrollVideo2';
import './VolunteerSocialMediaLanding.css'; // Import CSS for additional styles

const VolunteerSocialMediaLanding: React.FC = () => {
  const navigate = useNavigate();

  const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
    <div className="flex flex-col items-center m-4 w-full sm:w-1/2 lg:w-1/4"> {/* Adjusted width for responsiveness */}
      <div className="text-4xl mb-2">{icon}</div>
      <p className="font-bold text-lg">{title}</p>
      <p className="text-center text-sm text-gray-600">{description}</p>
    </div>
  );

  return (
    <div className="dark:bg-navy-900 p-4 rounded">
      <NavbarAuth />
      <div className="mx-auto max-w-screen-xl  mt-20"> {/* Adjusted margin top */}
        <div className="flex flex-col items-center justify-center max-w-full px-4 md:px-0">
          <div className="text-center">
            
            <ScrollVideo />
            <p className="text-gray-900 font-bold text-3xl md:text-5xl mb-8 dark:text-white">
              Join the Movement
              <span className="relative">
                {' '}Empower Change with Every Connection 
              </span>
            </p>
            <div className="flex flex-wrap justify-center max-w-full px-8 md:px-0"> {/* Adjusted flex properties */}
              <FeatureCard icon={<FaComments />} title="Chat" description="Discover local faster" />
              <FeatureCard icon={<FaUsers />} title="Groups" description="Local Accountability to Improve Zip-Codes" />
              <FeatureCard icon={<FaSearch />} title="Search" description="Find the exact information you need" />
              <FeatureCard icon={<FaShieldAlt />} title="Verification" description="Real Time Updates from Your favorite businesses" />
            </div>
            <div className="relative w-full mx-auto mb-8">
              <img src={DemoImage1} alt="Product" className="w-full" />
              <p className="text-gray-600 font-medium text-base lg:text-lg w-full mx-auto mb-4 dark:text-white">
                Join the wave of change with HarmonyFlow! Visualize your volunteer journey, share inspiring tales, and get recognized for every act of kindness.
              </p>
            </div>
            <p className="text-purple-700 font-bold text-base lg:text-xl mb-4 mt-5">
              ImpactWave is Changing the World
            </p>
            <p className="text-gray-900 font-bold text-3xl md:text-5xl mb-8 dark:text-white">
              Elevated Collaboration 🧑‍🤝‍🧑
              <span className="relative">
                {' '}Workflow Automations 🦾
              </span>
            </p>
            <p className="text-gray-600 font-medium text-base lg:text-lg w-full mx-auto mb-4 dark:text-white">
              Make every act count with HarmonyFlow! 🌊 Experience the ripple effect of your goodwill with our intuitive impact tracking. Share stories, earn recognition, and contribute to a giant leap for communities. HarmonyFlow isn't just a platform; it's a movement where every volunteer journey becomes a wave of enduring impact!
            </p>
            <div className="relative w-full mx-auto mb-8">
              <img src={DemoImage2} alt="Product" className="w-full" />
            </div>
            <p className="text-purple-700 font-bold text-base lg:text-xl mb-4 mt-5">
              Embark on Missions That Matter
            </p>
            <p className="text-gray-900 font-bold text-3xl md:text-5xl mb-8 dark:text-white">
              ✨Connect With Your Community
              <span className="relative">
                {' '} & Drive Change✨
              </span>
            </p>
            <p className="text-gray-600 font-medium text-base lg:text-lg w-full mx-auto mb-4 dark:text-white">
              Find your cause, connect with opportunities, and make a tangible difference. Your skills can drive change - join missions that match your passion!
            </p>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default VolunteerSocialMediaLanding;
