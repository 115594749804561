import { useState } from "react";
import Stepper from "./components/Stepper";
import StepperControl from "./components/StepperControl";
import { UseContextProvider } from "./contexts/StepperContext";
import CampaignSettings from "./components/steps/CampaignSettings";
import CampaignEditor from "./components/steps/Assets";
import Confirmation from "./components/steps/Confirmation";
import Card from "components/card";
import NewCampaignLayout from "./components/design/NewCampaignLayout";
const NewCampaign = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    { stepNo: 1, name: "Campaign Info" },
    { stepNo: 2, name: "Content and Design" },
    { stepNo: 3, name: "Scheduling & Delivery" },
  ];
  const displayStep = (step: {
    stepNo: number;
    name: string;
    highlighted?: boolean;
    selected?: boolean;
    completed?: boolean;
  }) => {
    switch (step.stepNo) {
      case 1:
        return <CampaignSettings />;
      case 2:
        return <NewCampaignLayout />;
      case 3:
        return <Confirmation />;
      default:
    }
  };

  const handleClick = (direction: string = "prev") => {
    if (direction === "next" && currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    } else if (direction === "prev" && currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else if (currentStep === steps.length - 1) {
      // Handle the submit action here, like sending data to a server
    }
  };

  const handleSave = () => {
    // Send the current campaign data to a server
    console.log('Saving campaign data...');
  };

  const handlePreview = () => {
    // Open a preview of the current campaign
    console.log('Opening campaign preview...');
  };

  return (
    <div className="mt-3 h-full w-full">
    <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary md:h-[390px]" />
    <div className="w-md:full mx-auto h-full w-full md:px-3  3xl:w-7/12">
      <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          <Stepper
            action={setCurrentStep}
            steps={steps}
            currentStep={currentStep}
          />
        </div>

        <Card extra={"h-auto mx-auto pb-3 overflow-hidden"}>
            <div className="rounded-[20px] overflow-auto">
                <UseContextProvider>
                    {displayStep(steps[currentStep - 1])}
                </UseContextProvider>
            </div>
          {/* navigation button */}

          <StepperControl
            handleClick={handleClick}
            currentStep={currentStep}
            steps={steps}
       
           
          />
        </Card>
      </div>
    </div>
  );
};

export default NewCampaign;
