import { DataSnapshot, onValue, ref } from "firebase/database";
import SearchTableUsers from "./components/SearchTableUsersOverview";
import tableDataUsersOverview from "./variables/tableDataUsersOverview";
import Card from "components/card";
import {db,auth} from '../../../../../firebase'
import { useEffect, useState } from "react";

interface UserProps {
  team: string;
  companyCode: string;
  email: string;
  firstName:string;
  lastName:string;
  job: string;
  name:string
  uid: string;
  userName: string;
  username: string;
  aboutMe: string;
}

type RowObj = {
  userName: string;
  email: string;
  job: string;
  name:[string,string,string]
  team: string;
  view?: any;
  aboutMe: string;
};


const UserOverview = () => {
  const [companyKey, setCompanyKey] = useState<string | null>(null);
  const [userData, setUserData] = useState<RowObj[]>([]);
  const currentUser = auth.currentUser;
  const [userEmail, setUserEmail] = useState("");
  const [userKey, setUserKey] = useState("");
  // Fetch User Data


  useEffect(() => {
    const email = currentUser.email || "";
    if (email) {
      setUserEmail(email);
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                setUserKey(userKey);
                localStorage.setItem("companyKey", companyKey);
              }
            }
          }
        }
      });
    }
  }, [currentUser, db]);

  useEffect(() => {
    const fetchUserData = async () => {
  if (companyKey) {
    const userRef = ref(db, `companies/${companyKey}/users/`);
    onValue(userRef, (snapshot: DataSnapshot) => {
      const users = snapshot.val() as Record<string, UserProps> | null;
      if (users) {
        const userArray: RowObj[] = Object.entries(users).map(([id, userProps]) => {
          const {
            userName,
            email,
            job,
            name:[firstName,lastName,profilePicture],
            team,
            aboutMe
          } = userProps;

          return {
            id,
            userName,
            email,
            job,
            name: [firstName,lastName,profilePicture],
            team,
            aboutMe
          };
        });

        setUserData(userArray);
        console.log(userArray);
      } else {
        setUserData([]);
        console.log("No data found");
      }
    });
  }
};

fetchUserData();
},[companyKey]);
  return (
    <Card extra={"w-full h-full mt-3"}>
      <SearchTableUsers tableData={userData} />
    </Card>
  );
};

export default UserOverview;
