import { Modal, ModalOverlay, ModalContent, ModalBody, Image, Button, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

const CustomLightbox = ({ isOpen, onClose, images, initialIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex || 0);

  // Condition to check if images are available.
  if (!images || images.length === 0) {
    return null; // Return null to render nothing if there are no images.
  }

  const handleNext = () => {
    setCurrentIndex((prevIndex: number) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex: number) => (prevIndex - 1 + images.length) % images.length);
  };

  // The modal will only render if there are images.
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl" isCentered >
      <ModalOverlay />
      <ModalContent maxW="5xl">
        <ModalBody p={0} display="flex" alignItems="center" justifyContent="center">
          <Button onClick={handlePrev} m={2} position="absolute" left={0}>
            Prev
          </Button>
          <Image src={images[currentIndex]} alt={`image ${currentIndex}`} boxSize="600px" objectFit="contain" />
          <Button onClick={handleNext} m={2} position="absolute" right={0}>
            Next
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default CustomLightbox;
