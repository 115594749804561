import React, { useState, useEffect } from 'react';
import { ref, get, update, set } from "firebase/database";
import { db } from '../../../../../../firebase';
import { Button, List, ListItem, Text, Box, VStack, HStack, Divider } from "@chakra-ui/react";
import Card from 'components/card';

const BusinessLinker = ({ linkedBusinesses, onLink, onUnlink }) => {
  const [allBusinesses, setAllBusinesses] = useState([]);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const businessSnapshot = await get(ref(db, 'businesses/byName'));
        const businesses = businessSnapshot.val();
        if (businesses) {
          setAllBusinesses(Object.keys(businesses).map(key => ({
            ...businesses[key].info,
            id: key,
          })));
        } else {
          setAllBusinesses([]);
        }
      } catch (error) {
        console.error('Error fetching businesses:', error);
      }
    };
    fetchBusinesses();
  }, []);

  const handleLinkBusiness = async (businessId, businessName) => {
    if (!businessId || !businessName) {
      console.error('Invalid business ID or business name');
      return;
    }

    const companyId = localStorage.getItem('companyKey');
    if (companyId) {
      try {
        const companySnapshot = await get(ref(db, `companies/${companyId}/data`));
        const companyData = companySnapshot.val();

        if (!companyData || !companyData.name) {
          console.error('Company data or company name is undefined');
          return;
        }

        const businessRef = ref(db, `businesses/byName/${businessName}`);
        const businessSnapshot = await get(businessRef);
        if (!businessSnapshot.exists()) {
          // Create a new business with the full company data structure if it doesn't exist
          await set(businessRef, {
            analytics: {},
            cards: {},
            csrGoals: {},
            info: {
              name: companyData.name,
              description: companyData.description || "",
              industry: companyData.industry || "",
              location: companyData.location || "",
              profileImage: companyData.profileImage || "",
              bannerImage: companyData.bannerImage || "",
              website: companyData.website || ""
            },
            team: {},
            galleryImages: companyData.galleryImages || {},
            tags: companyData.tags || {},
            csr: companyData.csr || {},
            founded: companyData.founded || ""
          });
        }

        const updates = {};
        updates[`businesses/byName/${businessName}/linkedCompanies/${companyId}`] = {
          name: companyData.name,
          companyId: companyId,
        };
        updates[`companies/${companyId}/businessesLinked/${businessId}`] = {
          name: companyData.name,
          businessId: businessId,
        };

        await update(ref(db), updates);
        onLink(businessId);
      } catch (error) {
        console.error('Error linking business:', error);
      }
    } else {
      console.error('No company key found in local storage');
    }
  };

  const handleUnlinkBusiness = async (businessId) => {
    if (!businessId) {
      console.error('Invalid business ID');
      return;
    }

    const companyId = localStorage.getItem('companyKey');
    if (companyId) {
      try {
        const updates = {};
        updates[`businesses/byName/${businessId}/linkedCompanies/${companyId}`] = null;
        updates[`companies/${companyId}/businessesLinked/${businessId}`] = null;

        await update(ref(db), updates);
        onUnlink(businessId);
      } catch (error) {
        console.error('Error unlinking business:', error);
      }
    } else {
      console.error('No company key found in local storage');
    }
  };

  return (
    <Card>
      <Box p={4}>
        <Text fontSize="xl" fontWeight="bold" color="navi-700" _dark={{ color: "white" }}>
          Linked Businesses
        </Text>
        <List spacing={3} mt={3}>
          {linkedBusinesses.map((business: { id: React.Key; name: any; }) => (
            <ListItem key={business.id}>
              <HStack justify="space-between">
                <Text>{business.name || 'Unnamed Business'}</Text>
                <Button size="sm" colorScheme="red" onClick={() => handleUnlinkBusiness(business.id)}>
                  Unlink
                </Button>
              </HStack>
            </ListItem>
          ))}
        </List>
        <Divider my={4} />
        <Text mt={4} fontSize="md" color="gray.600">
          Link your business to a page that is already up and running
        </Text>
        <List spacing={3} mt={3}>
          {allBusinesses.map(business => (
            <ListItem key={business.id}>
              <HStack justify="space-between">
                <Text>{business.name || 'Unnamed Business'}</Text>
                <Button size="sm"  onClick={() => handleLinkBusiness(business.id, business.name)}>
                  Link
                </Button>
              </HStack>
            </ListItem>
          ))}
        </List>
      </Box>
    </Card>
  );
};

export default BusinessLinker;
