import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

export const useImpactMetrics = () => {
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartOptions, setLineChartOptions] = useState({});

  useEffect(() => {
    const firestore = getFirestore();
    const missionsRef = collection(firestore, 'missions');

    const unsubscribe = onSnapshot(missionsRef, (snapshot) => {
      const missions = [];
      snapshot.forEach(doc => missions.push(doc.data()));

      // Calculate the metrics
      const dates = missions.map(mission => new Date(mission.startDate.seconds * 1000).toLocaleDateString('en-US'));
      const completions = missions.map(mission => mission.formFields.filter(activity => activity.completed).length);

      // Create data for the chart
      const data = [
        {
          name: 'Mission Completions',
          data: completions,
        }
      ];

      setLineChartData(data);

      const options = {
        chart: {
          toolbar: {
            show: false,
          },
          dropShadow: {
            enabled: true,
            top: 13,
            left: 0,
            blur: 10,
            opacity: 0.1,
            color: "#4318FF",
          },
        },
        colors: ["#4318FF", "#39B8FF"],
        markers: {
          size: 0,
          colors: "white",
          strokeColors: "#7551FF",
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          showNullDataPoints: true,
        },
        tooltip: {
          theme: "dark",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          type: "line",
        },
        xaxis: {
          type: "category",
          categories: dates,
          labels: {
            style: {
              colors: "#A3AED0",
              fontSize: "12px",
              fontWeight: "500",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
          column: {
            color: ["#7551FF", "#39B8FF"],
            opacity: 0.5,
          },
        },
        color: ["#7551FF", "#39B8FF"],
      };

      setLineChartOptions(options);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return { lineChartData, lineChartOptions };
};
