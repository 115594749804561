import React, { useState, useEffect } from 'react';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getDatabase, push, ref } from 'firebase/database';
import { User } from 'firebase/auth';
import Card from 'components/card';
import { MdCameraAlt, MdExpandMore } from 'react-icons/md';
import { format } from 'util';
import SolidSubtleAlert from 'views/admin/main/others/notifications/components/SolidSubtleAlert';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { Box, Button, Textarea, useToast, VStack, HStack, IconButton, keyframes } from '@chakra-ui/react';

interface CreatePostFormProps {
  currentUser: User;
}

interface PostComment {
  id: string;
  authorId: string;
  content: string;
  timestamp: number;
}

const CreatePost: React.FC<CreatePostFormProps> = ({ currentUser }) => {
  const [content, setContent] = useState('');
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [expanded, setExpanded] = useState(false);
  const [dailyPrompt, setDailyPrompt] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const db = getDatabase();
  const storage = getStorage();
  const toast = useToast();

  useEffect(() => {
    const lastPromptDate = localStorage.getItem('lastPromptDate');
    const today = format(new Date(), 'yyyy-MM-dd');

    if (lastPromptDate !== today) {
      setShowPrompt(true);
    }
  }, []);

  const handleClosePrompt = () => {
    const today = format(new Date(), 'yyyy-MM-dd');
    localStorage.setItem('lastPromptDate', today);
    setShowPrompt(false);
  };

  useEffect(() => {
    const fetchDailyPrompt = async () => {
      const prompt = "How did you make a difference today?✨";
      setDailyPrompt(prompt);
    };

    fetchDailyPrompt();
  }, []);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).slice(0, 3);
      setImageFiles(filesArray);
    }
  };

  const calculateRows = (text: string) => {
    const newLines = text.split('\n').length;
    return Math.max(1, newLines);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (content.trim() === '' && imageFiles.length === 0) {
      toast({
        title: "Empty Post",
        description: "Cannot create an empty post. Please add some text or images.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const imageUrls = await Promise.all(imageFiles.map(async (file) => {
      const imageRef = storageRef(storage, `images/${file.name}_${Date.now()}`);
      const uploadResult = await uploadBytes(imageRef, file);
      return getDownloadURL(uploadResult.ref);
    }));

    const newPost = {
      authorId: currentUser.uid,
      content,
      images: imageUrls,
      timestamp: Date.now(),
      likes: 0,
      comments: [] as PostComment[],
    };

    const postsRef = ref(db, 'posts/');
    await push(postsRef, newPost);

    setContent('');
    setImageFiles([]);
    setExpanded(false);
  };

  // Define keyframes for the RGB glowing effect
  const rotate = keyframes`
    0% { filter: hue-rotate(0deg); }
    100% { filter: hue-rotate(360deg); }
  `;

  return (
    <VStack spacing={4} align="stretch">
      {showPrompt && (
        <SolidSubtleAlert
          title=""
          description="How did you make a difference today? Share your story down below."
          onClose={handleClosePrompt}
          iconColor="text-white dark:!text-navy-900"
          closeBg="hover:bg-white/20 text-white dark:!text-navy-900"
          bg="bg-green-500 dark:!bg-green-300"
          mb="mb-2 mt-4"
          solid="solid"
          icon={<BsFillCheckCircleFill />}
        />
      )}
      <Card extra="w-full h-full px-6 py-4">
        <form onSubmit={handleSubmit}>
          <Textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder={"What's on your mind? ✨"}
            className="w-full rounded border-2 p-2 font-sm outline-none mb-4"
            rows={calculateRows(content)}
            onFocus={() => setExpanded(true)}
          />
          {expanded && (
            <>
              <Box textAlign="center">
              <label htmlFor="file-upload" className="cursor-pointer">
                  <MdCameraAlt size={28} />
                </label>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  className="hidden"
                  accept="image/*"
                />
              </Box>
              <Box textAlign="center" my={2}>
                
                <Button
                  type="submit"
                  colorScheme="purple"
                  borderRadius="full"
                  p="6"
                  fontSize="lg"
                  boxShadow="0 0 15px rgba(130, 87, 229, 0.5)"
                  _hover={{
                    boxShadow: "0 0 25px rgba(130, 87, 229, 0.7)",
                  }}
                  _focus={{
                    outline: "none",
                  }}
                  disabled={content.trim() === '' && imageFiles.length === 0}
                  style={{
                    background: 'linear-gradient(90deg, #ff0047, #2c7df7, #00d2ff)',
                    animation: `${rotate} 3s linear infinite`,
                  }}
                >
                  Post
                </Button>
                
              </Box>
            </>
          )}
          {!expanded && <IconButton aria-label="Expand" icon={<MdExpandMore />} className="ml-2 text-xl" onClick={() => setExpanded(!expanded)} />}
        </form>
      </Card>
    </VStack>
  );
};

export default CreatePost;
