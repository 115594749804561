// userSelector.tsx
import { createSelector } from 'reselect';
import RootState from './rootState';


export const selectUser = (state: { user: any; }) => state.user;

const getMessagesState = (state: RootState) => state.messages; // Or whatever the correct path to your messages array is

export const getMessages = createSelector(
  getMessagesState,
  (messages) => messages
);