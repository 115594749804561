import { JSXElementConstructor, Key, ReactElement, ReactFragment, ReactPortal, useEffect, useState } from "react";
import Card from "components/card"; // Ensure this path matches your project structure
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import auth, { db, ref } from '../../firebase'
import { deleteDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { getFirestore } from 'firebase/firestore';
import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import { getDatabase, get , ref as firebaseRef} from "firebase/database";
import { useNavigate } from "react-router-dom";
import { MdSettings } from "react-icons/md";

type Group = {
  id: string;
  title: string;
  description: string;
  logo: string;
  events: string[];
};

const GroupCard = ({
  groupId,
  image,
  banner,
  title,
  description,
  events,
  extra,
  membershipStatus,
  onJoinLeaveCancel,
  creatorId,
  currentUserUid,
  onSettingsClick,
}: {
  groupId: string;
  image: string;
  banner: string;
  title: string;
  description: string;
  events: string[];
  extra: string;
  membershipStatus: string;
  onJoinLeaveCancel: (groupId: string, status: string) => void;
  creatorId: string;
  currentUserUid: string;
  onSettingsClick: (groupId: string) => void;
}) => {
  const [creatorName, setCreatorName] = useState("");
  const [heart, setHeart] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const dbRT = getDatabase(); 
  const db = getFirestore();
// Request Function
  useEffect(() => {
    // Adjust the path according to your Realtime Database structure
    const creatorRef = firebaseRef(dbRT, `volunteers/${creatorId}`);

    get(creatorRef).then((snapshot) => {
      if (snapshot.exists()) {
        const volunteer = snapshot.val();
        setCreatorName(`${volunteer.volunteerName.firstName} ${volunteer.volunteerName.lastName}`);
      } else {
        console.log("No creator information found.");
      }
    }).catch((error) => {
      console.error("Error fetching creator information: ", error);
    });
  }, [creatorId]);



  const handleCardClick = () => {
    navigate(`/volunteer/group/${groupId}`);
  };
// Request Buttons
  const handleJoinGroupRequest = async (groupId: any) => {
    if (!auth.currentUser) {
      console.log("User not authenticated");
      return;
    }
    const requestRef = doc(db, `groups/${groupId}/requests/${auth.currentUser.uid}`);
    try {
      await setDoc(requestRef, {
        userId: auth.currentUser.uid,
        requestedAt: new Date(),
        status: 'pending'
      });
      console.log("Join request sent");
    } catch (error) {
      console.error("Error sending join request: ", error);
    }
  };
  
  const handleCancelRequest = async (groupId: any) => {
    if (!auth.currentUser) {
      console.log("User not authenticated");
      return;
    }
    try {
      await deleteDoc(doc(db, `groups/${groupId}/requests/${auth.currentUser.uid}`));
      console.log("Join request canceled");
    } catch (error) {
      console.error("Error canceling join request: ", error);
    }
  };
  
  const handleLeaveGroup = async (groupId: any) => {
    if (!auth.currentUser) {
      console.log("User not authenticated");
      return;
    }
    try {
      await deleteDoc(doc(db, `groups/${groupId}/members/${auth.currentUser.uid}`));
      console.log("Left group successfully");
    } catch (error) {
      console.error("Error leaving group: ", error);
    }
  };
  
  // Adjusted to accept groupId
  const handleButtonClick = async (groupId: string) => {
    switch (membershipStatus) {
      case 'none':
        await handleJoinGroupRequest(groupId);
        break;
      case 'requested':
        await handleCancelRequest(groupId);
        break;
      case 'member':
        await handleLeaveGroup(groupId);
        break;
      default:
        console.error('Unhandled membership status:', membershipStatus);
    }
  
    // Optionally, invoke a passed function to refresh group status or UI
    if (typeof onJoinLeaveCancel === 'function') {
      onJoinLeaveCancel(groupId, membershipStatus);
    }
  };

  return (
  
    <><Card extra={`flex flex-col w-full h-full p-4 bg-white ${extra}`}>
      <div className="relative">
        <img src={image || 'path/to/default/image.png'} className="mb-3 w-full rounded-xl" alt="Group" />
        <button onClick={() => setHeart(!heart)} className="absolute top-3 right-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:cursor-pointer">
          {heart ? <IoHeart className="text-red-500" /> : <IoHeartOutline className="text-gray-400" />}
        </button>
      </div>

      <h3 className="mt-2 text-lg font-bold text-navy-700 dark:text-white cursor-pointer" onClick={handleCardClick}>{title}</h3>
      <p className="text-base font-semibold text-[#3965FF] dark:text-white mt-1">Creator: {creatorName}</p>
      <p className="mt-2 text-sm text-navy-700 dark:text-white">{description}</p>
      <div className="mt-2 mb-4">
        {events.map((event, index) => (
          <p key={index} className="text-sm font-medium text-gray-500">Upcoming: {event}</p>
        ))}
      </div>
      <Button
        className="settings-button bg-brand-300 text-white font-medium py-2 px-4 rounded-[20px] hover:bg-brand-800 transition duration-200"
        onClick={() => handleButtonClick(groupId)}
      >
        {membershipStatus === 'none' ? 'Request to Join' :
          membershipStatus === 'requested' ? 'Cancel Request' : 'Leave Group'}
      </Button>
      {auth.currentUser?.uid === creatorId && (
        <Button className="mt-2 linear rounded-lg bg-brand-900 px-4 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 w-full flex justify-center items-center gap-2" onClick={() => onSettingsClick(groupId)}>Settings <MdSettings /></Button>
      )}
    </Card><Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Additional details as needed */}
          </ModalBody>
          <Button
            className="mt-4 linear rounded-lg bg-brand-900 px-4 py-2 text-sm font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90 w-full"
            onClick={() => handleButtonClick(groupId)}
          >
            {membershipStatus === 'none' ? 'Request to Join' :
              membershipStatus === 'requested' ? 'Cancel Request' : 'Leave Group'}
          </Button>
        </ModalContent>
      </Modal></>
  );
};

export default GroupCard;