import React, { useState } from "react";
import { MdDashboard, MdApps } from "react-icons/md";
import SearchIcon from "components/icons/SearchIcon";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react";
import UserGroupsModal from "./UserGroupsModal";

interface Group {
  id: string;
  title: string;
  logo: string; // Assuming logo is a URL string
}

interface SearchProps {
  groups: Group[];
}

const Search: React.FC<SearchProps> = ({ groups }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState<Group[]>([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const { isOpen, onOpen, onClose } = useDisclosure();

  

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    setShowResults(term.trim() !== "");

    if (term.trim() !== "") {
      const filtered = groups.filter((group) =>
        group.title.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredGroups(filtered);
    } else {
      setFilteredGroups([]);
    }
  };

  // Function to handle selection of a group
  const handleGroupSelect = (groupId: string) => {
    navigate(`/volunteer/group/${groupId}`); // Navigate to the group page
  };

  

  return (
    <div className="relative mx-auto mt-5 flex w-full gap-1 overflow-visible md:!gap-6 xl:w-[96%]">
    <div className="flex h-[30px] w-full rounded-xl border !border-gray-200 dark:!border-none dark:!bg-navy-700 md:w-[870px] xl:w-full">
      
      <input
        type="text"
        placeholder={"Search for groups..."}
        
        value={searchTerm}
        onChange={handleSearchChange}
        onFocus={() => setShowResults(true)}
        onBlur={() => setTimeout(() => setShowResults(false), 200)} // Add delay to allow click event to register on dropdown items
        className="w-full rounded-xl text-sm font-medium outline-none dark:bg-navy-700 dark:text-white pl-10"
      />
    </div>
    {showResults && filteredGroups.length > 0 && (
      <div className="absolute z-50 top-full left-0 mt-1 w-full max-h-40 overflow-y-auto rounded-md bg-white shadow-lg dark:bg-navy-700">
        {filteredGroups.map((group) => (
          <div key={group.id} className="flex items-center p-2 hover:bg-gray-100 dark:hover:bg-navy-800 cursor-pointer" onClick={() => handleGroupSelect(group.id)}>
            <img src={group.logo} alt={group.title} className="h-8 w-8 rounded-full object-cover" />
            <span className="ml-2 text-sm font-medium dark:text-white">{group.title}</span>
          </div>
        ))}
      </div>
    )}
 <Button onClick={onOpen} display="flex" alignItems="center" justifyContent="center" h="40px" minW="40px">
  <Icon as={MdApps} w={6} h={6} /> {/* Adjust w and h as needed */}
</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>My Groups and Requests</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
           <UserGroupsModal/>
          </ModalBody>
        </ModalContent>
      </Modal>
  </div>
);
};


export default Search;
