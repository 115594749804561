import React, { useState, useEffect, useContext, ReactNode } from 'react';
import { Box, SimpleGrid, Image, Text, Button, Input, IconButton, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, useBreakpointValue, extendTheme, ChakraProvider, Wrap, WrapItem, Tag, HStack, Flex, useToast, Avatar, Icon, useDisclosure, Progress, FormControl, FormLabel } from '@chakra-ui/react';
import { getFirestore, collection, getDocs, addDoc, arrayUnion, doc, updateDoc, getDoc, arrayRemove, onSnapshot, Unsubscribe, Timestamp, Firestore } from 'firebase/firestore';

import { MdStar, MdStarHalf } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref as dbRef, onValue, set, update, get, Database } from "firebase/database";
import { AuthContext } from 'contexts/AuthContext';
import { BiArrowBack, BiSend } from 'react-icons/bi';
import ParticipantsList from '../toolsComponents/ParticipantsList';
import ScrollableCardList from '../toolsComponents/ScrollableCarousel';
import Card from 'components/card';
import RequestManagement from '../toolsComponents/RequestManagement';

import firebase from 'firebase/compat';
import MissionTimeline from '../toolsComponents/Timeline';
import { MdOutlineLowPriority, MdPriorityHigh, MdWarning } from 'react-icons/md'; // Import suitable icons
import useShare from '../toolsComponents/useShare';
import { BusinessCircle, BusinessDetailsModal } from './BusinessesList';
import Nango from '@nangohq/frontend';
import auth, { firestore } from 'firebase';
import { getAuth } from 'firebase/auth';
import { loadStripe,Stripe } from '@stripe/stripe-js';
import { getFunctions, httpsCallable } from 'firebase/functions';
import WrappedPaymentModal from 'components/payments/DonationModal';

interface Mission {
    id: string;
    authorId: string;
    missionName: string;
    fileUrl: string;
    description: string;
    missionCategory: string;
    roles: string[]; // Array of roles
    skills: string[];
    participants: { pending?: string[], confirmed?: string[] };
    startDate: firebase.firestore.Timestamp | Timestamp; // Use firebase.firestore.Timestamp for older SDK versions
    location: string;
    urgencyLevel: string;
    missionDescription: string;
    participantsMetric: number;
    imageUrl?: string;
    fileUrls?: string[];
    formFields: any[];
}

interface MissionDetailsProps {
    mission: Mission; // Use the Mission interface here
}
export interface Participant {
    uid: string;
    name: string;
    profileUrl: string;
}

type ParticipantsList = {
    participants: Participant[];
};
interface CoAuthor {
    status: string;
}
const stripePromise = loadStripe('pk_live_51PDAuJLqk5h9G7zkz1Db5DhgfF5Xx4ehYIdfYMjpvdoujobnjy4e7XX05DZOLkwvWF9Xs82KLShtq7VIDZVsUQUp004rsSREaL');

const MissionDetails = () => {
    const { missionId } = useParams();
    const [mission, setMission] = useState(null);
    const [comment, setComment] = useState("");
    const [comments, setComments] = useState([]);

    const [joinStatus, setJoinStatus] = useState('none');
    const [formFields, setFormFields] = useState([]);
    const toast = useToast();
    const db = getFirestore();
    const [donationAmount, setDonationAmount] = useState('');
    const [loading, setLoading] = useState(true);
    const firestore = getFirestore();
    const auth = getAuth();
    const navigate = useNavigate();
    const isMobile = useBreakpointValue({ base: true, md: false });
    const [participants, setParticipants] = useState([]);
    const { currentUser, userRole } = useContext(AuthContext);
    const [userEmail, setUserEmail] = useState("");
    const db2 = getDatabase();
    const share = useShare();
    const [coAuthors, setCoAuthors] = useState({});
    const [acceptedPartners, setAcceptedPartners] = useState([]);
    const [hasJoined, setHasJoined] = useState(false);
    const [businessParticipants, setBusinessParticipants] = useState([]);
    const [userInfo, setUserInfo] = useState({
        uid: "",
        contributeCode: "",
        volunteerEmail: "",
        firstName: "",
        lastName: "",
        job: "",
        aboutMe: "",
        username: "",
    });

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedBusiness, setSelectedBusiness] = useState(null);

    const handleBusinessClick = (business) => {
        setSelectedBusiness(business);
        onOpen();
    };
    const fetchUserData = async () => {
        if (!currentUser) return;

        const volunteerRef = dbRef(db2, `volunteers/${currentUser.uid}`);
        const volunteerSnapshot = await get(volunteerRef);

        if (volunteerSnapshot.exists()) {
            const volunteerData = volunteerSnapshot.val();
            setUserInfo(volunteerData);

            const companiesRef = dbRef(db2, 'companies');
            const companiesSnapshot = await get(companiesRef);

            if (companiesSnapshot.exists()) {
                const companies = companiesSnapshot.val();
                for (const companyKey in companies) {
                    if (companies[companyKey].users) {
                        for (const userKey in companies[companyKey].users) {
                            const user = companies[companyKey].users[userKey];
                            if (user.email === currentUser.email) {
                                setUserInfo(prev => ({
                                    ...prev,
                                    ...user
                                }));
                                break;
                            }
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [currentUser, db]);

    const fetchComments = async () => {
        if (!missionId) return;

        const commentsRef = collection(db, "missions", missionId, "comments");
        const commentSnapshots = await getDocs(commentsRef);
        const fetchedComments = commentSnapshots.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setComments(fetchedComments);
    };

    useEffect(() => {
        fetchComments();
    }, [missionId, db]);

    const checkJoinStatus = () => {
        if (mission?.participants?.pending?.includes(userInfo.uid)) {
            setJoinStatus('pending');
        } else if (mission?.participants?.confirmed?.includes(userInfo.uid)) {
            setJoinStatus('joined');
        } else {
            setJoinStatus('none');
        }
    };

    useEffect(() => {
        checkJoinStatus();
    }, [mission, userInfo.uid]);

    const fetchMissionDetails = async () => {
        const db: Firestore = getFirestore();
        const realTimeDb: Database = getDatabase();
        let isMounted = true;

        if (!missionId) return;

        setLoading(true);
        try {
            const missionRef = doc(db, "missions", missionId);
            const missionSnap = await getDoc(missionRef);

            if (missionSnap.exists() && isMounted) {
                const missionData = missionSnap.data();

                const fetchedMission = {
                    id: missionSnap.id,
                    authorId: missionData.authorId || '',
                    missionName: missionData.missionName || '',
                    fileUrl: missionData.fileUrl || '',
                    description: missionData.description || '',
                    missionCategory: missionData.missionCategory || '',
                    roles: missionData.roles || [],
                    skills: missionData.skills || [],
                    participants: missionData.participants || { pending: [], confirmed: [] },
                    startDate: missionData.startDate || new Timestamp(0, 0),
                    location: missionData.location || '',
                    urgencyLevel: missionData.urgencyLevel || '',
                    missionDescription: missionData.missionDescription || '',
                    participantsMetric: missionData.participantsMetric || 0,
                    imageUrl: missionData.imageUrl || '',
                    fileUrls: missionData.fileUrls || [],
                    formFields: missionData.formFields || [],
                    coAuthors: missionData.coAuthors || {},
                    status: missionData.status || ''
                };

                setMission(fetchedMission);

                const participants = missionData.participants || { pending: [], confirmed: [] };
                const confirmedParticipants = participants.confirmed || [];
                const pendingParticipants = participants.pending || [];

                const allParticipantIds = [...confirmedParticipants, ...pendingParticipants];
                if (allParticipantIds.length > 0) {
                    const uniqueParticipantIds = Array.from(new Set(allParticipantIds));
                    const participantsDetails = await Promise.all(uniqueParticipantIds.map(async (uid) => {
                        const volunteerRef = dbRef(realTimeDb, `volunteers/${uid}`);
                        const snapshot = await get(volunteerRef);
                        return snapshot.exists() ? { uid, ...snapshot.val() } : null;
                    }));
                    setParticipants(participantsDetails.filter(Boolean));
                }

                // Fetch business participants
                const coAuthors = missionData.coAuthors as Record<string, CoAuthor>;
                const acceptedPartners = Object.entries(coAuthors)
                    .filter(([key, value]) => value.status === 'accepted')
                    .map(([key, value]) => ({ businessName: key, ...value }));

                const businessDetails = await Promise.all(acceptedPartners.map(async (partner) => {
                    const businessRef = dbRef(realTimeDb, `businesses/byName/${partner.businessName}/info`);
                    const snapshot = await get(businessRef);
                    return snapshot.exists() ? { ...snapshot.val(), businessName: partner.businessName } : null;
                }));

                setBusinessParticipants(businessDetails.filter(Boolean));
            }
        } catch (error) {
            console.error("Error fetching mission details:", error);
        } finally {
            if (isMounted) setLoading(false);
        }
    };






    useEffect(() => {
        fetchMissionDetails();

        return () => {
            setLoading(false);
        };
    }, [missionId]);

    const fetchFormFields = async () => {
        if (!missionId) return;

        const missionDocRef = doc(db, "missions", missionId);
        const missionDocSnap = await getDoc(missionDocRef);

        if (missionDocSnap.exists()) {
            setFormFields(missionDocSnap.data().formFields || []);
        } else {
            console.log("No such document!");
        }
    };

    useEffect(() => {
        fetchFormFields();
    }, [missionId, db]);

    if (loading) {
        return <div>Loading mission details...</div>;
    }
    if (!mission) {
        return <div>Mission not found or error loading mission details.</div>;
    }

    const handleJoinMission = async () => {
        if (!currentUser) {
            window.location.href = "/auth/sign-up/volunteer";
            return;
        }
        setJoinStatus('pending');
        const missionRef = doc(db, "missions", missionId);
        try {
            await updateDoc(missionRef, {
                [`participants.pending`]: arrayUnion(userInfo.uid)
            });
            toast({
                title: 'Request Sent',
                description: 'Your request to join the mission is pending approval.',
                status: 'info',
                duration: 5000,
                isClosable: true,
            });
            setJoinStatus('pending');
        } catch (error) {
            toast({
                title: 'Error',
                description: 'There was an error sending your join request.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.error("Error sending join request:", error);
            setJoinStatus('none');
        }
    };

    const handleCancelJoinRequest = async () => {
        const missionRef = doc(db, "missions", missionId);
        try {
            await updateDoc(missionRef, {
                [`participants.pending`]: arrayRemove(userInfo.uid)
            });
            toast({
                title: 'Request Cancelled',
                description: 'Your join request has been cancelled.',
                status: 'warning',
                duration: 5000,
                isClosable: true,
            });
            setJoinStatus('none');
        } catch (error) {
            toast({
                title: 'Error',
                description: 'There was an error cancelling your join request.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
            console.error("Error cancelling join request:", error);
        }
    };

    const handleAddComment = async (comment: string) => {
        if (!comment) return;
        if (!currentUser) {
            window.location.href = "/auth/sign-up/volunteer";
            return;
        }
        const commentsRef = collection(db, "missions", missionId, "comments");
        try {
            await addDoc(commentsRef, {
                text: comment,
                createdBy: userInfo.firstName,
                createdAt: new Date()
            });
            console.log("Comment added successfully");
        } catch (error) {
            console.error("Error adding comment:", error);
        }
    };

    const handleGoBack = () => {
        navigate(-1);
    };

    const handleShareClick = () => {
        const currentUrl = window.location.href;
        share(currentUrl, 'Check out this page!');
    };

    const requestManagementComponent = missionId ? (
        <RequestManagement missionId={missionId} authorId={mission.authorId} />
    ) : null;

    const fetchParticipants = async (participantUIDs: string[]) => {
        const participantDetails: Participant[] = [];
        setLoading(true);

        for (const uid of participantUIDs) {
            const volunteerRef = doc(db, `volunteers/${uid}`);
            const docSnap = await getDoc(volunteerRef);

            if (docSnap.exists()) {
                const { firstName, profileURL } = docSnap.data();
                participantDetails.push({
                    uid,
                    name: firstName,
                    profileUrl: profileURL,
                });
            } else {
                console.log(`No data found for UID: ${uid}`);
            }
        }

        setParticipants(participantDetails);
        setLoading(false);
    };


    const handleDonate = async () => {
        if (!auth.currentUser) {
          window.location.href = "/auth/sign-up/volunteer";
          return;
        }
    
        try {
          const functions = getFunctions();
          const createPaymentIntent = httpsCallable<{ amount: number }, { clientSecret: string }>(functions, 'createPaymentIntent');
          const amountInCents = parseInt(donationAmount) * 100; // Convert to cents
    
          const result = await createPaymentIntent({ amount: amountInCents });
          const { clientSecret } = result.data;
    
          const stripe = await stripePromise;
          const { error, paymentIntent } = await stripe!.confirmCardPayment(clientSecret);
    
          if (error) {
            toast({
              title: "Error",
              description: error.message,
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            return;
          }
    
          // Save donation info in Firestore
          const donationRef = collection(firestore, 'missions', missionId, 'donations');
          await addDoc(donationRef, {
            amount: amountInCents,
            userId: auth.currentUser.uid,
            createdAt: Timestamp.now(),
          });
    
          // Update mission amount raised
          const missionRef = doc(firestore, 'missions', missionId);
          await updateDoc(missionRef, {
            amountRaised: (mission!.amountRaised || 0) + amountInCents,
          });
    
          toast({
            title: "Donation Successful",
            description: "Thank you for your contribution!",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
    
          // Clear the input field
          setDonationAmount('');
        } catch (error) {
          console.error("Error during donation:", error);
          toast({
            title: "Donation Error",
            description: error.message,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      };


    if (loading) {
        return <div>Loading...</div>;
    }

    if (!mission) {
        return <div>Mission not found or error loading mission details.</div>;
    }

    let urgencyColor = 'gray';
    let UrgencyIcon = MdOutlineLowPriority;
    let urgencyLabel = 'Low';

    switch (mission.urgencyLevel) {
        case 'medium':
            urgencyColor = 'orange';
            UrgencyIcon = MdWarning;
            urgencyLabel = 'Medium';
            break;
        case 'high':
            urgencyColor = 'red';
            UrgencyIcon = MdPriorityHigh;
            urgencyLabel = 'High';
            break;
        default:
            break;
    }
    return (
        <VStack align="inherit" spacing={0} minH="100vh" className='dark:!bg-navy-800 dark:text-white'>
            <Box position="relative" className='dark:!bg-navy-800 overflow-hidden'>
                <VStack
                    position="absolute"
                    top={0}
                    h={{ base: '29vh', md: '33vh' }}
                    p={4}
                    spacing={4}
                    alignItems={'flex-start'}
                    justifyContent="flex-start"
                    borderRadius="xl"
                    style={{ zIndex: 2 }}
                >
                    <IconButton
                        icon={<BiArrowBack />}
                        onClick={handleGoBack}
                        variant="ghost"
                        position="absolute"
                        top="4"
                        left="4"
                        color="white" aria-label={''} />
                </VStack>
                <Image
                    src={mission?.fileUrl || 'path_to_some_default_image.jpg'}
                    alt={mission?.missionName}
                    objectFit="cover"
                    w="full"
                    h={{ base: '40vh', md: '50vh' }}
                    transition="0.3s ease-in-out"
                />
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    backgroundImage="linear-gradient(to top, rgba(0,0,0,0.7) 20%, rgba(0,0,0,0) 80%)"
                ></Box>
                <VStack
                    position="absolute"
                    bottom="4"
                    width="full"
                    p={4}
                    spacing={4}
                    alignItems={'center'}
                    justifyContent="flex-end"
                >
                    <Text fontSize={{ base: "2xl", md: "4xl" }} fontWeight="bold" color={'white'} textAlign={'center'}>
                        {mission?.missionName}
                    </Text>
                    <Text fontSize={{ base: "sm", md: "xl" }} fontWeight="light" color={'white'}>
                        {mission?.missionCategory}
                    </Text>
                   
                    <HStack spacing={4}>
                        <Button
                            onClick={() => {
                                if (!currentUser) {
                                    // User is not logged in, redirect to sign-up
                                    window.location.href = "/auth/sign-up/volunteer";
                                } else {
                                    joinStatus === 'pending' ? handleCancelJoinRequest() : handleJoinMission()
                                }
                            }}
                            size="md"
                            bg={joinStatus === 'pending' ? 'orange.400' : '#7551FF'}
                            color="white"
                            variant="solid"
                            borderRadius="full"
                            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                            isDisabled={joinStatus === 'joined'}
                            _hover={{ bg: joinStatus === 'pending' ? 'orange.500' : '#6e4fd1' }}
                            _active={{ bg: joinStatus === 'pending' ? 'orange.600' : '#634ac0' }}
                        >
                            {joinStatus === 'joined' ? "Joined" : joinStatus === 'pending' ? "Cancel Request" : "Join Mission"}
                        </Button>
                        <Button
                            onClick={handleShareClick}
                            size="md"
                            className="linear rounded-xl bg-gradient-to-br from-brandLinear to-blueSecondary px-5 py-3 text-base font-medium text-white shadow-xl shadow-blueSecondary/50 transition duration-200 hover:bg-gradient-to-l"
                            variant="solid"
                            borderRadius="full"
                            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                            _active={{ bg: "purple.700" }}
                        >
                            Share Mission
                        </Button>
                    </HStack>
                </VStack>
            </Box>

            {/* Main Content */}
            <Box borderRadius={{ md: "xl" }} p={2} zIndex={1}>
                <Tabs variant="soft-rounded" align="center" isFitted mt={4} flexGrow={4} className='dark:!bg-navy-800'>
                    <TabList mb="1em">
                        <Tab _selected={{ bg: '#7551FF', color: 'white' }}>Details</Tab>
                        <Tab _selected={{ bg: '#7551FF', color: 'white' }}>Plan</Tab>
                        <Tab _selected={{ bg: '#7551FF', color: 'white' }}>Activity</Tab>
                    </TabList>

                    <TabPanels p={4}>
                        <TabPanel className='border-gray-600 dark:!border-none dark:!bg-navy-800'>
                        <Card extra="w-full h-full px-6 py-5 mb-2">
      <VStack align="start" spacing={4}>
        <Box w="full">
          <Text fontSize="2xl" fontWeight="bold">
            Donate To Mission
          </Text>
        </Box>
        {mission.donationGoal && mission.amountRaised !== undefined ? (
          <>
            <Box w="full">
              <Text fontSize="lg" fontWeight="bold">
                Goal: ${mission.donationGoal}
              </Text>
              <Text fontSize="lg" fontWeight="bold" mt={2}>
                Amount Raised: ${(mission.amountRaised / 100).toFixed(2)}
              </Text>
              <Progress
                value={(mission.amountRaised / mission.donationGoal) * 100}
                max={100}
                mt={2}
                colorScheme="green"
                borderRadius="md"
              />
            </Box>
            <Box w="full">
              <FormControl>
                <FormLabel>Enter Donation Amount</FormLabel>
                <Input
                  placeholder="Enter donation amount"
                  value={donationAmount}
                  onChange={(e) => setDonationAmount(e.target.value)}
                  type="number"
                />
              </FormControl>
            </Box>
          </>
        ) : (
          <Box w="full" display="flex" justifyContent="center" alignItems="center">
            <Button
              onClick={onOpen}
              animation="gradient 2s infinite linear"
              size="md"
                            className="linear rounded-xl bg-gradient-to-br from-brandLinear to-blueSecondary px-5 py-3 text-base font-medium text-white shadow-xl shadow-blueSecondary/50 transition duration-200 hover:bg-gradient-to-l"
                            variant="solid"
                            borderRadius="full"
                            boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                            _active={{ bg: "purple.700" }}
            >
              Donate
            </Button>
          </Box>
        )}
      </VStack>
      <WrappedPaymentModal
        isOpen={isOpen}
        onClose={onClose}
        recipientId={mission.authorId}
      />
    </Card>
                            <Card extra={"w-full h-full px-[30px] py-[21px] "}>
                                <Text fontWeight="bold" mt={4}>Description</Text>
                                <Text className='text-md font-bold text-navy-700 dark:text-white'>{mission?.missionDescription}</Text>


                            </Card>
                            
                            <Card extra={"w-full h-full px-[30px] py-[21px] mt-2 "}>
                                {/* Display involved partners */}
                                <Text fontWeight="bold" mt={4}>Involved Partners</Text>
                                <HStack overflowX="scroll" spacing={4}>
                                    {businessParticipants.length > 0 ? (
                                        businessParticipants.map((business, index) => (
                                            <BusinessCircle key={index} business={business} onClick={handleBusinessClick} />
                                        ))
                                    ) : (
                                        <Text>No business participants</Text>
                                    )}
                                </HStack>
                            </Card>
                            <Card extra={"w-full h-full px-[30px] py-[21px] mt-2"}>
                                <Text fontWeight="bold" mb={2}>Roles</Text>
                                <Wrap justify="center">
                                    {mission?.roles && mission.roles.length > 0 ? (
                                        mission.roles.map((role, index) => (
                                            <WrapItem key={index}>
                                                <Tag size="md" variant="solid" colorScheme="purple">{role}</Tag>
                                            </WrapItem>
                                        ))
                                    ) : (
                                        <WrapItem>
                                            <Tag size="md" variant="outline" colorScheme="purple">Open to Anyone</Tag>
                                        </WrapItem>
                                    )}
                                </Wrap>
                            </Card>
                            <Card extra={"w-full h-full px-[30px] py-[21px] mt-2"}>
                                <Text fontWeight="bold" mt={4} mb={2}>Skills Needed</Text>
                                <Wrap justify="center">
                                    {mission?.skills && mission.skills.length > 0 ? (
                                        mission.skills.map((skill, index) => (
                                            <WrapItem key={index}>
                                                <Tag size="md" variant="solid" colorScheme="green">{skill}</Tag>
                                            </WrapItem>
                                        ))
                                    ) : (
                                        <WrapItem>
                                            <Tag size="md" variant="outline" colorScheme="green">Defaults</Tag>
                                        </WrapItem>
                                    )}
                                </Wrap>
                            </Card>
                            <Card extra="w-full h-full px-[30px] py-[21px] mt-2">
                                <Text fontWeight="bold" mb="2">Urgency</Text>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Tag size="md" variant="subtle" colorScheme={urgencyColor}>
                                        <Icon as={UrgencyIcon} mr="2" />
                                        {urgencyLabel}
                                    </Tag>
                                </Box>
                            </Card>
                        </TabPanel>


                        <TabPanel>
                    
                            <Text fontWeight="bold">Date</Text>
                            <Card extra={"w-full h-full px-[30px] py-[21px] mt-2 mb-4"}>

                                <Text className='pt-[5px] text-xl text-navy-700 dark:text-white' mb={4}>
                                    {mission?.startDate?.toDate().toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </Text>
                               
                            </Card>
                            <Text className='pt-[5px] text-md font-bold text-navy-700 dark:text-white' fontWeight="bold">Location</Text>
                            <Card extra={"w-full h-full px-[30px] py-[21px] mt-2 mb-4"}>
                          
                                <Text className='pt-[5px] text-xl text-navy-700 dark:text-white'>{mission?.location || 'No location information'}</Text>     
                            </Card>
                            <Text fontWeight="bold">Timeline</Text>
                            <MissionTimeline />
                        </TabPanel>

                        <TabPanel>
                        <Text fontWeight="bold">Status</Text>
                        <Card extra={"w-full h-full px-[30px] py-[21px]  mb-4"}>
                        <Text className='pt-[5px] text-xl text-navy-700 dark:text-white' mb={2}>
                        {mission?.status}
                    </Text>
                    </Card>
                            <Box>
                                <Card extra={"mt-2"}>
                                    {requestManagementComponent}
                                </Card>
                                <Card extra={"w-full h-full px-[30px] py-[21px] mt-2"}>
                                    {participants.length > 0 ? (
                                        <ParticipantsList participants={participants} />
                                    ) : (
                                        <Text>No participants have joined this mission yet.</Text>
                                    )}
                                </Card>
                            </Box>
                            <Card extra="w-full h-full px-[30px] py-[21px] mt-2">
                                
                                <Box mt={3}>
                                    <Text fontWeight="bold" fontSize="xl">Comments</Text>
                                    {comments.length > 0 ? (
                                        <VStack spacing={4} align="stretch" mt={4}>
                                            {comments.map((comment) => (
                                                <Box
                                                    key={comment.id}
                                                    p={4}
                                                    bg="gray.50"
                                                    borderRadius="lg"
                                                    border="1px solid"
                                                    borderColor="#F7FAFC"
                                                    className='dark:!bg-navy-800 dark:text-white'
                                                >
                                                    <HStack spacing={3}>
                                                        <Avatar size="sm" name={comment.profileURL} /> {/* Assuming `createdBy` can be used for the avatar name */}
                                                        <Text fontWeight="bold">{comment.createdBy}</Text>
                                                        <Text fontSize="sm" color="gray.500">
                                                            {new Date(comment.createdAt.seconds * 1000).toLocaleDateString("en-US", {
                                                                year: "numeric",
                                                                month: "short",
                                                                day: "numeric",
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                            })}
                                                        </Text>
                                                    </HStack>
                                                    <Text mt={2} align={'left'}>{comment.text}</Text>
                                                </Box>
                                            ))}
                                        </VStack>
                                    ) : (
                                        <Text mt={4}>No comments yet.</Text>
                                    )}
                                </Box>
                                <Box mt={6}>
                                    <HStack>
                                        <Input
                                            placeholder="Write a comment..."
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                            rounded="xl"
                                            borderColor="gray.200"
                                            _dark={{ borderColor: 'whiteAlpha.300', bg: 'navy.800' }}
                                            flexGrow={1}
                                        />
                                        <Button
                                            bg="brand.500"
                                            px={4}
                                            py={2}
                                            rounded="xl"
                                            _hover={{ bg: 'brand.600' }}
                                            _active={{ bg: 'brand.700' }}
                                            onClick={() => handleAddComment(comment)}
                                        >
                                            <BiSend />
                                        </Button>
                                    </HStack>
                                </Box>
                            </Card>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
            {selectedBusiness && (
                <BusinessDetailsModal
                    business={selectedBusiness}
                    isOpen={isOpen}
                    onClose={() => {
                        setSelectedBusiness(null);
                        onClose();
                    }}
                />
            )}
                  {/* <WrappedPaymentModal isOpen={isOpen} onClose={onClose} 
                        recipientId={mission.authorId}

                   /> */}

        </VStack>
    );
};

export default MissionDetails;
