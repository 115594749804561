import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue } from "firebase/database";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import BusinessCard from "components/card/BusinessCard";
import Banner from "./components/Banner";

const HarmonyMarketplace = () => {
  const [businesses, setBusinesses] = useState([]);
  const [filteredBusinesses, setFilteredBusinesses] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const navigate = useNavigate();

  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const businessesRef = ref(db, 'businesses/byName');

    onValue(businessesRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const businessArray = Object.keys(data).map(key => ({
          id: key,
          name: data[key].info?.name || "No Name Provided",
          description: data[key].info?.description || "No Description Provided",
          industry: data[key].info?.industry || "No Industry Provided",
          founded: data[key].info?.founded || "No Time Provided",
          location: data[key].info?.location || "No Time Provided",
          profileImage: data[key].info?.profileImage || "https://via.placeholder.com/150"
        }));
        setBusinesses(businessArray);
        setFilteredBusinesses(businessArray);
      } else {
        console.log("No data available");
        setBusinesses([]);
      }
    }, error => {
      console.error(error);
    });
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    if (category) {
      const filtered = businesses.filter(business => business.industry === category);
      setFilteredBusinesses(filtered);
    } else {
      setFilteredBusinesses(businesses);
    }
  };

  return (
    <div className="mt-3 px-4 xl:px-0 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3 dark:bg-navy-900 dark:text-white">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2 dark:bg-navy-900">
        <div className="flex items-center mb-4">
          <IconButton
            icon={<FiArrowLeft />}
            mr={2}
            variant="ghost"
            onClick={() => navigate('/volunteer/contribute')}
            aria-label="Back to menu"
            className="dark:text-white"
          />
          <h4 className="text-md font-bold text-navy-700 dark:text-white">
          Social Responsibility Marketplace
          </h4>
        </div>
        <Banner />
        <div className="mb-4 mt-5 flex flex-col justify-between md:flex-row md:items-center">
          <h4 className="ml-1 text-2xl font-bold text-navy-700 dark:text-white">
            Trending Businesses
          </h4>
          <ul className="mt-4 flex items-center justify-between md:mt-0 md:justify-center md:!gap-5 2xl:!gap-12">
            <li>
              <button
                className={`text-base font-medium ${selectedCategory === 'Environmental' ? 'text-brand-500' : 'text-gray-500'} hover:text-brand-500 dark:text-white`}
                onClick={() => handleCategoryClick('Environmental')}
              >
                Environmental
              </button>
            </li>
            <li>
              <button
                className={`text-base font-medium ${selectedCategory === 'Social' ? 'text-brand-500' : 'text-gray-500'} hover:text-brand-500 dark:text-white`}
                onClick={() => handleCategoryClick('Social')}
              >
                Social
              </button>
            </li>
            <li>
              <button
                className={`text-base font-medium ${selectedCategory === 'Economic' ? 'text-brand-500' : 'text-gray-500'} hover:text-brand-500 dark:text-white`}
                onClick={() => handleCategoryClick('Economic')}
              >
                Economic
              </button>
            </li>
            <li>
              <button
                className={`text-base font-medium ${selectedCategory === 'Consumer' ? 'text-brand-500' : 'text-gray-500'} hover:text-brand-500 dark:text-white`}
                onClick={() => handleCategoryClick('Consumer')}
              >
                Consumer
              </button>
            </li>
            <li>
              <button
                className={`text-base font-medium ${selectedCategory === '' ? 'text-brand-500' : 'text-gray-500'} hover:text-brand-500 dark:text-white`}
                onClick={() => handleCategoryClick('')}
              >
                All
              </button>
            </li>
          </ul>
        </div>
        <div className="z-20 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3">
          {filteredBusinesses.map((business) => (
            <BusinessCard key={business.id} business={business} image={undefined} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HarmonyMarketplace;
