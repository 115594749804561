// ListsComponent.tsx
import React, { Key } from 'react';
import { Box, VStack, Heading, List, ListItem, Button, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, IconButton, Card } from '@chakra-ui/react';
import { IoAdd } from "react-icons/io5";

type ActivityType = 'email' | 'call' | 'donation';

interface Activity {
  timestamp: Date;
  description: string;
  type: string; // e.g., 'email', 'call', 'donation'
}

interface Member {
    profileType: string 
    communication: string;
      id: string;
      name: [string, string, string];
      email: string;
      donations: Donation[];
      status: string;
      priceNumeric?: number;
      date: string;
      edit?: any;
      memberMissions: string[]; // I assumed memberMissions is an array of strings. Adjust as needed.
      activities: Activity[];
      address: any;
  
  }

interface CategorizedMembers {
    Active: Member[];
    Prospective: Member[];
    Pending: Member[];
    Lapsed: Member[];
    HighDonors: Member[];
    RecentJoins: Member[];
    // Add more categories as needed
  }

interface ListProps {
  categorizedMembers: CategorizedMembers;
  deleteMember: (memberId: Key) => void;
  addNewList: () => void;
  textColor: string;
}
interface Donation {
    dollars: string;
    cents: string;
    date: string;
  }
  
const ListsComponent: React.FC<ListProps> = ({ categorizedMembers, deleteMember, addNewList, textColor }) => {
  return (
    <Card className="w-full h-full sm:overflow-auto px-6">
    <VStack align="stretch" spacing={4} h="full">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Heading size="lg" color={textColor} className='dark:text-white'>Lists</Heading>
        {/* <IconButton aria-label="Add new list" icon={<IoAdd />} onClick={addNewList} /> */}
      </Box>
      <Accordion defaultIndex={[0]} allowMultiple>
        {Object.entries(categorizedMembers).map(([category, members]) => (
          <AccordionItem key={category}>
            <h2 className='mt-10'>
              <AccordionButton>
                <Box flex="1" textAlign="left" className='dark:text-white'>
                  {category} 
                </Box>
                <AccordionIcon />
              </AccordionButton >
            </h2>
            <AccordionPanel pb={4} >
              <List spacing={2}>
                {members.map((member: { id: React.Key; name: any[]; }) => (
                  <ListItem key={member.id} className='dark:text-white mb-4'>
                    {member.name[0][0] + " " + member.name[0][1]}
                    <Button size="sm" ml={4}  onClick={() => deleteMember(member.id)}>
                      Delete
                    </Button>
                  </ListItem>
                ))}
              </List>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </VStack>
    </Card>
  );
};

export default ListsComponent;
