import React, { useState, ChangeEvent } from 'react';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Input, Textarea, Select, useDisclosure, Card } from '@chakra-ui/react';
import GroupCard from 'components/card/GroupCard';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import auth from '../../../../../firebase'
import GroupPage from './GroupPage';
// Adjust import path as necessary

const CreateGroupForm = () => {

  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentUser = auth.currentUser;
  const [isUploading, setIsUploading] = useState(false);
  const [step, setStep] = useState(1);
  const [groupData, setGroupData] = useState({
    creatorId: currentUser ? currentUser.uid : null,
    title: '',
    logo: null,
    banner: null,
    description: '',
    category: '',
    logoPreview: '', // Added preview fields
    bannerPreview: '',
  });

  const db = getFirestore();

  const handleChange = (e: { target: { name: any; value: any; }; }) => {
    setGroupData({ ...groupData, [e.target.name]: e.target.value });
  };
  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>, fieldName: 'logo' | 'banner') => {
    if (e.target.files && e.target.files[0]) {
      setIsUploading(true); // Start uploading
      const file = e.target.files[0];

      const storage = getStorage();
      const storageReference = storageRef(storage, `groups/${fieldName}/${file.name}`);

      try {
        const uploadResult = await uploadBytes(storageReference, file);
        const downloadURL = await getDownloadURL(uploadResult.ref);
        setGroupData((prevGroupData) => ({
          ...prevGroupData,
          [fieldName]: downloadURL,
          [`${fieldName}Preview`]: URL.createObjectURL(file), // Set preview URL
        }));
      } catch (error) {
        console.error("Error uploading file: ", error);
      }
      setIsUploading(false);
    }
  };

  

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleSubmit = async () => {
    if (isUploading) {
      // If an upload is in progress, do not submit the form yet
      alert('Please wait until the upload is finished.');
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'groups'), groupData);
      console.log('Group created with ID: ', docRef.id);
      onClose(); // Close modal after submission
    } catch (error) {
      console.error('Error adding group: ', error);
    }
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 1:
        return (
          <>
        
            <Input className='flex h-[45px] w-full rounded-xl border !border-gray-200 dark:!border-none dark:!bg-navy-700 md:w-[870px] xl:w-full mb-2' placeholder="Group Name" name="title" value={groupData.title} onChange={handleChange} />
            <Textarea className='flex h-[45px] w-full rounded-xl border !border-gray-200 dark:!border-none dark:!bg-navy-700 md:w-[870px] xl:w-full mb-4' placeholder="Description" name="description" value={groupData.description} onChange={handleChange} />
            <Select placeholder="Select Category" name="category" value={groupData.category} onChange={handleChange} className=''>
              <option value="environmental" className=''>Environmental</option>
              <option value="educational">Educational</option>
              <option value="health">Health</option>
              {/* Add other categories */}
            </Select>
          
          </>
        );
      case 2:
        return (
          <>
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <label htmlFor="logo-upload">Upload Logo</label>
        <input id="logo-upload" type="file" accept="image/*" onChange={(e) => handleImageChange(e, 'logo')} style={{ display: 'none' }} />
        <div onClick={() => document.getElementById('logo-upload').click()} style={{ cursor: 'pointer', marginTop: '10px' }}>
          {groupData.logoPreview ? (
            <img src={groupData.logoPreview} alt="Logo preview" style={{ maxWidth: '100px', maxHeight: '100px' }} />
          ) : (
            <div style={{ width: '100px', height: '100px', border: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span>No Logo Uploaded</span>
            </div>
          )}
        </div>
      </div>

      <div style={{ textAlign: 'center' }}>
        <label htmlFor="banner-upload">Upload Banner</label>
        <input id="banner-upload" type="file" accept="image/*" onChange={(e) => handleImageChange(e, 'banner')} style={{ display: 'none' }} />
        <div onClick={() => document.getElementById('banner-upload').click()} style={{ cursor: 'pointer', marginTop: '10px' }}>
          {groupData.bannerPreview ? (
            <img src={groupData.bannerPreview} alt="Banner preview" style={{ maxWidth: '200px', maxHeight: '100px' }} />
          ) : (
            <div style={{ width: '200px', height: '100px', border: '1px solid black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <span>No Banner Uploaded</span>
            </div>
          )}
        </div>
      </div>

          </>
        );
      case 3:
        return (
          <GroupCard
            currentUserUid={''} onSettingsClick={undefined} membershipStatus={''} onJoinLeaveCancel={undefined} events={[]} image={groupData.logo} // Make sure 'group.logo' contains the correct full URL
            title={groupData.title}
            description={groupData.description}
            groupId={''}
            extra="" {...groupData} /> // Adjust props as necessary for your GroupCard component
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Button onClick={onOpen}>Create New Group</Button>

      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Group</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{renderStepContent(step)}</ModalBody>
          <GroupPage/>
          <ModalFooter>
            {step > 1 && <Button variant="ghost" onClick={prevStep}>Back</Button>}
            {step < 3 ? (
              <Button  onClick={nextStep}>Next</Button>
            ) : (
              <Button  onClick={handleSubmit} isDisabled={isUploading}>
                {isUploading ? 'Uploading...' : 'Confirm'}
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateGroupForm;
