import { User } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { SignOutUser, db, ref, userStateListener } from "../firebase";
import { createContext, useState, useEffect, ReactNode, useContext, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentUser } from "store/Actions/action";
import RootState from "store/rootState";
import { get, onValue } from "firebase/database";

interface Props {
  children?: ReactNode;
}
interface VolunteerUser {
  email: string;
  // add other properties of a volunteer
}

interface CompanyUser {
  email: string;
  // add other properties of a company user
}
interface AuthContextType {
  currentUser: User | null;
  userRole: string | null;
  companyKey: string | null;
  contributeCode: string | null;
  signOut: () => void;
  updateCompanyKey: (key: string | null) => void;
  updateContributeKey: (key: string | null) => void;
  setUserRole: (role: string | null) => void; // Corrected type to match a setter function
}

// Creating the context with the correct initial value types
export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  userRole: null,
  companyKey: null,
  contributeCode: null,
  signOut: () => {},
  updateCompanyKey: () => {},
  updateContributeKey: () => {},
  setUserRole: () => {}, // Providing a no-op function as the initial value
});
export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: Props) => {
  const currentUser = useSelector((state: RootState) => state.user);
  const [companyKey, setCompanyKey] = useState<string | null>(null);
  const [contributeCode, setContributeCode] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateCompanyKey = useCallback((key: string) => {
    setCompanyKey(key);
  }, []);

  const updateContributeKey = useCallback((key: string) => {
    setContributeCode(key);
  }, []);

  const signOut = useCallback(() => {
    SignOutUser();
    dispatch(setCurrentUser(null));
    navigate('/auth/default');
  }, [dispatch, navigate]);

  useEffect(() => {
    const unsubscribe = userStateListener((user) => {
      if (user) {
        dispatch(setCurrentUser(user));
        const userEmail = user.email || "";

        let foundAsVolunteer = false;
        let foundContributeCode = null;

        const volunteersRef = ref(db, "volunteers");
        onValue(volunteersRef, async (volunteerSnapshot) => {
          if (volunteerSnapshot.exists()) {
            const volunteers = volunteerSnapshot.val();

            for (const key in volunteers) {
              const volunteer = volunteers[key];
              if (volunteer.email === userEmail) {
                foundAsVolunteer = true;
                foundContributeCode = volunteer.contributeCode;
                break;
              }
            }
          }

          if (!foundAsVolunteer) {
            const companiesRef = ref(db, "companies");
            await get(companiesRef).then((companySnapshot) => {
              if (companySnapshot.exists()) {
                const companies = companySnapshot.val();

                for (const companyKey in companies) {
                  const companyUsers = companies[companyKey].users || {};

                  if (companyUsers[user.uid]) {
                    setUserRole("companyUser");
                    updateCompanyKey(companyKey);
                    break;
                  }
                }
              }
            });
          } else {
            setUserRole("volunteer");
            updateContributeKey(foundContributeCode);
          }
        });
      } else {
        dispatch(setCurrentUser(null));
        setUserRole(null);
        setCompanyKey(null);
        setContributeCode(null);
      }
    });

    return unsubscribe;
  }, [dispatch, navigate]);

  const value: AuthContextType = useMemo(() => ({
    currentUser,
    userRole,
    companyKey,
    contributeCode,
    signOut,
    updateCompanyKey,
    updateContributeKey,
    setUserRole,
  }), [currentUser, userRole, companyKey, contributeCode]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
