import React, { useState, useEffect } from 'react';
import { doc, getDoc, getFirestore, updateDoc, collection, getDocs, setDoc, deleteDoc } from 'firebase/firestore';
import { Button, Input, Stack, Box, Text, Select, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { getDatabase, ref as firebaseRef, get } from 'firebase/database';
import CreateGroupForm from './CreateGroupForm';
import { MdSettings } from 'react-icons/md';

const GroupSettingsForm = ({ groupId }: { groupId: string }) => {
  const [group, setGroup] = useState(null);
  const [requests, setRequests] = useState([]);
  const [members, setMembers] = useState([]);
  const dbRT = getDatabase(); // Assuming you've already initialized Firebase Realtime Database
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    const db = getFirestore();
    const fetchGroup = async () => {
      const docRef = doc(db, 'groups', groupId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setGroup(docSnap.data());
      } else {
        console.log("No such group!");
      }
    };

    const fetchRequestsAndUsers = async () => {
        const reqsRef = collection(db, `groups/${groupId}/requests`);
        const querySnapshot = await getDocs(reqsRef);
        const requestsWithUserNames = await Promise.all(querySnapshot.docs.map(async (doc) => {
          const userId = doc.id;
          const userRef = firebaseRef(dbRT, `volunteers/${userId}`);
          const snapshot = await get(userRef);
          const userData = snapshot.val();
          // Check if userData and volunteerName exist before accessing firstName and lastName
          const name = userData ? `${userData.volunteerName.firstName} ${userData.volunteerName.lastName}` : 'Unknown User';
          return {
            id: userId,
            name: name,
            ...doc.data(),
          };
        }));
        setRequests(requestsWithUserNames);
      };

      const fetchMembersAndUsers = async () => {
        const membersRef = collection(db, `groups/${groupId}/members`);
        const querySnapshot = await getDocs(membersRef);
        const membersWithUserNames = await Promise.all(querySnapshot.docs.map(async (doc) => {
          const userId = doc.id;
          const userRef = firebaseRef(dbRT, `volunteers/${userId}`);
          const snapshot = await get(userRef);
          const userData = snapshot.val();
          // Check if userData and volunteerName exist before accessing firstName and lastName
          const name = userData && userData.volunteerName ? `${userData.volunteerName.firstName} ${userData.volunteerName.lastName}` : 'Unknown User';
          return {
            id: userId,
            name: name,
            role: doc.data().role,
          };
        }));
        setMembers(membersWithUserNames);
      };

    fetchGroup();
    fetchRequestsAndUsers();
    fetchMembersAndUsers();
  }, [groupId]);

  const handleSaveSettings = async () => {
    const groupRef = doc(getFirestore(), 'groups', groupId);
    await updateDoc(groupRef, { ...group });
  };

  const handleApproveRequest = async (userId: string) => {
    // Move user from requests to members with a default role
    await setDoc(doc(getFirestore(), `groups/${groupId}/members`, userId), { role: 'member' });
    await deleteDoc(doc(getFirestore(), `groups/${groupId}/requests`, userId));
    // Refresh data
    // You might want to fetch requests and members again here to update the UI
  };

  const handleAssignRole = async (userId: string, role: string) => {
    await updateDoc(doc(getFirestore(), `groups/${groupId}/members`, userId), { role });
    // Refresh members
   
  };
  const openGroupSettings = () => {

    onOpen();
  };

  if (!group) return <div>Loading...</div>;

  return (
    <Stack spacing={4}>
        <h1 className='mt-4 text-lg text-color-black font-normal leading-8 text-navy-700 dark:text-white' >Group Name</h1>
      <Input
        value={group.title}
        onChange={(e) => setGroup({ ...group, title: e.target.value })}
        placeholder="Group Title"
        className='text-base font-semibold text-[#3965FF] dark:text-white'
      />
      {/* Other fields for group settings */}
      <Button onClick={handleSaveSettings}>Save Settings</Button>
      <Box>
        <Text fontSize="lg" fontWeight="bold">Membership Requests</Text>
        {requests.map(request => (
          <Box key={request.id} p={2}>
            <Text>{request.name}</Text>
            <Button size="sm" onClick={() => handleApproveRequest(request.userId)}>Approve</Button>
          </Box>
        ))}
      </Box>
      <Box>
        <Text fontSize="lg" fontWeight="" >Members</Text>
        {members.map(member => (
          <Box key={member.id} p={2} display="flex" alignItems="center">
            <Text className='ml-[0px] text-sm font-bold text-navy-700 dark:text-white' flex="1">{member.name} - {member.role}</Text>
            <Select className='text-sm font-bold text-navy-700 dark:text-white' size="sm" value={member.role} onChange={(e) => handleAssignRole(member.id, e.target.value)}>
              <option value="member">Member</option>
              <option value="admin">Admin</option>
              <option value="moderator">Moderator</option>
            </Select>
            
          </Box>
        ))}
      </Box>
      <button onClick={onOpen} className="settings-button bg-brand-900 text-white font-medium py-2 px-4 rounded-[20px] hover:bg-brand-800 transition duration-200">Create New Group</button>
      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
  <ModalOverlay />
  <ModalContent>
    <ModalHeader>Group Settings</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
    
  <CreateGroupForm />
    </ModalBody>
  </ModalContent>
</Modal>

    </Stack>
  );
};

export default GroupSettingsForm;
