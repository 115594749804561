import React, { useEffect, useState } from "react";
import Card from "components/card";
import SetUp from "components/actions/SetUp";
import Nango from "@nangohq/frontend";
import * as firebase from "firebase/app";
import { getFunctions, httpsCallable } from 'firebase/functions';

import { Collapse, Button } from "@chakra-ui/react";
import { get, getDatabase, ref, set } from "firebase/database";
import { getAuth } from "firebase/auth";

import { functions } from "../../../../../../firebase";



const Connect = () => {

  const nango = new Nango({
    publicKey: process.env.REACT_APP_NANGO_PUBLIC_KEY,
  });
  const auth = getAuth();
  const db = getDatabase();
  const functions = getFunctions();


  // Connectors authentication status
  const [isMailchimpConnected, setIsMailchimpConnected] = useState(
    localStorage.getItem("isMailchimpConnected") === "true"
  );
  const [isSquareConnected, setIsSquareConnected] = useState(
    localStorage.getItem("isSquareConnected") === "true"
  );

  function storeCredentials(userId: string, serviceName: string, credentials: any) {
    const userRef = ref(db, 'users/' + userId + '/credentials/' + serviceName);
    return set(userRef, credentials);
  }


  const retrieveTokensFunction = httpsCallable(functions, 'retrieveTokens');
  const [isGoogleConnected, setIsGoogleConnected] = useState(
    localStorage.getItem("isGoogleConnected") === "true"
  );
  const [providerConfigKey, setProviderConfigKey] = useState("");
  const [connectionId, setConnectionId] = useState("");
  const [isMarketingOpen, setMarketingOpen] = useState(false);
  const [isSocialOpen, setSocialOpen] = useState(false);
  const [isFinancialOpen, setFinancialOpen] = useState(false);
  const [isGeneralOpen, setGeneralOpen] = useState(false);
  const userId = auth.currentUser.uid
  const [isShopifyConnected, setIsShopifyConnected] = useState(
    localStorage.getItem("isShopifyConnected") === "true"
  );

  const fetchAndStoreTokens = async (integrationId: string) => {
    try {
      const result = await retrieveTokensFunction({ integrationId });
      const tokens = result.data;

      // Store the returned tokens in your Firebase Realtime Database
      await storeCredentials(userId, integrationId, tokens);
    } catch (error) {
      console.error("Error fetching tokens from Cloud Function:", error);
    }
  };



  // Google authentication
  const handleGoogleLogin = async () => {
    try {
      const result = await nango.auth("google", userId);
      setIsGoogleConnected(true);
      setProviderConfigKey(result.providerConfigKey);
      setConnectionId(result.connectionId);
      localStorage.setItem("isGoogleConnected", "true");

      // Fetch and store tokens
      await fetchAndStoreTokens("google");  // Replace "google" with the appropriate integrationId if different
    } catch (err) {
      console.error("Google authentication error:", err);
    }
  };


  const handleGoogleLogout = () => {
    // Handle Google logout logic here
    setIsGoogleConnected(false);
    localStorage.removeItem("isGoogleConnected");
  };

  // Mailchimp authentication
  const handleMailchimpLogin = async () => {
    try {
      const result = await nango.auth("mailchimp", userId);
      setIsMailchimpConnected(true);
      setProviderConfigKey(result.providerConfigKey);
      setConnectionId(result.connectionId);
      localStorage.setItem("isMailchimpConnected", "true");

      // Fetch and store tokens
      await fetchAndStoreTokens("mailchimp");  // Replace "mailchimp" with the appropriate integrationId if different
    } catch (err) {
      console.error("Mailchimp authentication error:", err);
    }
  };

 // Handle Mailchimp logout logic here
  const handleMailchimpLogout = () => {
   
    setIsMailchimpConnected(false);
    setProviderConfigKey("");
    setConnectionId("");
    localStorage.removeItem("isMailchimpConnected");
  };


  async function fetchMailchimpContacts() {
    const userId = auth.currentUser.uid;  // Assuming you have auth imported

    try {
      // Call the cloud function
      const response = await fetch(
        "https://us-central1-safehaven-80301.cloudfunctions.net/fetchMailchimpData",
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: userId })
        }
      );


      const data = await response.json();

      // Handle the data (for now, we just log it)
      console.log(data);
    } catch (err) {
      console.error("Error fetching Mailchimp data:", err);
    }
  }
  // Shopify authentication
  const handleShopifyLogin = async () => {
    try {
      const result = await nango.auth("shopify", userId);
      setIsShopifyConnected(true);
      setProviderConfigKey(result.providerConfigKey);
      setConnectionId(result.connectionId);
      localStorage.setItem("isShopifyConnected", "true");

      // Fetch and store tokens
      await fetchAndStoreTokens("shopify");  // Replace "shopify" with the appropriate integrationId if different
    } catch (err) {
      console.error("Shopify authentication error:", err);
    }
  };
  // Handle Shopify logout logic here
  const handleShopifyLogout = () => {
  
    setIsShopifyConnected(false);
    setProviderConfigKey("");
    setConnectionId("");
    localStorage.removeItem("isShopifyConnected");
  };
  const handleSquareLogin = async () => {
    try {
      const result = await nango.auth("squareup-sandbox", userId);
      console.log("Authentication result:", result);
      setIsSquareConnected(true);
      setProviderConfigKey(result.providerConfigKey);
      setConnectionId(result.connectionId);
      localStorage.setItem("isSquareConnected", "true");

      // Fetch and store tokens (if necessary)
      await fetchAndStoreTokens("squareup-sandbox");

    } catch (err) {
      console.error("Square authentication error:", err);
    }
  };
  const handleSquareLogout = () => {
    setIsSquareConnected(false);
    setProviderConfigKey("");
    setConnectionId("");
    localStorage.removeItem("isSquareConnected");
  };
  async function fetchSquareData() {
    const userId = auth.currentUser.uid;
    console.log(userId)
    try {
      const response = await fetch(
        "https://us-central1-safehaven-80301.cloudfunctions.net/fetchSquareData",
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: userId })
        }
      );
      
      if (!response.ok) {
        const text = await response.text();
        throw new Error(`Server responded with ${response.status}: ${text}`);
     
        
      }
    
      
      const data = await response.json();
      console.log("Square Data:", data);
      
    } catch (err) {
      console.error("Error fetching Square data:", err);
    }
  }



  const toggleCollapsible = (section: string) => {
    switch (section) {
      case 'marketing':
        setMarketingOpen(!isMarketingOpen);
        break;
      case 'social':
        setSocialOpen(!isSocialOpen);
        break;
      case 'financial':
        setFinancialOpen(!isFinancialOpen);
        break;
      case 'general':
        setGeneralOpen(!isGeneralOpen);
        break;
      default:
        break;
    }
  };


  return (
    <>
      <br />
      <Card extra="w-full px-[30px] py-[30px]">
        {/* Header */}
        <p className="text-xl font-bold text-navy-700 dark:text-white">
        Integrations
    </p>
    <br>
    </br>
        <div className="flex items-center justify-between font-dm">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Marketing & Outreach Accounts
          </h4>
          <Button variant="brand"
            className="text-navy-700 dark:text-white"
            onClick={() => toggleCollapsible('marketing')}
          >
            {isMarketingOpen ? 'Collapse' : 'Expand'}
          </Button>
        </div>
        {/* Content */}
        <Collapse in={isMarketingOpen} animateOpacity>
          <div>
            <SetUp
              py=" py-[20px]"
              border="border-b"
              name="Google Analytics"
              value={isGoogleConnected ? "Configured" : "Not Configured"}
              actionName={isGoogleConnected ? "Log Out" : "Set up"}
              onClick={isGoogleConnected ? handleGoogleLogout : handleGoogleLogin}
            />
          </div>
          {isMailchimpConnected ? (
            <><SetUp
              py=" py-[20px]"
              border="border-b"
              name="Mailchimp"
              value={isMailchimpConnected ? "Configured" : "Not Configured"}
              actionName={isMailchimpConnected ? "Log Out" : "Set up"}
              onClick={isMailchimpConnected ? handleMailchimpLogout : handleMailchimpLogin} /><button onClick={fetchMailchimpContacts}>Fetch Contacts</button></>

          ) : (
            <SetUp
              border="border-b"
              py="py-[20px]"
              name="Mailchimp"
              value={"Not Configured"}
              actionName="Set up"
              onClick={handleMailchimpLogin}
            />
          )}
        </Collapse>
        <br />
        <div className="flex items-center justify-between font-dm">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Social Accounts
          </h4>
          <Button variant="brand"
            className="text-navy-700 dark:text-white"
            onClick={() => toggleCollapsible('social')}
          >
            {isSocialOpen ? 'Collapse' : 'Expand'}
          </Button>
        </div>
        <Collapse in={isSocialOpen} animateOpacity>
          <SetUp
            border="border-b"
            py=" py-[20px]"
            name="Instagram Account"
            value="Not Configured"
            actionName="Set up" />
          <SetUp
            border="border-b"
            py="py-[20px]"
            name="Facebook Account"
            value={"Not Configured"}
            actionName="Set up" />
          <SetUp
            py="py-[20px]"
            name="Twitter Account"
            value={"Not Configured"}
            actionName="Set up" />
        </Collapse>
        <br />
        <div className="flex items-center justify-between font-dm">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            Financial & Ecommerce Accounts
          </h4>
          <Button variant="brand"
            className="text-navy-700 dark:text-white"
            onClick={() => toggleCollapsible('financial')}
          >
            {isSocialOpen ? 'Collapse' : 'Expand'}
          </Button>
        </div>

        <Collapse in={isFinancialOpen} animateOpacity>
          <SetUp
            border="border-b"
            py=" py-[20px]"
            name="QuickBooks Account"
            value="Not Configured"
            actionName="Set up" />
          <SetUp
            border="border-b"
            py=" py-[20px]"
            name="Shopify Account"
            value={isShopifyConnected ? "Configured" : "Not Configured"}
            actionName={isShopifyConnected ? "Log Out" : "Set up"}
            onClick={isShopifyConnected ? handleShopifyLogout : handleShopifyLogin}
          />

          <SetUp
            border="border-b"
            py="py-[20px]"
            name="Square Account"
            value={isSquareConnected ? "Configured" : "Not Configured"}
            actionName={isSquareConnected ? "Log Out" : "Set up"}
            onClick={isSquareConnected ? handleSquareLogout : handleSquareLogin}
          />
          {isSquareConnected ? (
            <button onClick={fetchSquareData}>Fetch Square Data</button>
          ) : null}

        </Collapse>
        <br />

        <div className="flex items-center justify-between font-dm">
          <h4 className="text-2xl font-bold text-navy-700 dark:text-white">
            General Accounts
          </h4>
          <Button variant="brand"
            className="text-navy-700 dark:text-white"
            onClick={() => toggleCollapsible('general')}
          >
            {isSocialOpen ? 'Collapse' : 'Expand'}
          </Button>
        </div>
        <Collapse in={isGeneralOpen} animateOpacity>
          <SetUp
            border="border-b"
            py=" py-[20px]"
            name="Gmail Account"
            value="Not Configured"
            actionName="Set up" />
          <SetUp
            py="py-[20px]"
            name="Facebook Account"
            value={"Not Configured"}
            actionName="Set up" />
        </Collapse>
      </Card>
    </>
  );
};

export default Connect;
