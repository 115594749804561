import EventCalendar from "components/calendar/EventCalendar";
import Event from "./components/Event";
import TimeLine from "./components/TimeLine";
import { calendarData } from "./variables/calendar";
import  { db, auth} from "../../../../../firebase";
import { onValue,ref, set ,push, update, remove} from "firebase/database";
import { useEffect, useState } from "react";


const Calendar = () => {
  const today = new Date().toISOString().split("T")[0];

  const [companyKey, setCompanyKey] = useState<string | null>(null);
  const [missionId, setMissionId] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string>("");
  const [userKey, setUserKey] = useState<string | null>(null);
  const [events, setEvents] = useState([]);  // Add this line

  useEffect(() => {
    const currentUser = auth.currentUser; // Get the current user
    if (currentUser) {
      const eventsRef = ref(db, `events/${currentUser.uid}`);
      onValue(eventsRef, (snapshot) => {
        const eventsData = snapshot.val();
        // Convert eventsData from object to array if necessary and set it to state
        const eventsArray = eventsData ? Object.keys(eventsData).map(key => ({ id: key, ...eventsData[key] })) : [];
        setEvents(eventsArray);
      });
    }
  }, []);
  

  const handleDateSelect = (selectInfo: { startStr: any; endStr: any; }) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const eventsRef = ref(db, `events/${currentUser.uid}`);
      const newEventRef = push(eventsRef); // Correctly obtain a reference to a new child location
      set(newEventRef, {
        title: "New Event",
        start: selectInfo.startStr,
        end: selectInfo.endStr,
        // Additional event details
      }).then(() => {
        console.log("New event added successfully!");
      }).catch((error) => {
        console.error("Failed to add new event:", error);
      });
    }
  };
  
  const updateEvent = (eventId: any, eventData: object) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const eventRef = ref(db, `events/${currentUser.uid}/${eventId}`);
      update(eventRef, eventData);
    }
  };
  
  const deleteEvent = (eventId: any) => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const eventRef = ref(db, `events/${currentUser.uid}/${eventId}`);
      remove(eventRef);
    }
  };

  
  
  return (
    <div className="mt-3 grid h-full w-full grid-cols-11 gap-5 rounded-[20px]">
      <div className="b col-span-11 w-full rounded-[20px] lg:col-span-4">
        <div>
          <TimeLine events={events}/>
        </div>
        <div className="mt-5">
          <Event />
        </div>
      </div>
      {/* Calendar */}
      <div className="z-0 col-span-11 h-full w-full rounded-[20px] bg-white bg-clip-border px-[24px] py-[20px] shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none lg:col-span-7">
        {/* Calendar Header */}
        <div>
          <h5 className="text-xl font-bold text-navy-700 dark:text-white">
            Calendar
          </h5>
          <p className="mt-1 text-base font-medium text-gray-600">{today}</p>
        </div>
        <div className="mt-[28px] h-[700px] w-full rounded-[20px] dark:text-white">
          <EventCalendar initialDate={today} calendarData={events} onDateSelect={''}/>
        </div>
      </div>
    </div>
  );
};

export default Calendar;
