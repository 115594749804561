import React from 'react';
import { useDisclosure, Modal } from '@chakra-ui/react';
import { MdOutlineModeEdit } from 'react-icons/md';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  flexRender,
} from '@tanstack/react-table';
import Card from "components/card";
import SearchIcon from "components/icons/SearchIcon";
import SettingsProduct from "../../settingsProduct";
import { useFirebaseMissions } from 'hooks/useFirebaseMissions';

const columnHelper = createColumnHelper<any>();  // Update this to match your data model types if needed

function CheckTable({ tableData, handleEditMission, handleModal }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedMission, setSelectedMission] = React.useState(null);

  const columns = [
    columnHelper.accessor('missionName', {
      header: () => "Mission Name",
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('location', {
      header: () => "Location",
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('missionCategory', {
      header: () => "Category",
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('conservationType', {
      header: () => "Conservation Type",
      cell: info => info.getValue(),
    }),
    columnHelper.accessor('isOnlineMission', {
      header: () => "Online",
      cell: info => info.getValue() ? 'Yes' : 'No',
    }),
    columnHelper.accessor('urgencyLevel', {
      header: () => "Urgency",
      cell: info => info.getValue(),
    }),
  ];

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      pagination: { pageIndex: 0, pageSize: 5 },
    },
  });

  return (
    <Card>
      <div className="flex items-center gap-2 p-4">
        <SearchIcon />
        <input
          type="text"
          placeholder="Search missions..."
          className="flex-1 block rounded-md border border-gray-300 p-2"
          onChange={e => table.setGlobalFilter(e.target.value)}
        />
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <th key={header.id} className="px-4 py-2">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
                <th>Actions</th>
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => (
              <tr key={row.id} className="bg-white">
                {row.getVisibleCells().map(cell => (
                  <td key={cell.id} className="px-4 py-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
                <td>
                  <button
                    onClick={() => {
                      setSelectedMission(row.original);
                      handleModal(row.original);
                      onOpen();
                    }}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <MdOutlineModeEdit />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {selectedMission && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <SettingsProduct selectedMission={selectedMission} handleEditMission={handleEditMission}  />
        </Modal>
      )}
    </Card>
  );
}

export default CheckTable;
