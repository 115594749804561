import React from 'react';
import Conversion from "./Conversion";
import { VolunteerData } from '../VolunteerModal';

interface MissionActivityProps {
    volunteerData: VolunteerData;
}

const MissionActivity: React.FC<MissionActivityProps> = ({ volunteerData }) => {
    if (!volunteerData) {
        return <div>No volunteer data available</div>;
    }

    const { 
        skillSet = [],
        availabilitySchedule = { days: [], times: '' },
        pastVolunteerExperience = { pastRoles: [], pastOrganizations: [] },
        engagementLevel = '',
        languagesSpoken = [],
        volunteerMissions = [],
        interestAreas = [],
    } = volunteerData;

    return (
        <div className='w-auto h-auto px-8 pb-8 sm:overflow-x-auto bg-white dark:text-white dark:!bg-navy-700 bg-navy-700 linear justify-center rounded-lg font-bold transition duration-200'>
            <h2>Mission Activity</h2>
            <Conversion volunteerData={volunteerData} />

            <div>
                <h3>Skills</h3>
                {skillSet.map((skill, index) => (
                    <p key={index}>{skill}</p>
                ))}
            </div>
            <hr style={{ borderColor: 'lightgrey', margin: '20px 0' }} />

            <div>
                <h3>Availability</h3>
                <p>Days: {availabilitySchedule.days.join(', ')}</p>
                <p>Times: {availabilitySchedule.times}</p>
            </div>
            <hr style={{ borderColor: 'lightgrey', margin: '20px 0' }} />


            <div>
                <h3>Past Missions</h3>
                {volunteerMissions.map((mission: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal, index: React.Key) => (
                    <p key={index}>{mission}</p> // Display mission details
                ))}
            </div>
            <hr style={{ borderColor: 'lightgrey', margin: '20px 0' }} />

            <div>
                <h3>Language Proficiency</h3>
                {languagesSpoken.join(', ')}
            </div>
            <hr style={{ borderColor: 'lightgrey', margin: '20px 0' }} />

            <div>
                <h3>Engagement Level</h3>
                <p>{engagementLevel}</p>
            </div>

            {/* Add other sections like Languages, Interest Areas, etc. */}
        </div>
    );
};

export default MissionActivity;
