import React, { useEffect, useState } from "react";
import CardMenu from "./CardMenu";
import Card from "components/card";
import { MdEdit } from "react-icons/md";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import VolunteerModal from "./VolunteerModal";

type Skill = {
  id: number;
  name: string;
};

// Update the RowObj type to use Skill[] for the skills property
type RowObj = {
  firstName: string;
  lastName: string;
  email: string; // Assuming this is the same as volunteerEmail
  job: string;
  skills: Skill[]; // Use an array of Skill objects
  interests: string[]; // Assuming interests are still an array of strings
  // Include any other fields you need from the volunteer info
};



const columnHelper = createColumnHelper<RowObj>();
// const columns = columnsDataCheck;
export default function ComplexTable(props: { tableData: RowObj[] }) {
  const { tableData } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedVolunteer, setSelectedVolunteer] = React.useState(null);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const openModal = (volunteer: RowObj) => {
    console.log('Selected volunteer:', volunteer); // Debugging
    setSelectedVolunteer(volunteer);
    setIsModalOpen(true);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const filterData = (data: RowObj[], query: string) => {
    if (!query) return data;
    return data.filter((row) => {
      const fullName = `${row.firstName} ${row.lastName}`.toLowerCase();
      return fullName.includes(query.toLowerCase());
    });
  };

  const [filteredData, setFilteredData] = useState<RowObj[]>([]);
  useEffect(() => {
    setFilteredData(filterData(tableData, searchQuery));
  }, [searchQuery, tableData]);



  

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedVolunteer(null);
  };
  const columnHelper = createColumnHelper<RowObj>();
  const columns = [
    columnHelper.accessor("firstName", {
      header: "Name",
      cell: (info) => `${info.row.original.firstName} ${info.row.original.lastName}`,
    }),
    columnHelper.accessor("job", {
      header: "Job",
    }),
    columnHelper.accessor("skills", {
      header: "Skills",
      cell: (info) => {
        const skills = info.getValue();
        if (Array.isArray(skills)) {
          // Extract the 'name' property of each skill object in the array
          return skills.map(skill => skill.name).join(", ");
        }
        // Return an empty string if 'skills' is not an array
        return "";
      },
    }),
    
    
    
    
    // Add other columns as needed
  ];

  const [data, setData] = React.useState<RowObj[]>(tableData);
  useEffect(() => {
    // Update the filtered data when searchQuery or tableData changes
    const newFilteredData = filterData(tableData, searchQuery);
    setFilteredData(newFilteredData);
  }, [searchQuery, tableData]);

  const table = useReactTable({
    data: filteredData, // Use filteredData here
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
    // other table configurations
  });

  return (
    <Card extra={"w-full h-full px-8 pb-8 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Volunteer Management
        </div>
        <CardMenu />
      </div>
    <input
      type="text"
      placeholder="Search by name..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="dark:text-white dark:bg-navy-700 mt-4"
    />
      <div className="mt-8 overflow-x-scroll xl:overflow-x-hidden">
  
        <table className="w-full">
     
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="!border-px !border-gray-400">
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      onClick={header.column.getToggleSortingHandler()}
                      className="mb-2 cursor-pointer border-b border-gray-200 pt-4 pb-4 pr-4 text-start dark:border-white/10"
                    >
                      <div className="items-center justify-between text-xs text-gray-200">
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: "",
                          desc: "",
                        }[header.column.getIsSorted() as string] ?? null}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table
              .getRowModel()
              .rows.slice(0, 20)
              .map((row) => {
                return (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <td key={cell.id} className="border-white/0 py-3 pr-4">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div>
      {/* ... rest of your table code */}
      {isModalOpen && selectedVolunteer && (
  <VolunteerModal
    isOpen={isModalOpen}
    onClose={closeModal}
    volunteerData={selectedVolunteer}
  />
)}
    </div>
    </Card>
  );
}
