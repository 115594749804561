import { configureStore } from '@reduxjs/toolkit';
import userReducer from 'store/Reducers/userReducer';
import messagesReducer from 'store/Reducers/messageReducer';
import chatReducer from 'store/Reducers/chatReducer';
export const store = configureStore({
  reducer: {
    user: userReducer,
    messages: messagesReducer,
    chat:chatReducer,
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
