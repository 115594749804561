import { SetStateAction } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { AddressAutofill, useConfirmAddress } from '@mapbox/search-js-react';

export interface MissionDetailsProps {
  companyKey: string;
  missionDetails: {
    missionName: string;
    location: string;
    missionCategory: string;
    conservationType: string;
    missionDescription: string;
    isOnlineMission: boolean;
    isTimeCommitmentRequired: boolean;
    urgencyLevel: string;
    startDate: Date;
    endDate: Date;
    onlineUrl: string;
    groups: string;
  };
  setMissionDetails: (details: SetStateAction<MissionDetailsProps["missionDetails"]>) => void;
  saveMissionDetails: Function;
}

const MAPBOX_TOKEN = "pk.eyJ1Ijoic2ltbW1wbGUiLCJhIjoiY2wxeG1hd24xMDEzYzNrbWs5emFkdm16ZiJ9.q9s0sSKQFFaT9fyrC-7--g";

const MissionDetails = ({
  companyKey,
  missionDetails,
  setMissionDetails,
  saveMissionDetails,
}: MissionDetailsProps) => {
  const { missionName, location, missionCategory, conservationType, missionDescription, isOnlineMission, isTimeCommitmentRequired, urgencyLevel, startDate, endDate, onlineUrl, groups } = missionDetails;

  const { formRef, showConfirm } = useConfirmAddress({ minimap: true });

  const handleAddressSelect = (res: { features: string | any[]; }) => {
    if (res.features && res.features.length > 0) {
      const selectedLocation = res.features[0].place_name;
      setMissionDetails(prevDetails => ({
        ...prevDetails,
        location: selectedLocation
      }));
    }
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const result = await showConfirm();
    console.log(result);
  }

  const handleInputChange = (field: keyof MissionDetailsProps["missionDetails"], value: any) => {
    setMissionDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">Mission Info</h4>

      {/* content */}
      <div className="mt-6 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <form ref={formRef} onSubmit={handleSubmit}>
            <InputField
              extra="mb-4"
              label="Mission Name"
              placeholder="eg. Charity Event"
              id="missionname"
              type="text"
              value={missionName}
              onChange={(e: any) => handleInputChange("missionName", e.target.value)}
            />
               <AddressAutofill
        accessToken={MAPBOX_TOKEN}
        onRetrieve={handleAddressSelect}
      >
        <InputField
          extra="mb-4"
          label="Location"
          placeholder="eg. Sports Center Dallas"
          id="missionlocation"
          type="text"
          value={location}
          onChange={(e: { target: { value: string; }; }) => handleInputChange('location', e.target.value)}
          />
      </AddressAutofill>
            <FormControl id="missioncategory" mb={4}>
              <FormLabel>Mission Category</FormLabel>
              <Select
                value={missionCategory}
                onChange={(e: any) => handleInputChange("missionCategory", e.target.value)}
                placeholder="Select Mission Category"
                bgColor="F7FAFC"
                _placeholder={{ color: "gray.400" }}
              >
                <option value="Peer-to-peer">Peer-to-peer fundraising</option>
                <option value="Charity">Charity auctions</option>
                <option value="Donation">Donation kiosks</option>
                <option value="Online">Online donations</option>
                <option value="Crowdfunding">Crowdfunding campaign</option>
                <option value="Talent">Talent show</option>
                <option value="HappyHour">Happy hour</option>
                <option value="Networking">Networking dinner</option>
                <option value="Fashion">Fashion show</option>
                <option value="Social">Social Event</option>
              </Select>
            </FormControl>
            <FormControl id="conservationtype" mb={4}>
              <FormLabel>Conservation Type</FormLabel>
              <Select
                value={conservationType}
                onChange={(e: any) => handleInputChange("conservationType", e.target.value)}
                placeholder="Select Conservation Type"
                bgColor="F7FAFC"
                _placeholder={{ color: "gray.400" }}
              >
                <option value="Preservation">Preservation</option>
                <option value="Rehabilitation">Rehabilitation</option>
                <option value="Sustainable Use">Sustainable Use</option>
              </Select>
        </FormControl> 
         <TextField
            label="Mission Description"
            placeholder="Short description about the mission!"
            id="missiondescription"
            cols={30}
            rows={7}
            state={missionDescription}
            onChange={(e: any) => handleInputChange("missionDescription", e.target.value)}
          />
          </form>
        </div>
        {/* right side */}
        <div className="col-span-2 md:col-span-1">
          {/* <InputField
            extra="mb-3"
            label="Mission Goals"
            placeholder="eg. Classic"
            id="missiongoals"
            type="text"
            value={missionDetails.missionGoals}
            onChange={(e: any) => handleInputChange("missionGoals", e.target.value)}
          /> */}
        
            <FormControl id="isOnlineMission" mb={4}>
              <FormLabel>Is this an Online Mission?</FormLabel>
              <Select
                value={isOnlineMission ? "Yes" : "No"}
                onChange={(e: any) => handleInputChange("isOnlineMission", e.target.value === "Yes")}
                placeholder="Select"
                bgColor="F7FAFC"
                _placeholder={{ color: "gray.400" }}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
            </FormControl>
            <FormControl id="isTimeCommitmentRequired" mb={4}>
              <FormLabel>Is Time Commitment Required?</FormLabel>
              <Select
                value={isTimeCommitmentRequired ? "Yes" : "No"}
                onChange={(e: any) => handleInputChange("isTimeCommitmentRequired", e.target.value === "Yes")}
                placeholder="Select"
                bgColor="F7FAFC"
                _placeholder={{ color: "gray.400" }}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
            </FormControl>
            <FormControl id="urgencyLevel" mb={4}>
              <FormLabel>Urgency Level</FormLabel>
              <Select
                value={urgencyLevel}
                onChange={(e: any) => handleInputChange("urgencyLevel", e.target.value)}
                placeholder="Select Urgency Level"
                bgColor="F7FAFC"
                _placeholder={{ color: "gray.400" }}
              >
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Select>
            </FormControl>
            <InputField
              extra="mb-4"
              label="Online URL"
              placeholder="eg. https://example.com"
              id="onlineurl"
              type="text"
              value={onlineUrl}
              onChange={(e: any) => handleInputChange("onlineUrl", e.target.value)}
            />
            <InputField
              extra="mb-4"
              label="Groups"
              placeholder="eg. Environmentalists"
              id="groups"
              type="text"
              value={groups}
              onChange={(e: any) => handleInputChange("groups", e.target.value)}
            />
            <InputField
              extra="mb-4"
              label="Start Date"
              placeholder="Start Date"
              id="startdate"
              type="datetime-local"
              value={startDate.toISOString().substring(0, 16)}
              onChange={(e: any) => handleInputChange("startDate", new Date(e.target.value))}
            />
            <InputField
              extra="mb-4"
              label="End Date"
              placeholder="End Date"
              id="enddate"
              type="datetime-local"
              value={endDate.toISOString().substring(0, 16)}
              onChange={(e: any) => handleInputChange("endDate", new Date(e.target.value))}
            />
        </div>
      </div>
    </div>
  );
};

export default MissionDetails;
