import React, { useEffect, useState } from "react";
import BarChart from "components/charts/BarChart";
import { barChartOptionsUserActivity } from "variables/charts";
import { Availability } from "../..";

interface UserActivityProps {
  availability: Record<string, Availability>;
}

const UserActivity: React.FC<UserActivityProps> = ({ availability }) => {
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    const categories = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    let data = categories.map(day => {
      return {
        x: day,
        y: Object.values(availability).reduce((acc, curr) => {
          return acc + (Object.keys(curr).includes(day) ? 1 : 0);
        }, 0)
      };
    });

    setChartData([{
      name: 'Volunteer Availability',
      data: data.map(item => item.y)
    }]);
  }, [availability]);

  return (
    <div className="relative flex h-[355px] w-full flex-col rounded-[20px] bg-white bg-clip-border px-[25px] py-[29px] shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none" >
      <div className="flex w-full justify-between px-[8px]">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white">Volunteer Availability</h4>
        {/* <select className="text-sm font-medium text-gray-600 dark:!bg-navy-800 dark:text-white">
          <option value="weekly">Weekly</option>
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </select> */}
      </div>
      <div className="h-full w-full dark:text-white">
        <BarChart
          chartData={chartData}
          chartOptions={{
            ...barChartOptionsUserActivity,
            xaxis: {
              categories: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
            },
            plotOptions: {
              bar: {
                borderRadius: 10,
                columnWidth: "30px",
              },
            },
            fill: {
              type: "solid",
              colors: ["#5E37FF", "#6AD2FF"],
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
            colors: ["#5E37FF", "#6AD2FF"],
          }}
        />
      </div>
    </div>
  );
};

export default UserActivity;
