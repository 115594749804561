import React, { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, update } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { Button, useDisclosure } from '@chakra-ui/react';
import PaymentModal from './components/PaymentModal';
import ProfileFilter from "./components/ProfileFilter";
import Banner from "./components/Banner";
import Information from "./components/Information";
import CSRManagement from "./components/Business";
import BusinessNodeUpdateComponent from './components/admin/BusinessNodeUpdateComponent';

const VolunteerProfileSetting = () => {
  const [volunteerInfo, setVolunteerInfo] = useState(null);
  const [businessInfo, setBusinessInfo] = useState(null);
  const [activeSetting, setActiveSetting] = useState('Account');  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSupportEmail, setIsSupportEmail] = useState(false);

  const auth = getAuth();
  const database = getDatabase();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      if (user.email === 'support@harmony-flow.com') {
        setIsSupportEmail(true);
      }

      const volunteerRef = ref(database, 'volunteers/' + user.uid);
      onValue(volunteerRef, (snapshot) => {
        const data = snapshot.val();
        setVolunteerInfo(data);
        if (data && data.businessName) {
          const businessRef = ref(database, `businesses/byName/${data.businessName}/info`);
          onValue(businessRef, (businessSnapshot) => {
            setBusinessInfo({ ...businessSnapshot.val(), id: data.businessId });
          });
        }
      });
    }
  }, [auth, database]);

  const handleFileUpload = async (file, type) => {
    const storage = getStorage();
    const fileRef = storageRef(storage, `images/${type}/${auth.currentUser.uid}`);
    try {
      await uploadBytes(fileRef, file);
      const fileURL = await getDownloadURL(fileRef);
      await updateProfileImageURL(type, fileURL);
    } catch (error) {
      console.error("Error uploading file: ", error);
    }
  };

  const updateProfileImageURL = async (type, url) => {
    const userRef = ref(database, `volunteers/${auth.currentUser.uid}`);
    const updates = type === 'profile' ? { profileURL: url } : { bannerURL: url };
    await update(userRef, updates);
  };

  const updateUserProfile = async (updates) => {
    const userRef = ref(database, `volunteers/${auth.currentUser.uid}`);
    await update(userRef, updates);
  };

  const handleProfileImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleFileUpload(e.target.files[0], 'profile');
    }
  };

  const handleBannerImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleFileUpload(e.target.files[0], 'banner');
    }
  };

  const handleFilterChange = (newFilter) => {
    setActiveSetting(newFilter);
  };

  const updateBusinessStatus = (status) => {
    // update logic for setting business account status
  };

  if (!volunteerInfo) {
    return <div>Loading...</div>;
  }

  const hasBusiness = Boolean(volunteerInfo.businessName && businessInfo);

  return (
    <div className="mt-3 flex flex-col gap-5 rounded-lg">
    <ProfileFilter
      onFilterChange={handleFilterChange}
      activeSetting={activeSetting}
      hasBusiness={hasBusiness}
      isSupportEmail={isSupportEmail}
    />
    {!hasBusiness && (
      <Button className='px-full linear mt-5 rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200' onClick={onOpen}>
        Upgrade to Business Account
      </Button>
    )}
    {activeSetting === 'Account' && (
      <Banner
        profileImage={volunteerInfo.profileURL}
        bannerImage={volunteerInfo.bannerURL}
        onProfileImageChange={handleProfileImageChange}
        onBannerImageChange={handleBannerImageChange}
        userName={volunteerInfo.firstName}
        updateUserProfile={updateUserProfile}
      />
    )}
    <div>
      <PaymentModal isOpen={isOpen} onClose={onClose} updateBusinessStatus={updateBusinessStatus} />
      {activeSetting === 'Account' ? (
        <Information />
      ) : hasBusiness && activeSetting === 'Business' ? (
        <CSRManagement companyName={volunteerInfo.businessName} />
      ) : isSupportEmail && activeSetting === 'BusinessNodeUpdate' ? (
        <BusinessNodeUpdateComponent />
      ) : null}
    </div>
  </div>
  );
};

export default VolunteerProfileSetting;
