import React from "react";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import Card from "components/card";
import profileAvatar from "assets/img/avatars/avatar4.png";

function Profile() {
  const auth = getAuth();
  const currentUser = auth.currentUser;

  return (
    <Card extra={"w-full py-[18px] px-[18px] h-[140px]"}>
      <div className="flex items-center gap-3">
        <div className="flex h-[90px] w-[90px] items-center justify-center rounded-full">
          <img
            className="h-full w-full rounded-full"
            src={profileAvatar}
            alt="photo"
          />
        </div>
        <div>
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {currentUser.displayName}
          </p>
          <p className="text-base text-gray-600">{currentUser.email}</p>
        </div>
      </div>
    </Card>
  );
}

export default Profile;
