// userAction.js
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';


export const setUser = (user) => ({
  type: SET_USER,
  payload: user
});
export const setCurrentUser = (user) => {
  return {
    type: 'SET_CURRENT_USER',
    payload: user,
  };
};

export const clearUser = () => ({
  type: CLEAR_USER
});

export default { setUser, clearUser,setCurrentUser };
