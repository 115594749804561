import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { extendTheme, ChakraProvider, theme } from '@chakra-ui/react';
import { AuthContext } from "./contexts/AuthContext";
import { RequireAuth } from "./contexts/require-auth";
import { VolunteerProvider } from "contexts/VolunteerContext";
import { MissionProvider } from "contexts/MissionContext";
import RTLLayout from "./layouts/rtl";
import AdminLayout from "./layouts/admin";
import AuthLayout from "./layouts/auth";
import VolunteerLayout from "layouts/volunteer";
import MissionDetails from "views/volunteer/volunteerDashboard/impactWave/components/MissionDetails";
import ThreadList from "views/volunteer/volunteerDashboard/forums/components/ThreadList";
import GroupPage from "views/volunteer/volunteerDashboard/groups/components/GroupPage";
import "assets/css/Plugins.css";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import HarmonyMarketplace from "views/volunteer/volunteerDashboard/businessMarket/marketplace";
const stripePromise = loadStripe('pk_live_51PDAuJLqk5h9G7zkz1Db5DhgfF5Xx4ehYIdfYMjpvdoujobnjy4e7XX05DZOLkwvWF9Xs82KLShtq7VIDZVsUQUp004rsSREaL');



const App = () => {
  const { currentUser, userRole } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (userRole && currentUser) {
      const targetRoute = userRole === 'volunteer' ? "/volunteer/" : "/admin/";
      const currentPath = window.location.pathname;

      // Check if the current path is not part of the target route (i.e., user is not already in their role-specific area)
      const shouldRedirect = !currentPath.startsWith(targetRoute);
      if (shouldRedirect) {
        navigate(targetRoute);
      }
    }
  }, [userRole, currentUser, navigate]);

  return (
    <ChakraProvider theme={theme}>
    <Elements stripe={stripePromise}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
 <Route path="volunteer/missions/:missionId" element={

    <MissionDetails />
  
} />
        <Route path="admin/*" element={<RequireAuth><AdminLayout /></RequireAuth>} />

        <Route path="volunteer/*" element={
          <RequireAuth>
            <VolunteerProvider>
              <VolunteerLayout />
            </VolunteerProvider>
          </RequireAuth>
        } />
   

{/* <Route path="volunteer/missions/:missionId" element={
          // <RequireAuth>

            <VolunteerProvider>

              <MissionDetails />
            </VolunteerProvider>
          
        } /> */}
        <Route path="volunteer/group/:groupId" element={
          <RequireAuth>

            <VolunteerProvider>

              <GroupPage />
            </VolunteerProvider>
          </RequireAuth>
        } />
         <Route path="volunteer/social-responsibility/market" element={
          <RequireAuth>

            <VolunteerProvider>
           
              <HarmonyMarketplace />
            </VolunteerProvider>
          </RequireAuth>
        } />
        <Route path="volunteer/category/:categoryId" element={
          <RequireAuth>

            <VolunteerProvider>

              <ThreadList />
            </VolunteerProvider>
          </RequireAuth>
        } />

        <Route path="rtl/*" element={<RTLLayout />} />

        <Route path="/" element={<Navigate to="/auth/" />} />

      </Routes>
      </Elements>
    </ChakraProvider>
  );
};

export default App;
{/* <Route path="admin/*" element={<AdminLayout />} /> */ }