import React, { useState, useEffect } from 'react';
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Button } from '@chakra-ui/react';
import { MdApps } from 'react-icons/md';
import auth from '../../../../../firebase'
import { collection, query, where, getDocs } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import CreateGroupForm from './CreateGroupForm';

const UserGroupsModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userGroups, setUserGroups] = useState([]);
  const [groupRequests, setGroupRequests] = useState([]);
  const db = getFirestore();

  useEffect(() => {
    const fetchUserGroupsAndRequests = async () => {
      const user = auth.currentUser;
      if (user) {
        // Fetch groups where user is a member
        const groupsRef = collection(db, 'groups');
        const q = query(groupsRef, where('members', 'array-contains', user.uid));
        const groupsSnapshot = await getDocs(q);
        const groupsData = groupsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Fetch group requests
        // Assuming you have a structure to store requests
        // This structure and query will depend on your database schema
        const requestsRef = collection(db, 'groupRequests');
        const qRequests = query(requestsRef, where('userId', '==', user.uid), where('status', '==', 'pending'));
        const requestsSnapshot = await getDocs(qRequests);
        const requestsData = requestsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setUserGroups(groupsData);
        setGroupRequests(requestsData);
      }
    };

    fetchUserGroupsAndRequests();
  }, []);

  return (
    <>
      {/* <Button onClick={onOpen} className="linear flex items-center justify-center rounded-xl border !border-gray-200 p-3 text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-gray-100 active:bg-gray-50 dark:!border-none dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10">
        <MdApps /> */}
     
            
            
     <div className="relative mx-auto mt-5 flex w-full gap-1 overflow-visible md:!gap-6 xl:w-[96%]">
            {/* Display groups and requests */}
            <h2>My Groups</h2>
            {/* List groups */}
            {userGroups.map(group => (
              <div key={group.id}>{group.title}</div>
            ))}
            <h2>My Requests</h2>
            {/* List requests */}
            {groupRequests.map(request => (
              <div key={request.id}>{request.groupId} - {request.status}</div>
            ))}
</div>
<div className="relative mx-auto mt-5 flex w-full gap-1 overflow-visible md:!gap-6 xl:w-[96%]">
          <CreateGroupForm/>
          </div>
    </>
  );
};

export default UserGroupsModal;
