import { MdOutlineLocationOn } from "react-icons/md";
import { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card";
import CircularSlider from "react-circular-slider-svg";
import { getDatabase, ref, onValue } from "firebase/database";
import { useAuth } from "contexts/AuthContext";

const MarketSimulator = () => {
  const { currentUser } = useAuth();
  const db = getDatabase();
  const [companyKey, setCompanyKey] = useState(null);
  const [location, setLocation] = useState("retail store");
  const [selectedProduct, setSelectedProduct] = useState("");
  const [productDetails, setProductDetails] = useState({
    name: "",
    description: "",
    price: 0,
    climateImpact: 0,
    type: "",
    lifecycleCost: 0,
    quantity: 1,
    productionCost: 0,
  });
  const [products, setProducts] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const email = currentUser?.email || "";
    if (email) {
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                localStorage.setItem("companyKey", companyKey);
              }
            }
          }
        }
      });
    }
  }, [currentUser, db]);

  useEffect(() => {
    if (companyKey) {
      const productsRef = ref(db, `companies/${companyKey}/items`);
      onValue(productsRef, (snapshot) => {
        if (snapshot.exists()) {
          setProducts(Object.values(snapshot.val()));
        }
      });
    }
  }, [companyKey, db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      [name]: name === "price" || name === "climateImpact" || name === "quantity" || name === "productionCost" ? parseFloat(value) : value,
    }));
  };

  const handleSelectProduct = (e) => {
    const selectedProductName = e.target.value;
    setSelectedProduct(selectedProductName);
    const product = products.find((p) => p.name === selectedProductName);
    if (product) {
      setProductDetails({ ...product, quantity: 1, productionCost: 0 });
    }
  };

  const calculateLifecycleCost = () => {
    const lifecycleCost =
      productDetails.quantity *
      (productDetails.productionCost +
        productDetails.price +
        productDetails.climateImpact * 10);
    setProductDetails((prevDetails) => ({
      ...prevDetails,
      lifecycleCost,
    }));
  };

  const calculateClimateImpact = (description) => {
    const keywords = [
      { word: "sustainable", weight: -5 },
      { word: "recyclable", weight: -3 },
      { word: "biodegradable", weight: -4 },
      { word: "organic", weight: -2 },
      { word: "plastic", weight: 5 },
      { word: "carbon", weight: 4 },
      { word: "emission", weight: 4 },
    ];
    let impactScore = 0;

    keywords.forEach((keyword) => {
      const regex = new RegExp(keyword.word, "gi");
      const matches = (description.match(regex) || []).length;
      impactScore += matches * keyword.weight;
    });

    return impactScore;
  };

  useEffect(() => {
    if (
      productDetails.price &&
      productDetails.climateImpact &&
      productDetails.quantity &&
      productDetails.productionCost
    ) {
      calculateLifecycleCost();
    }
  }, [
    productDetails.price,
    productDetails.climateImpact,
    productDetails.quantity,
    productDetails.productionCost,
  ]);

  useEffect(() => {
    if (productDetails.description) {
      const impactScore = calculateClimateImpact(productDetails.description);
      setProductDetails((prevDetails) => ({
        ...prevDetails,
        climateImpact: impactScore,
      }));
    }
  }, [productDetails.description]);

  return (
    <Card
      extra={
        "flex flex-col w-full h-full relative px-[30px] pb-[40px] pt-[20px] "
      }
    >
      <h4 className="mb-1.5 text-lg font-bold text-navy-700 dark:text-white">
        Production Simulator
      </h4>
      <div className="flex items-center gap-1">
        <p className="text-gray-600 dark:text-white">
          <MdOutlineLocationOn />
        </p>
        <select
          className="bg-none text-sm font-bold text-gray-600 dark:bg-navy-800 dark:text-white"
          name="location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        >
          <option value="retail store">Retail Store</option>
          <option value="warehouse">Warehouse</option>
          <option value="website">Website</option>
        </select>
      </div>
      {/* Product Selection */}
      <div className="mt-4">
        <FormControl mb={2}>
          <FormLabel>Select Product/Service</FormLabel>
          <Select
            placeholder="Select product/service"
            value={selectedProduct}
            onChange={handleSelectProduct}
            rounded="full"
          >
            {products.map((product, index) => (
              <option key={index} value={product.name}>
                {product.name}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <FormControl mb={2}>
        <FormLabel>Production Cost</FormLabel>
        <Input
          placeholder="Production Cost"
          name="productionCost"
          type="number"
          value={productDetails.productionCost}
          onChange={handleInputChange}
          rounded="full"
        />
      </FormControl>
      <FormControl mb={2}>
        <FormLabel>Product Description</FormLabel>
        <Input
          placeholder="Product Description"
          name="description"
          value={productDetails.description}
          onChange={handleInputChange}
          rounded="full"
        />
      </FormControl>
      {/* Quantity Slider */}
      <div className="ml-1 flex h-full w-full flex-col items-center justify-center">
        <div className="relative ml-3 flex h-full w-full items-center justify-center">
          <CircularSlider
            startAngle={45}
            endAngle={315}
            handleSize={6}
            minValue={1}
            maxValue={1000}
            size={230}
            arcColor="#4318FF"
            arcBackgroundColor="#E1E9F8"
            handle1={{
              value: productDetails.quantity,
              onChange: (v) =>
                setProductDetails((prevDetails) => ({
                  ...prevDetails,
                  quantity: Math.round(v),
                })),
            }}
          />
          <p className="absolute left-[50%] top-[42%] translate-x-[-50%] text-[34px] font-bold text-navy-700 dark:text-white">
            {`${productDetails.quantity}`}
          </p>
        </div>
        <div className="z-50 mt-[-40px] flex items-center rounded-xl bg-white py-[20px] px-8 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="mr-[75px] text-md font-bold text-navy-700 dark:text-white">
            1 Item
          </p>
          <p className="text-md font-bold text-navy-700 dark:text-white">
            1000 Items
          </p>
        </div>
      </div>
      <button onClick={onOpen} className="mt-4 linear flex items-center justify-center rounded-xl bg-brand-800 px-4 py-2 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-900 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
        View Lifecycle Analysis
      </button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Lifecycle Analysis</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <p>Name: {productDetails.name}</p>
            <p>Quantity: {productDetails.quantity}</p>
            <p>Production Cost: ${productDetails.productionCost}</p>
            <p>Price per Unit: ${productDetails.price}</p>
            <p>Climate Impact: {productDetails.climateImpact}</p>
            <p>Type: {productDetails.type}</p>
            <p>Lifecycle Cost: ${productDetails.lifecycleCost}</p>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default MarketSimulator;
