// components/SearchComponent.jsx
import React, { useState } from 'react';
import {
  Input,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  VStack,
  Text,
  useColorModeValue,
  Flex,
  Spacer,
} from '@chakra-ui/react';
import InputField from 'components/fields/InputField';

const RESULTS_PER_PAGE = 5;

const SearchComponent = ({ addMember }) => {
  const [query, setQuery] = useState('');
  const [location, setLocation] = useState('');
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedResult, setSelectedResult] = useState(null);
  const [weight, setWeight] = useState(1);
  const modalBg = useColorModeValue('gray.50', 'gray.700');

  const handleSearch = async () => {
    const yelpResponse = await fetch(`https://api.yelp.com/v3/businesses/search?term=${query}&location=${location}`, {
      headers: {
        Authorization: `Bearer 2G8H1oIiL9b8mhcByH0hACP2gmIB4KFKN4ieieHZX4vUn6oPYvAfiN5aR25G4bQVfoT6tD3in0DEu885xwKUG9IffCCGCU7dhoZyVju3IB6S4YYrCy3XcVmXHQCUZnYx`,
      },
    });
    const yelpData = await yelpResponse.json();
    const yelpResults = yelpData.businesses.map((business) => ({
      name: business.name,
      description: business.categories.map((cat) => cat.title).join(', '),
      address: business.location.address1,
      phone: business.display_phone,
    }));

    const clearbitResponse = await fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${query}`);
    const clearbitData = await clearbitResponse.json();
    const clearbitResults = clearbitData.map((company) => ({
      name: company.name,
      description: company.domain,
      address: '', // Clearbit does not provide address, but you can merge with Yelp data if found
      phone: '', // Clearbit does not provide phone, but you can merge with Yelp data if found
    }));

    setResults([...yelpResults, ...clearbitResults]);
    setCurrentPage(1);
  };

  const handleSelectResult = (result) => {
    setSelectedResult(result);
    onOpen();
  };

  const handleAddMember = () => {
    if (selectedResult) {
      addMember({ ...selectedResult, weight });
      onClose();
    }
  };

  const startIndex = (currentPage - 1) * RESULTS_PER_PAGE;
  const paginatedResults = results.slice(startIndex, startIndex + RESULTS_PER_PAGE);

  return (
    <Box>
      <Box  mb={4}>
      <InputField
  extra="mb-3"
  label="Search for companies..."
  placeholder="Type the industry here"
  id="query"
  value={query}
  type="text"
  onChange={(e) => setQuery(e.target.value)}
/>
        {/* <Input
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search for companies..."
          mr={2}
        /> */}
              <InputField
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              placeholder="Enter desired zip code" id={''} label={'Enter city or zip code...'} 
/>  <Button onClick={handleSearch} className='mt-4' >Search</Button>
        {/* <Input
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="Enter city or zip code..."
          mr={2}
        /> */}
      
      </Box>
      <Box>
        {paginatedResults.map((result, index) => (
          <Box
            key={index}
            p={4}
            mb={2}
            borderRadius="md"
            onClick={() => handleSelectResult(result)}
            cursor="pointer"
          >
          
            <Text fontWeight="bold">{result.name}</Text>
            <Text>{result.description}</Text>
            {result.address && <Text>Address: {result.address}</Text>}
            {result.phone && <Text>Phone: {result.phone}</Text>}
          </Box>
          
        ))}
        
      </Box>
      <Flex mt={4}>
        <Button
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <Spacer />
        <Button
          onClick={() => setCurrentPage((prev) => (startIndex + RESULTS_PER_PAGE < results.length ? prev + 1 : prev))}
          disabled={startIndex + RESULTS_PER_PAGE >= results.length}
        >
          Next
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg={modalBg}>
          <ModalHeader>Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedResult && (
              <VStack align="start">
                <Text fontWeight="bold">{selectedResult.name}</Text>
                <Text>{selectedResult.description}</Text>
                {selectedResult.address && <Text>Address: {selectedResult.address}</Text>}
                {selectedResult.phone && <Text>Phone: {selectedResult.phone}</Text>}
                <Input
                  type="number"
                  value={weight}
                  onChange={(e) => setWeight(Number(e.target.value))}
                  placeholder="Weight"
                  mt={4}
                />
              </VStack>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleAddMember}>
              Add to List
            </Button>
            <Button onClick={onClose} ml={3}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SearchComponent;
