import React, { useContext, useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Grid,
  GridItem,
  useToast,
  Tabs, TabList, Tab, TabPanels, TabPanel
} from '@chakra-ui/react';
import { db, auth } from '../../../../../../firebase'
import { onValue, update, ref } from 'firebase/database';
import { AuthContext } from 'contexts/AuthContext';
import MissionActivity from './volunteerProfile/MissionActivity';
import ContactActivity from './volunteerProfile/ContactActivity';
import PersonalInformation from './volunteerProfile/PersonalInformation';


export interface VolunteerData {
  id: any;
  volunteerName: [string, string, string];
  volunteerAge: number;
  volunteerEmail: string;
  volunteerAddress: string;
  volunteerStatus: string; // e.g., 'Active', 'Inactive'
  volunteerDate: string; // Date of joining or last activity
  volunteerRole: string;
  volunteerMissions: []; // Array of mission or task IDs
  preferredContactMethod: string; // e.g., 'Email', 'Text'
  optInStatus: boolean; // true if opted in for communications
  availabilitySchedule: {
    days: string[]; // e.g., ['Monday', 'Wednesday']
    times: string; // e.g., '9AM - 5PM'
  };
  immediateAvailability: boolean;
  skillSet: string[]; // e.g., ['First Aid', 'Event Planning']
  interestAreas: string[]; // e.g., ['Childcare', 'Environment']
  pastVolunteerExperience: {
    pastRoles: string[];
    pastOrganizations: string[];
  };
  languagesSpoken: string[]; // e.g., ['English', 'Spanish']
  educationLevel: string; // e.g., 'Bachelor's Degree'
  emergencyContact: {
    name: string;
    relationship: string;
    contactDetails: string;
  };
  personalNotes: string;
  communicationHistory: string[]; // e.g., ['Email sent on 01/01/2023']
  engagementLevel: string; // e.g., 'High', 'Moderate'
  geographicPreferences: string[]; // e.g., ['Downtown', 'Suburbs']
  accessibilityNeeds: string;
}
export type RowObj = {
  volunteerName: [string, string, string];
  volunteerDate: string;
  volunteerRole: string;
  volunteerStatus: string;
  edit?: any;
  volunteerMissions: [];
  skillSet: string[]

};
const VolunteerModal = ({
  isOpen,
  onClose,
  volunteerData, // Now TypeScript knows the structure of volunteerData
}: {
  isOpen: boolean;
  onClose: () => void;
  volunteerData: VolunteerData;
}) => {

  const toast = useToast();

  const handleChange = (e: { target: { value: any; }; }, field: any) => {
    if (field === 'volunteerName') {
      setEditableVolunteerData(prevData => ({
        ...prevData,
        [field]: e.target.value
      }));
    } else if (field === 'skillSet') {
      // Handle skillSet as an array of strings
      const skillsArray = e.target.value.split(',').map((skill: string) => skill.trim());
      setEditableVolunteerData({ ...editableVolunteerData, skillSet: skillsArray });
    } else {
      setEditableVolunteerData({ ...editableVolunteerData, [field]: e.target.value });
    };
  }
  const { companyKey } = useContext(AuthContext);
  const [eligibleMissions, setEligibleMissions] = useState([]);

  const [editableVolunteerData, setEditableVolunteerData] = useState<VolunteerData>({
    id: '',
    volunteerName:volunteerData?.volunteerName ,
    volunteerAge: volunteerData?.volunteerAge,
    volunteerEmail: volunteerData?.volunteerEmail,
    volunteerAddress: '',
    volunteerStatus: '',
    volunteerDate: '',
    volunteerRole: '',
    volunteerMissions: volunteerData?.volunteerMissions,
    preferredContactMethod: '',
    optInStatus: false,
    availabilitySchedule: { days: [], times: '' },
    immediateAvailability: false,
    skillSet: volunteerData?.skillSet || [],
    interestAreas: volunteerData?.interestAreas || [],
    pastVolunteerExperience: { pastRoles: [], pastOrganizations: [] },
    languagesSpoken: [],
    educationLevel: '',
    emergencyContact: { name: '', relationship: '', contactDetails: '' },
    personalNotes: volunteerData?.personalNotes || '',
    communicationHistory: [],
    engagementLevel: '',
    geographicPreferences: [],
    accessibilityNeeds: '',
  });

  // Handler to save the updated data to Firebase
  const handleSave = async () => {
    if (!editableVolunteerData || !editableVolunteerData.id) {
      console.error('Volunteer ID is missing');
      return;
    }
    try {
      // Correct path assuming `editableVolunteerData.id` holds the key of the volunteer in Firebase
      await update(ref(db, `companies/${companyKey}/volunteers/${editableVolunteerData.id}/volunteerInfo`), editableVolunteerData);
      toast({
        title: 'Success',
        description: 'Volunteer information updated successfully.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose(); // Close the modal
    } catch (error) {
      toast({
        title: 'Error',
        description: 'There was an issue updating the volunteer information.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error('Error updating volunteer data:', error);
    }
  };

  useEffect(() => {
    const fetchMissions = async () => {
      const missionsRef = ref(db, 'missions');
      onValue(missionsRef, (snapshot) => {
        if (snapshot.exists()) {
          const missions = Object.values(snapshot.val());
          const filteredMissions = missions.filter(mission => {
            // Replace this with your actual filtering logic
            return mission === editableVolunteerData.volunteerAddress;
          });
          setEligibleMissions(filteredMissions);
        }
      });
    };

    fetchMissions();
  }, [editableVolunteerData, db]);

  useEffect(() => {
    console.log("Editable Volunteer Data:", editableVolunteerData);
  }, [editableVolunteerData]);
  

  if (!volunteerData) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl" >
      <ModalOverlay />
      <ModalContent backgroundColor="gray.50" borderRadius="lg" className='w-auto h-auto px-8 pb-8 sm:overflow-x-auto bg-white dark:text-white dark:!bg-navy-700 bg-navy-700 linear justify-center rounded-lg font-bold transition duration-200'>
        <ModalHeader className="dark:text-white"> {editableVolunteerData.volunteerName[0]}
      
        
        </ModalHeader>
        <ModalCloseButton className="dark:text-white" />
        <Tabs variant="enclosed" colorScheme='Black' className='w-auto h-auto px-8 pb-8 sm:overflow-x-auto bg-white dark:text-white dark:!bg-navy-700 bg-navy-700 linear justify-center rounded-lg font-bold transition duration-200'>
      <TabList mb="1em">
        <Tab>Personal Information</Tab>
        <Tab>Contact Activity</Tab>
        <Tab>Mission Activity</Tab>
      </TabList>
    

      <TabPanels>
        <TabPanel>
          {/* Content for Personal Information */}
          <ModalBody className="dark:text-white p-6">
          <PersonalInformation 
  editableVolunteerData={editableVolunteerData} 
  setEditableVolunteerData={setEditableVolunteerData} 
/>
        </ModalBody>
        </TabPanel>
        <TabPanel>
          {/* Content for Contact Activity */}
          <ContactActivity />
        </TabPanel>
        <TabPanel>
          {/* Content for Mission Activity */}
          <MissionActivity volunteerData={volunteerData} />
        </TabPanel>
      </TabPanels>
    </Tabs>
      
        <ModalFooter>
          <Button variant="brand" onClick={handleSave}>Save</Button>
          <Button onClick={onClose} ml={3}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VolunteerModal;
