import React, { useRef, useEffect, useState } from 'react';
import ad1 from 'assets/svg/Ad1.mp4';
import './style.css';

const ScrollVideo = () => {
  const videoRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoDuration = 10; // Assume the video duration is 10 seconds for this example

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setScrollY(scrollPosition);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;

    const maxScrollY = document.documentElement.scrollHeight - window.innerHeight;
    const scrollFraction = scrollY / maxScrollY;
    const videoTime = scrollFraction * videoDuration;

    if (!isPlaying && scrollFraction > 0.75) {
      videoElement.currentTime = videoTime;
      videoElement.play();
      setIsPlaying(true);
    } else if (isPlaying && scrollFraction <= 0.75) {
      videoElement.pause();
      setIsPlaying(false);
    } else {
      videoElement.currentTime = videoTime;
    }
  }, [scrollY, isPlaying]);

  return (
    <div className="video-container">
      <video ref={videoRef} className="video absolute top-0 left-0 w-full h-full object-cover" 
          
              muted
              loop>
        <source src={ad1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default ScrollVideo;
