import { useState, useEffect, useContext, useRef } from "react";
import Card from "../../../../../components/card";
import InputField from "../../../../../components/fields/InputField";
import TextField from "../../../../../components/fields/TextField"
import { getAuth } from "firebase/auth";

import { AuthContext } from "../../../../../contexts/AuthContext";
import { getDatabase, ref, onValue, set, update, get } from "firebase/database";
import TagsField from "components/fields/TagsField";
import AvailabilityCalendar from "components/calendar/AvailabilityCalendar";
import ProfileFilter from "./ProfileFilter";


  const Information = () => {
    const { currentUser,userRole } = useContext(AuthContext);
    const [userEmail, setUserEmail] = useState("");
    const [combinedVolunteerInfo, setCombinedVolunteerInfo] = useState(null);

    const db = getDatabase();
    const [skills, setSkills] = useState([]);
    const [interests, setInterests] = useState([]);
    const [availability, setAvailability] = useState({}); // Example state for availability

    const [userInfo, setUserInfo] = useState({
      username: "",
      firstName: "",
      lastName: "",
      job: "",
      aboutMe: "",
      userType: "",
 
      volunteerEmail: "",
      isAdmin: false,      
    });
    const [businessInfo, setBusinessInfo] = useState({
      businessName: "",
      businessDescription: "",
  });

  useEffect(() => {
    if (!currentUser) return;
    const userRef = ref(db, `volunteers/${currentUser.uid}`);
    
    get(userRef).then((snapshot) => {
      if (snapshot.exists()) {
        setUserInfo(snapshot.val());
      }
    });

    // Fetch business info if the user is a business type and has admin rights
    if (userInfo.userType === "business" && userInfo.isAdmin) {
      const businessRef = ref(db, `businesses/byName/${userInfo.username.toLowerCase()}`);
      get(businessRef).then((snapshot) => {
        if (snapshot.exists()) {
          setBusinessInfo({
            businessName: snapshot.val().businessName,
            businessDescription: snapshot.val().businessDescription,
          });
        }
      });
    }
  }, [currentUser, db, userInfo.username, userInfo.userType, userInfo.isAdmin]);
  
  const handleInputChange = (event: { target: { name: any; value: any; }; }) => {
    const { name, value } = event.target;
    if (name === "businessName" || name === "businessDescription") {
      setBusinessInfo(prev => ({ ...prev, [name]: value }));
    } else {
      setUserInfo(prev => ({ ...prev, [name]: value }));
    }
  };
  
  const handleSaveChanges = async (event: { preventDefault: () => void; }) => {
    event.preventDefault();
    if (userInfo.userType === "business" && !userInfo.isAdmin) {
      console.error("Unauthorized attempt to update business information by non-admin.");
      return;
    }

    const userUpdates = { ...userInfo };
    const businessUpdates = userInfo.isAdmin ? { 
      name: businessInfo.businessName, 
      description: businessInfo.businessDescription 
    } : {};

    const updates = {};
    updates[`volunteers/${currentUser.uid}`] = userUpdates;

    if (userInfo.userType === "business" && userInfo.isAdmin) {
      updates[`businesses/byName/${userInfo.username.toLowerCase()}`] = businessUpdates;
    }

    try {
      await update(ref(db), updates);
      console.log("Information updated successfully.");
    } catch (error) {
      console.error("Error updating information: ", error);
    }
  };

    
    
    const saveAvailability = async (day: string, timeSlot: string) => {
      if (!currentUser) return;
  
      const availabilityRef = ref(getDatabase(), `volunteerAvailability/${currentUser.uid}/${day}`);
      await set(availabilityRef, timeSlot)
        .then(() => console.log(`Availability updated for ${day} with time slot ${timeSlot}.`))
        .catch((error) => console.error("Error updating availability: ", error));
    };
  
  return (
    
    <Card extra={"flex w-full flex-col gap-5 lg:gap-5"}>
      {/* Header */}
      <div className="w-full px-[8px] mt-4 mb-4">
      <h4 className="text-xl font-bold text-navy-700 dark:text-white">
        Account Settings
      </h4>
     
      <p className="mt-1 text-base text-gray-600">
        Here you can change user account information
      </p>
    </div>
      {/* Form */}
     
        {/* inputs */}
        
        <form onSubmit={handleSaveChanges}>
        <div className="mt-7 mb-2 grid grid-cols-2 gap-3">
       
          <InputField
            label='Username'
            placeholder="@harmonyflow.com"
            id="username"
            type="text"
            value={userInfo.username}
            onChange={handleInputChange}
          />
          <InputField
            label='Email'
            placeholder="@harmony.ui"
            id="volunteerEmail"
            type="text"
            value={userInfo.volunteerEmail}
            onChange={handleInputChange}
          />
          <InputField
            label='First Name'
            placeholder="Vlad"
            id="firstName"
            type="text"
            value={userInfo.firstName}
            onChange={handleInputChange}
          />
          <InputField
            label='Last Name'
            placeholder="Mihalache"
            id="lastName"
            type="text"
            value={userInfo.lastName}
            onChange={handleInputChange}
          />
        </div>
        {/* full width inputs */}
        <InputField
          label='Job'
          placeholder="CTO"
          id="job"
          type="text"
          extra="mb-2"
          value={userInfo.job}
          onChange={handleInputChange}
        />
 <InputField
  label='About Me'
  placeholder="Tell something about yourself in 150 characters!"
  onChange={handleInputChange}
  id="aboutMe"
  extra="mb-5"
  value={userInfo.aboutMe}
/>
<div className="mt-2 w-full mb-6">
<TagsField
          label="Your Skills?"
          tags={skills}
          setTags={setSkills}
          placeholder="Type skill and press enter"
        />
        <TagsField
          label="Your Interests?"
          tags={interests}
          setTags={setInterests}
          placeholder="Type interest and press enter"
        />
        </div>
        <div    className={`flex  h-full max-w-full flex-col rounded-[20px] bg-white px-3 py-4 dark:border dark:!border-white/10 dark:!bg-navy-800`}>
        <AvailabilityCalendar
        initialAvailability={combinedVolunteerInfo}
        onSave={saveAvailability}
      />
       </div>
<div className="mt-4 mb-4 p-4 flex w-full justify-end">
        <button type="submit" className="linear rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
          Save Changes
        </button>
        </div>
        </form>
  
    </Card>
  );
};

export default Information;
