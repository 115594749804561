import { useState, useEffect } from "react";
import { useDisclosure } from "@chakra-ui/react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
} from "@chakra-ui/react";
import { getDatabase, ref, onValue, set, push } from "firebase/database";
import { useAuth } from "contexts/AuthContext";
import Card from "components/card";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { MdOutlineLocationOn, MdOutlineLightbulb } from "react-icons/md";

const GoalMeasure = () => {
  const { currentUser } = useAuth();
  const db = getDatabase();
  const [companyKey, setCompanyKey] = useState(null);
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [goal, setGoal] = useState(0);
  const [currentUsage, setCurrentUsage] = useState(0);
  const [goalDescription, setGoalDescription] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const email = currentUser?.email || "";
    if (email) {
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const key in companies) {
            const company = companies[key];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(key);
                localStorage.setItem("companyKey", key);
              }
            }
          }
        }
      });
    }
  }, [currentUser, db]);

  useEffect(() => {
    if (companyKey) {
      const itemsRef = ref(db, `companies/${companyKey}/items`);
      onValue(itemsRef, (snapshot) => {
        if (snapshot.exists()) {
          setItems(Object.values(snapshot.val()));
        }
      });
    }
  }, [companyKey, db]);

  const handleSelectItem = (e) => {
    const selectedItemName = e.target.value;
    setSelectedItem(selectedItemName);
    const item = items.find((i) => i.name === selectedItemName);
    if (item) {
      setGoal(item.goal || 0);
      setCurrentUsage(item.usage || 0);
      setGoalDescription(item.goalDescription || "");
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "goal") {
      setGoal(parseInt(value, 10));
    }
    if (name === "currentUsage") {
      setCurrentUsage(parseInt(value, 10));
    }
    if (name === "goalDescription") {
      setGoalDescription(value);
    }
  };

  const handleSaveGoal = async () => {
    if (companyKey && selectedItem) {
      const itemRef = ref(db, `companies/${companyKey}/items/${selectedItem}`);
      await set(itemRef, { goal, usage: currentUsage, goalDescription });
      onClose();
    }
  };

  const handleSliderChange = (value) => {
    if (Array.isArray(value)) {
      setCurrentUsage(value[0]);
    } else {
      setCurrentUsage(value);
    }
  };

  return (
    <Card extra={"flex flex-col w-full relative pt-4 pb-5 px-6 pb-7 h-full"}>
      <h4 className="ml-px mb-1.5 text-lg font-bold text-navy-700 dark:text-white">
        Goal Measure
      </h4>
      <div className="mb-8 flex items-center gap-1">
        <p className="text-gray-600">
          <MdOutlineLocationOn />
        </p>
        <Select
          className="bg-none text-sm font-bold text-gray-600 dark:bg-navy-800"
          placeholder="Select Item"
          onChange={handleSelectItem}
          value={selectedItem}
        >
          {items.map((item, index) => (
            <option key={index} value={item.name}>
              {item.name}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex flex-col items-center bg-white dark:!bg-navy-800">
        <div className="mb-4 flex h-[90px] w-[90px] items-center justify-center rounded-full text-[50px] text-brand-500 shadow-xl shadow-shadow-500 dark:bg-navy-700 dark:text-white dark:shadow-none">
          <MdOutlineLightbulb />
        </div>
        <p className="mb-2 font-medium text-gray-600">Resource Goal</p>
        <p className="mb-4 text-center text-lg font-bold text-navy-700 dark:text-white">
          Save Resources Effectively
        </p>
        <div className="mt-3 w-full px-3">
          <Slider
            max={goal}
            value={currentUsage}
            onChange={handleSliderChange}
            trackStyle={{
              backgroundColor: "rgb(67 24 255)",
              height: 10,
              cursor: "pointer",
            }}
            handleStyle={{
              border: "3px solid white",
              backgroundColor: "rgb(67, 24, 255)",
              opacity: 1,
              height: 18,
              cursor: "pointer",
              width: 18,
            }}
            railStyle={{
              backgroundColor: "#E1E9F8",
              height: 10,
              cursor: "pointer",
            }}
          />
        </div>
      </div>

      <button onClick={onOpen} className="mt-4 linear flex items-center justify-center rounded-xl bg-brand-800 px-4 py-2 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-900 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
        Set Goal
      </button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Set Resource Goal</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Goal Description</FormLabel>
              <Input
                placeholder="Goal Description"
                name="goalDescription"
                value={goalDescription}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Goal</FormLabel>
              <Input
                placeholder="Set Goal"
                name="goal"
                type="number"
                value={goal}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Current Usage</FormLabel>
              <Input
                placeholder="Current Usage"
                name="currentUsage"
                type="number"
                value={currentUsage}
                onChange={handleInputChange}
                rounded="full"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSaveGoal}>
              Save
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default GoalMeasure;
