import { SetStateAction, useState } from "react";
import TagsField from "components/fields/TagsField";
import TextField from "components/fields/TextField";
import { Box, FormLabel, Input } from "@chakra-ui/react";

interface MissionLogisticsProps {
  companyKey: string;
  missionId: string;
  missionLogistics: {
    missionPartnerships: { name: string; id: number }[];
    missionResources: string;
  
  };
  setMissionLogistics: (details: SetStateAction<MissionLogisticsProps["missionLogistics"]>) => void;
  saveMissionLogistics: Function;
}

const MissionLogistics = ({
  companyKey,
  missionId,
  missionLogistics,
  saveMissionLogistics,
  setMissionLogistics,
}: MissionLogisticsProps) => {
  const { missionPartnerships, missionResources } = missionLogistics;
  const [tags, setTags] = useState<{ name: string; id: number }[]>([]);

  const handleInputChange = (field: keyof MissionLogisticsProps["missionLogistics"], value: any) => {
    setMissionLogistics((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h1 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Mission Logistics
      </h1>

      {/* inputs */}
      <div className="mt-10 grid h-full w-full grid-cols-1 gap-3 md:grid-cols-2">
        <TagsField
          label="Mission Partnerships"
          tags={tags}
          setTags={(tags) => handleInputChange("missionPartnerships", tags)}
        />
        <TextField
          label="Mission Resources"
          placeholder="e.g., equipment, supplies"
          id="missionresources"
          cols={30}
          rows={7}
          extra={missionResources}
          onChange={(e: any) => handleInputChange("missionResources", e.target.value)}
        />
        <div className="flex h-fit gap-3">
          <Box>
            <FormLabel htmlFor="missionStartDate">Start Date</FormLabel>
            {/* <Input
              id="missionStartDate"
              type="datetime-local"
              placeholder="eg. Classic"
              value={missionStartDate}
              onChange={(e: any) => handleInputChange("missionStartDate", e.target.value)}
            />
          </Box>
          <Box>
            <FormLabel htmlFor="missionEndDate">End Date</FormLabel>
            <Input
              id="missionEndDate"
              type="datetime-local"
              placeholder="eg. Classic"
              value={missionEndDate}
              onChange={(e: any) => handleInputChange("missionEndDate", e.target.value)}
            /> */}
          </Box>
        </div>
      </div>
    </div>
  );
};

export default MissionLogistics;
