import { useEffect, useState } from "react";
import { FaRegCommentDots } from "react-icons/fa";
import { MdOutlineFavoriteBorder, MdOutlineEdit, MdOutlineDelete } from "react-icons/md";
import { FiSend } from "react-icons/fi";
import { formatDistanceToNow } from 'date-fns'; 
import { getDatabase, ref, get, update, push, onValue, remove } from "firebase/database";
import { Image, Textarea, useDisclosure } from "@chakra-ui/react";
import auth from "../../../../firebase";
import CommentsModal from "./CommentsModal";
import ProfileOverviewModal from "./ProfileOverviewModal";
import CustomLightbox from "./CustomLightBox";
import Card from "components/card";

interface PostAuthor {
  id: string;
  name: string;
  profileURL: string;
  username: string;
}

interface PostComment {
  id: string;
  name: string;
  profileURL: string
  authorId: string;
  content: string;
  timestamp: number;
  likes: number;
  likedByCurrentUser: boolean;
} 

export interface UserPost {
  id: string;
  authorId: string;
  content: string;
  images: string | string[];
  timestamp: number;
  likes: number;
  comments: PostComment[];
  tags?: string[];
}

interface UserProfile {
  id: string;
  firstName: string;
  lastName: string
  profileURL: string;
  username: string;
}

interface PostProps {
  data: UserPost;
  usersData: Record<string, UserProfile>;
}

const Post: React.FC<PostProps> = ({ data, usersData }) => {
  const authorData = usersData[data.authorId];
  const comments = data.comments || [];
  const [commentText, setCommentText] = useState('');
  const db = getDatabase();
  const currentUser = auth.currentUser;
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [volunteerData, setVolunteerData] = useState(null);
  const [authorInfo, setAuthorInfo] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [currentUserProfile, setCurrentUserProfile] = useState('');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isContentExpanded, setIsContentExpanded] = useState(false);
  const CONTENT_PREVIEW_LIMIT = 300;

  useEffect(() => {
    const currentUserUid = auth.currentUser?.uid;
    if (currentUserUid) {
      const volunteerRef = ref(db, `volunteers/${currentUserUid}`);
      const unsubscribe = onValue(volunteerRef, (snapshot) => {
        if (snapshot.exists()) {
          setVolunteerData(snapshot.val());
        }
      });
      return () => unsubscribe();
    }
  }, [db]);

  useEffect(() => {
    if (currentUser) {
      const currentUserRef = ref(db, `volunteers/${currentUser.uid}`);
      onValue(currentUserRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setCurrentUserProfile(userData.profileURL || 'default_profile_pic_url');
        }
      });
    }
  }, [currentUser, db]);

  const formatDate = (timestamp: number) => {
    const date = new Date(timestamp);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const handleLike = async () => {
    const postRef = ref(db, `posts/${data.id}`);
    const updatedLikes = data.likes + 1;
    await update(postRef, { likes: updatedLikes });
  };

  const handleCommentSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (!commentText.trim()) return;

    const currentUserData = usersData[currentUser?.uid];
    const newComment = {
      id: `comment_${Date.now()}`,
      authorId: currentUser?.uid,
      profileURL: currentUserData?.profileURL || '',
      name: `${currentUserData?.firstName || ''} ${currentUserData?.lastName || ''}`,
      content: commentText,
      timestamp: Date.now(),
    };
    const commentsRef = ref(db, `posts/${data.id}/comments`);
    await push(commentsRef, newComment);
    setCommentText('');
  };

  const handleEdit = async () => {
    // Prompt for new content
    const newContent = prompt('Edit your post:', data.content);
    if (newContent === null) return; // User cancelled

    // Update the post content
    const postRef = ref(db, `posts/${data.id}`);
    await update(postRef, { content: newContent });
  };

  const handleDelete = async () => {
    const postRef = ref(db, `posts/${data.id}`);
    await remove(postRef);
  };

  const handleProfileClick = (authorId: string) => {
    const authorProfile = usersData[authorId];
    if (authorProfile) {
      setSelectedProfile({
        ...authorProfile,
        id: authorId,
      });
      setShowModal(true);
    } else {
      console.error("User data not found for ID:", authorId);
    }
  };

  const openLightboxAtIndex = (index: number) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  const handleNext = () => {
    setCurrentImageIndex(prevIndex => prevIndex + 1 < imagesArray.length ? prevIndex + 1 : 0);
  };

  const handlePrev = () => {
    setCurrentImageIndex(prevIndex => prevIndex - 1 >= 0 ? prevIndex - 1 : imagesArray.length - 1);
  };

  const onNewCommentSubmit = async (commentText: string) => {
    if (!commentText.trim()) return;

    const db = getDatabase();
    const currentUserUid = auth.currentUser.uid;

    const volunteerRef = ref(db, `volunteers/${currentUserUid}`);
    const volunteerSnapshot = await get(volunteerRef);

    let name = 'Anonymous';
    let profileURL = '';

    if (volunteerSnapshot.exists()) {
      const volunteerData = volunteerSnapshot.val();
      name = `${volunteerData.firstName || ''} ${volunteerData.lastName || ''}`;
      profileURL = volunteerData.profileURL || '';
    }

    const newComment = {
      id: `comment_${Date.now()}`,
      authorId: currentUserUid,
      profileURL,
      name,
      content: commentText,
      timestamp: Date.now(),
    };

    const commentsRef = ref(db, `posts/${data.id}/comments`);
    await push(commentsRef, newComment);

    setCommentText('');
  };
  const hasImages = data.images && data.images.length > 0;


  const imagesArray = Array.isArray(data.images) ? data.images : [data.images];

  const postContent = data.content.length > CONTENT_PREVIEW_LIMIT && !isContentExpanded
    ? `${data.content.substring(0, CONTENT_PREVIEW_LIMIT)}... `
    : data.content;

  const hasTags = data.tags && data.tags.length > 0;

  return (
    <Card extra={"w-full h-full px-[30px] py-[21px] mb-4"}>
       <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-[13px]">
          <div className="h-14 w-14 rounded-full border-[3px] bg-white p-[2px]">
            <img src={authorData.profileURL} className="h-full w-full rounded-full" alt="" />
          </div>
          <div className="mt-px">
            <h5 className="mb-[4px] text-[17px] font-bold text-navy-700 dark:text-white" onClick={() => handleProfileClick(data.authorId)}>
              {authorData.firstName + ` ` + authorData.lastName || "Unknown Author"}
            </h5>
            <p className="text-sm font-medium text-gray-600">
              @{authorData.username || "Unknown Author"}
            </p>
          </div>
        </div>
        {currentUser?.uid === data.authorId && (
          <div className="flex items-center gap-2">
            <button onClick={handleEdit} className="text-navy-700 dark:text-white">
              <MdOutlineEdit />
            </button>
            <button onClick={handleDelete} className="text-red-500">
              <MdOutlineDelete />
            </button>
          </div>
        )}
      </div>

      <div>
        <div className="mt-[44px] h-full w-full rounded-xl sm:h-full">
          <p className="mt-2 w-full text-base text-sm ">
            {postContent}
            {data.content.length > CONTENT_PREVIEW_LIMIT && (
              <span className="text-blue-500 cursor-pointer" onClick={() => setIsContentExpanded(!isContentExpanded)}>
                {isContentExpanded ? 'Read Less' : 'Read More'}
              </span>
            )}
          </p>
          <p className="mt-2 mb-4 w-full text-base text-sm text-gray-600">
            {formatDate(data.timestamp)}
          </p>
          {hasImages ? (
          <>
            {imagesArray.map((image, index) => (
              <div key={index} className="relative">
                <Image
                  src={image}
                  alt={`Slide ${index}`}
                  onClick={() => openLightboxAtIndex(index)}
                  className="w-full max-w-[400px] rounded-xl object-cover mx-auto cursor-pointer mt-2"
                />
                {imagesArray.length > 1 && (
                  <div className="absolute bottom-2 right-2 bg-black bg-opacity-50 text-white text-sm rounded-full px-2 py-1">
                    {index + 1}/{imagesArray.length}
                  </div>
                )}
              </div>
            ))}
            <CustomLightbox
              isOpen={isLightboxOpen}
              onClose={() => setIsLightboxOpen(false)}
              images={imagesArray}
              initialIndex={currentImageIndex}
            
            />
          </>
          ) : null}
        </div>

        {hasTags ? (
          <div className="mt-2 mb-4 w-full text-base text-sm text-gray-600">
            <p>Tags: {data.tags.join(', ')}</p>
          </div>
        ) : (
          <div className="mt-2 mb-4 w-full text-base text-sm text-gray-600">
            <p>No Tags</p>
          </div>
        )}

        <div className="px-3">
          <div className="flex items-center justify-between border-b border-gray-200 py-[30px] dark:border-white/10">
            <div className="flex items-center gap-1 text-base font-medium text-navy-700 dark:text-white">
              <p className="text-lg" onClick={handleLike}>
                <MdOutlineFavoriteBorder />
              </p>
              <p>
                {(data.likes + ` ` || 0).toLocaleString()} <span className="hidden md:inline"> Likes </span>
              </p>
            </div>
            <div className="flex items-center gap-1 text-base font-medium text-navy-700 dark:text-white">
              <p className="text-lg">
                <FaRegCommentDots />
              </p>
              <span>{comments.length} {comments.length === 1 ? '' : ''}</span>
            </div>
          </div>

          <div className="mt-2 w-full text-base text-sm">
            {data.comments && data.comments.length > 0 ? (
              data.comments.slice(0, 4).map((comment) => {
                const commentAuthorData = usersData[comment.authorId];
                return (
                  <div className="mt-6 flex w-full items-start gap-3" key={comment.id}>
                    <div className="h-[50px] w-[53px] flex-shrink-0 rounded-full mt-4">
                      <Image
                        src={commentAuthorData?.profileURL || 'default_profile_pic_url'}
                        alt={commentAuthorData?.firstName || 'User'}
                        className="h-full w-full rounded-full "
                        onClick={() => handleProfileClick(commentAuthorData.id)}
                      />
                    </div>
                    <div className="flex flex-grow flex-col">
                      <p className="text-navy-700 dark:text-white">
                        <span className="font-bold" onClick={() => handleProfileClick(commentAuthorData?.id)}>
                          {commentAuthorData?.firstName || ''} {commentAuthorData?.lastName || ''}{" "}
                        </span>
                        {comment.content}
                      </p>
                      <p className="comment-timestamp">
                        {formatDate(comment.timestamp)}
                      </p>
                      <p className="mt-1 text-sm font-medium text-navy-700 hover:cursor-pointer dark:text-white">
                        Reply
                      </p>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-gray-600">No comments yet</p>
            )}

            {data.comments && data.comments.length > 4 && (
              <p className="text-blue-500 cursor-pointer text-center" onClick={() => setIsModalOpen(true)}>More</p>
            )}
          </div>

          <form onSubmit={handleCommentSubmit}>
            <div className="mt-6 flex w-full justify-between gap-3">
              <div className="h-[50px] w-[53px] rounded-full">
                <Image src={currentUserProfile} className="h-full w-full rounded-full" alt="" />
              </div>
              <div className="flex h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3 dark:border dark:!border-white/10 dark:!bg-navy-800">
                <Textarea
                  placeholder="Write your comment...."
                  size="md"
                  p={{ base: "2", md: "4" }}
                  fontSize={{ base: "sm", md: "md" }}
                  borderRadius="full"
                  border="none"
                  _focus={{ boxShadow: "none" }}
                  resize="none"
                  minH="unset"
                  _placeholder={{ color: "navy.700", opacity: 1 }}
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                />
                <div className="flex items-center ">
                  <button className="border-gray-600 dark:!border-none dark:bg-white/5" type="submit">
                    <FiSend />
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <CommentsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        comments={data.comments}
        postData={data}
        onCommentSubmit={onNewCommentSubmit}
        usersData={usersData}
      />
      {selectedProfile && (
        <ProfileOverviewModal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          profile={selectedProfile}
          usersData={usersData}
        />
      )}
    </Card>
  );
};

export default Post;
