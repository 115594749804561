// import DailyTraffic from "./components/DailyTraffic";
import Synthesis from "./components/Synthesis";
import OverallRevenue from "./components/OverallRevenue";
import ProfitEstimation from "./components/ProfitEstimation";
import ProjectStatus from "./components/ProjectStatus";
import LineAreaChart from "./components/LineAreaChart";
import tableDataMostVisited from "./variables/tableDataMostVisited";
import YourTransaction from "views/admin/main/account/billing/components/YourTransaction";

const Analytics = () => {
  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        {/* left side */}
        <div className="mt-5 grid w-full gap-5">

          <div className="col-span-6 3xl:col-span-4">
           
            <Synthesis />
          </div>
          <div>

          </div>
        </div>
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
          {/* overall & Balance */}
          <div className="mt-5 grid w-full grid-cols-6 gap-5">
            <div className="col-span-6 md:col-span-3 3xl:col-span-2">
              {/* <DailyTraffic /> */}
            </div>
            <div className="col-span-6 md:col-span-3 3xl:col-span-2">
              <ProjectStatus />
            </div>
            <div className="col-span-6 3xl:col-span-2">
              <ProfitEstimation />
            </div>
          </div>
          <br />
          <div className="mb-5 grid grid-cols-6 gap-5">
            <div className="col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 h-full w-half rounded-xl">
              <OverallRevenue />
            </div>
            <div className="col-span-6 md:col-span-6 lg:col-span-6 xl:col-span-6 h-full w-half">
              <LineAreaChart />
            </div>
          </div>
          {/* Daily Traffic and.. */}
          {/* Your Transfers & tables */}
        </div>
      </div>
      {/* line */}
      {/* right section */}
    </div>
  );
};

export default Analytics;