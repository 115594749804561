import React from 'react';
import { BiLock } from 'react-icons/bi';

const ProfileFilter = ({ onFilterChange, activeSetting, hasBusiness, isSupportEmail }) => {
  return (
    <div className="flex w-full py-3">
      <button
        onClick={() => onFilterChange('Account')}
        className={`linear flex items-center justify-center rounded-full border px-4 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-50 dark:border-none dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20 md:mr-[20px] ${
          activeSetting === 'Account' ? 'border-gray-600 dark:!border-none dark:bg-white/5' : '!border-none'
        }`}
      >
        Account
      </button>
      <button
        onClick={() => (hasBusiness ? onFilterChange('Business') : null)}
        className={`linear flex items-center justify-center rounded-full border px-4 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-50 dark:border-none dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20 md:mr-[20px] ${
          activeSetting === 'Business' && hasBusiness ? 'border-gray-200 dark:!border-none dark:bg-white/5' : '!border-none'
        }`}
        disabled={!hasBusiness}
      >
        {hasBusiness ? 'Business' : <><BiLock aria-hidden="true" />Business</>}
      </button>
      {isSupportEmail && (
        <button
          onClick={() => onFilterChange('BusinessNodeUpdate')}
          className={`linear flex items-center justify-center rounded-full border px-4 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-50 dark:border-none dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20 md:mr-[20px] ${
            activeSetting === 'BusinessNodeUpdate' ? 'border-gray-600 dark:!border-none dark:bg-white/5' : '!border-none'
          }`}
        >
          Business Node Update
        </button>
      )}
    </div>
  );
};

export default ProfileFilter;
