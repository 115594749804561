import Card from "components/card";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import { useState, useEffect } from "react";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";

type TeamAndVolunteers = {
  teamContact: string;
  teamLeader: string;
  team: string;
  volunteerRequirements: string;
};

type MissionLogistics = {
  missionPartnerships: string;
  missionResources: string;
};

type RowObj = {
  missionDetails: {
    missionName: string;
    location: string;
    missionCategory: string;
    missionDescription: string;
    missionGoals: string;
    teamAndVolunteers: TeamAndVolunteers;
    missionLogistics: MissionLogistics;
  };
};

type InformationProps = {
  missionName: string;
  location: string;
  missionCategory: string;
  missionDescription: string;
  missionGoals: string;
  teamAndVolunteers: TeamAndVolunteers;
  missionLogistics: MissionLogistics;
  onUpdateMission: (updatedMission: Partial<RowObj['missionDetails']>) => void;
};

const Information = ({
  missionName,
  location,
  missionCategory,
  missionDescription,
  missionGoals,
  teamAndVolunteers,
  missionLogistics,
  onUpdateMission
}: InformationProps) => {
  const [updatedMission, setUpdatedMission] = useState({
    missionName,
    location,
    missionCategory,
    missionDescription,
    missionGoals,
    teamContact: teamAndVolunteers.teamContact,
    teamLeader: teamAndVolunteers.teamLeader,
    team: teamAndVolunteers.team,
    volunteerRequirements: teamAndVolunteers.volunteerRequirements,
    missionPartnerships: missionLogistics.missionPartnerships,
    missionResources: missionLogistics.missionResources,
  });

  useEffect(() => {
    setUpdatedMission({
      missionName,
      location,
      missionCategory,
      missionDescription,
      missionGoals,
      teamContact: teamAndVolunteers.teamContact,
      teamLeader: teamAndVolunteers.teamLeader,
      team: teamAndVolunteers.team,
      volunteerRequirements: teamAndVolunteers.volunteerRequirements,
      missionPartnerships: missionLogistics.missionPartnerships,
      missionResources: missionLogistics.missionResources,
    });
  }, [missionName, location, missionCategory, missionDescription, missionGoals, teamAndVolunteers, missionLogistics]);

  const handleInputChange = (event: { target: { id: any; value: any; name: any; }; }) => {
    const { id, name, value } = event.target;
    setUpdatedMission((prev) => ({
      ...prev,
      [name || id]: value,
    }));
  };

  const handleEditMission = () => {
    onUpdateMission({
      missionName: updatedMission.missionName,
      location: updatedMission.location,
      missionCategory: updatedMission.missionCategory,
      missionDescription: updatedMission.missionDescription,
      missionGoals: updatedMission.missionGoals,
      teamAndVolunteers: {
        teamContact: updatedMission.teamContact,
        teamLeader: updatedMission.teamLeader,
        team: updatedMission.team,
        volunteerRequirements: updatedMission.volunteerRequirements,
      },
      missionLogistics: {
        missionPartnerships: updatedMission.missionPartnerships,
        missionResources: updatedMission.missionResources,
      },
    });
  };

  return (
    <Card extra={"w-full px-[20px] py-[22px] h-fit"}>
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Mission Information
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change your mission information
        </p>
      </div>
      <div className="mt-9 grid grid-cols-2 gap-3">
        <div className="col-span-2 md:col-span-1">
          <InputField
            extra="mb-3"
            label="Mission Name"
            placeholder={missionName}
            type="text"
            id="missionName"
            value={updatedMission.missionName}
            onChange={handleInputChange}
          />
          <InputField
            extra="mb-3"
            label="Location"
            placeholder={location}
            id="location"

            type="text"
            value={updatedMission.location}
            onChange={handleInputChange}
          />
          <InputField
            label="Mission Goals"
            placeholder="e.g., purple"
            id="missionGoals"

            type="text"
            value={updatedMission.missionGoals}
            onChange={handleInputChange}
          />
        </div>
        <div className="col-span-2 md:col-span-1">
          <FormControl id="missionCategory" mb={4}>
            <FormLabel>Mission Category</FormLabel>
            <Select
              name="missionCategory"
              value={updatedMission.missionCategory}
              onChange={handleInputChange}
              placeholder="Select Mission Category"
              bgColor="F7FAFC"
            >
              <option value="Peer-to-peer">Peer-to-peer fundraising</option>
              <option value="Charity">Charity auctions</option>
              <option value="Donation">Donation kiosks</option>
              <option value="Online">Online donations</option>
              <option value="Crowdfunding">Crowdfunding campaign</option>
              <option value="Talent">Talent show</option>
              <option value="HappyHour">Happy hour</option>
              <option value="Networking">Networking dinner</option>
              <option value="Fashion">Fashion show</option>
              <option value="Social">Social Event</option>
            </Select>
          </FormControl>
          <TextField
            id="missionDescription"
        
            cols={30}
            rows={6}
            placeholder="Mission Description"
            extra={updatedMission.missionDescription}
            onChange={handleInputChange}
            label="Mission Description"
          />
        </div>
      </div>
      <Card extra="gap-4 rounded-[20px] px-[28px] py-[50px]">
        <div className="ml-auto flex flex-col items-center gap-4 md:flex-row">
          <button className="rounded-xl border border-red-500 bg-none px-10 py-2 text-base font-medium text-red-500 hover:bg-red-50">
            Delete Product
          </button>
          <button
            onClick={handleEditMission}
            className="linear rounded-xl bg-brand-500 px-10 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Save Changes
          </button>
        </div>
      </Card>
    </Card>
  );
};

export default Information;
