import React, { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs, addDoc, doc, getDoc, Timestamp } from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAuth } from 'firebase/auth';
import { Button, FormControl, FormLabel, Select, Switch, Input, Stack, Flex, ListItem, List, Modal, ModalBody, ModalHeader, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, Radio, RadioGroup, Text, FormHelperText } from '@chakra-ui/react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css'; // Make sure to import the calendar CSS
import InputField from "components/fields/InputField";
import TagsField from 'components/fields/TagsField';
import FormBuilderModal from './FormBuilderModal';
import { getDatabase, onValue, ref as dbRef } from 'firebase/database';

interface FormState {
  missionName: string;
  authorId: string;
  location: string;
  missionCategory: string;
  conservationType: string;
  missionDescription: string;
  roles: any[];
  formFields: any[];
  isOnlineMission: boolean;
  isTimeCommitmentRequired: boolean;
  urgencyLevel: string;
  startDate: Date;
  endDate: Date;
  onlineUrl: string;
  skills: any[];
  files?: File[];
  groups: string;
  participantsMetric: number;
  imageUrl?: string;
  fileUrls?: string[]; // Assuming you'll store the URLs of uploaded files here
}

interface CreateMissionButtonProps {
  onOpenModal: () => void; // Specify that onOpenModal is a function
}

const useMapboxAutocomplete = (query: string | any[]) => {
  const [suggestions, setSuggestions] = useState([]);
  const MAPBOX_TOKEN = "pk.eyJ1Ijoic2ltbW1wbGUiLCJhIjoiY2wxeG1hd24xMDEzYzNrbWs5emFkdm16ZiJ9.q9s0sSKQFFaT9fyrC-7--g";

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (query.length > 2) {
        const response =
          await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?access_token=${MAPBOX_TOKEN}&limit=5`);
        const data = await response.json();
        setSuggestions(data.features);
      } else {
        setSuggestions([]);
      }
    };

    fetchSuggestions();
  }, [query]);

  return { suggestions, setSuggestions };
};

const CreateMissionButton = ({ onOpenModal }) => {
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [locationQuery, setLocationQuery] = useState('');
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState('');
  const storage = getStorage();
  const firestore = getFirestore();
  const auth = getAuth();
  const today = new Date().toISOString().split("T")[0];
  const { suggestions, setSuggestions } = useMapboxAutocomplete(locationQuery);
  const openPreview = () => setIsPreviewOpen(true);
  const closePreview = () => setIsPreviewOpen(false);
  const [teams, setTeams] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState('');
  const [roles, setRoles] = useState([
    { name: "Coordinator", id: 1 },
    { name: "Volunteer", id: 2 },
  ]);
  const [skills, setSkills] = useState([
    { name: "Coding", id: 1 },
    { name: "Excel", id: 2 },
  ]);
  const db = getDatabase();
  const [formState, setFormState] = useState({
    missionName: '',
    location: '',
    authorId: '',
    missionCategory: '',
    conservationType: '',
    missionDescription: '',
    roles: [],
    formFields: [{ label: 'Field Label', type: 'text', value: '', required: false }],
    isOnlineMission: false,
    isTimeCommitmentRequired: false,
    urgencyLevel: '',
    startDate: new Date(), // If using a date picker
    endDate: new Date(), // Add endDate here
    onlineUrl: '',
    skills: [],
    files: [],
    groups: '',
    participantsMetric: 0,
    business: '',
    businessId: '', // Add businessId
    isBusinessMission: false // Add isBusinessMission flag
  });

  useEffect(() => {
    const fetchTeamsAndBusinesses = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user is logged in");
        return;
      }

      const db = getDatabase();

      // Fetch teams
      const teamRef = dbRef(db, 'businesses/byName/HarmonyFlow/team');
      onValue(teamRef, (snapshot) => {
        const data = snapshot.val();
        const userTeams = [];
        for (const key in data) {
          if (data[key].userId === user.uid) {
            userTeams.push({ id: key, ...data[key] });
          }
        }
        setTeams(userTeams);
        // Optionally, set the first team as the default selected team
        if (userTeams.length > 0) {
          setSelectedTeam(userTeams[0].id);
        }
      });

      // Fetch businesses
      const businessesRef = dbRef(db, 'businesses/byName');
      onValue(businessesRef, (snapshot) => {
        const data = snapshot.val();
        const businessesList = [];
        for (const key in data) {
          businessesList.push({ id: key, name: data[key].info.name });
        }
        setBusinesses(businessesList);
      });
    };

    fetchTeamsAndBusinesses();
  }, [auth]);

  useEffect(() => {
    const fetchGroups = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user is logged in");
        return;
      }
      const userGroupsQuerySnapshot = await getDocs(collection(firestore, 'groups'));
      const userGroups = [];

      for (const groupDoc of userGroupsQuerySnapshot.docs) {
        const memberId = user.uid; // Current user's ID
        const memberDocSnapshot = await getDoc(doc(firestore, `groups/${groupDoc.id}/members/${memberId}`));
        if (memberDocSnapshot.exists()) {
          // The user is a member of this group
          userGroups.push({ id: groupDoc.id, ...groupDoc.data() });
        }
      }

      setGroups(userGroups);
      // Optionally, set the first group as the default selected group
      if (userGroups.length > 0) {
        setSelectedGroupId(userGroups[0].id);
      }
    };

    fetchGroups();
  }, [firestore, auth]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setFormState(prevState => ({
        ...prevState,
        authorId: user.uid, // Set the user's UID as the authorId
      }));
    } else {
      console.error("No user is logged in");
      // Handle the case when no user is logged in or redirect to login
    }
  }, [auth]);

  const handleInputChange = (field, value) => {
    setFormState({ ...formState, [field]: value });
  };

  const handleFormFieldsChange = (fields) => {
    setFormState(prevState => ({ ...prevState, formFields: fields }));
  };

  const handleLocationChange = (e) => {
    setLocationQuery(e.target.value);
  };

  const selectLocation = (place_name) => {
    setLocationQuery(place_name);
    setFormState(prevState => ({
      ...prevState,
      location: place_name // Update the location in formState
    }));
    setSuggestions([]); // Clear suggestions after selection
  };

  async function uploadFiles(files) {
    const urls = await Promise.all(files.map(async (file) => {
      const storageRef1 = storageRef(storage, `path/to/your/files/${file.name}`);
      const uploadResult = await uploadBytes(storageRef1, file);
      return getDownloadURL(uploadResult.ref);
    }));
    return urls;
  }

  const handleOnlineToggle = (e) => {
    setFormState(prevState => ({
      ...prevState,
      isOnlineMission: e.target.checked
    }));
  };

  const handleTimeCommitmentToggle = (e) => {
    setFormState(prevState => ({
      ...prevState,
      isTimeCommitmentRequired: e.target.checked
    }));
  };

  const handleCategoryChange = (e) => {
    handleInputChange("missionCategory", e.target.value);
    // Additional logic for showing/hiding fields
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { files, startDate, endDate, roles, skills, ...restOfFormState } = formState;

    // Convert startDate and endDate to Firestore Timestamp
    const formattedStartDate = Timestamp.fromDate(new Date(startDate));
    const formattedEndDate = Timestamp.fromDate(new Date(endDate));

    let fileUrls = [];
    if (files?.length > 0) {
      // Upload files and get their URLs
      fileUrls = await uploadFiles(files);
    }

    const rolesNames = roles.map(role => role.name);
    const skillsNames = skills.map(skill => skill.name);

    // Determine the authorId based on the selected business
    const authorId = formState.isBusinessMission ? formState.businessId : auth.currentUser.uid;

    // Prepare your document data, excluding the files array and including the file URLs
    const documentData = {
      ...restOfFormState,
      roles: rolesNames,
      skills: skillsNames,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      fileUrls,
      authorId,  // Set the authorId
      business: selectedBusiness  // Include the selected business
    };

    try {
      // Save the document to Firestore
      const docRef = await addDoc(collection(firestore, "missions"), documentData);
      console.log("Document written with ID: ", docRef.id);
      // Handle successful submission here (e.g., show a success message)
    } catch (error) {
      console.error("Error adding document: ", error);
      // Handle the error here (e.g., show an error message)
    }
  };

  const nextPage = () => setCurrentPage(current => current < 3 ? current + 1 : current);
  const prevPage = () => setCurrentPage(current => current > 1 ? current - 1 : current);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <Flex direction="column" align="center" justify="center" p={4}>
      <form onSubmit={handleSubmit}>
        {currentPage === 1 && (
          <>
            <h3 className='mb-4' style={{ textAlign: 'center', fontSize: '1.25rem' }}>General Mission Info 📋 </h3>
            <InputField
              extra="mb-4"
              label="Mission Name"
              placeholder="eg. Charity Event"
              value={formState.missionName}
              onChange={(e) => handleInputChange("missionName", e.target.value)}
              id={'missionName'}
            />
            <InputField
              extra="mb-4"
              label="Goal"
              placeholder="Brief description of the mission"
              value={formState.missionDescription}
              onChange={(e) => handleInputChange("missionDescription", e.target.value)}
              id={'missionDescription'}
            />
            <FormControl mb={4}>
              <FormLabel>Mission Category</FormLabel>
              <Select
                value={formState.missionCategory}
                onChange={(e) => handleInputChange("missionCategory", e.target.value)}
                placeholder="Select Mission Category"
              >
                <option value="Environmental">Environmental Conservation</option>
                <option value="Education">Education and Literacy</option>
                <option value="Food Security">Food Security</option>
                <option value="Health & Wellness">Health and Wellness</option>
                <option value="Arts & Culture">Arts and Culture</option>
                <option value="Community & Development">Community Development</option>
                <option value="Youth Programs">Youth Programs</option>
                <option value="Senior & Support">Senior Support</option>
                <option value="Animal & Welfare">Animal Welfare</option>
                <option value="Emergency Response">Emergency Response and Preparedness</option>
              </Select>
            </FormControl>
            {formState.missionCategory === "Environmental" && (
              <FormControl mb={4}>
                <FormLabel>Conservation Type</FormLabel>
                <Select placeholder="Select type" onChange={(e) => handleInputChange("conservationType", e.target.value)}>
                  <option value="Land">Land</option>
                  <option value="Air">Air</option>
                  <option value="Water">Water</option>
                </Select>
              </FormControl>
            )}
            <FormControl mb={4}>
              <FormLabel>Team</FormLabel>
              <Select
                placeholder="Select Team"
                value={selectedTeam}
                onChange={(e) => setSelectedTeam(e.target.value)}
              >
                {teams.map(team => (
                  <option key={team.id} value={team.id}>{team.username}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl mb={4}>
              <FormLabel>Request to Partner</FormLabel>
              <Select
                placeholder="Select Business"
                value={formState.businessId}
                onChange={(e) => {
                  setFormState({
                    ...formState,
                    businessId: e.target.value,
                    isBusinessMission: !!e.target.value // Set flag based on business selection
                  });
                }}
              >
                {businesses.map(business => (
                  <option key={business.id} value={business.id}>{business.name}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl display="flex" alignItems="center" mb={4}>
              <FormLabel htmlFor="online-toggle" mb="0">
                Online Mission
              </FormLabel>
              <Switch id="online-toggle" onChange={handleOnlineToggle} />
            </FormControl>
            {!formState.isOnlineMission && (
              <InputField
                extra="mb-4"
                label="Location"
                placeholder="Start typing..."
                value={locationQuery}
                onChange={handleLocationChange}
                id={'location'}
              />
            )}
            {formState.isOnlineMission && (
              <InputField
                label="URL"
                extra="mb-4"
                placeholder="Enter online meeting URL"
                value={formState.onlineUrl}
                onChange={(e) => handleInputChange("onlineUrl", e.target.value)}
                id="onlineUrl"
              />
            )}
            {locationQuery && suggestions.length > 0 && (
              <List spacing={2} mt={2} boxShadow="md" maxH="200px" overflowY="auto" bg="white">
                {suggestions.map((suggestion) => (
                  <ListItem key={suggestion.id} p={2} cursor="pointer" _hover={{ bg: "gray.100" }} onClick={() => selectLocation(suggestion.place_name)}>
                    {suggestion.place_name}
                  </ListItem>
                ))}
              </List>
            )}
            <Button className='linear rounded-xl bg-green-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-green-600 active:bg-green-700 dark:bg-green-400 dark:text-white dark:hover:bg-green-300 dark:active:bg-green-200' onClick={nextPage}>Next</Button>
          </>
        )}
        {currentPage === 2 && (
          <>
            <h3 className='mb-4' style={{ textAlign: 'center', fontSize: '1.25rem' }}>Mission Design 🎨</h3>
            <TagsField
              label="Add Roles"
              placeholder="Type a role and press Enter"
              tags={roles}
              setTags={setRoles}
            />
            <TagsField
              label="What skills do you need?"
              placeholder="Type a skill and press Enter"
              tags={skills}
              setTags={setSkills}
            />
            <Select
              className='mt-5'
              placeholder="Select Mission Status"
              onChange={(e) => handleInputChange("status", e.target.value)}
            >
              <option value="Planning">Planning 📅</option>
              <option value="Active">Active ⚡</option>
              <option value="Upcoming">On Hold 🚀</option>
              {/* <option value="Cancelled">Cancelled</option> */}
            </Select>
            <Select value={formState.groups} onChange={(e) => handleInputChange("groups", e.target.value)} className='mt-6 mb-4' placeholder='Add to your Group'>
              {groups.map(group => (
                <option key={group.id} value={group.id}>{group.title}</option> // Assuming each group has a 'name' field
              ))}
            </Select>
            <FormControl display="flex" alignItems="center" mb={4}>
              <FormLabel htmlFor="time-commitment-toggle" mb="0">
                Time Commitment Required
              </FormLabel>
              <Switch id="time-commitment-toggle" onChange={handleTimeCommitmentToggle} />
            </FormControl>
            {formState.isTimeCommitmentRequired && (
              <>
                <FormLabel htmlFor="start-date" mb="0">
                  Start date
                </FormLabel>
                <Calendar
                  onChange={(date) => handleInputChange('startDate', date)}
                  value={formState.startDate}
                />
                <FormLabel htmlFor="end-date" mb="0">
                  End date
                </FormLabel>
                <Calendar
                  onChange={(date) => handleInputChange('endDate', date)}
                  value={formState.endDate}
                />
              </>
            )}
            <FormControl>
              <FormLabel as="legend">Urgency Level</FormLabel>
              <RadioGroup onChange={(val) => handleInputChange("urgencyLevel", val)} value={formState.urgencyLevel} className='mb-4' aria-label='Urgency Level'>
                <Stack direction="row">
                  <Radio value="high">High</Radio>
                  <Radio value="medium">Medium</Radio>
                  <Radio value="low">Low</Radio>
                </Stack>
                <FormHelperText>The priority level of a mission may change over time due to shifts in strategic focus, resource availability, or external factors affecting the mission's urgency.</FormHelperText>
              </RadioGroup>
            </FormControl>
            <Stack direction="row" spacing={4} align="center">
              <Button className='linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200' onClick={prevPage}>Back</Button>
              <Button className='linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200' onClick={nextPage}>Next</Button>
            </Stack>
          </>
        )}
        {currentPage === 3 && (
          <>
            <h3 className='mb-4' style={{ textAlign: 'center', fontSize: '1.25rem' }}>Mission Planning Hub 🗺️</h3>
            <InputField
              label="Number of Participants"
              placeholder="Number of Participants"
              onChange={(e) => handleInputChange("participantsMetric", e.target.value)}
              id={'participantsMetric'}
              type="number"
            />
            <InputField
              label="Activities Planned"
              placeholder="Activities Planned"
              onChange={(e) => handleInputChange("activitiesPlanned", e.target.value)}
              id={'activitiesPlanned'}
              type="number"
            />
            <div className='mt-4'>
              <FormBuilderModal formFields={formState.formFields} setFormFields={handleFormFieldsChange} />
            </div>
            <Stack direction="row" spacing={4} marginTop={4} align="center">
              <Button className='linear rounded-xl border border-navy-700 px-5 py-3 text-base font-medium text-navy-700 transition duration-200 hover:bg-navy-800/5 active:bg-navy-900/5 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20' onClick={prevPage}>Back</Button>
              <Button className='linear rounded-xl border border-green-500 px-5 py-3 text-base font-medium text-green-500 transition duration-200 hover:bg-green-600/5 active:bg-green-700/5 dark:border-green-400 dark:bg-green-400/10 dark:text-white dark:hover:bg-green-300/10 dark:active:bg-green-200/10' type="submit">Submit</Button>
              <Button className='linear rounded-xl border border-gray-200 px-5 py-3 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-200/10 active:bg-gray-300/10 dark:border-white/20 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20' onClick={openPreview}>Preview</Button>
            </Stack>
          </>
        )}
      </form>
      {isPreviewOpen && (
        <Modal isOpen={isPreviewOpen} onClose={closePreview}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Impact Preview</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <p><strong>Mission Name:</strong> {formState.missionName}</p>
              <p><strong>Mission Description:</strong> {formState.missionDescription}</p>
              <p><strong>Location:</strong> {formState.location}</p>
              <p><strong>Category:</strong> {formState.missionCategory}</p>
              {/* Other fields */}
              <p><strong>Roles:</strong></p>
              <ul>
                {roles.map(role => (
                  <li key={role.id}>{role.name}</li>
                ))}
              </ul>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={closePreview}>Edit</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Flex>
  );
};

export default CreateMissionButton;
