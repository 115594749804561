import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Text, Avatar, Tooltip, VStack, Badge, Wrap, Tag, Flex, useToast, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import { ref, getDatabase, get } from 'firebase/database';
import { doc, updateDoc, getFirestore, getDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { AuthContext } from 'contexts/AuthContext';
import { FaCheck, FaTimes } from 'react-icons/fa';

interface Volunteer {
  aboutMe: string;
  bannerURL: string;
  contributeCode: string;
  firstName: string;
  interests: { id: number; name: string }[];
  skills: { id: number; name: string }[];
  username: string;
  job: string;
  lastName: string;
  profileURL: string;
  businessName: string;
}

interface RequestManagementProps {
  missionId: string; // Assuming missionId is a string
  authorId: string; // ID of the user who created the mission
}

type VolunteersState = Record<string, Volunteer>; // A Record type for volunteer state

const RequestManagement: React.FC<RequestManagementProps> = ({ missionId, authorId }) => {
  const [requests, setRequests] = useState<string[]>([]);
  const [volunteers, setVolunteers] = useState<Record<string, Volunteer>>({});
  const { currentUser } = useContext(AuthContext);
  const toast = useToast();
  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');

  useEffect(() => {
    if (currentUser?.uid === authorId) {
      const fetchRequests = async () => {
        const firestore = getFirestore();
        const missionRef = doc(firestore, "missions", missionId);
        const missionSnap = await getDoc(missionRef);

        if (missionSnap.exists()) {
          const missionData = missionSnap.data();
          setRequests(missionData.participants.pending || []);
        } else {
          toast({
            title: 'Mission not found',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        }
      };

      fetchRequests();
    }
  }, [missionId, authorId, currentUser?.uid, toast]);

  useEffect(() => {
    const fetchVolunteerDetails = async () => {
      const db = getDatabase();
      const volunteersTemp: Record<string, Volunteer> = {};
      for (let uid of requests) {
        const volunteerRef = ref(db, `volunteers/${uid}`);
        const snapshot = await get(volunteerRef);
        if (snapshot.exists()) {
          volunteersTemp[uid] = snapshot.val();
        }
      }
      setVolunteers(volunteersTemp);
    };

    if (requests.length > 0) {
      fetchVolunteerDetails();
    }
  }, [requests]);

  const handleAccept = async (uid: string) => {
    const firestore = getFirestore();
    const missionRef = doc(firestore, "missions", missionId);
    await updateDoc(missionRef, {
      'participants.confirmed': arrayUnion(uid),
      'participants.pending': arrayRemove(uid),
    });
    setRequests(current => current.filter(request => request !== uid));
  };

  const handleDecline = async (uid: string) => {
    const firestore = getFirestore();
    const missionRef = doc(firestore, "missions", missionId);
    await updateDoc(missionRef, {
      'participants.pending': arrayRemove(uid),
    });
    setRequests(current => current.filter(request => request !== uid));
  };

  const flexDir = useBreakpointValue({ base: 'column', md: 'row' });
  const avatarSize = useBreakpointValue({ base: 'xs', md: 'sm' });
  const textSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const buttonSize = useBreakpointValue({ base: 'xs', md: 'sm' });

  return (
    <VStack spacing={4} align="stretch">
      {requests.length > 0 ? requests.map(uid => (
        <Box key={uid} bg={bgColor} p={4} shadow="md" borderRadius="lg" display="flex" flexDirection="column" justifyContent="space-between" minHeight="250px">
          <VStack spacing={4}>
            <Flex align="center">
              <Avatar src={volunteers[uid]?.profileURL} size={avatarSize} name={`${volunteers[uid]?.firstName} ${volunteers[uid]?.lastName}`} mr={3} />
              <Box>
                <Text fontWeight="bold">{volunteers[uid]?.firstName} {volunteers[uid]?.lastName}</Text>
                <Text fontSize="sm">@{volunteers[uid]?.username}</Text>
                <Badge colorScheme="blue" mt={1}>{volunteers[uid]?.job}</Badge>
              </Box>
            </Flex>
            <Box>
              <Text fontWeight="semibold">Interests:</Text>
              <Wrap>
                {volunteers[uid]?.interests && Array.isArray(volunteers[uid]?.interests) ? (
                  volunteers[uid].interests.map((interest, index) => (
                    <Tag key={index} colorScheme="purple" m={1}>{interest.name}</Tag>
                  ))
                ) : (
                  <Text>No interests available</Text>
                )}
              </Wrap>
            </Box>
            <Box>
              <Text fontWeight="semibold">Skills:</Text>
              <Wrap>
                {volunteers[uid]?.skills && Array.isArray(volunteers[uid]?.skills) ? (
                  volunteers[uid].skills.map((skill, index) => (
                    <Tag key={index} colorScheme="green" m={1}>{skill.name}</Tag>
                  ))
                ) : (
                  <Text>No skills available</Text>
                )}
              </Wrap>
            </Box>
          </VStack>
          <Flex pt={4} justifyContent="center">
            <Tooltip label="Accept Request" hasArrow>
              <Button colorScheme="green" onClick={() => handleAccept(uid)} leftIcon={<FaCheck />} mr={2} size={buttonSize}>Accept</Button>
            </Tooltip>
            <Tooltip label="Decline Request" hasArrow>
              <Button colorScheme="red" onClick={() => handleDecline(uid)} leftIcon={<FaTimes />} size={buttonSize}>Decline</Button>
            </Tooltip>
          </Flex>
        </Box>
      )) : <Text>No pending requests.</Text>}
    </VStack>
  );
};

export default RequestManagement;
