import { action } from "@material-tailwind/react/types/components/chip";

function Chat(props: {
  photo: string;
  active: boolean;
  name: string;
  time: string;
  message: string;
  last?: boolean;
  senderId: string;
  id: string;
  participants: string;
  onClick?: () => void; // Add onClick prop
}) {
  const {id, photo, active, name, time, message, participants, last, senderId, onClick } = props;

  return (
    <div
      className={`flex w-full items-center gap-3 px-2 ${
        last ? "pt-6 pb-3" : "border-b border-gray-100 py-6"
      }  cursor-pointer dark:!border-navy-700 lg:cursor-pointer`}
      onClick={onClick}
    >
      <div className="relative h-[50px] !w-[60px] rounded-full">
        <img className="h-full w-full rounded-full" src={photo} alt="" />
       
      </div>

      {/*  */}
      <div className="w-full">
        <div className="flex w-full items-center justify-between">
          <h1 className="text-xl font-bold capitalize text-navy-700 dark:text-white">
            {name} 
          </h1>
          <h4 className="text-sm font-medium text-gray-400 ">{time} </h4>
        </div>
        <p className="text-sm font-normal tracking-normal text-navy-700 dark:text-white">
          {message}
        </p>
      </div>
    </div>
  );
}

export default Chat;
