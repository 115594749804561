import React, { useState, useEffect } from 'react';
import {
  VStack,
  SimpleGrid,
  Input,
  Button,
  useToast,
  IconButton,
  FormControl,
  FormLabel,
  GridItem,
  HStack,
} from '@chakra-ui/react';
import { MdEdit, MdCheck, MdDelete, MdSave } from 'react-icons/md';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';

const TaskForm = ({ missionId }) => {
  const [formFields, setFormFields] = useState([]);
  const firestore = getFirestore();
  const missionRef = doc(firestore, "missions", missionId);
  const toast = useToast();

  useEffect(() => {
    const getMission = async () => {
      const missionSnapshot = await getDoc(missionRef);
      if (missionSnapshot.exists()) {
        const fetchedFormFields = missionSnapshot.data().formFields || [];
        const updatedFormFields = fetchedFormFields.map((field: { completed: any; }) => ({
          completed: field.completed ?? false,
          ...field
        }));
        setFormFields(updatedFormFields);
      }
    };
    getMission();
  }, [missionRef]);

  const handleToggleComplete = (index: number) => {
    const updatedFormFields = [...formFields];
    updatedFormFields[index].completed = !updatedFormFields[index].completed;
    setFormFields(updatedFormFields);
  };

  const handleFieldChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    // Create a new array with all items but replacing the item at index
    const updatedFormFields = formFields.map((item, i) => {
      if (i === index) {
        return { ...item, [event.target.name]: event.target.value };
      }
      return item;
    });
  
    setFormFields(updatedFormFields);
  };
  

  const handleSaveFields = async () => {
    await updateDoc(missionRef, { formFields });
    toast({
      title: "Fields Saved",
      description: "The mission's form fields have been updated.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const handleDeleteField = async (index: number) => {
    const values = [...formFields];
    values.splice(index, 1);
    setFormFields(values);
    await updateDoc(missionRef, { formFields: values });
    toast({
      title: "Field Deleted",
      description: "The field has been removed from the form.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });
  };

  return (
    <VStack spacing={4} align="stretch">
      <SimpleGrid columns={[1, 2, 3]} spacing={4}>
        {formFields.map((field, index) => (
          <GridItem key={index}>
            <FormControl >
              <HStack spacing={3}>
                <Input
                  id={`field-${index}`}
                  name="label"
                  value={field.label}
                  onChange={(event) => handleFieldChange(index, event)}
                />
                <IconButton
                  aria-label="Toggle completion"
                  icon={field.completed ? <MdCheck color="green.500" /> : <MdEdit />}
                  onClick={() => handleToggleComplete(index)}
                  size="sm"
                />
                <IconButton
                  aria-label="Delete field"
                  icon={<MdDelete />}
                  onClick={() => handleDeleteField(index)}
                  size="sm"
                  colorScheme="red"
                />
              </HStack>
            </FormControl>
          </GridItem>
        ))}
      </SimpleGrid>
      <Button leftIcon={<MdSave />} colorScheme="blue" onClick={handleSaveFields}>
        Save Fields
      </Button>
    </VStack>
  );
};

export default TaskForm;
