import React, { useState } from 'react';
import { getDatabase, ref, push, set } from 'firebase/database';
import InputField from 'components/fields/InputField';

const WaitlistComponent = () => {
  const db = getDatabase();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [message, setMessage] = useState('');

  function isValidEmail(email) {
    // Simple regex to validate email format
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  }

  function addToWaitlist(e) {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setMessage('Please enter a valid email.');
      return;
    }

    const waitlistRef = ref(db, 'waitlist');
    const newWaitlistRef = push(waitlistRef);
    set(newWaitlistRef, {
      email: email,
      firstName: firstName,
      companyName: companyName,
    })
      .then(() => {
        console.log("Added to waitlist");
        setMessage("Successfully added to the waitlist!");
        setEmail('');
        setFirstName('');
        setCompanyName('');
      })
      .catch((error) => {
        console.error("Error adding to waitlist: ", error);
        setMessage("Error adding to the waitlist. Please try again.");
      });
  }

  return (
    <div className="waitlist-container">
      <form onSubmit={addToWaitlist}>
        <h3 className="text-4xl font-bold text-navy-700 dark:text-white mb-4">
          HarmonyFlow for Businesses
        </h3>
        <h4 className="text-lg font-medium text-gray-800 dark:text-gray-300 mb-4">
          Gain access to the Premium HarmonyFlow Platform
        </h4>
        <InputField
          variant="auth"
          extra="mb-3"
          label="First Name"
          placeholder="First Name..."
          id="firstName"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
          required
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Company Name"
          placeholder="Company Name..."
          id="companyName"
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
        />
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email"
          placeholder="Email..."
          id="email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200" type="submit">Join Waitlist</button>
      </form>
      {message && <p className="mt-4 text-sm text-red-500">{message}</p>}
    </div>
  );
};

export default WaitlistComponent;
