import { SetStateAction, useState } from "react";
type FilterProps = {
  onFilterChange: (newFilter: string) => void;
};

const Filter: React.FC<FilterProps> = ({ onFilterChange }) => {
  const [active, setActive] = useState("Popular");

  const handleFilterClick = (filter: string) => {
    setActive(filter);
    onFilterChange(filter);
  };
  return (
    <div className="mt-9 flex h-[60px] w-full justify-between pl-[19px] pt-[18px]">
      <h4 className="text-xl font-semibold text-navy-700 dark:text-white">
        Feed
      </h4>
      <div className="flex justify-between">
        <button
         onClick={() => handleFilterClick("Latest")}
          className={`linear flex items-center justify-center rounded-full border px-4 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-50 dark:border-none dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20 md:mr-[20px] ${
            active === "Latest"
              ? "border-gray-600 dark:!border-none dark:bg-white/5"
              : "!border-none"
          } `}
        >
          Latest
        </button>

        <button
          onClick={() => handleFilterClick("Popular")}
          className={`linear flex items-center justify-center rounded-full border px-4 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-50 dark:border-none dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20 md:mr-[20px] ${
            active === "Popular"
              ? "border-gray-200 dark:!border-none dark:bg-white/5"
              : "!border-none"
          } `}
        >
          Popular
        </button>
      </div>
    </div>
  );
};

export default Filter;
