import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useToast,
  Box,
  Text,
  VStack,
} from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref, child, get } from 'firebase/database';

interface ChargeResponse {
  success: boolean;
  message?: string;
}

interface ChargeParams {
  amount: string;
  paymentMethodId: string;
  recipientId: string;
  userId: string | undefined;
}

const stripePromise = loadStripe('pk_live_51PDAuJLqk5h9G7zkz1Db5DhgfF5Xx4ehYIdfYMjpvdoujobnjy4e7XX05DZOLkwvWF9Xs82KLShtq7VIDZVsUQUp004rsSREaL');

const DonationModal = ({ isOpen, onClose, recipientId }) => {
  const [amount, setAmount] = useState('');
  const [volunteerName, setVolunteerName] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const auth = getAuth();
  const functions = getFunctions();

  useEffect(() => {
    if (recipientId) {
      const fetchVolunteerName = async () => {
        try {
          const dbRef = ref(getDatabase());
          const snapshot = await get(child(dbRef, `volunteers/${recipientId}`));
          if (snapshot.exists()) {
            const volunteerData = snapshot.val();
            setVolunteerName(volunteerData.firstName);
          } else {
            setVolunteerName('Unknown');
          }
        } catch (error) {
          console.error('Error fetching volunteer data:', error);
          setVolunteerName('Error fetching name');
        }
      };
      fetchVolunteerName();
    }
  }, [recipientId]);

  const handlePayment = async () => {
    if (!stripe || !elements) {
      toast({
        title: 'Error',
        description: "Stripe has not loaded yet. Please try again.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const cardElement = elements.getElement(CardElement);

    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        throw error;
      }

      const createCharge = httpsCallable<ChargeParams, ChargeResponse>(functions, 'createCharge');
      const user = auth.currentUser;

      const chargeResponse = await createCharge({
        amount,
        paymentMethodId: paymentMethod.id,
        recipientId,
        userId: user?.uid,
      });

      if (chargeResponse.data.success) {
        toast({
          title: 'Payment Successful',
          description: "Payment has been sent!",
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        onClose();
      } else {
        throw new Error(chargeResponse.data.message || 'Payment failed');
      }
    } catch (error: any) {
      console.error('Error during payment process:', error);
      toast({
        title: 'Payment Error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box p={4}>
            <VStack spacing={4}>
              <Text fontSize="2xl" fontWeight="bold">Donate to Support</Text>
              <Text fontSize="lg">Ambassador: {volunteerName}</Text>
              <FormControl id="amount" isRequired>
                <FormLabel>Amount</FormLabel>
                <Input
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Enter the amount"
                  type="number"
                />
              </FormControl>
              <Input
                type="hidden"
                value={recipientId}
                readOnly
              />
              <Box width="100%" borderWidth="1px" borderRadius="md" p={4}>
                <CardElement options={{ style: { base: { fontSize: '16px', color: '#424770', '::placeholder': { color: '#aab7c4' } } } }} />
              </Box>
              <Button onClick={handlePayment} disabled={!stripe} width="full">Send Payment</Button>
            </VStack>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const StripeWrapper = ({ children }) => {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default function WrappedPaymentModal(props) {
  return (
    <StripeWrapper>
      <DonationModal {...props} />
    </StripeWrapper>
  );
}