import { ChangeEvent, SetStateAction, forwardRef,Ref, useContext, useEffect, useState } from "react";
import InputField from "components/fields/InputField";
import TextField from "components/fields/TextField";
import {
  FormControl,
  VStack,
} from '@chakra-ui/react';
import { RadioGroup, HStack, Radio } from "@chakra-ui/react";
import { AuthContext } from "contexts/AuthContext";
import auth, { ref, db, storage } from '../../../../../../firebase'
import { push } from "firebase/database";

export interface VolunteerInfo {
  volunteerName: string;
  volunteerAge: number;
  volunteerEmail: string;
  volunteerAddress: string;
  volunteerStatus: string; // e.g., 'Active', 'Inactive'
  volunteerDate: string; // Date of joining or last activity
  volunteerRole: string;
  volunteerMissions: []; // Array of mission or task IDs
  preferredContactMethod: string; // e.g., 'Email', 'Text'
  optInStatus: boolean; // true if opted in for communications
  availabilitySchedule: {
    days: string[]; // e.g., ['Monday', 'Wednesday']
    times: string; // e.g., '9AM - 5PM'
  };
  immediateAvailability: boolean;
  skillSet: string[]; // e.g., ['First Aid', 'Event Planning']
  interestAreas: string[]; // e.g., ['Childcare', 'Environment']
  pastVolunteerExperience: {
    pastRoles: string[];
    pastOrganizations: string[];
  };
  languagesSpoken: string[]; // e.g., ['English', 'Spanish']
  educationLevel: string; // e.g., 'Bachelor's Degree'
  emergencyContact: {
    name: string;
    relationship: string;
    contactDetails: string;
  };
  personalNotes: string;
  communicationHistory: string[]; // e.g., ['Email sent on 01/01/2023']
  engagementLevel: string; // e.g., 'High', 'Moderate'
  geographicPreferences: string[]; // e.g., ['Downtown', 'Suburbs']
  accessibilityNeeds: string;
}


const NewVolunteer = () => {
const { companyKey } = useContext(AuthContext);
const [userKey, setUserKey] = useState("");

const [volunteerInfo, setVolunteerInfo] = useState<VolunteerInfo>({
  volunteerName: "",
  volunteerAge: 0,
  volunteerEmail: "",
  volunteerAddress: "",
  volunteerStatus: "",
  volunteerDate: "",
  volunteerRole: "",
  volunteerMissions: [],
  preferredContactMethod: "",
  optInStatus: false,
  availabilitySchedule: {
    days: [], // Assuming default as an empty array
    times: "", // Assuming default as an empty string
  },
  immediateAvailability: false,
  skillSet: [''],
  interestAreas: [],
  pastVolunteerExperience: {
    pastRoles: [],
    pastOrganizations: [],
  },
  languagesSpoken: [],
  educationLevel: "",
  emergencyContact: {
    name: "",
    relationship: "",
    contactDetails: "",
  },
  personalNotes: "",
  communicationHistory: [],
  engagementLevel: "",
  geographicPreferences: [],
  accessibilityNeeds: "",
  // Include any other missing fields with appropriate default values
});
const [skillInput, setSkillInput] = useState("");

const handleSkillInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  setVolunteerInfo(prevInfo => ({
    ...prevInfo,
    skillSet: [...prevInfo.skillSet, e.target.value]
  }));
};

const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  const { name, value } = e.target;
  setVolunteerInfo(prevState => ({ ...prevState, [name]: value }));
};

const handleStatusChange = (nextValue: string) => {
  setVolunteerInfo(prev => ({ ...prev, volunteerStatus: nextValue }));
};

const handleSkillKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'Enter') {
    e.preventDefault(); // Prevent form submission
    if (e.currentTarget.value.trim()) {
      setVolunteerInfo(prevInfo => ({
        ...prevInfo,
        skillSet: [...prevInfo.skillSet, e.currentTarget.value.trim()]
      }));
      e.currentTarget.value = ''; // Clear the input field
    }
  }
};


const removeSkill = (skillToRemove: string) => {
  setVolunteerInfo(prevInfo => ({
    ...prevInfo,
    skillSet: prevInfo.skillSet.filter(skill => skill !== skillToRemove)
  }));
};
  
  const cleanData = (data: Record<string, any>): Record<string, any> => {
    const cleanedData: Record<string, any> = {};
    Object.keys(data).forEach(key => {
      if (key !== "" && data[key] !== undefined && data[key] !== null) {
        cleanedData[key] = data[key];
      }
    });
    return cleanedData;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    // Split the name into first and last name
    const nameParts = volunteerInfo.volunteerName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.length > 1 ? nameParts.slice(1).join(' ') : '';
  
    const newVolunteerData = cleanData({
      ...volunteerInfo,
      volunteerName: [`${firstName} ${lastName}`, volunteerInfo.volunteerEmail], // Combine name and email in an array
    });
  
    const volunteerDataToPush = {
      volunteerInfo: newVolunteerData // Nesting newVolunteerData inside volunteerInfo
    };
  
    console.log("Data to be saved:", volunteerDataToPush); // Debugging
  
    try {
      const dbRef = ref(db, `companies/${companyKey}/volunteers/`);
      await push(dbRef, volunteerDataToPush);
      console.log("Data saved successfully");
      // ... reset state if needed
    } catch (error) {
      console.error("Error saving data:", error);
    }
  }

console.log(volunteerInfo)

  return (

    <form onSubmit={handleSubmit} className="w-auto h-auto px-8 pb-8 sm:overflow-x-auto bg-white dark:text-white dark:!bg-navy-700 bg-navy-700 linear justify-center rounded-lg font-bold transition duration-200">
      {/* Header */}
      <h4 className="text-xl font-bold mb-6 text-gray-900">Volunteer Info</h4>

      {/* Inputs */}
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-2 space-y-4">
          {/* Volunteer Name */}
          <div className="relative mb-4">
            <label htmlFor="volunteername" className="font-bold">Volunteer Name</label>
            <input
              id="volunteerName"
              name="volunteerName"
              type="text"
              placeholder="Enter volunteer name"
              className="w-full border border-gray-300 rounded-md py-2 px-3"
              value={volunteerInfo.volunteerName}
              onChange={handleInputChange}
            />
          </div>
          {/* Volunteer Age */}
          <div className="relative mb-4">
            <label htmlFor="volunteerage" className="font-bold">Volunteer Age</label>
            <div className="flex items-center">
              <span className="bg-gray-300 text-gray-600 px-3 py-2 rounded-l-md text-lg">+</span>
              <input
                id="volunteerAge"
                name="volunteerAge"
                type="number"
                placeholder="Enter volunteer age"
                className="w-full border border-gray-300 rounded-none py-2 px-3"
                value={volunteerInfo.volunteerAge}
                onChange={handleInputChange}
              />
              <span className="bg-gray-300 text-gray-600 px-3 py-2 rounded-r-md">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                  <path d="M6.146 4.146a.5.5 0 0 1 .708 0L8 4.793l1.146-1.147a.5.5 0 0 1 .708.708L8.707 5.5l1.147 1.146a.5.5 0 0 1-.708.708L8 6.207l-1.146 1.147a.5.5 0 1 1-.708-.708L7.293 5.5 6.146 4.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </span>
            </div>
          </div>
          {/* Volunteer Email */}
          <div className="relative mb-4">
            <label htmlFor="volunteeremail" className="font-bold">Volunteer Email</label>
            <input
              id="volunteeremail"
              name="volunteerEmail"
              type="email"
              placeholder="Enter volunteer email"
              className="w-full border border-gray-300 rounded-md py-2 px-3"
              value={volunteerInfo.volunteerEmail}
              onChange={handleInputChange}
            />
          </div>
          {/* Address */}
          <div className="relative mb-4">
            <label htmlFor="volunteeraddress" className="font-bold">Address</label>
            <input
              id="volunteeraddress"
              name="volunteerAddress"
              type="text"
              placeholder="Address"
              className="w-full border border-gray-300 rounded-md py-2 px-3"
              value={volunteerInfo.volunteerAddress}
              onChange={handleInputChange}
             
            />

         
          </div>
          <div className="file-upload">
          </div>
        </div>

        {/* Right side */}
        <div className="mt-6">
          {/* Volunteer Date Joined */}
          <div className="relative mb-4">
            <label htmlFor="volunteerdate" className="font-bold">Volunteer Date Joined</label>
            <input
              id="volunteerdate"
              name="volunteerDate"
              type="date"
              placeholder="eg. Classic"
              className="w-full border border-gray-300 rounded-md py-2 px-3"
              value={volunteerInfo.volunteerDate}
              onChange={handleInputChange}
            />
          </div>
          {/* Volunteer Skills */}
          <div className="relative mb-4">
          <input 
          type="text" 
          value={skillInput}
          onChange={(e) => setSkillInput(e.target.value)}
          onKeyDown={handleSkillKeyPress}
          placeholder="Enter skill & press Enter" 
          className="w-full border border-gray-300 rounded-md py-2 px-3"
        />
        <div>
          {volunteerInfo.skillSet.length > 0 ? (
            volunteerInfo.skillSet.map((skill, index) => (
              <div key={index} className="skill-tag">
                {skill}
                <button onClick={() => removeSkill(skill)}>Remove</button>
              </div>
            ))
          ) : (
            <p className="mt-3">No skills added yet.</p>
          )}
        </div>

      
         
      
</div>


          {/* Volunteer Status */}
          <div className="mt-6 space-y-4">
            {/* Volunteer Status */}
            <div className="relative">
            <FormControl as="fieldset">
              <label htmlFor="volunteerstatus" className="font-bold text-gray-700">Volunteer Status</label>
              <RadioGroup name="volunteerStatus" value={volunteerInfo.volunteerStatus} onChange={handleStatusChange} defaultValue="Pending" >
                <VStack spacing={2} align="start">
                  <Radio colorScheme="black" value="Active">Active</Radio>
                  <Radio colorScheme="brand" value="Inactive">Inactive</Radio>
                  <Radio colorScheme="brand" value="Pending">Pending</Radio>
                  <Radio colorScheme="brand" value="Completed">Completed</Radio>
                </VStack>
              </RadioGroup>
              </FormControl>
            </div>
            {/* Button */}
            <button type="submit" className="bg-brand-500 hover:bg-brand-600 text-white py-2 px-4 rounded-md">
              Add Volunteer
            </button>
          </div>
        </div>
      </div>
    </form>

              );

  };


export default NewVolunteer;
