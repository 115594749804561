import React from "react";
import Chart from "react-apexcharts";

type BarChartProps = {
  chartData: any[];
  chartOptions: any;
};

const BarChart: React.FC<BarChartProps> = ({ chartData, chartOptions }) => {
  return (
    <Chart
      options={chartOptions}
      series={chartData}
      type="bar"
      width="100%"
      height="100%"
    />
  );
};

export default BarChart;
