import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Box, SimpleGrid, Image, Text, Button, Flex, Select, Stack, ButtonGroup,
  useDisclosure, IconButton, Modal, ModalBody, ModalCloseButton, ModalContent,
  ModalFooter, ModalHeader, ModalOverlay, FormControl, FormLabel, Input, Textarea, useToast, Avatar
} from '@chakra-ui/react';
import { arrayUnion, collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth'; // Import to use Firebase Authentication
import { format } from 'date-fns'; // make sure you have date-fns installed
import { getDatabase, get, ref } from 'firebase/database';
import { ref as storageRef, getDownloadURL, getStorage } from 'firebase/storage'; // Import if you're using Firebase Storage
import { MdEdit } from 'react-icons/md';
import TaskForm from '../toolsComponents/TaskForm';
import VolunteerData1 from '../../../../../assets/img/others/VolunteerData1.jpg';
import VolunteerData2 from '../../../../../assets/img/others/VolunteerData2.jpg';
import VolunteerData3 from '../../../../../assets/img/others/VolunteerData3.jpg';

interface Mission {
  id: string;
  missionName: string;
  fileUrl?: string; // Marked as optional because you're using a fallback for it
  isMyMission: boolean;
  authorId: string;
  missionDescription: string;
  authorImageUrl?: string; // Add authorImageUrl property
  missionCategory: string;
  startDate?: any; // Add startDate property, adjust type as needed (e.g., Timestamp if using Firestore)
  coAuthors?: { [key: string]: { status: string } }; // New field to store co-authors' UIDs and their statuses
}

export const defaultImages = [VolunteerData1, VolunteerData2, VolunteerData3];

const MissionsDisplay = () => {
  const [missions, setMissions] = useState<Mission[]>([]);
  const toast = useToast();
  const firestore = getFirestore();
  const navigate = useNavigate();
  const auth = getAuth();
  const currentUser = auth.currentUser; // This might be null if the authentication state hasn't been resolved yet
  const [filter, setFilter] = useState('all'); // 'all' or 'mine'
  const [selectedCategory, setSelectedCategory] = useState('all'); // Assuming categories are predefined
  const [potentialCoAuthors, setPotentialCoAuthors] = useState([]); // List of potential co-author businesses
  const [currentPage, setCurrentPage] = useState(1);
  const [missionsPerPage] = useState(6);
  const [isStatsModalOpen, setIsStatsModalOpen] = useState(false);
  const [selectedMission, setSelectedMission] = useState<Mission | null>(null); // Holds selected mission's data
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [authorImageUrl, setAuthorImageUrl] = useState('');
  const [coAuthorUid, setCoAuthorUid] = useState('');
  const [coAuthorsDetails, setCoAuthorsDetails] = useState([]);
  const [editMissionData, setEditMissionData] = useState<Mission>({
    id: '',
    missionName: '',
    fileUrl: '',
    isMyMission: false,
    missionCategory: '',
    missionDescription: '',
    authorId: '',
    coAuthors: {},
  });

  // Fetch business names for potential co-authors
  useEffect(() => {
    const fetchBusinessNames = async () => {
      const db = getDatabase();
      const businessesRef = ref(db, 'businesses/byName');
      const snapshot = await get(businessesRef);
      if (snapshot.exists()) {
        const businessNames = Object.keys(snapshot.val());
        setPotentialCoAuthors(businessNames);
      }
    };

    fetchBusinessNames();
  }, []);

  useEffect(() => {
    const fetchCoAuthorsDetails = async (coAuthorsNames: any[]) => {
      const db = getDatabase();
      const businessDetails = await Promise.all(
        coAuthorsNames.map(async (name: string) => {
          const businessRef = ref(db, `businesses/byName/${name}`);
          const snapshot = await get(businessRef);
          if (snapshot.exists()) {
            return { name, ...snapshot.val() }; // Adjust to match your business data structure
          } else {
            return null;
          }
        })
      );
      setCoAuthorsDetails(businessDetails.filter(Boolean));
    };

    if (selectedMission?.coAuthors) {
      fetchCoAuthorsDetails(Object.keys(selectedMission.coAuthors));
    }
  }, [selectedMission]);

  useEffect(() => {
    const fetchMissions = async () => {
      const missionsCollectionRef = collection(firestore, 'missions');
      const missionsSnapshot = await getDocs(missionsCollectionRef);
      const missionsData = await Promise.all(missionsSnapshot.docs.map(async (doc) => {
        const data = doc.data() as Mission;
        const authorImageUrl = await fetchAuthorImageUrl(data.authorId); // Fetch author image URL for each mission
        return {
          id: doc.id,
          missionName: data.missionName,
          fileUrl: data.fileUrl,
          isMyMission: currentUser ? data.authorId === currentUser.uid : false,
          missionCategory: data.missionCategory,
          missionDescription: data.missionDescription,
          startDate: data.startDate, // Include startDate
          authorId: data.authorId,
          authorImageUrl: authorImageUrl, // Store author image URL in the mission object
          coAuthors: data.coAuthors || {}, // Include coAuthors
        };
      }));

      // Apply filters outside the map function
      const filteredMissions = missionsData.filter(mission => {
        const filterCondition = filter === 'all' || (filter === 'mine' && mission.isMyMission);
        const categoryCondition = selectedCategory === 'all' || mission.missionCategory === selectedCategory;
        return filterCondition && categoryCondition;
      });

      setMissions(filteredMissions);
    };

    fetchMissions();
  }, [firestore, currentUser, filter, selectedCategory]);

  const handleMissionSelect = (missionId: string) => {
    navigate(`/volunteer/missions/${missionId}`);
  };

  const canEdit = (mission: Mission) => {
    return currentUser?.uid === mission.authorId || Object.keys(mission.coAuthors || {}).includes(currentUser?.uid || '');
  };

  const getButtonStyles = (isActive: boolean) => ({
    bg: isActive ? 'brand.900' : 'transparent', // Change background based on active state
    color: isActive ? 'white' : 'purple.500', // Change text color based on active state
    boxShadow: isActive ? '0 0 12px rgba(130, 87, 229, 0.7)' : '0px 4px 10px rgba(0, 0, 0, 0.25)', // Different shadow for active button
    _hover: { bg: isActive ? 'band.600' : 'purple.100' }, // Different hover styles for active button
    _active: { bg: 'brand.900' }, // Same active color for both
  });

  const indexOfLastMission = currentPage * missionsPerPage;
  const indexOfFirstMission = indexOfLastMission - missionsPerPage;
  const currentMissions = missions.slice(indexOfFirstMission, indexOfLastMission);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleEditModalOpen = (mission: Mission) => {
    if (canEdit(mission)) {
      setSelectedMission(mission); // Set the selected mission
      setEditMissionData({
        id: mission.id,
        missionName: mission.missionName,
        fileUrl: mission.fileUrl,
        isMyMission: mission.isMyMission,
        missionCategory: mission.missionCategory,
        missionDescription: mission.missionDescription,
        authorId: mission.authorId,
        coAuthors: mission.coAuthors,
      });
      onOpen();
    }
  };
  

  const handleEditMission = async () => {
    if (!editMissionData.id) {
      toast({
        title: 'Error Updating Mission',
        description: "Invalid mission selected.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const missionRef = doc(firestore, "missions", editMissionData.id);
      await updateDoc(missionRef, {
        missionName: editMissionData.missionName,
        fileUrl: editMissionData.fileUrl,
        // ... other fields
      });
      onClose(); // Close the modal
      // Optionally, re-fetch missions here or update local state to reflect the changes
    } catch (error) {
      toast({
        title: 'Error Updating Mission',
        description: `There was a problem updating the mission's details: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error("Error updating mission:", error);
    }
  };

  const handleAddCoAuthor = async () => {
    if (!selectedMission?.id) {
      toast({
        title: 'Error Adding Co-Author',
        description: "Invalid mission selected.",
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      console.error("Invalid mission selected. Selected mission:", selectedMission);
      return;
    }
  
    console.log("Adding co-author to mission:", selectedMission.id);
    console.log("Co-author UID:", coAuthorUid);
  
    const missionRef = doc(firestore, 'missions', selectedMission.id);
    await updateDoc(missionRef, {
      [`coAuthors.${coAuthorUid}`]: { status: 'requested' },
    });
  
    toast({
      title: 'Co-Author Added',
      description: 'A new co-author has been successfully added.',
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  
    // Optionally, re-fetch mission data or update local state
    // fetchMissions();
  };
  

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setEditMissionData(prevData => ({
      ...prevData,
      [name]: value, // This will handle changes from both inputs and selects
    }));
  };

  const fetchAuthorImageUrl = async (authorUid: string) => {
    const firebaseStorage = getStorage();
    const db = getDatabase();
    const authorRef = ref(db, `volunteers/${authorUid}/profileURL`);
    const snapshot = await get(authorRef);
    if (snapshot.exists()) {
      return snapshot.val();
    } else {
      try {
        const defaultImageUrl = await getDownloadURL(storageRef(firebaseStorage, `path_to_default_profile_image.jpg`));
        return defaultImageUrl;
      } catch (error) {
        console.error("Failed to fetch default image URL:", error);
        return ''; // Return empty string or path to a local default image
      }
    }
  };

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    await handleEditMission(); // Make sure this function updates Firestore and handles errors
    onClose(); // Close the modal
  };

  return (
    <>
      <div className="px-4 py-6">
        <div className="mb-4 flex justify-between items-center">
          <div className="flex space-x-2">
            {/* Button to handle the tabs  */}
            <Button
              {...getButtonStyles(filter === 'all')} // Spread the dynamic styles
              size="md"
              rounded="full"
              px={5}
              py={3}
              className='linear rounded-full bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
              transition="duration-200"
              onClick={() => setFilter('all')}
            >
              All Missions
            </Button>
            <Button
              {...getButtonStyles(filter === 'mine')} // Spread the dynamic styles
              size="md"
              rounded="full"
              px={5}
              py={3}
              className='linear rounded-full bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
              transition="duration-200"
              onClick={() => setFilter('mine')}
            >
              My Missions
            </Button>
          </div>
        </div>

        {/* Displays for the cards  */}
        <div className="missions-container" style={{ margin: '0', padding: '0' }}>
          <div className={`grid grid-cols-1 ${missions.length > 1 ? 'sm:grid-cols-2 lg:grid-cols-3' : ''} gap-4`}>
            {missions.map((mission) => (
              <Box
                key={mission.id}
                rounded="lg"
                overflow="hidden"
                position="relative"
                cursor="pointer"
                mx={{ base: "0", md: "4" }}
                h={{ base: "300px", md: "200px" }}
                w="full"
              >
                {/* Mission Image */}
                <Image
                  src={mission.fileUrl || defaultImages[Math.floor(Math.random() * defaultImages.length)]}
                  alt={mission.missionName}
                  h="full"
                  w="full"
                  objectFit="cover"
                />
                {/* Overlay Gradient */}
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  bgGradient="linear(to-t, transparent, blackAlpha.700)"
                  zIndex="1"
                />
                {/* Mission Date */}
                <Text
                  position="absolute"
                  top="2"
                  right="2"
                  zIndex="4" // Ensure the date is above the clickable overlay
                  fontWeight="bold"
                  color="black"
                  bg="white"
                  borderRadius="md"
                  px="2"
                  py="1"
                >
                  {mission.startDate ? format(mission.startDate.toDate(), 'dd MMM').toUpperCase() : 'NO DATE'}
                </Text>
                <Avatar
                  src={mission.authorImageUrl}
                  size="md"
                  position="absolute"
                  top="2"
                  left="2" // Adjust positioning as needed
                  zIndex="4" // Ensure it is above the overlay
                  className='border-[3px] border-[#FF9966]'
                />

                {/* Mission Info */}
                <Box p="4" position="absolute" bottom="0" left="0" right="0" zIndex="4">
                  <Flex justify="space-between" align="center" mb="2">
                    <Text fontSize="xl" fontWeight="bold" color="white" onClick={() => handleMissionSelect(mission.id)}>{mission.missionName}</Text>
                    {mission.isMyMission && (
                      <IconButton
                        aria-label="Edit mission"
                        icon={<MdEdit />}
                        size="md"
                        variant="ghost"
                        className='flex items-center justify-center rounded-lg bg-brandLinear p-2 text-xl text-white transition-opacity hover:cursor-pointer hover:opacity-80'
                        colorScheme="white"
                        _hover={{ bg: "whiteAlpha.300" }}
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event bubbling to the Flex container
                          handleEditModalOpen(mission);
                        }}
                        zIndex="5" // Ensure the button is above the clickable overlay
                      />
                    )}
                  </Flex>
                  {/* Join button or other content goes here */}
                </Box>
                {/* Clickable overlay to select mission */}
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  zIndex="3" // Lower zIndex so it's below the edit button
                />
              </Box>
            ))}
          </div>
        </div>

        <Flex justifyContent="center" alignItems="center" p={4}>
          <ButtonGroup isAttached>
            {Array.from({ length: Math.ceil(missions.length / missionsPerPage) }, (_, i) => (
              <Button className='linear rounded-xl bg-gradient-to-br from-brandLinear to-blueSecondary px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-blueSecondary/5' key={i + 1} onClick={() => paginate(i + 1)}>{i + 1}</Button>
            ))}
          </ButtonGroup>
          <select
            className="ml-4 rounded-lg border linear rounded-xl border border-purple-500 px-5 py-3 text-base font-medium text-purple-500 transition duration-200 hover:bg-purple-600/5 active:bg-purple-700/5 dark:border-purple-400 dark:bg-purple-400/10 dark:text-white dark:hover:bg-purple-300/10 dark:active:bg-purple-200/10"
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="all">All Categories</option>
            <option value="conservation">Conservation</option>
            <option value="education">Education</option>
            {/* Add more categories as needed */}
          </select>
        </Flex>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
  <ModalOverlay />
  <ModalContent as="form" onSubmit={handleSubmit} className='mb-3 mr-2 flex items-center justify-center text-sm font-bold text-gray-600 hover:cursor-pointer dark:!bg-navy-800 dark:text-white'>
    <ModalHeader>Edit Mission</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Mission Name</FormLabel>
          <Input
            id="missionName"
            name="missionName"
            value={editMissionData?.missionName || ''}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Mission Description</FormLabel>
          <Textarea
            name="missionDescription"
            value={editMissionData?.missionDescription || ''}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Category</FormLabel>
          <Select
            name="missionCategory"
            value={editMissionData?.missionCategory || ''}
            onChange={handleChange}
          >
            <option value="Community Development">Community Development</option>
            <option value="Education">Education</option>
            <option value="Environmental">Environmental Conservation</option>
            <option value="Education">Education and Literacy</option>
            <option value="Food Security">Food Security</option>
            <option value="Health & Wellness">Health and Wellness</option>
            <option value="Arts & Culture">Arts and Culture</option>
            <option value="Community & Development">Community Development</option>
            <option value="Youth Programs">Youth Programs</option>
            <option value="Senior & Support">Senior Support</option>
            <option value="Animal & Welfare">Animal Welfare</option>
            <option value="Emergency Response">Emergency Response and Preparedness</option>
          </Select>
        </FormControl>
        <TaskForm missionId={editMissionData.id} />
        <FormControl>
          <FormLabel>Add Co-Author</FormLabel>
          <Select
            placeholder="Select Business"
            onChange={(e) => setCoAuthorUid(e.target.value)} // Update your state accordingly
          >
            {potentialCoAuthors.map((business) => (
              <option key={business} value={business}>{business}</option>
            ))}
          </Select>
          <Button onClick={handleAddCoAuthor} className='mt-4'>Add Co-Author</Button>
        </FormControl>
      </Stack>
    </ModalBody>
    <ModalFooter>
      <Button className='linear rounded-full bg-gray-100 px-5 py-3 text-base font-medium text-navy-700 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-black dark:hover:bg-white/20 dark:active:bg-white/30' type="submit">Save Changes</Button>
    </ModalFooter>
  </ModalContent>
</Modal>

    </>
  );
};

export default MissionsDisplay;
