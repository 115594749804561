import React, { ChangeEvent, useEffect, useState } from "react";
import { Avatar, Box, Flex, IconButton, useDisclosure, useToast, Image, Text, Button, VStack, HStack, Select, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Progress, Textarea, Input, Badge, Checkbox } from "@chakra-ui/react";
import { MdOutlineFavoriteBorder, MdEditNote, MdNewReleases } from "react-icons/md";
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import Card from "components/card";
import { getDatabase, get, ref } from "firebase/database";
import { getStorage, getDownloadURL, ref as storageRef } from "firebase/storage";
import { collection, doc, getDocs, getFirestore, updateDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import { calculateCompletionRate, logCompletedActivity } from 'functions/BusinessAnalytics';

const defaultImages = [
  'default_image1_url',
  'default_image2_url'
];

const SubActivityForm = ({ onSave, onCancel, parentId, coAuthors, teamMembers }) => {
  const [subActivity, setSubActivity] = useState({
    label: '',
    description: '',
    completed: false,
    client: '',
    resourceType: '',
    resourceQuantity: 0,
    deadline: '',
    assignedTo: '',
    publicUpdate: '',
    photoUrl: '',
    videoUrl: '',
    progress: 0,
    status: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parentId) {
      onSave(subActivity, parentId);
    } else {
      console.error('parentId is undefined');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <VStack spacing={4} align="stretch">
        <Input
          placeholder="Sub-activity name"
          value={subActivity.label}
          onChange={(e) => setSubActivity({ ...subActivity, label: e.target.value })}
        />
        <Textarea
          placeholder="Description"
          value={subActivity.description}
          onChange={(e) => setSubActivity({ ...subActivity, description: e.target.value })}
        />
        <Select
          placeholder="Select Client"
          value={subActivity.client}
          onChange={(e) => setSubActivity({ ...subActivity, client: e.target.value })}
        >
          {coAuthors.map(coAuthor => (
            <option key={coAuthor.id} value={coAuthor.name}>{coAuthor.name}</option>
          ))}
        </Select>
        <Select
          placeholder="Assign to"
          value={subActivity.assignedTo}
          onChange={(e) => setSubActivity({ ...subActivity, assignedTo: e.target.value })}
        >
          {teamMembers.map((member) => (
            <option key={member.userId} value={member.username}>
              {member.username}
            </option>
          ))}
        </Select>
        <Select
          placeholder="Resource Type"
          value={subActivity.resourceType}
          onChange={(e) => setSubActivity({ ...subActivity, resourceType: e.target.value })}
        >
          <option value="goods">Goods</option>
          <option value="services">Services</option>
          <option value="volunteers">Volunteers</option>
        </Select>
        <Input
          type="number"
          placeholder="Resource Quantity"
          value={subActivity.resourceQuantity}
          onChange={(e) => setSubActivity({ ...subActivity, resourceQuantity: parseInt(e.target.value, 10) })}
        />
        <Input
          type="date"
          placeholder="Deadline"
          value={subActivity.deadline}
          onChange={(e) => setSubActivity({ ...subActivity, deadline: e.target.value })}
        />
        <Input
          placeholder="Photo URL"
          value={subActivity.photoUrl}
          onChange={(e) => setSubActivity({ ...subActivity, photoUrl: e.target.value })}
        />
        <Input
          placeholder="Video URL"
          value={subActivity.videoUrl}
          onChange={(e) => setSubActivity({ ...subActivity, videoUrl: e.target.value })}
        />
        <Input
          type="number"
          placeholder="Progress"
          value={subActivity.progress}
          onChange={(e) => setSubActivity({ ...subActivity, progress: parseInt(e.target.value, 10) })}
        />
        <Progress value={subActivity.progress} size="sm" colorScheme="green" />
        <Textarea
          placeholder="Public Update"
          value={subActivity.publicUpdate}
          onChange={(e) => setSubActivity({ ...subActivity, publicUpdate: e.target.value })}
        />
        <Checkbox
          isChecked={subActivity.completed}
          onChange={(e) => setSubActivity({ ...subActivity, completed: e.target.checked })}
        >
          Completed
        </Checkbox>
        <HStack spacing={4}>
          <Button colorScheme="green" type="submit">Save</Button>
          <Button colorScheme="red" onClick={onCancel}>Cancel</Button>
        </HStack>
      </VStack>
    </form>
  );
};

const ActivityNode = ({ activity, depth = 0, coAuthors, isAuthor, onAddSubActivity, onUpdate, teamMembers }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isExpanded, setIsExpanded] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const realTimeDb = getDatabase();
  const { currentUser } = getAuth();
  const { missionId } = useParams();
  const handleExpand = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    const fetchUserDetails = async () => {
      if (activity.updatedBy) {
        const userRef = ref(realTimeDb, `volunteers/${activity.updatedBy}`);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          setUserDetails(snapshot.val());
        }
      }
    };

    fetchUserDetails();
  }, [activity.updatedBy]);

  const handleSaveSubActivity = (newSubActivity, parentId) => {
    if (parentId) {
      onAddSubActivity(parentId, newSubActivity);
    } else {
      console.error('parentId is undefined');
    }
    onClose();
  };

  useEffect(() => {
    if (!activity.id) {
      activity.id = uuidv4();
    }
  }, [activity]);

  const checkPermissions = async () => {
    const firestore = getFirestore();
    const missionRef = doc(firestore, "missions", missionId);
    const missionSnapshot = await getDoc(missionRef);

    if (missionSnapshot.exists()) {
      const missionData = missionSnapshot.data();

      if (currentUser.uid === missionData.authorId) {
        return true;
      }

      // Check Realtime Database
      const volunteerRef = ref(realTimeDb, `volunteers/${currentUser.uid}`);
      const volunteerSnapshot = await get(volunteerRef);

      if (volunteerSnapshot.exists()) {
        const volunteerData = volunteerSnapshot.val();

        if (volunteerData.businessName) {
          const businessRef = ref(realTimeDb, `businesses/byName/${volunteerData.businessName}`);
          const businessSnapshot = await get(businessRef);

          if (businessSnapshot.exists()) {
            if (missionData.coAuthors && missionData.coAuthors[volunteerData.businessName] && missionData.coAuthors[volunteerData.businessName].status === 'accepted') {
              return true;
            }
          }
        }
      }
    }

    return false;
  };

  const [isEditor, setIsEditor] = useState(false);

  useEffect(() => {
    const checkUserPermissions = async () => {
      const hasPermission = await checkPermissions();
      setIsEditor(hasPermission);
    };
    checkUserPermissions();
  }, [currentUser]);

  return (
    <VStack align="stretch" spacing={4} className="my-2">
      <HStack align="stretch" spacing={4} position="relative">
        {depth > 0 && (
          <Box
            position="absolute"
            left={0}
            top={0}
            bottom={0}
            width="2px"
            bg="gray.300"
            marginLeft="20px"
            zIndex={0}
          />
        )}
        <Card extra="w-full h-full px-4 py-6 mt-2 border rounded-md shadow-sm">
          <HStack justify="space-between" onClick={handleExpand} cursor="pointer">
            <Text fontWeight="bold">
              {activity.label}
            </Text>
            <HStack>
              {activity.subActivities && activity.subActivities.length > 0 && (
                isExpanded ? <ChevronDownIcon /> : <ChevronRightIcon />
              )}
              {activity.updatedRecently && <MdNewReleases color="orange" />}
            </HStack>
          </HStack>
          {activity.subActivities && activity.subActivities.length > 0 && (
            isExpanded ? <Text mt="2">Click Card Text See Less</Text> : <Text>Click Card Text See More</Text>
          )}
          <Text mt={2}>{activity.description}</Text>
          {activity.photoUrl && <Image src={activity.photoUrl} alt="activity photo" />}
          {activity.videoUrl && (
            <Box as="video" controls>
              <source src={activity.videoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </Box>
          )}
          {userDetails && (
            <HStack mt={2} spacing={2}>
              <Avatar src={userDetails.profileURL} size="sm" name={`${userDetails.firstName} ${userDetails.lastName}`} />
              <Text fontSize="sm">{`${userDetails.firstName} ${userDetails.lastName}`}</Text>
            </HStack>
          )}
          {isEditor && (
            <IconButton icon={<MdEditNote />} size="sm" onClick={onOpen} aria-label="Edit activity" mt={2} />
          )}
          {activity.subActivities && activity.subActivities.length > 0 && (
            <Badge ml="2" mt="2" colorScheme="purple">Updates</Badge>
          )}
          {!isEditor && activity.completed !== undefined && (
            <Progress mt={2} hasStripe value={activity.completed ? 100 : 0} />
          )}
        </Card>
      </HStack>
      {isExpanded && (
        <VStack align="stretch" spacing={4} pl={4} ml={8} borderLeft="2px solid gray" position="relative">
          {activity.subActivities && activity.subActivities.map((subActivity) => (
            <ActivityNode
              key={subActivity.id}
              activity={subActivity}
              depth={depth + 1}
              coAuthors={coAuthors}
              isAuthor={isAuthor}
              onAddSubActivity={onAddSubActivity}
              onUpdate={onUpdate}
              teamMembers={teamMembers} // Pass teamMembers here
            />
          ))}
          {isEditor && (
            <Button size="sm" onClick={onOpen} mt={2}>Add Sub-Activity</Button>
          )}
        </VStack>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Activity</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isEditor ? (
              <SubActivityForm
                onSave={handleSaveSubActivity}
                onCancel={onClose}
                parentId={activity.id}
                coAuthors={coAuthors} // Pass coAuthors here
                teamMembers={teamMembers} // Pass teamMembers here
              />
            ) : (
              <p />
            )}
          </ModalBody>
          <ModalFooter>
            {/* <Button onClick={onClose}>Cancel</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </VStack>
  );
};

const MissionTimeline = () => {
  const { missionId } = useParams();
  const [activities, setActivities] = useState([]);
  const [coAuthors, setCoAuthors] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const firestore = getFirestore();
  const toast = useToast();
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [missionAuthorId, setMissionAuthorId] = useState(null);
  const realTimeDb = getDatabase();

  const fetchMissionDetails = async () => {
    const missionRef = doc(firestore, "missions", missionId);
    try {
      const missionSnapshot = await getDoc(missionRef);
      if (missionSnapshot.exists()) {
        const data = missionSnapshot.data();
        if (data.formFields) {
          const activitiesWithId = data.formFields.map((activity) => ({
            ...activity,
            id: activity.id || uuidv4()  // Assign a new ID if not present
          }));
          setActivities(activitiesWithId);

          // Update Firestore to include the newly assigned IDs
          await updateDoc(missionRef, { formFields: activitiesWithId });
        }
        setMissionAuthorId(data.authorId);
        setCoAuthors(Object.keys(data.coAuthors || {}).map(key => ({ name: key, ...data.coAuthors[key] })));
      } else {
        toast({
          title: "Error",
          description: "No mission found",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Fetching error:", error);
      toast({
        title: "Error fetching mission details",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchTeamMembers = async () => {
    const volunteerRef = ref(realTimeDb, `volunteers/${currentUser.uid}`);
    const volunteerSnapshot = await get(volunteerRef);
    if (volunteerSnapshot.exists()) {
      const volunteerData = volunteerSnapshot.val();
      if (volunteerData.businessName) {
        const teamRef = ref(realTimeDb, `businesses/byName/${volunteerData.businessName}/team`);
        const teamSnapshot = await get(teamRef);
        if (teamSnapshot.exists()) {
          const teamData = teamSnapshot.val();
          setTeamMembers(Object.keys(teamData).map(key => ({ userId: key, ...teamData[key] })));
        }
      }
    }
  };

  useEffect(() => {
    fetchMissionDetails();
    fetchTeamMembers();
  }, [missionId, firestore, toast]);

  const isAuthor = currentUser?.uid === missionAuthorId;

  const onAddSubActivity = async (parentId, subActivityData) => {
    const missionRef = doc(firestore, "missions", missionId);
    try {
      const missionSnapshot = await getDoc(missionRef);
      if (missionSnapshot.exists()) {
        let activitiesData = missionSnapshot.data().formFields || [];
  
        // Ensure each activity has an ID
        activitiesData = activitiesData.map((activity) => ({
          ...activity,
          id: activity.id || uuidv4()
        }));
  
        const parentIndex = activitiesData.findIndex((act) => act.id === parentId);
        if (parentIndex === -1) {
          console.error(`Parent activity with ID ${parentId} not found`);
          return;
        }
  
        const newSubActivityId = `${parentId}-${Date.now()}`;
        const newSubActivity = {
          ...subActivityData,
          id: newSubActivityId,
          parentId,
          updatedBy: currentUser.uid
        };
  
        if (!activitiesData[parentIndex].subActivities) {
          activitiesData[parentIndex].subActivities = [];
        }
        activitiesData[parentIndex].subActivities.push(newSubActivity);
  
        await updateDoc(missionRef, { formFields: activitiesData });
        setActivities(activitiesData);
  
        // Log completed sub-activity if it's marked as completed
        if (newSubActivity.completed) {
          const volunteerRef = ref(realTimeDb, `volunteers/${currentUser.uid}`);
          const volunteerSnapshot = await get(volunteerRef);
          if (volunteerSnapshot.exists()) {
            const volunteerData = volunteerSnapshot.val();
            if (volunteerData.businessName) {
              logCompletedActivity(volunteerData.businessName, missionId, newSubActivity);
            }
          }
        }
  
        toast({
          title: 'Sub-activity added',
          description: 'The sub-activity has been successfully added.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        console.error('Mission data not found');
      }
    } catch (error) {
      console.error('Error adding sub-activity:', error);
      toast({
        title: 'Error adding sub-activity',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  

  const onUpdate = async (updatedActivity) => {
    const missionRef = doc(firestore, "missions", missionId);
    try {
      const missionSnapshot = await getDoc(missionRef);
      if (missionSnapshot.exists()) {
        let activitiesData = missionSnapshot.data().formFields || [];
        activitiesData = activitiesData.map((activity) => ({
          ...activity,
          id: activity.id || uuidv4()
        }));
        const activityIndex = activitiesData.findIndex((act) => act.id === updatedActivity.id);
        if (activityIndex !== -1) {
          const previousActivity = activitiesData[activityIndex];
          activitiesData[activityIndex] = { ...updatedActivity, updatedBy: currentUser.uid };
  
          await updateDoc(missionRef, { formFields: activitiesData });
          setActivities(activitiesData); // Update the entire activities list
  
          // Log completed activity if it's marked as completed
          if (!previousActivity.completed && updatedActivity.completed) {
            const volunteerRef = ref(realTimeDb, `volunteers/${currentUser.uid}`);
            const volunteerSnapshot = await get(volunteerRef);
            if (volunteerSnapshot.exists()) {
              const volunteerData = volunteerSnapshot.val();
              if (volunteerData.businessName) {
                logCompletedActivity(volunteerData.businessName, missionId, updatedActivity);
              }
            }
          }
  
          toast({
            title: 'Activity updated',
            description: 'The activity has been successfully updated.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      console.error('Error updating activity:', error);
      toast({
        title: 'Error updating activity',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  const completionRate = calculateCompletionRate(activities);

  return (
    <div className="container mx-auto p-4">
      {activities.map(activity => (
        <ActivityNode
          key={activity.id}
          activity={activity}
          isAuthor={isAuthor}
          coAuthors={coAuthors}
          onAddSubActivity={onAddSubActivity}
          onUpdate={onUpdate}
          teamMembers={teamMembers} // Pass teamMembers here
        />
      ))}
    </div>
  );
};

export default MissionTimeline;
