import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, setDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { Switch, FormControl, FormLabel, Box, Spinner, Select, Button, useToast, Text, Image, Progress } from '@chakra-ui/react';
import { getAuth } from 'firebase/auth';
import Nango from '@nangohq/frontend';

const DonationSettings = ({ businessId }) => {
  const [donationsEnabled, setDonationsEnabled] = useState(false);
  const [selectedMission, setSelectedMission] = useState('');
  const [missions, setMissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const firestore = getFirestore();
  const auth = getAuth();
  const toast = useToast();

  const handleOAuthSuccess = async (connectionDetails) => {
    console.log("OAuth Success - Connection Details:", connectionDetails);

    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'users', user.uid);

      const { connectionId, providerConfigKey: provider } = connectionDetails;
      const scopes = 'read_write';

      if (!connectionId || !provider) {
        toast({
          title: "Error",
          description: "Incomplete connection details.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const stripeConnection = {
        connectionId,
        scopes,
        provider,
      };

      await setDoc(userRef, { stripeConnection }, { merge: true });

      toast({
        title: "Stripe Connected",
        description: "Your Stripe account has been successfully connected.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleConnectStripe = () => {
    const nango = new Nango({ publicKey: 'c060978d-1755-443f-b594-c7343767be80' });
    nango.auth('stripe', 'test-connection-id')
      .then((result) => {
        handleOAuthSuccess(result);
      })
      .catch((err) => {
        toast({
          title: "Error Connecting Stripe Account",
          description: err.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    const fetchSettingsAndMissions = async () => {
      const user = auth.currentUser;
      if (!user) {
        toast({
          title: "Authentication Error",
          description: "You must be logged in to modify settings.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const missionsRef = collection(firestore, 'missions');
      const q = query(missionsRef, where('authorId', '==', user.uid));
      const missionDocs = await getDocs(q);
      const loadedMissions = missionDocs.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const docRef = doc(firestore, 'businesses', businessId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setDonationsEnabled(data.donationsEnabled || false);
        setSelectedMission(data.selectedMission || '');
        setMissions(loadedMissions);
      } else {
        setMissions(loadedMissions);
      }
      setLoading(false);
    };

    fetchSettingsAndMissions();
  }, [firestore, businessId, auth, toast]);

  const handleSwitchChange = async (event) => {
    const enabled = event.target.checked;
    setDonationsEnabled(enabled);

    const docRef = doc(firestore, 'businesses', businessId);
    try {
      await setDoc(docRef, {
        donationsEnabled: enabled,
        selectedMission,
      }, { merge: true });
      toast({
        title: "Donations Enabled",
        description: "Donations have been successfully enabled.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Failed to enable donations:", error);
      toast({
        title: "Failed to Enable Donations",
        description: "There was an error enabling donations. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleMissionChange = (event) => {
    setSelectedMission(event.target.value);
  };

  const saveSettings = async () => {
    const docRef = doc(firestore, 'businesses', businessId);
    await updateDoc(docRef, {
      selectedMission
    });
    toast({
      title: "Settings Saved",
      description: "Your settings have been successfully saved.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  if (loading) {
    return <Box display="flex" justifyContent="center"><Spinner /></Box>;
  }

  return (
    <Box padding="4" borderWidth="1px" borderRadius="lg">
      <FormControl display="flex" alignItems="center" justifyContent="space-between">
        <FormLabel htmlFor="donationsEnabled" mb="0">Enable Donations</FormLabel>
        <Switch id="donationsEnabled" isChecked={donationsEnabled} onChange={handleSwitchChange} />
      </FormControl>
      {donationsEnabled && (
        <>
          <FormControl mt="4">
            <FormLabel>Select Mission</FormLabel>
            <Select placeholder="Select mission" value={selectedMission} onChange={handleMissionChange}>
              {missions.map(mission => (
                <option key={mission.id} value={mission.id}>{mission.missionName}</option>
              ))}
            </Select>
          </FormControl>
          <Box mt="4">
            {missions.filter(m => m.id === selectedMission).map(mission => (
              <Box key={mission.id}>
                <Text fontSize="2xl" fontWeight="bold">{mission.missionName}</Text>
                <Text>{mission.missionDescription}</Text>
                <Image src={mission.fileUrl} alt={mission.missionName} mt="4" borderRadius="md" />
                <Text mt="4" fontWeight="bold">Activities Planned:</Text>
                <Text>{mission.activitiesPlanned}</Text>
                <Text mt="4" fontWeight="bold">Start Date:</Text>
                <Text>{mission.startDate.toDate().toDateString()}</Text>
                <Text mt="4" fontWeight="bold">End Date:</Text>
                <Text>{mission.endDate.toDate().toDateString()}</Text>
                <Text mt="4" fontWeight="bold">Location:</Text>
                <Text>{mission.location}</Text>
                <Progress value={mission.activitiesPlanned} max={100} mt="4" />
              </Box>
            ))}
          </Box>
          <Button mt="4" onClick={handleConnectStripe}>Connect Stripe Account</Button>
          <Button mt="4" onClick={saveSettings}>Save Settings</Button>
        </>
      )}
    </Box>
  );
};

export default DonationSettings;
