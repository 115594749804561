import { useState, useEffect } from "react";
import Card from "components/card";
import { IoHeart, IoHeartOutline } from "react-icons/io5";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure, Button } from "@chakra-ui/react";
import { getDatabase, ref, update, set, get, remove } from "firebase/database";
import auth from '../../firebase'
import BusinessCollection from "views/volunteer/volunteerDashboard/businessMarket/collection";

const BusinessCard = ({
  business, // Assuming business is an object containing all the info
  image // Assuming an image URL is passed directly
}) => {
  const [heart, setHeart] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const db = getDatabase();
  const currentUser = auth.currentUser;

  useEffect(() => {
    if (currentUser) {
      const followRef = ref(db, `follows/${currentUser.uid}/${business.id}`);
      get(followRef).then((snapshot) => {
        if (snapshot.exists()) {
          setIsFollowing(true);
        }
      });
    }
  }, [currentUser, business.id, db]);

  const handleOpen = () => {
    onOpen();
  };
console.log(business)
  const handleFollowToggle = async () => {
    if (!currentUser) return;

    const followRef = ref(db, `follows/${currentUser.uid}/${business.id}`);
    if (isFollowing) {
      // Unfollow the business
      await remove(followRef);
      setIsFollowing(false);
    } else {
      // Follow the business
      await set(followRef, {
        businessName: business.name,
        businessProfileImage: business.profileImage,
      });
      setIsFollowing(true);
    }
  };

  return (
    <>
      <Card extra={`flex flex-col w-full h-full !p-4 bg-white`}>
        <div className="relative w-full">
          <img
            src={image || business.profileImage || "https://via.placeholder.com/150"} // Use profileImage or default
            alt={business.name}
            className="mb-3 h-full w-full rounded-xl object-cover"
          />
          <button
            onClick={() => setHeart(!heart)}
            className="absolute top-3 right-3 flex items-center justify-center rounded-full bg-white p-2 text-brand-500 hover:bg-gray-100"
          >
            {heart ? (
              <IoHeart className="text-red-500 text-xl" />
            ) : (
              <IoHeartOutline className="text-xl" />
            )}
          </button>
        </div>

        <div className="px-5 py-4 dark:text-white">
          <h3 className="text-xl font-bold text-navy-700 dark:text-white">{business.name}</h3>
          <p className="mt-1 text-sm text-gray-600">{business.description}</p>
          <p className="mt-2 text-sm text-gray-500">Founded: {business.founded}</p>
          <p className="text-sm text-gray-500">Industry: {business.industry}</p>
          <p className="mb-3 text-sm text-gray-500">Location: {business.location}</p>
          <button
            className="inline-block linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
            onClick={handleOpen}
          >
            View Projects
          </button>
          <button
            onClick={handleFollowToggle}
            className={`mt-3 inline-block linear rounded-[20px] px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-${isFollowing ? 'red-700' : 'green-700'} active:bg-${isFollowing ? 'red-600' : 'green-600'} dark:hover:bg-${isFollowing ? 'red-600' : 'green-600'} dark:active:bg-${isFollowing ? 'red-500' : 'green-500'} ${isFollowing ? 'bg-red-500' : 'bg-green-500'} ml-2`}
          >
            {isFollowing ? "Unfollow" : "Follow"}
          </button>
        </div>
      </Card>

      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody className="dark:bg-navy-900 dark:text-white">
            <BusinessCollection businesses={{ [business.id]: business }} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BusinessCard;
