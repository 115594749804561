import React from 'react';
import {
  Box, FormControl, FormLabel, Grid, GridItem, Heading, Input, Textarea
} from '@chakra-ui/react';
import { VolunteerData } from '../VolunteerModal';

interface PersonalInformationProps {
    editableVolunteerData: VolunteerData;
    setEditableVolunteerData: React.Dispatch<React.SetStateAction<VolunteerData>>;
  }

  const PersonalInformation: React.FC<PersonalInformationProps> = ({
    editableVolunteerData, 
    setEditableVolunteerData
  }) => {

    // Example of handling a change in the child component
    const handleChange = (e: { target: { value: any; }; }, field: any) => {
        if (field === 'volunteerName') {
          setEditableVolunteerData(prevData => ({
            ...prevData,
            [field]: e.target.value
          }));
        } else if (field === 'skillSet') {
          // Handle skillSet as an array of strings
          const skillsArray = e.target.value.split(',').map((skill: string) => skill.trim());
          setEditableVolunteerData({ ...editableVolunteerData, skillSet: skillsArray });
        } else {
          setEditableVolunteerData({ ...editableVolunteerData, [field]: e.target.value });
        };
      }
  console.log(editableVolunteerData)
    return (
        <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
  gap={3}
      >
    {/* Personal Information */}
    <GridItem colSpan={{ base: 1, md: 3 }} >
      <Box  >
        {/* <Heading fontSize="xl" mt={6} mb={4}>Personal Information</Heading> */}
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap={6}>
          <GridItem>
          <FormControl>
          <FormLabel fontSize="xl">Name</FormLabel>
          <Input 
            value={editableVolunteerData.volunteerName || ''}
            onChange={(e) => handleChange(e, 'volunteerName')}
          />
        </FormControl>
          </GridItem>
          <GridItem>
          <FormControl>
          <FormLabel fontSize="xl">Age</FormLabel>
          <Input 
            type="number" 
            value={editableVolunteerData.volunteerAge || ''} 
            onChange={(e) => handleChange(e, 'volunteerAge')} 
          />
        </FormControl>
          </GridItem>
          <GridItem>
        <FormControl>
          <FormLabel fontSize="xl">Email</FormLabel>
          <Input 
            type="email" 
            value={editableVolunteerData.volunteerEmail || ''} 
            onChange={(e) => handleChange(e, 'volunteerEmail')} 
            placeholder={editableVolunteerData.volunteerEmail}
          />
        </FormControl>
      </GridItem>
          {/* Additional personal information fields... */}
        </Grid>
      </Box>
      {/* <Heading fontSize="xl" mt={6} mb={4}> Documantation</Heading> */}
    </GridItem>

    {/* Skills, Interests, and Notes */}

 
    <GridItem colSpan={{ base: 1, md: 1 }}>
      <Box className="card" p={5}>
        <Heading fontSize="xl" mt={6} mb={4}>Interests</Heading>
        <Textarea value={editableVolunteerData.interestAreas || ''} onChange={(e) => handleChange(e, 'interestAreas')} placeholder="Add interests here..." size="md"
          resize="vertical"
          focusBorderColor="blue.300"
          borderColor="gray.300"
          _hover={{ borderColor: 'gray.400' }}
          _placeholder={{ color: 'gray.500' }}
          padding="4"
          borderRadius="md"
          boxShadow="sm"
          lineHeight="tall" />
      </Box>
    </GridItem>
    <GridItem colSpan={{ base: 1, md: 1 }}>
      <Box className="card" p={5}>
        <Heading fontSize="xl" mt={6} mb={4}>Notes</Heading>
        <Textarea value={editableVolunteerData.personalNotes || ''} onChange={(e) => handleChange(e, 'personalNotes')} placeholder="Add notes here..." size="md"
          resize="vertical"
          focusBorderColor="blue.300"
          borderColor="gray.300"
          _hover={{ borderColor: 'gray.400' }}
          _placeholder={{ color: 'gray.500' }}
          padding="4"
          borderRadius="md"
          boxShadow="sm"
          lineHeight="tall" />
      </Box>
    </GridItem>
    {/* Add other sections as needed... */}
  </Grid>
  );
};

export default PersonalInformation;
