import React, { useState } from 'react';
import { Button, Input, Textarea, useToast } from '@chakra-ui/react';

const AccomplishmentCardForm = ({ onSave }) => {
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  const toast = useToast();

  const handleSubmit = () => {
    onSave({ title, details, type: 'accomplishment' });
    toast({
      title: 'Accomplishment Saved',
      description: 'The accomplishment has been successfully saved.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <div>
      <Input placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
      <Textarea placeholder="Details" value={details} onChange={(e) => setDetails(e.target.value)} />
      <Button onClick={handleSubmit}>Save Accomplishment</Button>
    </div>
  );
};

export default AccomplishmentCardForm;
