import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavbarAuth from './NavbarAuth';
import Footer from 'components/footer/Footer';
import { FaComments, FaUsers, FaSearch, FaShieldAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import DesktopVideo from 'assets/svg/DesktopPromo.mp4'; // Ensure this path is correct
import MobileVideo from 'assets/svg/Webpromo.mp4'; // Ensure this path is correct
import DemoImage1 from 'assets/svg/A0.png';
import DemoImage2 from 'assets/svg/A05.png';
import DemoImage3 from 'assets/svg/A08.png';
import './style.css';

const BusinessLandingPage: React.FC = () => {
  const location = useLocation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoDuration = 10; // Adjust based on your video duration

  const images = [
    { src: DemoImage1, alt: 'Demo 1', caption: 'Unlocking Insights with AI Analytics: Empowering Data-Driven Decisions for Your Business Success' },
    { src: DemoImage2, alt: 'Demo 2', caption: 'Seamlessly Manage and Deploy Missions: Streamline Events, Company Needs, and Collaborations for Maximum Impact' },
    { src: DemoImage3, alt: 'Demo 3', caption: 'Crafting Meaningful Missions: From Concept to Execution, We Turn Ideas into Action for Positive Change' }
  ];

  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;
  
    const handleScroll = () => {
      const maxScrollY = document.documentElement.scrollHeight - window.innerHeight;
      const scrollFraction = window.scrollY / maxScrollY;
      const videoTime = scrollFraction * videoDuration;
  
      // Maintain existing logic for scroll-based video playback
      if (!isPlaying && scrollFraction > 0.75) {
        videoElement.currentTime = videoTime;
        videoElement.play();
        setIsPlaying(true);
      } else if (isPlaying && scrollFraction <= 0.75) {
        videoElement.pause();
        setIsPlaying(false);
      } else {
        videoElement.currentTime = videoTime;
      }
    };
  
    // Add intersection observer for initial playback
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !isPlaying) { // Add a check for !isPlaying
          videoElement.currentTime = 0; // Reset video time for initial view
          // You can choose to play the video here if desired:
          // videoElement.play();
          // setIsPlaying(true);
        }
      },
      { threshold: 0.75 }
    );
  
    observer.observe(videoElement);
    window.addEventListener('scroll', handleScroll); // Keep scroll listener
  
    return () => {
      observer.unobserve(videoElement);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isPlaying, videoRef, videoDuration]); // Update dependencies
  
  
  

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="dark:bg-navy-900 rounded" key={location.pathname}>
      <div className='text-white'>
        <NavbarAuth />
      </div>
      <div className="relative">
        <div className="flex flex-col items-center justify-center max-w-full md:px-0">
          <div className="relative w-full h-screen">
            {window.innerWidth >= 769 && (
              <video
                className="absolute top-0 left-0 w-full h-full object-cover"
                ref={videoRef}
                muted
                loop
              >
                <source src={DesktopVideo} media="(min-width: 769px)" />
              </video>
            )}
            {window.innerWidth < 769 && (
              <video
                className="absolute top-0 left-0 w-full h-full object-cover"
                ref={videoRef}
                muted
                loop
              >
                <source src={MobileVideo} media="(max-width: 768px)" />
              </video>
            )}
          </div>
        </div>
        <div className="mt-8 text-center">
          <p className="text-purple-700 font-bold text-base lg:text-xl mb-4 mt-5">
            Why Choose HarmonyFlow?
          </p>
          <div className="flex flex-wrap justify-center max-w-full px-8 md:px-0">
            <FeatureCard icon={<FaComments />} title="Engage" description="Transform customer interactions with AI-enhanced communication." />
            <FeatureCard icon={<FaUsers />} title="Collaborate" description="Foster meaningful connections and partnerships within your community." />
            <FeatureCard icon={<FaSearch />} title="Discover" description="Gain actionable insights and drive informed decision-making." />
            <FeatureCard icon={<FaShieldAlt />} title="Impact" description="Lead with purpose and track your social responsibility initiatives." />
          </div>
          <p className="text-gray-900 font-bold text-3xl md:text-5xl mb-8 dark:text-white text-center">
            Drive Success & Make an Impact
            <span className="relative">
              {' '}with HarmonyFlow
            </span>
          </p>
          <p className="text-gray-600 font-medium text-base lg:text-lg w-full mx-auto mb-4 dark:text-white text-center">
            Embrace a new era of business where every decision and action is backed by advanced AI and a commitment to social good. HarmonyFlow is designed to help you lead, inspire, and create a better tomorrow.
          </p>
          <div className="relative w-full mx-auto mb-8">
            <div className="flex flex-col items-center">
              <div className="relative w-full">
                <button onClick={handlePrevious} className="absolute left-0 transform -translate-y-1/2 top-1/2 p-3 bg-gray-800 text-white rounded-full">
                  <FaArrowLeft />
                </button>
                <button onClick={handleNext} className="absolute right-0 transform -translate-y-1/2 top-1/2 p-3 bg-gray-800 text-white rounded-full">
                  <FaArrowRight />
                </button>
                <div className="flex flex-col items-center mt-4 w-full">
                  <img src={images[currentIndex].src} alt={images[currentIndex].alt} className="w-full rounded-lg shadow-lg" />
                  <p className="text-black-600 font-medium text-sm lg:text-base mt-2 text-center dark:text-white">
                    {images[currentIndex].caption}
                  </p>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                {images.map((_, index) => (
                  <span key={index} className={`h-3 w-3 rounded-full mx-1 ${index === currentIndex ? 'bg-gray-800 dark:bg-white' : 'bg-gray-400'}`}></span>
                ))}
              </div>
            </div>
          </div>
          <p className="text-purple-700 font-bold text-base lg:text-xl mb-4 mt-5 text-center">
            Join the Movement Today
          </p>
          <p className="text-gray-900 font-bold text-3xl md:text-5xl mb-8 dark:text-white text-center">
            Innovate, Connect, Thrive
            <span className="relative">
              {' '}with HarmonyFlow
            </span>
          </p>
          <p className="text-gray-600 font-medium text-base lg:text-lg w-full mx-auto mb-4 dark:text-white text-center">
            Whether you're a small business or a large enterprise, HarmonyFlow's AI-powered CRM and social responsibility platform will transform the way you operate and connect with your community. Elevate your business today.
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const FeatureCard: React.FC<{ icon: React.ReactNode; title: string; description: string }> = ({ icon, title, description }) => (
  <div className="flex flex-col items-center m-4 w-full sm:w-1/2 lg:w-1/4">
    <div className="text-4xl mb-2">{icon}</div>
    <p className="font-bold text-lg">{title}</p>
    <p className="text-center text-sm text-gray-600">{description}</p>
  </div>
);

export default BusinessLandingPage;
