import LineChart from "components/charts/LineChart";
import { lineChartDataSidebar } from "variables/charts";
import { lineChartOptionsSidebar } from "variables/charts";
import avatar4 from "assets/img/avatars/avatar4.png";
import { useState, useEffect, useContext, useRef } from "react";
import {
  Badge,
  Box,
  Button,
  Flex,
  Icon,
  Link,
  Menu,
  MenuButton,
  MenuList,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import auth from "../../../firebase"
import { getDatabase, ref, onValue, set, update } from "firebase/database";


const FreeCard = () => {
 
    const [userEmail, setUserEmail] = useState("");
    const [companyKey, setCompanyKey] = useState("");
    const [userKey, setUserKey] = useState("");
    const [userInfo, setUserInfo] = useState({
      username: "",
      email: "",
      firstName: "",
      lastName: "",
      job: "",
      aboutMe: "",
    });
    const db = getDatabase();
    const currentUser = auth.currentUser
    useEffect(() => {
      const email = currentUser?.email || "";
      if (email) {
        setUserEmail(email);
  
        // retrieve user's information from Firebase Realtime Database
        const userRef = ref(db, `companies/${companyKey}/users/${userKey}/users/${currentUser.uid}`);
        onValue(userRef, (snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            setUserInfo({
              username: data.username,
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              job: data.job,
              aboutMe: data.aboutMe,
             
            });
          
          }
         
        });
      }
    }, [currentUser, db]);
  
    const handleInputChange = (event: { target: { id: any; value: any; }; }) => {
      const { id, value } = event.target;
      setUserInfo((prevUserInfo) => ({
        ...prevUserInfo,
        [id]: value,
      }));
    };
    const handleSaveChanges = (event: any) => {
      const userRef = ref(db, `debug/users/${currentUser.uid}`);
      update(userRef,userInfo); // update the user information in the state
    
      // update the user information in the Firebase Realtime Database
      update(userRef, {
        username: userInfo.username,
        email: userInfo.email,
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        job: userInfo.job,
        aboutMe: userInfo.aboutMe,
      })
        .then(() => {
          console.log("User information updated successfully.");
        })
        .catch((error) => {
          console.error("Error updating user information: ", error);
        });
    };
  return (
    <div className="relative flex h-[300px] w-[240px] flex-col items-center rounded-[20px] bg-gradient-to-br from-brandLinear to-blueSecondary">
      <div className="mt-8 flex flex-col items-center">
        <h4 className="text-2xl font-bold text-white">13 Tasks to do</h4>
        <p className="mt-[4px] text-xs font-medium text-white">This weeks tasks</p>
        <div className="mt-3 flex items-center justify-center rounded-[20px] bg-[#C9FBD5] py-1 px-2">
          <p className="text-xs font-bold text-green-500">+2.45%</p>
        </div>
      </div>

      {/* Sidebar Card */}
      <div className="h-full w-full px-3 pb-3">
        <LineChart
          chartData={lineChartDataSidebar}
          chartOptions={lineChartOptionsSidebar}
        />
      </div>
      {/* Sidebar profile info */}
      <div className="my-14 ml-2 flex items-center justify-center gap-3">
        <div className="h-12 w-12 rounded-full bg-blue-200">
          <img src={avatar4} className="rounded-full" alt="avatar" />
        </div>
        <div className="ml-1">
          <h4 className="text-base font-bold text-navy-700 dark:text-white">
            {userEmail}
          </h4>
          <p className="text-sm font-medium text-gray-600">{}</p>
        </div>
      </div>
    </div>
  );
};

export default FreeCard;
