// src/pages/VolunteerForum.tsx
import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';
import { collection, getDocs, addDoc } from "firebase/firestore";
import { useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Text, Box, Container, Heading, VStack, useColorModeValue, Button, HStack, Tab, TabList, TabPanel, TabPanels, Tabs,Image } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { MdAdd } from 'react-icons/md';
import { NewCategoryForm } from './components/NewCategoryForm';
import ThreadList from './components/ThreadList';
import SearchIcon from 'components/icons/SearchIcon';
import ForYouComponent from './components/ForYouComponent';

type ForumCategory = {
    id: string;
    title: string;
    description: string;
};

const VolunteerForum: React.FC = () => {
    const [categories, setCategories] = useState<ForumCategory[]>([]);
    const [newCategoryTitle, setNewCategoryTitle] = useState('');
    const [newCategoryDescription, setNewCategoryDescription] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(null);
    const navigate = useNavigate();
    const db = getFirestore();
    const [searchQuery, setSearchQuery] = useState('');
const [searchResults, setSearchResults] = useState<ForumCategory[]>([]);



    useEffect(() => {
        const fetchCategories = async () => {
            const querySnapshot = await getDocs(collection(db, "categories"));
            const categoriesData: ForumCategory[] = [];
            querySnapshot.forEach((doc) => {
                categoriesData.push({
                    id: doc.id,
                    title: doc.data().title,
                    description: doc.data().description,
                });
            });
            setCategories(categoriesData);
        };

        fetchCategories();
    }, [db,]);

    const handleNewCategory = async () => {
        await addDoc(collection(db, "categories"), {
            title: newCategoryTitle,
            description: newCategoryDescription,
        });
        setNewCategoryTitle('');
        setNewCategoryDescription('');
        // Optionally fetch categories again to update the list
    };

    const bg = useColorModeValue('white', 'gray.800');

    const handleCategoryClick = (categoryId: string) => {
        navigate(`/volunteer/category/${categoryId}`);
      };

    const refreshCategories = async () => {
        const querySnapshot = await getDocs(collection(db, "categories"));
        const categoriesData: ForumCategory[] = [];
        querySnapshot.forEach((doc) => {
            categoriesData.push({
                id: doc.id,
                title: doc.data().title,
                description: doc.data().description,
            });
        });
        setCategories(categoriesData);
    };

    const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const newQuery = event.target.value;
        setSearchQuery(newQuery);
      
        // Implement search logic here
        // For demonstration, filtering categories directly as an example
        // Replace this with your actual search logic
        const results = categories.filter(category =>
          category.title.toLowerCase().includes(newQuery.toLowerCase())
        );
        setSearchResults(results);
      };
      
      const handleSearchSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // Implement search logic here
        // For demonstration, we're pretending to set searchResults directly
        // In practice, you'd search your threads here
        const results = categories.filter(category =>
          category.title.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setSearchResults(results);
      };
    return (
        <>     
        {/* Background overlay for text legibility */}
        <div className="relative w-full h-1/3"> {/* Adjust the height as needed */}
    {/* Background image */}
    <div className="absolute inset-0 w-full h-full bg-cover bg-no-repeat" >
    </div>

    {/* Background overlay for text legibility */}
    <div className="absolute inset-0 bg-black bg-opacity-50"></div>

    {/* Content container */}
    <div className="relative flex flex-col items-center justify-center w-full h-full">
      {/* Search bar */}
      <div className="w-full max-w-md mx-auto rounded-xl bg-white bg-opacity-80 p-4 !z-5 relative flex flex-col rounded-[20px] bg-white dark:!bg-navy-800 dark:text-white">
      <form onSubmit={handleSearchSubmit} className="w-full max-w-md mx-auto rounded-xl bg-white bg-opacity-80 p-4">
  <SearchIcon />
  <input
    type="text"
    placeholder="Ask a question see what others are saying"
    className="w-full rounded-xl text-sm font-medium outline-none pl-4"
    value={searchQuery}
    onChange={handleSearchChange}  // Notice the onChange handler here
  />
 
</form>
{searchResults.length > 0 && (
  <VStack spacing={4}>
    {searchResults.map((result) => (
      <Box key={result.id} className="search-result-card" onClick={() => navigate(`/volunteer/category/${result.id}`)}>
        <Text>{result.title}</Text>
        <Text>{result.description}</Text>
      </Box>
    ))}
  </VStack>
)}

      </div>

      {/* Category cards container */}
      <div className="mt-4 w-full max-w-md mx-auto overflow-x-auto flex snap-x gap-4 p-4">
      
        {/* Category cards */}
        {categories.map((category) => (
       
        <div
            key={category.id}
            className="snap-start shrink-0 w-40 h-20 r !z-5 relative flex flex-col rounded-[20px] bg-#F7FAFC dark:!bg-navy-800 dark:text-white p-4 shadow-md  "
            onClick={() => handleCategoryClick(category.id)}
          >
            <h3 className="text-sm font-semibold">{category.title}</h3>
         
            {/* <p className="text-sm">{category.description}</p> */}
          </div>
        ))}
      </div> 
      <MdAdd />
         <Text className='dark:text-white'>
Add Category
        </Text>
    </div>
  </div>
   <Box className='featured-card'>
                <Tabs variant="soft-rounded" align="center" isFitted mt={4} flexGrow={4} className='dark:bg-navy-900'>
                    <TabList>
                        <Tab _selected={{ bg: '#7551FF', color: 'white' }}>For You</Tab>
                        <Tab _selected={{ bg: '#7551FF', color: 'white' }}>Featured</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {/* Content for 'For You' tab */}
                            <ForYouComponent/>
                        </TabPanel>
                        <TabPanel>
                            {/* Content for 'Featured' tab */}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box></>
    );
};

export default VolunteerForum;
