import EagleView from "./components/EagleView";
import MapCard from "./components/MapCard";
import Phone from "./components/Phone";
import Connect from "views/admin/main/account/settings/components/Connect";

const CarInterface = () => {
  return (
    <div className=" h-full w-full grid-cols-1 gap-5 rounded-[20px] ">
   <Connect/>
    </div>
  );
};

export default CarInterface;
