import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Text,
  useToast,
} from '@chakra-ui/react';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { durationOptions,subscriptionOptions } from 'data/ subscription';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getDatabase, ref, runTransaction } from 'firebase/database';

interface SubscriptionResponse {
  subscriptionId: string;
}

const stripePromise = loadStripe('pk_live_51PDAuJLqk5h9G7zkz1Db5DhgfF5Xx4ehYIdfYMjpvdoujobnjy4e7XX05DZOLkwvWF9Xs82KLShtq7VIDZVsUQUp004rsSREaL');

const PaymentModal = ({ isOpen, onClose, updateBusinessStatus }) => {
  const [subscriptionType, setSubscriptionType] = useState('basic');
  const [duration, setDuration] = useState('1');
  const [totalPrice, setTotalPrice] = useState(subscriptionOptions.basic.pricePerMonth * durationOptions['1'].months);
  const [businessName, setBusinessName] = useState('');
  const [businessNameError, setBusinessNameError] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const auth = getAuth();
  const functions = getFunctions();
  const database = getDatabase();

  useEffect(() => {
    const newTotalPrice = subscriptionOptions[subscriptionType].pricePerMonth * durationOptions[duration].months;
    setTotalPrice(newTotalPrice);
  }, [subscriptionType, duration]);

  const generateBusinessId = async () => {
    const businessIdRef = ref(database, 'businessIds/nextId');
    const newBusinessId = await runTransaction(businessIdRef, (currentId) => {
      return (currentId || 0) + 1;
    });
    return `businessId${newBusinessId.snapshot.val()}`;
  };

  const handlePayment = async () => {
    if (!stripe || !elements) {
      toast({
        title: 'Error',
        description: "Stripe has not loaded yet. Please try again.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
  
    if (!businessName) {
      setBusinessNameError('Business name is required');
      return;
    }
  
    setBusinessNameError('');
  
    const cardElement = elements.getElement(CardElement);
  
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
  
      if (error) {
        throw error;
      }
  
      const attachPaymentMethod = httpsCallable(functions, 'attachPaymentMethod');
      const createSubscription = httpsCallable(functions, 'createSubscription');
      const updateBusinessStatusFunction = httpsCallable(functions, 'updateBusinessStatus');
  
      const user = auth.currentUser;
      console.log('Current user:', user);
      const businessId = await generateBusinessId();
      console.log('Generated business ID:', businessId);
  
      const attachResponse = await attachPaymentMethod({ paymentMethodId: paymentMethod.id });
      console.log('Attach payment method response:', attachResponse);
  
      const priceId = subscriptionOptions[subscriptionType].priceId;
      console.log('Price ID:', priceId);
  
      if (!priceId) {
        throw new Error('Price ID is not defined');
      }
  
      const subscriptionResponse = await createSubscription({ priceId });
      console.log('Create subscription response:', subscriptionResponse);
  
      const { subscriptionId } = (subscriptionResponse.data as SubscriptionResponse);
      console.log('Subscription ID:', subscriptionId);
  
      const updateResponse = await updateBusinessStatusFunction({ userId: user.uid, businessName, businessId });
      console.log('Update business status response:', updateResponse);
  
      updateBusinessStatus(true);
      onClose();
  
      toast({
        title: 'Payment successful',
        description: "You've successfully upgraded to a business account!",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Error during payment process:', error);
      toast({
        title: 'Payment error',
        description: error.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  };
  
  
  
  

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
      <ModalOverlay />
      <ModalCloseButton />
      <ModalContent>
        <ModalBody>
          <FormControl>
            <FormLabel>Business Name</FormLabel>
            <Input
              value={businessName}
              onChange={(e) => setBusinessName(e.target.value)}
              placeholder="Enter your business name"
              isInvalid={!!businessNameError}
            />
            {businessNameError && <Text color="red.500">{businessNameError}</Text>}
            <div className="h-full w-full rounded-xl bg-white px-2 py-3 dark:!bg-navy-800 xl:!pl-10 xl:!pr-4">
              <div className="col-span-12 h-full w/full xl:col-span-7">
                <h1 className="pt-[10px] text-[28px] font-semibold text-navy-700 dark:text-white md:text-[30px]">
                  Business Account
                </h1>
              </div>

              <div className="mt-[35px] lg:mt-[38px]">
                <p className="text-lg font-medium text-gray-600 line-through">
                  $1,235.00
                </p>
                <div className="flex items-center gap-2">
                  <p className="text-sm font-medium text-gray-600">
                    Starting at
                  </p>
                  <h2 className="mt-[3px] text-[32px] font-bold text-navy-700 dark:text-white">
                    $250.00
                  </h2>
                  <div className="flex w-[95px] items-center justify-center rounded-lg bg-green-100 py-2 text-xs font-bold text-green-500 dark:bg-green-50">
                    Per Month
                  </div>
                </div>
              </div>

              <div className="mt-[42px] grid w/full grid-cols-1 gap-4 lg:grid-cols-2 3xl:w-5/6">
                <div className="w/full">
                  <label className="ml-3 mb-2 text-sm font-bold text-navy-700 dark:text-white">
                    Subscription
                  </label>
                  <div className="w/full rounded-xl border border-gray-200 py-3 pr-3 pl-2.5 text-sm font-normal text-gray-600 outline-none dark:!border-white/10 dark:!bg-navy-800 dark:text-white sm:w/full md:w/full">
                    <Select value={subscriptionType} onChange={(e) => setSubscriptionType(e.target.value)}>
                      {Object.entries(subscriptionOptions).map(([key, option]) => (
                        <option key={key} value={key}>{option.description}</option>
                      ))}
                    </Select>
                    <Select value={duration} onChange={(e) => setDuration(e.target.value)}>
                      {Object.entries(durationOptions).map(([key, option]) => (
                        <option key={key} value={key}>{option.description}</option>
                      ))}
                    </Select>
                    <Text mt={4}>Total Price: ${totalPrice}</Text>
                  </div>
                </div>
              </div>

              <h2 className="mt/[35px] text/[20px] font-bold text-navy-700 dark:text-white">
                Subscribe
              </h2>
              <CardElement />
              <button className="linear mt/[24px] flex w/full items-center justify-center rounded-xl bg-brand-500 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active/bg-brand-200 3xl:w-5/6" onClick={handlePayment} disabled={!stripe}>
                Pay ${totalPrice}
              </button>
            </div>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant='ghost' className='mt-5' onClick={onClose}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

const StripeWrapper = ({ children }) => {
  return (
    <Elements stripe={stripePromise}>
      {children}
    </Elements>
  );
};

export default function WrappedPaymentModal(props) {
  return (
    <StripeWrapper>
      <PaymentModal {...props} />
    </StripeWrapper>
  );
}
