import React, { useState } from 'react';
import { Button, Input, Textarea, useToast } from '@chakra-ui/react';

const UpdateCardForm = ({ onSave }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const toast = useToast();

  const handleSubmit = () => {
    onSave({ title, description, type: 'update' });
    toast({
      title: 'Update Saved',
      description: 'The update has been successfully saved.',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  return (
    <div>
      <Input placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
      <Textarea placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} />
      <Button onClick={handleSubmit}>Save Update</Button>
    </div>
  );
};

export default UpdateCardForm;
