import React, { useContext, useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { signInWithEmailAndPassword } from "firebase/auth";
import { AuthContext } from "contexts/AuthContext";
import { Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";
import auth from '../../../firebase';
import Checkbox from "components/checkbox";

const SignInDefault = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [error, setError] = useState(null);
  const { setUserRole, userRole } = useContext(AuthContext);

  const handleEmailAndPasswordSignIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;

        if (keepLoggedIn) {
          localStorage.setItem("user", JSON.stringify(user));
        }

        // Set user role and redirect
        setUserRole(userRole);
        navigate('/volunteer');
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = error.message;

        if (errorCode === 'auth/wrong-password' || errorCode === 'auth/user-not-found') {
          errorMessage = "Incorrect email or password.";
        } else if (errorCode === 'auth/network-request-failed') {
          errorMessage = "Network error. Please check your internet connection and try again.";
        }

        setError(errorMessage);
      });
  };

  return (
    <Default
      maincard={
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
              Sign In
            </h3>
            <p className="mb-9 ml-1 text-base text-gray-600">
              Enter your email and password to sign in!
            </p>

            {error && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}

            <form onSubmit={handleEmailAndPasswordSignIn}>
              <InputField
                variant="auth"
                extra="mb-3"
                label="Email*"
                placeholder="mail@simmmple.com"
                id="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Password*"
                placeholder="Min. 8 characters"
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="mb-4 flex items-center justify-between px-2">
                <div className="mt-2 flex items-center">
                  <Checkbox
                    id="keepLoggedIn"
                    checked={keepLoggedIn}
                    onChange={(e) => setKeepLoggedIn(e.target.checked)}
                  />
                  <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                    Keep me logged in
                  </p>
                </div>
                <NavLink
                  className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                  to="/auth/forgot-password/default"
                >
                  Forgot Password?
                </NavLink>
              </div>
              <button type="submit" className="linear w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                Sign In
              </button>
              <div className="mt-4">
                <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                  Not registered yet?
                </span>
                <NavLink
                  to="/auth/sign-up/default"
                  className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                >
                  Create an account
                </NavLink>
              </div>
            </form>
          </div>
        </div>
      }
    />
  );
}

export default SignInDefault;
