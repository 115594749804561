import React, { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot, getFirestore, updateDoc, doc } from 'firebase/firestore';
import { Box, Text, VStack, Badge, Button } from '@chakra-ui/react';

const Notifications = ({ userId }) => {
  const [notifications, setNotifications] = useState([]);
  const firestore = getFirestore();

  useEffect(() => {
    if (!userId) return;

    const notificationsRef = collection(firestore, 'notifications');
    const q = query(notificationsRef, where('userId', '==', userId));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedNotifications = [];
      querySnapshot.forEach((doc) => {
        fetchedNotifications.push({ id: doc.id, ...doc.data() });
      });
      setNotifications(fetchedNotifications);
    });

    return () => unsubscribe();
  }, [userId, firestore]);

  const markAsRead = async (id) => {
    const notificationRef = doc(firestore, 'notifications', id);
    await updateDoc(notificationRef, { read: true });
  };

  return (
    <VStack spacing={4} align="stretch">
      {notifications.map((notification) => (
        <Box
          key={notification.id}
          p={4}
         
          bg={notification.read ? 'gray.100' : 'white'}
          onClick={() => markAsRead(notification.id)}
        >
          <Text fontWeight="bold">{notification.message}</Text>
          <Badge colorScheme={notification.read ? 'green' : 'red'}>
            {notification.read ? 'Read' : 'Unread'}
          </Badge>
        </Box>
      ))}
      {notifications.length === 0 && (
        <Box p={4} >
          <Text>No notifications</Text>
        </Box>
      )}
    </VStack>
  );
};

export default Notifications;
