import Card from "components/card";
import TimelineItem from "components/dataDisplay/TimelineItem";
import { Button, ButtonProps } from '@chakra-ui/react';
import { MdAdd, MdOutlineCalendarToday } from "react-icons/md";
import { Link } from 'react-router-dom';
interface EventProps {
  title: string;
  start: string;
  end: string;
}

interface TimeLineProps {
  events: EventProps[];
}

const TimeLine = ({ events }: TimeLineProps) => {

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  return (
    <Card extra={"w-full p-5"}>
      {/* Header */}
      <div>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Timeline
        </h4>
        <br>
        </br>
        <Button className="flex items-center justify-center rounded-[20px] bg-brand-500 p-3 text-4xl font-bold text-white shadow-1xl shadow-brand-500 dark:bg-brand-400 dark:shadow-brand-400" 
  as={Link} 
  to="/admin/main/ecommerce/new-mission" 
    colorScheme={'#7551FF'}
  leftIcon={<MdAdd />} 
>
  Create a new Mission
</Button>
        <p className="text-base font-medium text-gray-600">
          <br/>
          See your Missions in your timeline:
        </p>
      </div>

      {/* Timeline items */}
      <div className="mt-[30px]">
      {events.map((event, index) => {
  if (!event.start || !event.end) {
    return null;  // Skip this event if it doesn't have a start date or end date
  }

  const [startDateString, startTimeString] = event.start.split("T");
  const [endDateString, endTimeString] = event.end.split("T");

  // Add ":00" to the time to set the seconds to 00
  const startDate = new Date(`${startDateString}T${startTimeString}:00`);
  const endDate = new Date(`${endDateString}T${endTimeString}:00`);

  const startHours = startDate.getHours();
  const startMinutes = startDate.getMinutes();
  const endHours = endDate.getHours();
  const endMinutes = endDate.getMinutes();

  console.log('Event:', event);  // Log the event data
  console.log('Parsed dates:', startDate, endDate);  // Log the parsed dates

  return (
    <TimelineItem
      key={index}
      title={event.title}
      day={startDate.getDate().toString()}
      weekday={days[startDate.getDay()]}
      hours={`${startHours}:${startMinutes} - ${endHours}:${endMinutes}`}
      current="current"
      mb="mb-[16px]"
    />
  )
})}

      </div>
    </Card>
  );
};

export default TimeLine;
