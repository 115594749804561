import Card from "components/card";
import React, { useEffect, useState } from "react";
import CircularProgress from "components/charts/CircularProgress";
import "react-circular-progressbar/dist/styles.css";

// Dummy data for demonstration purposes
const volunteerData = {
  totalVolunteers: 3,
  activeVolunteers: 3,
  mostCommonSkill: "First Aid",
  averageHours: "5 hours per week",
};

function Conversion() {
  const [activePercentage, setActivePercentage] = useState(0);

  useEffect(() => {
    // Calculate the percentage of active volunteers
    const percentage =
      (volunteerData.activeVolunteers / volunteerData.totalVolunteers) * 100;
    setActivePercentage(percentage);
  }, []);

  return (
    <Card extra={"w-full py-4 px-8"}>
      <div className="mt-2 flex flex-col px-4 text-center">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Volunteer Data
        </p>
        <p className="mt-2 px-4 text-sm font-medium text-gray-600">
          Most Common Skill: {volunteerData.mostCommonSkill}
        </p>
      </div>
      {/* Circular progressbar */}
      <div className="mx-auto mt-3 flex h-[140px] w-[130px] items-center justify-center">
        <CircularProgress title="Active" percentage={activePercentage} />
      </div>

      {/* Additional metrics */}
      <div className="mt-2 flex h-fit w-full items-center justify-center rounded-[20px] px-4 py-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-normal text-gray-600">Average Contribution</p>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {volunteerData.averageHours}
          </p>
        </div>
        <div className="mx-8 h-11 w-px bg-gray-400 dark:!bg-white/20" />
        <div className="flex flex-col items-center justify-center">
          <p className="text-xs font-normal text-gray-600">Total Volunteers</p>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {volunteerData.totalVolunteers}
          </p>
        </div>
      </div>
    </Card>
  );
}

export default Conversion;
