import React, { useState, useEffect } from "react";
import MiniStatistics from "./components/MiniStatistics";
import { MdOutlineBarChart, MdPerson, MdFileCopy } from "react-icons/md";
import ManagementTable from "./components/ManagementTable";
import auth, { ref, db } from "../../../../../firebase";
import { onValue, DataSnapshot } from "firebase/database";
import FakeBarChart from "assets/img/account/FakeBarChart.png";
interface UserProps {
  team: string;
  companyCode: string;
  email: string;
  firstName: string;
  lastName: string;
  job: string;
  profilePicture: string;
  uid: string;
  userName: string;
  username: string;
  aboutMe: string;
  permissions: string;
  price: string;
}

interface VolunteerProps {
  firstName: string;
  lastName: string;
  email: string;
  uid: string;
  profileURL: string;
}

type RowObj = {
  id: string;
  email: string;
  job: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
  team: string;
  permissions: string;
  price: string | number;
  edit?: any;
  view?: any;
};

const Application = () => {
  const [companyKey, setCompanyKey] = useState<string | null>(null);
  const [userData, setUserData] = useState<RowObj[]>([]);
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [totalSpendings, setTotalSpendings] = useState<number>(0);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [totalProjects, setTotalProjects] = useState<number>(0);
  const currentUser = auth.currentUser;

  useEffect(() => {
    const email = currentUser?.email || "";
    if (email) {
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                break;
              }
            }
          }
        }
      });
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchUserData = async () => {
      const userArray: RowObj[] = [];
      const teamUids: Set<string> = new Set();

      const fetchUsers = (path: string) => {
        return new Promise<void>((resolve) => {
          const userRef = ref(db, path);
          onValue(userRef, (snapshot: DataSnapshot) => {
            const users = snapshot.val() as Record<string, UserProps> | null;
            if (users) {
              const usersData = Object.entries(users).map(([id, userProps]) => {
                const {
                  email,
                  job,
                  firstName,
                  lastName,
                  profilePicture,
                  team,
                  permissions,
                  price,
                } = userProps;

                teamUids.add(userProps.uid);

                return {
                  id,
                  email,
                  job,
                  firstName,
                  lastName,
                  profilePicture,
                  team,
                  permissions,
                  price,
                };
              });
              userArray.push(...usersData);
            }
            resolve();
          });
        });
      };

      const fetchVolunteers = () => {
        return new Promise<void>((resolve) => {
          const volunteersRef = ref(db, "volunteers");
          onValue(volunteersRef, (snapshot: DataSnapshot) => {
            const volunteers = snapshot.val() as Record<string, VolunteerProps> | null;
            if (volunteers) {
              const volunteersData = Object.entries(volunteers)
                .filter(([id, volunteerProps]) => id !== "byUsername" && teamUids.has(volunteerProps.uid))
                .map(([id, volunteerProps]) => {
                  const { firstName, lastName, email, uid, profileURL } = volunteerProps;

                  return {
                    id,
                    email,
                    job: "Volunteer",
                    firstName,
                    lastName,
                    profilePicture: profileURL,
                    team: "Volunteer",
                    permissions: "Volunteer",
                    price: "0",
                  };
                });
              userArray.push(...volunteersData);
            }
            resolve();
          });
        });
      };

      if (companyKey) {
        await fetchUsers(`companies/${companyKey}/users/`);

        // Fetch linked companies and their users
        const linkedCompaniesRef = ref(db, `companies/${companyKey}/linkedCompanies`);
        onValue(linkedCompaniesRef, async (snapshot) => {
          const linkedCompanies = snapshot.val() as Record<string, string> | null;
          if (linkedCompanies) {
            for (const linkedCompanyKey of Object.keys(linkedCompanies)) {
              await fetchUsers(`companies/${linkedCompanyKey}/users/`);
            }
          }

          // Fetch volunteers after fetching all users
          await fetchVolunteers();
          setUserData(userArray);

          // Calculate statistics
          const totalIncomeCalc = userArray.reduce((acc, user) => acc + (parseFloat(user.price as string) || 0), 0);
          const totalSpendingsCalc = userArray.filter(user => user.job === "Volunteer").length * 100; // Example calculation
          const totalUsersCalc = userArray.length;
          const totalProjectsCalc = userArray.filter(user => user.team === "Project Team").length;

          setTotalIncome(totalIncomeCalc);
          setTotalSpendings(totalSpendingsCalc);
          setTotalUsers(totalUsersCalc);
          setTotalProjects(totalProjectsCalc);
        });
      }
    };

    fetchUserData();
  }, [companyKey]);

  return (
    <div className="mt-3 h-full w-full rounded-[20px]">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 3xl:grid-cols-4">
        <div>
          <MiniStatistics
            icon={<MdOutlineBarChart className="text-4xl" />}
            title="Total Income"
            value={`$${totalIncome.toFixed(2)}`}
            growth={"+20%"} // This should be dynamically calculated based on your requirements
            growthColor="text-white"
            cardBg="bg-gradient-to-r from-brandLinear to-blueSecondary"
            titleColor="text-white"
            valueColor="text-white"
            bgColor="bg-gradient-to-r from-blueSecondary to-brandLinear"
            detailColor="text-white"
            iconColor="text-white"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<img alt="imag" src={FakeBarChart} />}
            title="Total Spendings"
            value={`$${totalSpendings.toFixed(2)}`}
            bgColor={"bg-white dark:!bg-navy-800"}
            growth={"-12%"} // This should be dynamically calculated based on your requirements
            growthColor="text-red-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white" 
            detailColor={""} 
            iconColor={""}
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdPerson className="text-4xl" />}
            title="Total Users"
            value={totalUsers.toString()}
            bgColor={"bg-lightPrimary dark:!bg-navy-700"}
            growth={"+16%"} // This should be dynamically calculated based on your requirements
            growthColor="text-green-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white" 
            detailColor={""} 
            iconColor={""}
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdFileCopy className="text-4xl" />}
            title="Total Projects"
            value={totalProjects.toString()}
            bgColor={"bg-lightPrimary dark:bg-navy-700"}
            growth={"+27%"} // This should be dynamically calculated based on your requirements
            growthColor="text-green-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white" 
            detailColor={""} 
            iconColor={""}
          />
        </div>
      </div>
      <div className="mt-3">
        <ManagementTable tableData={userData} />
      </div>
    </div>
  );
};

export default Application;
