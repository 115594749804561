import { DataSnapshot, get, onValue, push, ref, set } from 'firebase/database';
import auth, { db } from '../../../../../../firebase';
import React, { useState, useEffect } from 'react';

import Volunteer from '..';
import axios from 'axios';
import {  Button ,Input, InputGroup, InputRightElement, Spinner,Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Box,
    CloseButton,
    useDisclosure,} from '@chakra-ui/react';
import { preventDefault } from '@fullcalendar/react';

interface VolunteerProps {
 
    volunteerInfo: {
      volunteerName: string;
      volunteerAge: number;
      volunteerEmail: string;
      volunteerAddress:string;
      volunteerStatus: string;
      volunteerDate: string;
      volunteerRole: string;
      volunteerMissions:  string[];
    };
  }
  
  type RowObj = {
    id:string
    volunteerName: [string, string, string];
    volunteerDate: string;
    volunteerRole: string;
    volunteerStatus: string;
    edit?: any;
    volunteerMissions:  string[];
  };

  type RowObj2 = {
    id:string
    missionDetails: {
      missionName: string;
      location: string;
      missionCategory: string;
      missionDescription: string;
      missionGoals: string;
      teamAndVolunteers: {
        teamContact: string;
        teamLeader: string;
        team: string;
        volunteerRequirements: string;
      };
      missionLogistics: {
        missionPartnerships: string;
        missionResources: string;
      };
    };
  };
  const AddVolunteerComponent = () => {
    const [companyKey, setCompanyKey] = useState<string | null>(null);
    const [volunteerData, setVolunteerData] = useState<RowObj[]>([]);
  const currentUser = auth.currentUser;
  const [userEmail, setUserEmail] = useState("");
  
  const [userKey, setUserKey] = useState("");
  const [volunteers, setVolunteers] = useState([]);
  const [missions, setMissions] = useState([]);
  const [selectedMission, setSelectedMission] = useState<string>('');
  const [selectedVolunteers, setSelectedVolunteers] = useState<string[]>([]);
  const [missionData, setMissionData] = useState<RowObj2[]>([]);
  // const sgMail = require("@sendgrid/mail");
  const [isDarkMode, setIsDarkMode] = useState(false);
 
const [isVisible, setIsVisible] = useState(false); 
useEffect(() => {
    const email = currentUser.email || "";
    if (email) {
      setUserEmail(email);
      const usersRef = ref(db, "companies");
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const companies = snapshot.val();
          for (const companyKey in companies) {
            const company = companies[companyKey];
            for (const userKey in company.users) {
              const user = company.users[userKey];
              if (user.email === email) {
                setCompanyKey(companyKey);
                setUserKey(userKey);
                localStorage.setItem("companyKey", companyKey);
              
              }
            }
          }
        }
      });
    }
  }, [currentUser, db]);

  useEffect(() => {
    // Fetch active volunteers from your database
    const fetchVolunteers = async () => {
        if (companyKey) {
          const volunteerRef = ref(db, `companies/${companyKey}/volunteers/`);
          onValue(volunteerRef, (snapshot: DataSnapshot) => {
            const volunteers = snapshot.val() as Record<string, VolunteerProps> | null;
            if (volunteers) {
              const volunteerArray: RowObj[] = Object.entries(volunteers).map(([id, volunteerProps]) => {
                const {
                  volunteerName: [name, email, profilePicture],
                  volunteerAge,
                  volunteerEmail,
                  volunteerAddress = '',
                  volunteerStatus = '',
                  volunteerDate = '',
                  volunteerRole = '',
                  volunteerMissions,
                } = volunteerProps.volunteerInfo;
        
                return {
                  id,
                  volunteerName: [name, email, profilePicture],
                  volunteerEmail,
                  volunteerRole,
                  volunteerAddress,
                  volunteerStatus,
                  volunteerDate,
                  volunteerAge,
                  volunteerMissions,
                };
              });
              setVolunteerData(volunteerArray);
              console.log(volunteerArray);
            } else {
              setVolunteerData([]);
              console.log("No data found");
            }
          });
        }
      };
      
         
          fetchVolunteers();
        }, [companyKey]);

    
 

    // Fetch available missions from your database
    useEffect(() => {
        const fetchMissionData = async () => {
          if (companyKey) {
            const missionsRef = ref(db, `companies/${companyKey}/missions/`);
            onValue(missionsRef, (snapshot: DataSnapshot) => {
              const missions = snapshot.val();
              if (missions) {
                const missionArray: RowObj2[] = Object.values(missions);
                setMissionData(missionArray);
             
              } else {
                setMissionData([]);
                alert("No data found");
              }
            });
          }
        };
      
        fetchMissionData();
      }, [companyKey]);

      const handleMissionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMission(e.target.value);
      };

  const handleVolunteerSelection = (volunteerId: string) => {
    setSelectedVolunteers((prevSelectedVolunteers) => {
      if (prevSelectedVolunteers.includes(volunteerId)) {
        // If the volunteer is already selected, remove them
        return prevSelectedVolunteers.filter((id) => id !== volunteerId);
      } else {
        // If the volunteer is not selected, add them
        return [...prevSelectedVolunteers, volunteerId];
      }
    });
  };

 
  const addMissionToVolunteer = async (volunteerId: string, selectedMission: any) => {
    const volunteerRef = ref(
      db,
      `companies/${companyKey}/volunteers/${volunteerId}/volunteerInfo/volunteerMissions`
    );
  
    const volunteerSnapshot = await get(volunteerRef);
    const volunteerMissions = volunteerSnapshot.val();
  
    if (volunteerMissions) {
      // Check if the mission already exists in volunteerMissions
      const existingMission = Object.values(volunteerMissions).find(
        (mission: any) => mission === selectedMission
      );
  
      if (existingMission) {
        console.log("Mission already exists for the volunteer");
        return;
      }
    }
  
    const missionCount = volunteerMissions ? Object.keys(volunteerMissions).length : 0;
    const missionKey = missionCount.toString();
  
    const updatedVolunteerMissions = {
      ...(volunteerMissions || {}),
      [missionKey]: selectedMission,
    };
  
    await set(volunteerRef, updatedVolunteerMissions);
  };
  
  const handleAddMission: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    const volunteerId = event.currentTarget.dataset.volunteerId;
    if (volunteerId) {
      const volunteer = volunteerData.find((volunteer) => volunteer.id === volunteerId);
      const volunteerMissions = volunteer?.volunteerMissions;
      const existingMission = Array.isArray(volunteerMissions) && volunteerMissions.includes(selectedMission);
  
      if (existingMission) {
        setIsVisible(true);
        return;
      }
  
      addMissionToVolunteer(volunteerId, selectedMission);
    }
  };
  
  const handleCloseAlert = () => {
    setIsVisible(false);
  };

 
  return (
<div className={`bg-${isDarkMode ? 'gray-900' : 'gray-100'} p-4`}>
      <h2 className={`text-${isDarkMode ? 'white' : 'black'} text-2xl font-bold mb-4`}>Invite Volunteers to a Mission</h2>
      <h3 className={`text-${isDarkMode ? 'white' : 'black'} text-lg font-bold mb-2`}>Select Volunteers</h3>
      <ul className="mb-4">
      {volunteerData.map((volunteer) => (
  <li key={volunteer.id} className={`flex items-center mb-2 ${isDarkMode ? 'text-white' : 'text-black'}`}>
    <input
      type="checkbox"
      className="mr-2"
      checked={selectedVolunteers.includes(volunteer.id)}
      onChange={() => handleVolunteerSelection(volunteer.id)}
    />
    <span>{volunteer.volunteerName[0]}</span>
  </li>
))}
      </ul>
      <h3 className={`text-${isDarkMode ? 'white' : 'black'} text-lg font-bold mb-2`}>Available Missions</h3>
      <select
        value={selectedMission}
        onChange={handleMissionChange}
        className={`border border-${isDarkMode ? 'white' : 'gray-300'} rounded px-4 py-2 mb-4 bg-transparent ${isDarkMode ? 'text-white' : 'text-black'}`}
      >
        <option value="">Select a mission</option>
        {missionData.map((mission) => (
  <option key={mission.id} value={mission.id}>
    {mission.missionDetails.missionName}
  </option>
))}

      </select>
      {isVisible && (
      <Alert status="error">
        <AlertIcon />
        <Box>
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>
            Mission already exists for the volunteer
          </AlertDescription>
        </Box>
        <CloseButton
  className="ml-auto"
  onClick={handleCloseAlert}
/>
      </Alert>
    )}
      <Button color={"#9374FF"} 
       data-volunteer-id={selectedVolunteers} 
      onClick={handleAddMission}>
        Add Mission
      </Button>
  

    </div>
  );
};
export default AddVolunteerComponent;