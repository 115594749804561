import { SET_USER, CLEAR_USER,SET_CURRENT_USER } from '../Actions/userAction';

const initialState = null; // or set it to the initial user value

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
      case SET_CURRENT_USER:
        return action.payload;
    case CLEAR_USER:
      return null; // or set it to the initial user value
    default:
      return state;
  }
};

export default userReducer;
