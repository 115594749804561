// messagesReducer.js

import { ADD_MESSAGE, CLEAR_MESSAGES, SEND_MESSAGE, SET_MESSAGE_TEXT, SELECT_USER,ADD_SINGLE_MESSAGE } from '../Actions/action';

const initialState = {
  messages: [],
  messageText: "",
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return { ...state, messages: [...state.messages, ...action.payload] };
      case ADD_SINGLE_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload] }; // Single message
    case CLEAR_MESSAGES:
      return { ...state, messages: [] };
    case SEND_MESSAGE:
      return { ...state, messages: [...state.messages, action.payload] };
    case SET_MESSAGE_TEXT:
      return { ...state, messageText: action.payload };
    case SELECT_USER: // Add this case to handle the selected chat ID
      return { ...state, selectedChat: action.payload.chatId };
    default:
      return state;
  }
};

export const selectMessages = (state) => state.messages.messages;
export const selectMessageText = (state) => state.messages.messageText;


export default messageReducer;

