import React, { useEffect, useState } from 'react';
import { Box, Button, FormControl, HStack, Input, VStack,Text,Image,Avatar } from '@chakra-ui/react';
import { addDoc, collection, serverTimestamp, getFirestore,  increment, updateDoc, doc } from 'firebase/firestore';
import { MdArrowUpward,MdArrowDownward } from 'react-icons/md';
import auth from '../../../../../firebase';

interface ReplyType {
  id: string;
  content: string;
  createdAt: Date; // Use the appropriate type for your setup
  createdBy: string;
  parentReplyId?: string;
  upvotes: number;
  downvotes: number;
  user?: {
    firstName: string;
    profileURL: string;
  };
}

// Recursive component to render a reply and its nested replies
const Reply: React.FC<{ reply: ReplyType; allReplies: ReplyType[]; threadId: string; }> = ({ reply, allReplies, threadId }) => {
  const [nestedReplies, setNestedReplies] = useState<ReplyType[]>([]);
  const [showNestedReplyInput, setShowNestedReplyInput] = useState(false);
  const [newNestedReplyContent, setNewNestedReplyContent] = useState('');
  const db = getFirestore();
  
  const addNestedReply = async () => {
    if (!newNestedReplyContent.trim()) return;

    await addDoc(collection(db, 'threads', threadId, 'replies'), {
      content: newNestedReplyContent,
      createdAt: serverTimestamp(),
      createdBy: auth.currentUser?.uid, // make sure this is the correct way you access the user id
      parentReplyId: reply.id,
      upvotes: 0,
      downvotes: 0,
    });

    setNewNestedReplyContent('');
    setShowNestedReplyInput(false);
   
  };

  useEffect(() => {
    const nested = allReplies.filter(r => r.parentReplyId === reply.id);
    setNestedReplies(nested);
  }, [reply.id, allReplies]);

  const handleUpvote = async () => {
    const replyRef = doc(db, 'threads', threadId, 'replies', reply.id);
    await updateDoc(replyRef, {
      upvotes: increment(1),
    });
  };
  const renderNestedReplies = (replies: ReplyType[]) => {
    return replies.map(nestedReply => (
      <VStack key={nestedReply.id}  align="start" spacing={4} pl={4} borderLeft="2px solid grey" ml={4} mt={4}>
         {reply.user && (
            <Avatar src={reply.user.profileURL} name={reply.user.firstName} className="user-avatar" />
          )}
        <Reply reply={nestedReply} allReplies={allReplies} threadId={threadId} />
      </VStack>
    ));
  };

  const handleDownvote = async () => {
    const replyRef = doc(db, 'threads', threadId, 'replies', reply.id);
    await updateDoc(replyRef, {
      downvotes: increment(1),
    });
  };

  return (
    <VStack align="stretch" spacing={2}>
    <Box p={4}  >
    <HStack>
          {reply.user && (
            <Avatar src={reply.user.profileURL} name={reply.user.firstName} className="user-avatar" />
          )}
          <Text className="user-name">{reply.user?.firstName}</Text>
        </HStack>
      <Text>{reply.content}</Text>
      <HStack>
      <Button className='mb-4'
  onClick={handleUpvote}
  colorScheme={reply.upvotes > 0 ? "green" : "gray"} // Change color if upvoted
>
  <MdArrowUpward /> {reply.upvotes}
</Button>
        <Button onClick={handleDownvote} className='mb-4'  colorScheme={reply.upvotes > 0 ? "red" : "gray"}><MdArrowDownward /> {reply.downvotes}</Button>
      </HStack>
      {/* Render nested replies */}
      {nestedReplies.map(nestedReply => (
        <Box key={nestedReply.id} className='w-full h-full px-[30px] py-[21px] mb-4 z-5 relative flex flex-col rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:shadow-none' >
      {renderNestedReplies(nestedReplies)}
      <Button onClick={() => setShowNestedReplyInput(!showNestedReplyInput)}>Reply</Button>
      </Box>
      ))}
     
      {showNestedReplyInput && (
  <HStack>
    <Input
      value={newNestedReplyContent}
      onChange={(e) => setNewNestedReplyContent(e.target.value)}
      placeholder="Write a nested reply..."
    />
    <Button onClick={addNestedReply}>Send</Button>
  </HStack>
)}
    </Box>
  </VStack>
  );
};


export default Reply;
