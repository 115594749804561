// Assets
import Card from "components/card";
import Controller from "./Controller";
import {
  MdAcUnit,
  MdWifi,
  MdThermostat,
  MdOutlineLightbulb,
  MdOutlineLocationOn,
} from "react-icons/md";

const General = () => {
  return (
    <Card extra={"flex flex-col w-full h-full relative p-6 bg-white rounded-lg shadow-lg"}>
      {/* Header */}
      <h4 className="mt-4 mb-6 ml-1 text-2xl font-bold text-gray-800">
        General Controller
      </h4>
      <div className="mb-6 flex items-center gap-2">
        <MdOutlineLocationOn className="text-gray-600 text-xl" />
        <select
          className="bg-none text-lg font-bold text-gray-600"
          name=""
          id=""
        >
          <option value="dinner room">Dinner Room</option>
          <option value="living room">Living Room</option>
          <option value="kitchen">Kitchen</option>
        </select>
      </div>
      {/* Main */}
      <div className="grid grid-cols-2 gap-6">
        <Controller
          initial={false}
          text="Air Conditioner"
          onValue="ON"
          offValue="OFF"
          icon={<MdAcUnit className="text-4xl text-gray-600" />}
       
        />
        <Controller
          initial={true}
          text="Wi-Fi"
          onValue="Active"
          offValue="Inactive"
          icon={<MdWifi className="text-4xl text-gray-600" />}
     
        />
        <Controller
          initial={false}
          text="Thermostat"
          onValue="ON"
          offValue="OFF"
          icon={<MdThermostat className="text-4xl text-gray-600" />}
     
        />
        <Controller
          initial={false}
          text="Lights"
          onValue="ON"
          offValue="OFF"
          icon={<MdOutlineLightbulb className="text-4xl text-gray-600" />}
        />
      </div>
    </Card>
  );
};

export default General;
