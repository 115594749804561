import { Radio, RadioGroup } from "@chakra-ui/react";
import React, { Component, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { MdArrowDropUp } from "react-icons/md";
import Card from "components/card";

import { lineBarChartData,lineBarChartOptions,  } from "variables/charts";

import LineChart from "components/charts/LineChart";
// work in progress

function LineAreaChart() {
    return (
      <Card extra={"h-[381px] pb-8 px-6 pt-6"}>
        <div className="flex justify-between px-3 pt-1">
          <div className="flex items-center">
            <div className="flex flex-col">
              <p className="text-[34px] font-bold text-navy-700 dark:text-white">
                {" "}
                New Visitors
              </p>
              <p className="text-sm font-medium text-gray-600">
                {" "}
              </p>
            </div>
            <div className="ml-4 flex items-end pb-2">
              <MdArrowDropUp className="font-medium text-green-500" />
              <span className="text-sm font-bold text-green-500">+7.45%</span>
            </div>
          </div>
  
          <div className="flex items-center justify-center">
          
          </div>
        </div>
  
        {/* Charts */}
        <div className="flex h-full w-full flex-row sm:flex-wrap lg:flex-nowrap 2xl:overflow-hidden">
          <div className="h-full w-full">
            <LineChart
              chartData={lineBarChartData}
              chartOptions={lineBarChartOptions}
            />
          </div>
        </div>
      </Card>
    );
  }
export default LineAreaChart;
