import React, { useState, useEffect } from 'react';
import { getDatabase, ref as dbRef, get } from 'firebase/database';
import { Box, VStack, Image, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, HStack, useDisclosure, Avatar } from '@chakra-ui/react';
import { FaMapMarkerAlt, FaPhone, FaGlobe, FaIndustry, FaCalendarTimes } from 'react-icons/fa';

export const BusinessCircle = ({ business, onClick }) => (
   <VStack onClick={() => onClick(business)} cursor="pointer">
     <Avatar src={business.profileImage} size="xl" />
     <Text>{business.name}</Text>
   </VStack>
 );

export const BusinessDetailsModal = ({ business, isOpen, onClose }) => (
   <Modal isOpen={isOpen} onClose={onClose}>
   <ModalOverlay />
   <ModalContent className='dark:!bg-navy-800 dark:text-white'>
     <ModalHeader>{business.name}</ModalHeader>
     <ModalCloseButton />
     <ModalBody>
       <Image src={business.bannerImage} alt={business.name} borderRadius="md" />
       <VStack align="left" spacing={4} mt={4}>
         <Text className='text-md font-bold text-navy-700 dark:text-white'><strong>Description:</strong> {business.description}</Text>
         <HStack>
           <FaIndustry />
           <Text className='text-sm font-bold text-navy-700 dark:text-white'><strong>Industry:</strong> {business.industry}</Text>
         </HStack>
         <HStack>
         <FaMapMarkerAlt />
           <Text className='text-sm font-bold text-navy-700 dark:text-white'><strong>Location:</strong> {business.location}</Text>
         </HStack>
         <HStack>
           <FaCalendarTimes />
           <Text className='text-sm font-bold text-navy-700 dark:text-white'><strong>Founded:</strong> {business.founded}</Text>
         </HStack>
         <HStack>
           <FaGlobe />
           <Text className='text-sm font-bold text-navy-700 dark:text-white'><strong>Website:</strong> <a href={business.website} target="_blank" rel="noopener noreferrer">{business.website}</a></Text>
         </HStack>
       </VStack>
     </ModalBody>
     <ModalFooter>
       <Button className='dark:bg-brand-600 dark:hover:bg-brand-700 dark:focus:bg-brand-800' onClick={onClose}>
         Close
       </Button>
     </ModalFooter>
   </ModalContent>
 </Modal>
);

const BusinessesList = () => {
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchBusinesses = async () => {
      const realTimeDb = getDatabase();
      const businessRef = dbRef(realTimeDb, 'businesses/byName');
      const snapshot = await get(businessRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        const businessArray = Object.keys(data).map(key => data[key].info);
        setBusinesses(businessArray);
      }
    };

    fetchBusinesses();
  }, []);

  const handleBusinessClick = (business) => {
    setSelectedBusiness(business);
    onOpen();
  };

  return (
    <Box>
      <HStack overflowX="scroll" spacing={4}>
        {businesses.map((business, index) => (
          <BusinessCircle key={index} business={business} onClick={handleBusinessClick} />
        ))}
      </HStack>

      {selectedBusiness && (
        <BusinessDetailsModal
          business={selectedBusiness}
          isOpen={isOpen}
          onClose={() => {
            setSelectedBusiness(null);
            onClose();
          }}
        />
      )}
    </Box>
  );
};

export default BusinessesList;
