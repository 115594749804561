import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Textarea,
    useDisclosure, 
    Flex, Box, Image, Text
  } from '@chakra-ui/react';
import { Key, ReactElement, JSXElementConstructor, ReactFragment, ReactPortal, useState } from 'react';
import '../style/CommentsStyle.css'
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { UserPost } from './Post';
import { get, update,ref,getDatabase } from 'firebase/database';
import { formatDistanceToNow } from 'date-fns';
interface Comment {
  id: string;
  name: string;
  content: string;
  profileURL: string;
  authorId: string;
  likes: number; // New field for like count
  likedByCurrentUser: boolean; // New field to indicate if liked by current user
  timestamp: number;
}

  interface UserProfile {
    id: string;
    firstName: string;
    lastName:string
    profileURL: string;
    username: string;
    // Add any other user-related fields you need
  }
  interface PostProps {
    data: UserPost;
    usersData: Record<string, UserProfile>; 
  
  }
  


  interface CommentsModalProps {
    isOpen: boolean;
    onClose: () => void;
    comments: Comment[];
    onCommentSubmit: (comment: string) => void;
    usersData: Record<string, UserProfile>;
    postData: UserPost; // Assuming you're passing the entire post object
  }

const CommentsModal: React.FC<CommentsModalProps> = ({ isOpen, onClose, comments, onCommentSubmit,usersData,postData }) => {
  const postId = postData.id;
    const [newComment, setNewComment] = useState('');
    const db = getDatabase();
    const handleSubmit = (e: { preventDefault: () => void; }) => {
      e.preventDefault();
      onCommentSubmit(newComment);
      setNewComment('');
    };

    const handleLikeToggle = async (postId: string,commentId: any) => {
      const commentRef = ref(db, `posts/${postId}/comments/${commentId}`); // Adjust path as necessary
      get(commentRef).then((snapshot) => {
        if (snapshot.exists()) {
          const commentData = snapshot.val();
          let newLikes = commentData.likes || 0;
          // Toggle the like status based on current state
          const hasLiked = commentData.likedByCurrentUser || false;
          
          if (hasLiked) {
            newLikes -= 1; // Decrement if already liked
          } else {
            newLikes += 1; // Increment if not already liked
          }
          
          // Update the database
          update(commentRef, {
            likes: newLikes,
            likedByCurrentUser: !hasLiked,
          });
          
          // Optionally, update local state here to reflect the change immediately
          // This might involve setting a new state with the updated comments list
        }
      }).catch((error) => {
        console.error("Error updating likes:", error);
      });
    };

    const formatDate = (timestamp: number) => {
      // Convert the timestamp to a date object
      const date = new Date(timestamp);
      // Use 'date-fns' library to show how much time has passed since the comment was posted
      return formatDistanceToNow(date, { addSuffix: true });
    };
    
    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay className='flex w-0 bg-gray-200 dark:!bg-white/10 xl:w-px' />
      <ModalContent backgroundColor="gray.100" className='dark:bg-navy-700 dark:text-white'>
        <ModalHeader>Comments</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
        {comments.map((comment) => {
          const commentAuthorData = usersData[comment.authorId];
          return (
            <Flex key={comment.id} direction="column" mb={4} p={4} shadow="sm" bg="white" borderRadius="lg" className="comments-container dark:bg-navy-800 dark:shadow-md dark:border dark:border-gray-600">
              <Flex alignItems="start">
                <Image
                  src={commentAuthorData?.profileURL || 'default_profile_pic_url'}
                  borderRadius="full"
                  boxSize="40px"
                  mr={3}
                  alt={commentAuthorData?.firstName || 'User'}
                />
                <Box flex="1">
                  <Text fontWeight="bold">
                    {commentAuthorData?.firstName} {commentAuthorData?.lastName}
                  </Text>
                  <Text fontSize={'sm'} fontWeight={'light'} textColor={'lightgray'} >
          {formatDate(comment.timestamp)} {/* Call formatDate to display the timestamp */}
        </Text>
                  <Text fontSize="sm" mt={2}>{comment.content}</Text>
                </Box>
                <Button onClick={() => handleLikeToggle(postId, comment.id)} size="sm" variant="ghost" className="ml-auto">
                  {comment.likedByCurrentUser ? <IoHeart className="text-red-500" /> : <IoHeartOutline className="text-gray-400" />}
                  <span className="ml-2">{comment.likes || 0} likes</span>
                </Button>
              </Flex>
            </Flex>
          );
        })}
        <form onSubmit={handleSubmit}>
          <Textarea
            placeholder="Add a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            mt={4}
            backgroundColor="white"
            className='rounded-full px-4 text-sm text-navy-700 outline-none dark:bg-navy-800 dark:text-white'
          />
          <Button
            type="submit"
            mt={4}
            className="w-full rounded-full bg-brand-500 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300"
          >
            Post Comment
          </Button>
        </form>
        </ModalBody>
      </ModalContent>
    </Modal>
    );
  };
  
  export default CommentsModal;
  