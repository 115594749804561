import React, { useState, useEffect } from 'react';
import Balance from "./components/Balance";
import Invoice from "./components/Invoice";
import Market from "./components/Market";
import PaymentMethod from "./components/PaymentMethod";
import YourCard from "./components/YourCard";
import YourTransaction from "./components/YourTransaction";
import PaymentModal from '../settings/components/PaymentModal';
import { Button, useDisclosure, useToast } from '@chakra-ui/react';
import YourTransfers from './components/YourTransfer';
import { getAuth } from 'firebase/auth';
import { getDatabase, ref, onValue } from 'firebase/database';

const VolunteerBilling = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isBusiness, setIsBusiness] = useState(false);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const toast = useToast();
  const database = getDatabase();

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userRef = ref(database, 'volunteers/' + user.uid);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setIsBusiness(data?.businessName ? true : false);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [auth, database]);

  const updateBusinessStatus = (status: boolean | ((prevState: boolean) => boolean)) => {
    setIsBusiness(status);
    toast({
      title: "Business Account Status Updated",
      description: status ? "You have successfully upgraded to a business account." : "Business account upgrade failed.",
      status: status ? "success" : "error",
      duration: 9000,
      isClosable: true,
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="mt-3 w-full">
      <div>Coming soon...</div>
     
    </div>
  );
};

export default VolunteerBilling;
 {/* <div className="mb-5 grid grid-cols-1 gap-5 xl:grid-cols-3">
        {!isBusiness && (
          <Button onClick={onOpen}>Upgrade to Business Account</Button>
        )}
        <PaymentModal isOpen={isOpen} onClose={onClose} updateBusinessStatus={updateBusinessStatus} />
        <YourCard />
        <div className="h-full w-full rounded-[20px]">
          <Balance />
          <PaymentMethod />
        </div>
        <Invoice />
      </div>
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-3">
        <div>
          <YourTransaction />
        </div>
        <div>
          <Market />
        </div>
        <div>
          <YourTransfers />
        </div>
      </div> */}