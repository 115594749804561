import { MdModeEditOutline } from "react-icons/md";
import image1 from "assets/img/profile/image1.png";
import image2 from "assets/img/profile/image2.png";
import image3 from "assets/img/profile/image3.png";
import Card from "components/card";

type Mission = {
  id: string;
  missionDetails: {
  missionName: string;
    location: string;
    missionCategory: string;
    missionDescription: string;
    missionGoals: string;
    teamAndVolunteers: {
      teamContact: string;
      teamLeader: string;
      team: string;
      volunteerRequirements: string;
    };
    missionLogistics: {
      missionPartnerships: string;
      missionResources: string;
      missionStartDate:string
    };
  }
  };

type ProjectProps = {
  missionData?: Mission[];
};


const Project: React.FC<ProjectProps> = ({ missionData }) => {

  if (!missionData) missionData = [];


  const upcomingMissions = missionData.filter(mission => {
    const startDate = mission?.missionDetails?.missionLogistics?.missionStartDate;
    return startDate && new Date(startDate) > new Date();
  }).sort((a, b) => {
    const dateA = new Date(a?.missionDetails?.missionLogistics?.missionStartDate || 0);
    const dateB = new Date(b?.missionDetails?.missionLogistics?.missionStartDate || 0);
    return dateA.getTime() - dateB.getTime();
});

console.log(missionData)

  return (
   <Card extra={"w-full p-4 h-full"}>
  <div className="mb-8 w-full">
    <p className="text-xl font-bold text-navy-700 dark:text-white">
      All Missions
    </p>
    <p className="mt-2 text-base text-gray-600">
      Here you can find more details about your projects. Keep your Teams
      engaged by providing meaningful information.
    </p>
  </div>

  {missionData.map(mission => (
    <div key={mission.id} className="mt-3 flex w-full items-center justify-between rounded-2xl bg-white p-3 shadow-2xl shadow-gray-200 dark:!bg-navy-700 dark:shadow-none">
      <div className="flex items-center">
        <div className="">
          {/* Consider fetching an image for each mission or having a placeholder */}
          <img className="h-[83px] w-[83px] rounded-lg" src={image1} alt="" />
        </div>
        <div className="ml-4">
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {mission.missionDetails.missionName}
          </p>
          <p className="mt-2 text-sm text-gray-600">
          {mission.missionDetails.missionGoals}
            <a
              className="ml-1 font-medium text-brand-500 hover:text-brand-500 dark:text-white"
              href=" " // Consider providing a link to the mission details
            >
              See mission details
            </a>
          </p>
        </div>
      </div>
      <div className="mr-4 flex items-center justify-center text-gray-600 dark:text-white">
        <MdModeEditOutline />
      </div>
    </div>
  ))}
</Card>

  );
};

export default Project;
