import React from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Button, IconButton } from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';
import Card from 'components/card';

type OpenModalFunction = () => void;
type HandleNavigationFunction = (path: string) => void;

// Define the props for the component
interface NavigationMenuProps {
  openModal: OpenModalFunction;
  handleNavigation: HandleNavigationFunction;
}


const NavigationMenu: React.FC<NavigationMenuProps> = ({ openModal, handleNavigation }) => {
   return (
      <Card>
       <Menu>
       <MenuButton 
        as={Button} 
        rightIcon={<BiChevronDown />} 
        className='linear rounded-xl bg-brand-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200'
        size="md"
      rounded="full"
      px={5}
      py={3}
      bgGradient="linear(to-r, purple.500, purple.600)"
      color="white"
      position="relative"
      _after={{
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        boxShadow: '0 0 15px 2px rgba(130, 87, 229, 0.7)',
        borderRadius: 'full',
        filter: 'blur(10px)',
        zIndex: '-1',
      }}
      _hover={{
        bgGradient: "linear(to-r, purple.600, purple.700)",
        _after: {
          boxShadow: '0 0 20px 2px rgba(130, 87, 229, 0.9)',
        },
      }}
      _active={{
        bgGradient: "linear(to-r, purple.700, purple.800)",
      }}
      >
        Interactions
      </MenuButton>
      <MenuList className="mt-2 mb-2 text-sm font-bold text-navy-700 dark:text-white  dark:!bg-navy-800">
        <MenuItem className="mt-2 mb-2 text-sm font-bold text-navy-700 dark:text-white  dark:!bg-navy-800" onClick={openModal}>Create Mission</MenuItem>
        <MenuItem className="mt-2 mb-2 text-sm font-bold text-navy-700 dark:text-white  dark:!bg-navy-800" onClick={() => handleNavigation('/volunteer/forums')}>Forums</MenuItem>
        <MenuItem className="mt-2 mb-2 text-sm font-bold text-navy-700 dark:text-white  dark:!bg-navy-800"  onClick={() => handleNavigation('/volunteer/groups')}>Groups</MenuItem>
      </MenuList>
    </Menu>
    </Card>
  );
};

export default NavigationMenu;
