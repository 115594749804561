import React, { useEffect, useState } from "react";
import Card from "components/card";
import { ref as dbRef, get, update } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from '../../../../../../firebase';
import avatar from "assets/img/avatars/avatar11.png";
import defaultBanner from "assets/img/profile/banner.png";
import InputField from "components/fields/InputField";

interface BannerProps {
  companyKey: string;
  setCompanyData: React.Dispatch<React.SetStateAction<Partial<CompanyData>>>;
}

type CompanyData = {
  name: string;
  type: string;
  description?: string;
  location?: string;
  profileImage?: string;
  bannerImage?: string;
};

const Banner: React.FC<BannerProps> = ({ companyKey, setCompanyData }) => {
  const [localCompanyData, setLocalCompanyData] = useState<Partial<CompanyData>>({});
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);
  const [bannerImageFile, setBannerImageFile] = useState<File | null>(null);
  const storage = getStorage();

  useEffect(() => {
    const fetchCompanyData = async () => {
      const companyRef = dbRef(db, `companies/${companyKey}/data`);
      const snapshot = await get(companyRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setLocalCompanyData(data);
        setCompanyData(data);
      } else {
        console.warn("No data available");
      }
    };

    fetchCompanyData();
  }, [companyKey, setCompanyData]);

  const handleInputChange = (field: keyof CompanyData, value: string) => {
    setLocalCompanyData(prev => ({ ...prev, [field]: value }));
  };

  const handleFileChange = (field: 'profileImage' | 'bannerImage', file: File | null) => {
    if (field === 'profileImage') {
      setProfileImageFile(file);
    } else {
      setBannerImageFile(file);
    }
  };

  const handleSave = async () => {
    const companyRef = dbRef(db, `companies/${companyKey}/data`);
    let updatedData = { ...localCompanyData };

    if (profileImageFile) {
      const profileImageRef = storageRef(storage, `images/${companyKey}/profileImage`);
      await uploadBytes(profileImageRef, profileImageFile);
      const profileImageUrl = await getDownloadURL(profileImageRef);
      updatedData.profileImage = profileImageUrl;
    }

    if (bannerImageFile) {
      const bannerImageRef = storageRef(storage, `images/${companyKey}/bannerImage`);
      await uploadBytes(bannerImageRef, bannerImageFile);
      const bannerImageUrl = await getDownloadURL(bannerImageRef);
      updatedData.bannerImage = bannerImageUrl;
    }

    update(companyRef, updatedData)
      .then(() => {
        console.log("Data updated successfully");
        setCompanyData(updatedData); // Update the parent state
        setLocalCompanyData(updatedData); // Update the local state
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  return (
    <Card extra={"items-center w-full h-full p-[16px] bg-cover"}>
      <div className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
           style={{ backgroundImage: `url(${localCompanyData.bannerImage || defaultBanner})` }}>
        <input type="file" accept="image/*"
               onChange={(e) => handleFileChange('bannerImage', e.target.files ? e.target.files[0] : null)}
               className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover" />
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={localCompanyData.profileImage || avatar} alt="" />
          <input type="file" accept="image/*"
                 onChange={(e) => handleFileChange('profileImage', e.target.files ? e.target.files[0] : null)}
                 className="h-full w-full rounded-full" />
        </div>
      </div>

      <div className="mt-16 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {localCompanyData.name || 'Name Placeholder'}
        </h4>
        <InputField
            label='Username'
            placeholder="Company Name"
            id="username"
            type="text"
            value={localCompanyData.name || ''}
            onChange={(e) => handleInputChange('name', e.target.value)}
            
          />
        {/* <input type="text" value={localCompanyData.name || ''} placeholder="Company Name"
               onChange={(e) => handleInputChange('name', e.target.value)} className="mt-2 p-2 border rounded" /> */}
                <InputField
            label='Description'
            value={localCompanyData.description || ''} placeholder="Company Description"
            onChange={(e) => handleInputChange('description', e.target.value)}
            id='Company'
          />
               <InputField
            label='Location'
            value={localCompanyData.location || ''} placeholder="Location"
            onChange={(e) => handleInputChange('location', e.target.value)}
            id='Company'
          />
        {/* <textarea value={localCompanyData.description || ''} placeholder="Company Description"
                  onChange={(e) => handleInputChange('description', e.target.value)} className="mt-2 p-2 border rounded" /> */}
        {/* <input type="text" value={localCompanyData.location || ''} placeholder="Location"
               onChange={(e) => handleInputChange('location', e.target.value)} className="mt-2 p-2 border rounded" /> */}
      </div>

      <button onClick={handleSave} className="mt-4 linear flex items-center justify-center rounded-xl bg-brand-800 px-4 py-2 font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-900 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
        Save
      </button>
    </Card>
  );
};

export default Banner;
