import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue, query, orderByKey, startAfter, limitToFirst, get } from 'firebase/database';
import { getFirestore, collection, query as firestoreQuery, orderBy, limit, getDocs, where } from 'firebase/firestore';
import auth from '../../../firebase';
import MissionItem from './components/MissionItem';
import Post from './components/Post';
import CreatePost from './components/CreatePost';
import Filter from './components/Filter';
import BusinessCard from './components/BusinessCard';
import { Box } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import BusinessUpdate from './impactWave/components/BusinessUpdate';
import Search from './impactWave/toolsComponents/Search';

interface UserProfile {
  id: string;
  firstName: string;
  lastName: string;
  profileURL: string;
  username: string;
}

interface UpdateComment {
  id: string;
  authorId: string;
  profileURL: string;
  name: string;
  content: string;
  timestamp: number;
}

interface Update {
  id: string;
  title: string;
  description?: string;
  details?: string;
  type: string;
  businessName: string;
  businessLogo?: string;
  likes: number;
  comments?: Record<string, UpdateComment>;
  images?: string[];
  options?: { label: string; votes: number }[];
  question?: string;
}

const VolunteerDashboard = () => {
  const [posts, setPosts] = useState<any[]>([]);
  const [missions, setMissions] = useState<any[]>([]);
  const [filter, setFilter] = useState('Popular');
  const [combinedFeed, setCombinedFeed] = useState<any[]>([]);
  const [usersData, setUsersData] = useState<Record<string, UserProfile>>({});
  const [businesses, setBusinesses] = useState<any[]>([]);
  const [businessUpdates, setBusinessUpdates] = useState<Update[]>([]);
  const [lastFetchedPostKey, setLastFetchedPostKey] = useState<string | null>(null);

  const db = getDatabase();
  const firestore = getFirestore();
  const currentUser = auth.currentUser;
  const navigate = useNavigate();

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const fetchProfilePicture = async (userId: string) => {
    const userRef = ref(db, `volunteers/${userId}/profileURL`);
    const snapshot = await get(userRef);
    return snapshot.exists() ? snapshot.val() : 'default_profile_pic_url';
  };

  const handleSeeAllClick = () => {
    navigate('/volunteer/social-responsibility/market');
  };

  const fetchPostsAndComments = () => {
    const POSTS_LIMIT = 10;
    const startQueryCondition = lastFetchedPostKey
      ? startAfter(lastFetchedPostKey)
      : orderByKey();
    const postsQuery = query(ref(db, 'posts'), startQueryCondition, limitToFirst(POSTS_LIMIT));

    onValue(postsQuery, (snapshot) => {
      const postsPromises: Promise<any>[] = [];
      snapshot.forEach((childSnapshot) => {
        const key = childSnapshot.key;
        const data = childSnapshot.val();
        setLastFetchedPostKey(key);

        const profilePromise = fetchProfilePicture(data.authorId).then((profileURL) => {
          const commentsArray = data.comments
            ? Object.entries(data.comments).map(([commentKey, commentValue]) => {
                if (commentValue && typeof commentValue === 'object') {
                  return { id: commentKey, ...commentValue };
                }
                return null;
              }).filter(comment => comment !== null)
            : [];
          return { ...data, profileURL, comments: commentsArray, id: key };
        });

        postsPromises.push(profilePromise);
      });

      Promise.all(postsPromises).then((fetchedPosts) => {
        setPosts(fetchedPosts);
      });
    });
  };

  useEffect(() => {
    fetchPostsAndComments();
    const fetchMissions = async () => {
      const missionsColRef = collection(firestore, 'missions');
      const missionsQuery = firestoreQuery(missionsColRef, orderBy('startDate', 'asc'), limit(10));
      const querySnapshot = await getDocs(missionsQuery);
      setMissions(shuffleArray(querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }))));
    };

    fetchMissions();
  }, [db, firestore]);

  useEffect(() => {
    const fetchUserData = async () => {
      const usersRef = ref(db, 'volunteers');
      onValue(usersRef, (snapshot) => {
        if (snapshot.exists()) {
          const loadedUsersData: Record<string, UserProfile> = {};
          snapshot.forEach((childSnapshot) => {
            loadedUsersData[childSnapshot.key] = childSnapshot.val();
          });
          setUsersData(loadedUsersData);
        }
      });
    };

    fetchUserData();
  }, [db]);

  const fetchBusinesses = async () => {
    const businessesRef = ref(db, 'businesses/byName');
    onValue(businessesRef, async (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const fetchedBusinesses = Object.keys(data).map(key => {
          const businessInfo = data[key].info || {};
          return {
            id: key,
            name: businessInfo.name || 'No Name Provided',
            description: businessInfo.description || 'No Description Provided',
            profileImage: businessInfo.profileImage || 'https://via.placeholder.com/150',
            industry: businessInfo.industry || 'Unknown',
            website: businessInfo.website || 'No Website'
          };
        });
        setBusinesses(fetchedBusinesses);
      } else {
        setBusinesses([]);
      }
    });
  };

  useEffect(() => {
    fetchBusinesses();
  }, [db]);

  const handleFilterChange = (newFilter: string) => {
    setFilter(newFilter);
  };

  const fetchFollowedBusinesses = async () => {
    const userRef = ref(db, `follows/${currentUser?.uid}`);
    const snapshot = await get(userRef);
    return snapshot.exists() ? Object.values(snapshot.val()).map((follow: any) => follow.businessName) : [];
  };

  const fetchBusinessUpdatesForFollowed = async (followedBusinesses: string[]) => {
    const updates: Update[] = [];
    for (const businessName of followedBusinesses) {
      const cardsRef = ref(db, `businesses/byName/${businessName}/cards`);
      const snapshot = await get(cardsRef);
      if (snapshot.exists()) {
        const cards = snapshot.val();
        for (const [cardKey, cardValue] of Object.entries(cards)) {
          const cardData = cardValue as Update;
          updates.push({ ...cardData, id: cardKey, businessName });
        }
      }
    }
    return updates;
  };

  useEffect(() => {
    const fetchAndSetUpdates = async () => {
      const followedBusinesses = await fetchFollowedBusinesses();
      const updates = await fetchBusinessUpdatesForFollowed(followedBusinesses);
      setBusinessUpdates(updates);
    };

    fetchAndSetUpdates();
  }, [db, currentUser]);

  useEffect(() => {
    const updateCombinedFeed = () => {
      const combinedData = [...posts, ...missions, ...businessUpdates].sort((a, b) => {
        if (filter === 'Latest') {
          return b.timestamp - a.timestamp;
        } else if (filter === 'Popular') {
          return b.likes - a.likes;
        }
        return 0;
      });

      setCombinedFeed(shuffleArray(combinedData));
    };

    updateCombinedFeed();
  }, [posts, missions, businessUpdates, filter]);

  return (
    <div>
       <Box py={5}>
        <h4 className="mb-3.5 max-w-full text-md font-bold text-black dark:text-white sm:text-lg md:w-2/3 md:text-3xl md:leading-10 lg:w-11/25 xl:w-17/20 2xl:w-3/4 3xl:w-13/25">
          Companies near you changing the world
        </h4>
        <div className="flex overflow-x-auto space-x-5 px-5 scrollbar-hide">
          {businesses.map(business => (
            <BusinessCard key={business.id} business={business} />
          ))}
        </div>
        <button
          className="dark:active-bg-white-20 linear rounded-[20px] mt-4 bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10"
          onClick={handleSeeAllClick}
        >
          See all
        </button>
      </Box>
      {/* <Search missions={missions} groups={[]} /> */}
      <CreatePost currentUser={currentUser} />
      <Filter onFilterChange={handleFilterChange} />
     
      {combinedFeed.map(item => (
        item.missionName ? <MissionItem key={item.id} mission={item} /> :
        item.businessName ? <BusinessUpdate key={item.id} update={item} usersData={usersData} /> :
        <Post key={item.id} data={item} usersData={usersData} />
      ))}
    </div>
  );
};

export default VolunteerDashboard;
