import Default from "layouts/auth/types/Default";
import React from 'react';
import Homeprod from 'assets/img/auth/Homeprod.png';
import learn1 from 'assets/svg/Safehavenlogo.svg'
import InputField from "components/fields/InputField";
const LearnMore = () => {
  return (
  
          <div className="text-center">
            {/* Heading */}
            <h3 className="text-4xl font-bold gradient-text dark:text-white mb-4">
              Introducing Our Generative AI CRM
            </h3>

            {/* Description */}
            <p className="text-base text-gray-600 mb-4">
              Our CRM, powered by state-of-the-art Generative AI, offers unique insights and automation capabilities to drive your non-profit's growth. Whether it's predicting donor behavior, automating communication, or generating actionable insights from vast datasets, our CRM stands out in delivering results.
            </p>

            <p className="text-base text-gray-600 mb-4">
              With a focus on usability and performance, our platform ensures that non-profits of any size can harness the power of AI without the need for a dedicated technical team.
            </p>
                 <InputField
            variant="auth"
            extra="mb-3"
            label=""
            placeholder="Email address.."
            id="email"
            type="email"
          />
         <button 
    type="submit" 
    style={{
        background: "linear-gradient(90deg, #5c3c92, #786fa6)", 
        color: "white", 
        padding: "10px 20px",
        borderRadius: "5px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        border: "none",
        cursor: "pointer",
        transition: "transform 0.2s"
    }}
   
>


    Join Waitlist
</button>
        
          </div>
  )

   
}

export default LearnMore;
