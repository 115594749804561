import React, { useEffect, useState, useMemo, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { Container, ISourceOptions } from '@tsparticles/engine';
import { MdArrowRightAlt } from 'react-icons/md';
import NavbarAuth from './NavbarAuth';
import Footer from 'components/footer/Footer';
import WaitlistComponent from './WaitlistComponent';
import LP from 'assets/svg/Infopack/Landingpage1.png'
import './style.css';
import LearnMore from './LearnMore';
import Homeprod from 'assets/img/auth/Homeprod.png';
import Speed from 'assets/svg/Infopack/Speed.png';
import Contacts from 'assets/svg/Infopack/Contacts.png'
import InputField from 'components/fields/TextField';
import DemoImage2 from 'assets/svg/DemoImage2.png';
import { separator } from '@material-tailwind/react/types/components/breadcrumbs';
import { getDatabase, push,set,ref } from 'firebase/database';
import Card from 'components/card';
import { Divider } from '@chakra-ui/react';
import ad1 from 'assets/svg/Ad1.mp4'   
import ScrollVideo from './ScrollVideo';

const TitleComponent = () => {
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);
  const [init, setInit] = useState(false);
  const navigate = useNavigate();
  const db = getDatabase();
  const videoRef = useRef(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [message, setMessage] = useState('');


  function isValidEmail(email) {
    // Simple regex to validate email format
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  }

  function addToWaitlist(e) {
    e.preventDefault();

    if (!isValidEmail(email)) {
      setMessage('Please enter a valid email.');
      return;
    }

    const waitlistRef = ref(db, 'waitlist');
    const newWaitlistRef = push(waitlistRef);
    set(newWaitlistRef, {
      email: email,
      firstName: firstName,
      companyName: companyName,
    })
      .then(() => {
        console.log("Added to waitlist");
        setMessage("Successfully added to the waitlist!");
        setEmail('');
        setFirstName('');
        setCompanyName('');
      })
      .catch((error) => {
        console.error("Error adding to waitlist: ", error);
        setMessage("Error adding to the waitlist. Please try again.");
      });
  }
  const goToVolunteerPage = () => {
    navigate('/auth/volunteer');
  };

  useEffect(() => {
    initParticlesEngine(async (engine) => {
 
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options: ISourceOptions = useMemo(
    () => ({
      background: {
     
      },
      fpsLimit: 60,
      interactivity: {
        events: {
          onClick: { enable: false },
          onHover: { enable: false },
        },
      },
      particles: {
        color: { value: '#ffffff' },
        move: {
          direction: 'none',
          enable: true,
          outModes: { default: 'out' },
          random: true,
          speed: 6,
          straight: false,
        },
        number: {
          density: { enable: true },
          value: 80,
        },
        opacity: { value: 0.5 },
        shape: { type: 'circle' },
        size: { value: { min: 0.5, max: 2.5 } },
      },
      detectRetina: true,
    }),
    []
  );

  if (!init) return null;

  return (
    <div className="relative flex flex-col items-center justify-center p-8 md:p-8 max-w-full my-8">
      <Particles
        id="tsparticles"
        options={options}
        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '50%', zIndex: -1 }}
      />
      <div className="flex flex-col md:flex-row items-center justify-between w-full z-10">
        
        <div className="flex flex-col items-center md:items-start w-full md:w-1/2 mb-8 md:mb-0 px-4">
          <div className="text-left dark:text-white w-full mb-4">
            <h1 className="text-black font-bold text-2xl md:text-4xl lg:text-5xl mb-4 dark:text-white">
              The AI-first Social Responsibility Platforms
            </h1>
            <p className="text-gray-700 font-medium text-lg lg:text-xl mb-2 dark:text-white">
            HarmonyFlow pioneers a comprehensive AI ecosystem for amplifying individual contributions and enforcing corporate accountability.            </p>
            <Link to="/auth/business-harmony" className="mt-4">
              <button className="linear mt-4 w-auto rounded-full py-2 px-4 text-sm font-medium text-black border border-black bg-#000000 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:text-black dark:hover:bg-gray-200 dark:active:bg-gray-300 mr-2
              ">
                Explore AI for Business
              </button>
            </Link>
            <button className="linear mt-4 w-auto rounded-full py-2 px-4 text-sm font-medium text-black border border-black bg-#000000 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:text-black dark:hover:bg-gray-200 dark:active:bg-gray-300" onClick={() => setShowWaitlistModal(true)}>
              Request Demo
            </button>
            {showWaitlistModal && (
              <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50" onClick={() => setShowWaitlistModal(false)}>
                <div className="relative bg-white dark:bg-navy-800 rounded-lg p-6 max-w-lg w-full max-h-3/4 overflow-y-auto animate-fade-in" onClick={(e) => e.stopPropagation()}>
                  <button className="absolute top-2 right-2 text-gray-600 dark:text-gray-300" onClick={() => setShowWaitlistModal(false)}>
                    ×
                  </button>
                  <WaitlistComponent />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col items-center justify-center">
            <img src={LP} alt="Business Platform" className="full-width-image mb-4 mx-auto" />
          </div>
        </div>

      
        <div className="hidden md:block w-px bg-gray-300 mx-4 md:h-auto md:my-0 self-stretch"></div>  {/* Adjusted for full height in flex container */}


        <div className="flex flex-col items-center md:items-start w-full md:w-1/2 mb-8 md:mb-0 px-4">
          <div className="text-left dark:text-white w-full mb-4">
            <h1 className="text-black font-bold text-2xl md:text-4xl lg:text-5xl mb-4 dark:text-white">
              A Platform for Local Impact & Accountability
            </h1>
            <p className="text-gray-700 font-medium text-lg lg:text-xl mb-2 dark:text-white">
            By merging CSR management, business optimization, and volunteer coordination, we create a platform that connects users with their immediate environment.            </p>
            <Link to="/auth/volunteer" className="mt-4">
              <button className="linear mt-4 w-auto rounded-full py-2 px-4 text-sm font-medium text-black border border-black bg-#000000 transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:text-black dark:hover:bg-gray-200 dark:active:bg-gray-300">
                Explore a Better World
              </button>
            </Link>
          </div>
          <div className="flex justify-center w-full">
            <img src={DemoImage2} alt="Volunteer Platform" className="mb-4 mx-auto max-w-full max-h-[300px] object-contain" />
          </div>
        </div>
      </div>

     

      <div className="flex flex-wrap justify-center md:justify-start mt-4">
        <Link to="/auth/default/pricing" className="m-2">
          <button className="linear mt-4 w-auto rounded-full mr-2 bg-white py-2 px-4 text-sm font-medium text-black border border-black transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white dark:text-black dark:hover:bg-gray-200 dark:active:bg-gray-300">
            Explore Pricing
          </button>
        </Link>
      </div>

      <button onClick={goToVolunteerPage} className="mt-4 flex items-center text-black text-lg">
        <MdArrowRightAlt /> Create a free account today
      </button>
    </div>
  );
};

const InfoComponent = () => {
  return (
    <div className="flex flex-col items-center justify-center max-w-full px-4 md:px-0">
      <div className="text-center">
        <p className="text-purple-700 font-bold text-base lg:text-xl mb-4 mt-5">
          ADAPTIVE LEARNING ON YOUR FIRST DAY
        </p>
        <p className="text-gray-900 font-bold text-5xl mb-8 dark:text-white">
          The biggest time saver for your companies growth 🚀
          <span className="relative">
            <svg className="absolute w-20 h-2 bottom-[-10px] left-1/2 transform -translate-x-1/2" viewBox="0 0 272 8">
              {/* SVG content */}
            </svg>
            <svg className="absolute w-20 h-2 bottom-[-10px] left-1/2 transform -translate-x-1/2 filter blur-md" viewBox="0 0 272 8">
              {/* SVG content */}
            </svg>
          </span>
        </p>
        <p className="text-gray-600 font-medium text-base lg:text-lg w-12/12 mx-auto mb-4 dark:text-white">
          Experience a beautifully designed and user-friendly Business management platform with HarmonyFlow. Our application harnesses the power of Generative AI to analyze this data and provide valuable insights, allowing organizations to make data-driven decisions and optimize their strategies for maximum impact.
        </p>
        <img src={Homeprod} alt="Product" className="mb-4 w-[100vw] mx-auto" />
        <p className="text-purple-700 font-bold text-base lg:text-xl mb-4 mt-5">
          Collab-Hub Going Above & Beyond
        </p>
        <p className="text-gray-900 font-bold text-5xl mb-8 dark:text-white">
          Elevated Collaboration 🧑‍🤝‍🧑 Workflow Automations 🦾
        </p>
        <p className="text-gray-600 font-medium text-base lg:text-lg w-12/12 mx-auto mb-4 dark:text-white">
          HarmonyFlow enables seamless collaboration among your teams. From marketing to sales to customer support, everyone can work together efficiently, share information, and track progress within the platform. Enhance teamwork, streamline communication, and drive success together.
        </p>
        <img src={Contacts} alt="Product" className="mb-4 w-[100vw] mx-auto" />
        <p className="text-purple-700 font-bold text-base lg:text-xl mb-4 mt-5">
          One App to Rule Them All
        </p>
        <p className="text-gray-900 font-bold text-5xl mb-8 dark:text-white">
          ✨Seamless Integration with your Workflow✨ Out of the Box
          <span className="relative">
            <svg className="absolute w-20 h-2 bottom-[-10px] left-1/2 transform -translate-x-1/2" viewBox="0 0 272 8">
              {/* SVG content */}
            </svg>
            <svg className="absolute w-20 h-2 bottom-[-10px] left-1/2 transform -translate-x-1/2 filter blur-md" viewBox="0 0 272 8">
              {/* SVG content */}
            </svg>
          </span>
        </p>
        <p className="text-gray-600 font-medium text-base lg:text-lg w-12/12 mx-auto mb-4 dark:text-white">
          Safehaven's HarmonyFlow seamlessly integrates with your existing workflow and business processes. Connect HarmonyFlow with your favorite tools and systems to ensure a unified and synchronized CRM experience. Enjoy real-time updates, streamlined workflows, and enhanced productivity.
        </p>
      </div>
    </div>
  );
};

const LandingPageMain = () => {
  const ImageCard = ({ image, title, subtitle }) => (
    <div className="relative dark:bg-black overflow-hidden transition-transform transform hover:scale-105">
      <img src={image} alt="" className="w-full h-[370px] object-cover" />
      <div className="absolute inset-0 flex flex-col justify-end items-center bg-black bg-opacity-50 mb-8 lg:mb-1">
        <h2 className="text-black font-bold text-2xl lg:text-xl md:text-3xl lg:text-5xl mb-4 px-4 md:px-8 dark:text-white">{title}</h2>
        <p className="text-gray-600 font-medium text-base lg:text-xl w-4/5 mx-auto dark:text-white">{subtitle}</p>
      </div>
    </div>
  );

  const [showLearnMore, setShowLearnMore] = useState(false);
  const [showWaitlistModal, setShowWaitlistModal] = useState(false);

  return (
    <div className="dark:bg-navy-900 p-4 ">
      <div className="mb-10">
        <NavbarAuth />
      </div>
      <div className="flex flex-col items-center w-full">
        <div className="dark:bg-navy-900 rounded w-full">
          <TitleComponent />
          <div className="mx-auto mt-[96px] flex w-full max-w-screen-sm flex-col items-center justify-center text-center md:px-3" />
        </div>
       
        <div className="w-full mt-4 flex flex-col items-center">
          <ScrollVideo />
        </div>
       
       
        <div className="flex flex-wrap justify-center mt-8 mb-8">
          <Link to="/auth/default/pricing" className="m-2">
            <button className="bg-gradient-to-r from-purple-700 to-blue-500 text-white rounded-full py-4 px-8 text-sm md:text-base">
              See Pricing
            </button>
          </Link>
          <button className="border border-black text-black rounded-full py-4 px-8 text-sm md:text-base dark:text-white m-2" onClick={() => setShowWaitlistModal(true)}>
            Join Waitlist
          </button>
          {showWaitlistModal && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => setShowWaitlistModal(false)}
            >
              <div
                style={{
                  width: '75%',
                  maxHeight: '70%',
                  overflowY: 'auto',
                  backgroundColor: 'white',
                  padding: '20px',
                  borderRadius: '8px',
                }}
                onClick={(e) => e.stopPropagation()}
              >
                <WaitlistComponent />
              </div>
            </div>
          )}
        </div>
        <div>
          <article>
            <div className="mt-[7px] font-bold px-8 text-sm text-gray-400 md:px-9 md:text-base dark:text-white">
              {/* <InfoComponent /> */}
            </div>
          </article>
        </div>
      </div>
  
      {showLearnMore && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 9999,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => setShowLearnMore(false)}
        >
          <div
            style={{
              width: '80%',
              maxHeight: '80%',
              overflowY: 'auto',
              backgroundColor: 'white',
              padding: '20px',
              borderRadius: '8px',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {/* <LearnMore /> */}
          </div>
        </div>
      )}
      <div className="mx-auto max-w-screen-xl">
        <Footer />
      </div>
    </div>
);
};

export default LandingPageMain;