import React, { useEffect, useState } from "react";
import { getDatabase, ref, onValue, off } from "firebase/database";
import Banner from "./components/Banner";
import CompanyCardsDisplay from "./components/CompanyCardsDisplay";

interface PollOption {
  label: string;
  votes: number;
}

interface Card {
  images?: string[];
  title: string;
  description?: string;
  details?: string;
  type: string;
  options?: Record<string, PollOption>;
}

interface BusinessInfo {
  name: string;
  description: string;
  profileImage?: string;
  industry?: string;
  [key: string]: any;
}

interface CSRGoal {
  description: string;
  progress: number;
  title: string;
  achieved: boolean;
}

interface Mission {
  completed: boolean;
  impactScore: number;
  likes: number;
  comments: number;
  completionMonth: number;
}

interface Business {
  id: string;
  info: BusinessInfo;
  cards: { [key: string]: Card };
  csrGoals?: Record<string, CSRGoal>;
  missions?: Record<string, Mission>;
}

interface Businesses {
  [key: string]: Business;
}

interface BusinessCollectionProps {
  businesses: Businesses;
}

const BusinessCollection: React.FC<BusinessCollectionProps> = ({ businesses }) => {
  const [businessData, setBusinessData] = useState<{ [key: string]: Business }>({});
  const db = getDatabase();

  useEffect(() => {
    const businessRefs = Object.keys(businesses).map(businessKey => {
      const businessRef = ref(db, `businesses/byName/${businessKey}`);

      const listener = onValue(businessRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setBusinessData(prevState => ({
            ...prevState,
            [businessKey]: {
              id: businessKey,
              info: {
                ...data.info,
                name: data.info.name || businessKey, // Ensure name is populated
                profileImage: data.info.profileImage || '', // Ensure profileImage is populated
                industry: data.info.industry || 'Unknown', // Ensure industry is populated
              },
              cards: data.cards || {},
              csrGoals: data.csrGoals || {},
              missions: data.missions || {}
            }
          }));
        }
      });

      return { ref: businessRef, listener };
    });

    return () => {
      businessRefs.forEach(({ ref, listener }) => {
        off(ref, "value", listener);
      });
    };
  }, [businesses]);

  return (
    <div className="h-full w-full rounded-[20px] dark:bg-navy-900 dark:text-white">
      {Object.entries(businessData).map(([businessKey, business]) => (
        <React.Fragment key={businessKey}>
          <Banner business={business} />
          <h4 className="ml-[23px] mt-8 text-2xl font-bold text-navy-700 dark:text-white">
            Live Updates
          </h4>
          <div className="mt-4 dark:bg-navy-900 dark:text-white">
            {business.cards && Object.entries(business.cards).map(([cardId, card]) => {
              // Convert options to array if they exist and type is poll
              const optionsArray = card.type === "poll" && card.options ? Object.values(card.options) : undefined;
              return (
                <CompanyCardsDisplay
                  key={cardId}
                  businessId={businessKey}
                  cardId={cardId}
                  images={card.images} // Pass the entire array of images
                  title={card.title}
                  description={card.description || card.details || ''}
                  type={card.type}
                  options={optionsArray}
                  extra="mb-6"
                />
              );
            })}
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default BusinessCollection;
