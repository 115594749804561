import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update, push, set, remove } from 'firebase/database';
import {
  Button, Input, Box, FormControl, FormLabel, Select, VStack, HStack, Avatar, IconButton, Text
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';

const TeamManagement = ({ companyName }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [volunteers, setVolunteers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [role, setRole] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editingMemberId, setEditingMemberId] = useState(null);
  const db = getDatabase();

  useEffect(() => {
    if (!companyName) {
      console.error('Invalid companyName:', companyName);
      return;
    }

    const teamRef = ref(db, `businesses/byName/${companyName}/team`);
    onValue(teamRef, (snapshot) => {
      const data = snapshot.val();
      const teamArray = data ? Object.keys(data).map(key => ({
        id: key,
        ...data[key]
      })) : [];
      setTeamMembers(teamArray);
    });

    const volunteersRef = ref(db, 'volunteers');
    onValue(volunteersRef, (snapshot) => {
      const data = snapshot.val();
      const volunteersArray = data ? Object.keys(data).map(key => ({
        id: key,
        ...data[key]
      })) : [];
      setVolunteers(volunteersArray);
    });
  }, [db, companyName]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setSearchTerm('');
  };

  const handleAddOrUpdateMember = async () => {
    if (!selectedUser || !role) {
      console.error('User or role is not selected');
      return;
    }

    const teamRef = ref(db, `businesses/byName/${companyName}/team`);
    if (isEditing) {
      const memberRef = ref(db, `businesses/byName/${companyName}/team/${editingMemberId}`);
      await update(memberRef, { userId: selectedUser.id, username: selectedUser.username, role });
      setIsEditing(false);
      setEditingMemberId(null);
    } else {
      const newMemberRef = push(teamRef);
      await set(newMemberRef, { userId: selectedUser.id, username: selectedUser.username, role });
    }
    setSelectedUser(null);
    setRole('');
  };

  const handleEditMember = (member) => {
    setSelectedUser({ id: member.userId, username: member.username });
    setRole(member.role);
    setIsEditing(true);
    setEditingMemberId(member.id);
  };

  const handleDeleteMember = async (memberId) => {
    const memberRef = ref(db, `businesses/byName/${companyName}/team/${memberId}`);
    await remove(memberRef);
  };

  const filteredVolunteers = searchTerm
    ? volunteers.filter(vol => vol.username && vol.username.toLowerCase().includes(searchTerm.toLowerCase()))
    : [];

  return (
    <Box>
      <FormControl>
        <FormLabel>Search Users</FormLabel>
        <Input value={searchTerm} onChange={handleSearchChange} placeholder="Search by username" />
        {filteredVolunteers.length > 0 && (
          <Box mt={2}>
            {filteredVolunteers.map(vol => (
              <HStack key={vol.id} onClick={() => handleSelectUser(vol)} cursor="pointer">
                <Avatar src={vol.profileURL} size="sm" />
                <Text>{vol.username}</Text>
              </HStack>
            ))}
          </Box>
        )}
      </FormControl>
      <FormControl mt={4}>
        <FormLabel>Role</FormLabel>
        <Select value={role} onChange={(e) => setRole(e.target.value)} placeholder="Select role">
          <option value="admin">Admin</option>
          <option value="editor">Editor</option>
          <option value="viewer">Viewer</option>
        </Select>
      </FormControl>
      <Button mt={4} onClick={handleAddOrUpdateMember}>
        {isEditing ? 'Update Member' : 'Add Member'}
      </Button>
      <VStack mt={6} spacing={4} align="stretch">
        {teamMembers.map(member => (
          <HStack key={member.id} justify="space-between">
            <Avatar src={volunteers.find(vol => vol.id === member.userId)?.profileURL} size="sm" />
            <Text>{member.username}</Text>
            <Text>{member.role}</Text>
            <HStack>
              <IconButton icon={<EditIcon />} onClick={() => handleEditMember(member)} aria-label="Edit" />
              <IconButton icon={<DeleteIcon />} onClick={() => handleDeleteMember(member.id)} aria-label="Delete" />
            </HStack>
          </HStack>
        ))}
      </VStack>
    </Box>
  );
};

export default TeamManagement;
