import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { FcGoogle } from "react-icons/fc";
import Checkbox from "components/checkbox";
import { auth } from '../../../firebase'
import { createUserWithEmailAndPassword } from "firebase/auth";
import React, { useState } from 'react'
import { Navigate, useNavigate, NavLink } from "react-router-dom";
import { db } from "../../../firebase";
import { getDatabase, ref, child, onValue, get, set, update } from "firebase/database";
import { Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";



const SignUpDefault = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUseName] = useState("");

  const handleSignInClick = () => {
    navigate('/auth/sign-in/default');
  };
  const onSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault()
    const companiesRef = ref(db, "companies");
    const companiesSnapshot = await get(companiesRef);
    const companies = companiesSnapshot.val();

    let companyFound = true;
    let companyData: { key: any; } = null;

    for (const companyKey in companies) {
      const company = companies[companyKey];
      if (company.data.companyCode === companyCode) {
        companyFound = true;
        companyData = { ...company.data, key: companyKey };
        break;
      }
    }

    if (!companyFound) {
      console.log("Invalid Company Code");
      return;
    }

    // Company data is available in the companyData variable


    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential: { user: any; }) => {
        const user = userCredential.user;
        const companiesRef = ref(getDatabase(), "companies");
        const companiesSnapshot = await get(companiesRef);
        const companies = companiesSnapshot.val();
        const userRef = ref(getDatabase(), `companies/${companyData.key}/users/${user.uid}`);
        set(userRef, {
          email: user.email,
          userName: userName,
          firstName: firstName,
          lastName: lastName,
          companyCode: companyCode
        });
        navigate("/admin");
      });






  }

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign up section */}
          <div className="mt-[10vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
            Business Sign Up
            </h3>
            <p className="mt-[10px] ml-1 text-base text-gray-600">
              Enter your email, password and company ID to sign up!
            </p>
            <div className="mt-3">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                Not part of a Company?
              </span>
              <a
                href="/auth/sign-up/volunteer"
                className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
              >
                Join our ImpactWave Network For Free✨
              </a>
            </div>
            <br></br>


            <div className="mt-6 mb-4 flex items-center gap-3">
              <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
              <p className="text-base font-medium text-gray-600"> or </p>
              <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
            </div>
            <form onSubmit={onSubmit}>
              {/* user info */}
              <div className="mb-3 flex w-full items-center justify-center gap-4">
                <div className="w-1/2">

                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="First Name*"
                    placeholder="John"
                    id="firstname"
                    type="text"
                    value={firstName}
                    onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setFirstName(e.target.value)}
                  />
                </div>

                <div className="w-1/2">
                  <InputField
                    variant="auth"
                    extra="mb-3"
                    label="Last Name*"
                    placeholder="Doe"
                    id="lastname"
                    type="text"
                    value={lastName}
                    onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              {/* Email */}
              <InputField
                variant="auth"
                extra="mb-3"
                label="Email*"
                placeholder="mail@simmmple.com"
                id="email"
                type="email"
                value={email}
                onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setEmail(e.target.value)}
              />
              {/* Password */}
              <InputField

                variant="auth"
                extra="mb-3"
                label="Password*"
                placeholder="Min 8 characters"
                id="password"
                type="password"
                value={password}
                onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setPassword(e.target.value)}
              />
              <InputField
                variant="auth"
                extra="mb-3"
                label="Company Code*"
                placeholder="Enter company code"
                id="companyCode"
                type="text"
                value={companyCode}
                onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setCompanyCode(e.target.value)}
              />

              {/* Checkbox */}
              <div className="mt-4 flex items-center justify-between px-2">
                <div className="flex">
                  <Checkbox id="checkbox" />
                  <label
                    htmlFor="checkbox"
                    className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                  >
                    By creating an account means you agree to the Terms and
                    Conditions, and our Privacy Policy
                  </label>
                </div>
              </div>

              {/* button */}

              <button onClick={onSubmit} className="linear mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                Create my account
              </button>

              <div className="mt-3">
                <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                  Already a member?
                </span>
                <a
                  onClick={handleSignInClick}
                  className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                >
                  Sign In
                </a>
              </div>
            </form>
          </div>
        </div>
      }
      displayImage={true}
    />
  );
}


export default SignUpDefault;
