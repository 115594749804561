import React from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

function CircularProgress(props: {
  title: string | JSX.Element;
  percentage: number;
}) {
  const { title, percentage } = props;

  let pathColor = '#4318FF'; // Purple for scores above 50%
  let prompt = 'Making the world better';

  if (percentage < 25) {
    pathColor = '#FF0000'; // Red for scores below 25%
    prompt = 'Company needs to do more ';
  } else if (percentage < 50) {
    pathColor = '#FFD700'; // Yellow for scores between 25% and 50%
    prompt = 'Efforts are above average but not sustainable for planet';
  }

  return (
    <div style={{ width: 180, height: 230 }}> {/* Adjust width and height as needed */}
      <CircularProgressbarWithChildren
        value={percentage}
        styles={buildStyles({
          rotation: 0.25,
          strokeLinecap: 'round',
          textSize: '12px',  // Adjust text size for smaller component
          pathTransitionDuration: 0.5,
          pathColor: pathColor,
          textColor: '#1B2559',
          trailColor: '#E9EDF7',
          backgroundColor: pathColor,
        })}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center text-xs font-medium text-gray-600">
            {title}
          </div>
          <div className="text-lg font-bold text-navy-700 dark:text-white">
            {percentage}%
          </div>
        </div>
      </CircularProgressbarWithChildren>
      <div className="mt-2 text-sm text-black-600 dark:text-gray-400 text-center">
        {prompt}
      </div>
      <div className="mt-1 text-xs text-gray-500 dark:text-gray-300 text-center">
        Disclaimer: This score is based on the data we have collected and may not reflect the full scope of the company's efforts.
      </div>
    </div>
  );
}

export default CircularProgress;
