import React, { useEffect, useState } from 'react';
import { MdOutlineFavoriteBorder } from 'react-icons/md';
import { FaRegCommentDots } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';
import { Box, Text, Image, Textarea, useDisclosure, Button, HStack, VStack, Progress, Stack } from '@chakra-ui/react';
import { getDatabase, ref, update as Bupdate, push, onValue, get } from 'firebase/database';
import { getAuth } from 'firebase/auth';
import CommentsModal from '../../components/CommentsModal';
import CustomLightbox from '../../components/CustomLightBox';
import Card from 'components/card';

interface UpdateComment {
  id: string;
  authorId: string;
  profileURL: string;
  name: string;
  content: string;
  timestamp: number;
}

interface Update {
  id: string;
  title: string;
  description?: string;
  details?: string;
  type: string;
  businessName: string;
  businessLogo?: string;
  likes: number;
  comments?: Record<string, UpdateComment>;
  images?: string[];
  options?: { label: string; votes: number }[];
  question?: string;
}

interface BusinessUpdateProps {
  update: Update;
  usersData: Record<string, any>;
}

const BusinessUpdate: React.FC<BusinessUpdateProps> = ({ update, usersData }) => {
  const db = getDatabase();
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const [likeCount, setLikeCount] = useState(update.likes || 0);
  const [commentText, setCommentText] = useState('');
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentUserProfile, setCurrentUserProfile] = useState('');
  const [businessProfileImage, setBusinessProfileImage] = useState('');
  const [pollOptions, setPollOptions] = useState(update.options || []);
  const [hasVoted, setHasVoted] = useState(false); // To track if the user has already voted

  const handleLike = async () => {
    if (!currentUser) return;

    const updateRef = ref(db, `businesses/byName/${update.businessName}/cards/${update.id}`);
    const updatedLikes = likeCount + 1;
    setLikeCount(updatedLikes);

    try {
      await Bupdate(updateRef, { likes: updatedLikes });
    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };

  const handleCommentSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!commentText.trim() || !currentUser) return;

    const newComment: UpdateComment = {
      id: `comment_${Date.now()}`,
      authorId: currentUser.uid,
      profileURL: usersData[currentUser.uid]?.profileURL || '',
      name: `${usersData[currentUser.uid]?.firstName || ''} ${usersData[currentUser.uid]?.lastName || ''}`,
      content: commentText,
      timestamp: Date.now(),
    };

    const commentsRef = ref(db, `businesses/byName/${update.businessName}/cards/${update.id}/comments/${newComment.id}`);
    await Bupdate(commentsRef, newComment);
    setCommentText('');
  };

  const openLightboxAtIndex = (index: number) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  useEffect(() => {
    if (currentUser) {
      const currentUserRef = ref(db, `volunteers/${currentUser.uid}`);
      onValue(currentUserRef, (snapshot) => {
        const userData = snapshot.val();
        if (userData) {
          setCurrentUserProfile(userData.profileURL || 'default_profile_pic_url');
        }
      });
    }
  }, [currentUser, db]);

  useEffect(() => {
    const fetchBusinessProfileImage = async () => {
      const businessRef = ref(db, `businesses/byName/${update.businessName}/info/profileImage`);
      const snapshot = await get(businessRef);
      if (snapshot.exists()) {
        setBusinessProfileImage(snapshot.val());
      } else {
        setBusinessProfileImage('default_logo_url');
      }
    };

    fetchBusinessProfileImage();
  }, [update.businessName, db]);

  const totalVotes = pollOptions.reduce((total, option) => total + option.votes, 0);

  const handleVote = async (index: number) => {
    if (!currentUser || hasVoted) return;

    const updatedOptions = [...pollOptions];
    updatedOptions[index].votes += 1;

    setPollOptions(updatedOptions);
    setHasVoted(true);

    const updateRef = ref(db, `businesses/byName/${update.businessName}/cards/${update.id}`);
    await Bupdate(updateRef, { options: updatedOptions });
  };

  const commentsArray = update.comments ? Object.values(update.comments) : [];

  return (
    <Card extra={"w-full h-full px-[30px] py-[21px] mb-4  rounded-lg dark:text-white"}>
      <div className="flex w-full items-center justify-between">
        <div className="flex items-center gap-[13px]">
          <div className="h-14 w-14 rounded-full border-[3px] bg-white p-[2px]">
            <Image src={businessProfileImage} className="h-full w-full rounded-full" alt="" />
          </div>
          <div className="mt-px">
            <h5 className="mb-[4px] text-[17px] font-bold text-navy-700 dark:text-white">
              {update.businessName || "Unknown Business"}
            </h5>
          </div>
        </div>
      </div>

      <div className="mt-[44px] h-full w-full rounded-xl sm:h-full">
        <p className="mt-2 w-full text-base text-sm">{update.description || update.details}</p>
        {update.type === 'poll' && update.question && pollOptions.length > 0 && (
          <VStack spacing={4} mt={4} align="left">
            <Text fontWeight="bold" fontSize="lg" color="brand.700">{update.question}</Text>
            {pollOptions.map((option, index) => (
              <HStack key={index} justifyContent="space-between" w="full">
                <Button onClick={() => handleVote(index)} isDisabled={hasVoted} variant="ghost">
                  {option.label}
                </Button>
                <Progress
                  value={(option.votes / totalVotes) * 100}
                  size="sm"
                  colorScheme="green"
                  width="70%"
                  borderRadius="md"
                />
                <Text>{option.votes} Votes</Text>
              </HStack>
            ))}
          </VStack>
        )}
      </div>

      <div className="px-3">
        <div className="flex items-center justify-between py-[30px] dark:border-white/10">
          <div className="flex items-center gap-1 text-base font-medium text-navy-700 dark:text-white">
            <p className="text-lg" onClick={handleLike}>
              <MdOutlineFavoriteBorder />
            </p>
            <p>{likeCount.toLocaleString()} <span className="hidden md:inline">Likes</span></p>
          </div>
          <div className="flex items-center gap-1 text-base font-medium text-navy-700 dark:text-white">
            <p className="text-lg">
              <FaRegCommentDots />
            </p>
            <span>{commentsArray.length} {commentsArray.length === 1 ? 'comment' : 'comments'}</span>
          </div>
        </div>

        <div className="mt-2 w-full text-base text-sm">
          {commentsArray.length > 0 ? (
            commentsArray.slice(0, 4).map((comment) => {
              const commentAuthorData = usersData[comment.authorId];
              return (
                <div className="mt-6 flex w-full items-start gap-3" key={comment.id}>
                  <div className="h-[50px] w-[53px] flex-shrink-0 rounded-full mt-4">
                    <Image
                      src={commentAuthorData?.profileURL || 'default_profile_pic_url'}
                      alt={commentAuthorData?.firstName || 'User'}
                      className="h-full w-full rounded-full"
                    />
                  </div>
                  <div className="flex flex-grow flex-col">
                    <p className="text-navy-700 dark:text-white">
                      <span className="font-bold">
                        {commentAuthorData?.firstName || ''} {commentAuthorData?.lastName || ''}{" "}
                      </span>
                      {comment.content}
                    </p>
                    <p className="comment-timestamp">{new Date(comment.timestamp).toLocaleString()}</p>
                  </div>
                </div>
              );
            })
          ) : (
            <p className="text-gray-600">No comments yet</p>
          )}

          {commentsArray.length > 4 && (
            <p className="text-blue-500 cursor-pointer text-center" onClick={onOpen}>
              More
            </p>
          )}
        </div>

        <form onSubmit={handleCommentSubmit}>
          <div className="mt-6 flex w-full justify-between gap-3">
            <div className="h-[50px] w-[53px] rounded-full">
              <Image src={currentUserProfile || 'default_profile_pic_url'} className="h-full w-full rounded-full" alt="" />
            </div>
            <div className="flex h-[50px] w-full items-center rounded-full bg-lightPrimary pr-3  dark:!bg-navy-800 dark:text-white">
              <Textarea
                placeholder="Write your comment...."
                size="md"
                p={{ base: "2", md: "4" }}
                fontSize={{ base: "sm", md: "md" }}
                borderRadius="full"
                border="none"
                _focus={{ boxShadow: "none" }}
                resize="none"
                minH="unset"
                _placeholder={{ color: "navy.700", opacity: 1 }}
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <div className="flex items-center ">
                <button className="border-gray-600 dark:!border-none dark:bg-white/5" type="submit">
                  <FiSend />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <CustomLightbox
        isOpen={isLightboxOpen}
        onClose={onClose}
        images={update.images}
        initialIndex={currentImageIndex}
      />
    </Card>
  );
};

export default BusinessUpdate;
