import React, { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  VStack,
  Icon,
  Text,
  IconButton
} from "@chakra-ui/react";
import { FiSettings, FiCreditCard, FiBarChart2, FiBookOpen, FiArrowLeft, FiUsers, FiDollarSign } from 'react-icons/fi';

import BusinessForm from '../components/businessManagment/BusinessForm';
import CardsManager from '../components/businessManagment/CardManager';
import AnalyticsDashboard from '../components/businessManagment/AnalyticsDashboard';
import ImpactReports from '../components/businessManagment/ImpactReports';
import TeamManagement from './businessTools/TeamManagement';
import DonationSettings from '../components/businessManagment/DonationSettings';  // Import DonationSettings

const viewComponents = {
  businessForm: BusinessForm,
  cardsManager: CardsManager,
  analytics: AnalyticsDashboard,
  impactReports: ImpactReports,
  teamManagement: TeamManagement,
  donationSettings: DonationSettings  // Add DonationSettings
};

const viewButtons = [
  { key: 'businessForm', label: 'General Business Info', icon: FiSettings },
  { key: 'cardsManager', label: 'Manage Cards', icon: FiCreditCard },
  { key: 'analytics', label: 'Analytics', icon: FiBarChart2 },
  { key: 'impactReports', label: 'Impact Reports', icon: FiBookOpen },
  { key: 'teamManagement', label: 'Team Management', icon: FiUsers },
  { key: 'donationSettings', label: 'Donation Settings', icon: FiDollarSign }  // Add Donation Settings Button
];

const BusinessManagement = ({ companyName, businessId }) => {
  const [view, setView] = useState('menu');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOpen = (viewName) => {
    setView(viewName);
    onOpen();
  };

  const renderView = () => {
    if (view === 'menu') {
      return (
        <VStack spacing={4} align="stretch">
          {viewButtons.map(button => (
            <Button
              key={button.key}
              leftIcon={<Icon as={button.icon} />}
              justifyContent="flex-start"
              variant="ghost"
              onClick={() => handleOpen(button.key)}
            >
              <Text pl={2}>{button.label}</Text>
            </Button>
          ))}
        </VStack>
      );
    }

    const SpecificView = viewComponents[view];
    return <SpecificView companyName={companyName} businessId={businessId} />;  // Pass businessId to SpecificView
  };

  return (
    <React.Fragment>
      <Button className='mt-4 mb-4' onClick={() => handleOpen('menu')} leftIcon={<Icon as={FiSettings} />}>
        Manage Business
      </Button>
      <Drawer isOpen={isOpen} onClose={() => { onClose(); setView('menu'); }} size="full">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>
            <IconButton
              icon={<FiArrowLeft />}
              mr={2}
              variant="ghost"
              onClick={() => setView('menu')}
              aria-label="Back to menu"
            />
            Settings
          </DrawerHeader>
          <DrawerBody>
            {renderView()}
            {view !== 'menu' && <Button mt={4} onClick={() => setView('menu')}>Back to Menu</Button>}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </React.Fragment>
  );
};

export default BusinessManagement;
