export const subscriptionOptions = {
  basic: {
    pricePerMonth: 1,
    priceId: 'price_1PHYLZLqk5h9G7zkfNcvpCmy',  // Replace with your actual Stripe price ID
    description: "Basic subscription: $100/month"
  },
  pro: {
    pricePerMonth: 350,
    priceId: 'price_1PHYMALqk5h9G7zku3ZnM8Zu',  // Replace with your actual Stripe price ID
    description: "Pro subscription: $200/month"
  },
  ultra: {
    pricePerMonth: 1250,
    priceId: 'price_1PHYMSLqk5h9G7zkYdqwLc3A',  // Replace with your actual Stripe price ID
    description: "Ultra subscription: $300/month"
  }
};

export const durationOptions = {
  '1': {
    months: 1,
    description: "1 month"
  },
  '3': {
    months: 3,
    description: "3 months"
  },
  '12': {
    months: 12,
    description: "1 year"
  }
};
