import { useState } from 'react';
import { Box, Input, Image, Text, VStack, useDisclosure } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import SearchIcon from 'components/icons/SearchIcon';
interface Mission {
  id: string;
  missionName: string;
  location: string;
  fileUrl: string; // Assuming fileUrl is a URL string to the mission image
}

interface Group {
  id: string;
  title: string;
  logo: string; // Assuming logo is a URL string
}

interface SearchProps {
  missions: Mission[];
  groups: Group[];
}

const Search: React.FC<SearchProps> = ({ missions, groups }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState<(Mission | Group)[]>([]);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);

    if (term.trim() !== "") {
      const filteredMissions = missions.filter((mission) =>
        mission.missionName.toLowerCase().includes(term.toLowerCase()) ||
        mission.location.toLowerCase().includes(term.toLowerCase())
      );
      const filteredGroups = groups.filter((group) =>
        group.title.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredResults([...filteredMissions, ...filteredGroups]);
    } else {
      setFilteredResults([]);
    }
  };

  const handleSelect = (id: string, type: 'mission' | 'group') => {
    // Navigate based on type
    if (type === 'mission') {
      navigate(`/volunteer/missions/${id}`);
    } else {
      navigate(`/volunteer/group/${id}`);
    }
  };
  function isMission(result: any): result is Mission {
   return (result as Mission).fileUrl !== undefined;
 }
 function isGroup(result: any): result is Group {
   return (result as Group).logo !== undefined;
 }
  return (
    <VStack>
      <div className="flex h-[45px] w-full rounded-xl border !border-gray-200 dark:!border-none dark:!bg-navy-700 md:w-[870px] xl:w-full">
        <SearchIcon />
      <input
       
        placeholder="Search missions or groups"
        value={searchTerm}
        onChange={handleSearchChange}
        className=' className="w-full rounded-xl text-sm font-medium outline-none dark:bg-navy-700 dark:text-white pl-10"'
      />
      </div>
      {filteredResults.length > 0 && (
        <VStack align="start" w="full">
        {filteredResults.map((result) => (
  <Box
    key={result.id}
   display={'flex'}
    alignItems="center"
    p={2}
    cursor="pointer"
    onClick={() => handleSelect(result.id, isMission(result) ? 'mission' : 'group')}
  >
    <Image
      src={isMission(result) ? result.fileUrl : isGroup(result) ? result.logo : undefined}
      boxSize="50px"
      borderRadius="full"
      mr={2}
    />
    <Text>{isMission(result) ? result.missionName : isGroup(result) ? result.title : ''}</Text>
  </Box>
))}

        </VStack>
      )}
    </VStack>
  );
};
export default Search