import { useState, useEffect } from 'react';
import { Button, Box, Text, VStack, HStack, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card';
import OverallFlow from '../businessTools/OverallFlow';
import Traffic from '../businessTools/Traffic';
import SessionBadge from '../businessTools/SessionBadge';
import TimelineItem from '../businessTools/TimelineItem';
import { getFirestore, collection, onSnapshot } from 'firebase/firestore';
import { getDatabase } from 'firebase/database';

const AnalyticsDashboard = ({ companyName }) => {
  const [selectedMetric, setSelectedMetric] = useState('impact');
  const [impactMetrics, setImpactMetrics] = useState([]);
  const [outcomeMetrics, setOutcomeMetrics] = useState([]);

  useEffect(() => {
    const firestore = getFirestore();
    const realTimeDb = getDatabase();

    // Firestore listener for missions
    const missionsRef = collection(firestore, 'missions');
    const unsubscribeMissions = onSnapshot(missionsRef, (snapshot) => {
      let missions = [];
      snapshot.forEach(doc => missions.push({ id: doc.id, ...doc.data() }));
      computeImpactMetrics(missions);
      computeOutcomeMetrics(missions);
    });

    return () => {
      unsubscribeMissions();
    };
  }, []);

  const computeImpactMetrics = (missions) => {
    const completedActivities = missions.reduce((acc, mission) => {
      const completed = mission.formFields.filter(activity => activity.completed).length;
      return acc + completed;
    }, 0);
    const totalActivities = missions.reduce((acc, mission) => acc + mission.formFields.length, 0);

    const impactMetrics = [
      { detail: `${completedActivities}`, name: "Activities Completed", status: "Complete", bgTextColor: "bg-green-500" },
      { detail: `${totalActivities - completedActivities}`, name: "Activities Ongoing", status: "In Progress", bgTextColor: "bg-yellow-500" }
    ];
    setImpactMetrics(impactMetrics);
  };

  const computeOutcomeMetrics = (missions) => {
    const outcomeMetrics = missions.map(mission => {
      const startDate = new Date(mission.startDate.seconds * 1000);
      return {
        day: startDate.getDate(),
        weekday: startDate.toLocaleDateString('en-US', { weekday: 'short' }),
        hours: startDate.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
        title: mission.missionName,
        current: new Date() < startDate
      };
    });
    setOutcomeMetrics(outcomeMetrics);
  };

  const buttonColor = useColorModeValue('gray.300', 'gray.700');
  const activeButtonColor = useColorModeValue('purple.600', 'purple.400');
  const textColor = useColorModeValue('white', 'white');
  const cardBgColor = useColorModeValue('bg-white', 'bg-navy-700');

  return (
    <div>
      <HStack spacing={4} mb={4}>
        <Button 
          colorScheme={selectedMetric === 'impact' ? 'purple' : 'gray'} 
          bg={selectedMetric === 'impact' ? activeButtonColor : buttonColor}
          color={textColor}
          borderRadius="full"
          onClick={() => setSelectedMetric('impact')}
          _hover={{ bg: activeButtonColor }}
        >
          Impact
        </Button>
        <Button 
          colorScheme={selectedMetric === 'engagement' ? 'purple' : 'gray'} 
          bg={selectedMetric === 'engagement' ? activeButtonColor : buttonColor}
          color={textColor}
          borderRadius="full"
          onClick={() => setSelectedMetric('engagement')}
          _hover={{ bg: activeButtonColor }}
        >
          Engagement
        </Button>
        <Button 
          colorScheme={selectedMetric === 'outcome' ? 'purple' : 'gray'} 
          bg={selectedMetric === 'outcome' ? activeButtonColor : buttonColor}
          color={textColor}
          borderRadius="full"
          onClick={() => setSelectedMetric('outcome')}
          _hover={{ bg: activeButtonColor }}
        >
          Outcome
        </Button>
      </HStack>
      
        {selectedMetric === 'impact' && (
          <Box>
            {impactMetrics.map((metric, index) => (
              <SessionBadge key={index} {...metric} />
            ))}
            {outcomeMetrics.map((metric, index) => (
              <TimelineItem key={index} {...metric} />
            ))}
          </Box>
        )}
        {selectedMetric === 'engagement' && (
          <Box>
            <Traffic businessName={companyName} />
            {/* Add other engagement metric components here */}
          </Box>
        )}
        {selectedMetric === 'outcome' && (
          <Box>
            <OverallFlow />
          </Box>
        )}
  
    </div>
  );
};

export default AnalyticsDashboard;
